import { FeeDTO, FeeApi as FeesApi} from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";
import IFeeApi from "../ifee-api";

export class FeeApi extends BaseAPI implements IFeeApi {
  apiClient: FeesApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new FeesApi(configuration);
  }
  
  async getAllFee(): Promise<FeeDTO[]> {
    return await this.apiClient.getAllFee();
  }
}
