/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImprovementActionPrintedDTO,
    ImprovementActionPrintedDTOFromJSON,
    ImprovementActionPrintedDTOFromJSONTyped,
    ImprovementActionPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SecondBlockDTO
 */
export interface SecondBlockDTO {
    /**
     * 
     * @type {Array<ImprovementActionPrintedDTO>}
     * @memberof SecondBlockDTO
     */
    improvementActions?: Array<ImprovementActionPrintedDTO>;
}

export function SecondBlockDTOFromJSON(json: any): SecondBlockDTO {
    return SecondBlockDTOFromJSONTyped(json, false);
}

export function SecondBlockDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecondBlockDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'improvementActions': !exists(json, 'improvementActions') ? undefined : ((json['improvementActions'] as Array<any>).map(ImprovementActionPrintedDTOFromJSON)),
    };
}

export function SecondBlockDTOToJSON(value?: SecondBlockDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'improvementActions': value.improvementActions === undefined ? undefined : ((value.improvementActions as Array<any>).map(ImprovementActionPrintedDTOToJSON)),
    };
}

