import { Text, View } from "@react-pdf/renderer";
import { FC } from "react";
import { ColorsNewPdf } from "../../../../../../constants";
import { t } from "../../../book-v2/book-helpers";
import { TitleWithBlueBorder } from "../../../book-v2/components/titles/titles";
import { fontsNewPdf } from "../../../../../_common/fonts/fonts";
import { energyEfficiencyStyles } from "./energyEfficiency.styles";
import { EnergyEfficiencyCertificatePrintedDTO } from "../../../../../../clients";
import React from "react";
export const TableBuildingEnergyQualification: FC<{ data: EnergyEfficiencyCertificatePrintedDTO }> = ({ data }) => {
  const styles = energyEfficiencyStyles;

  const energyUse_CO2 = data.energyEfficiencyCertificateDTO?.co2Emissions;
  const energyUse_heating = data.energyEfficiencyCertificateDTO?.primaryEnergyNoRenewableConsumptionDTO;

  const buildingEfficiencyData = [
    {
      letter: "A",
      backgroundColor: ColorsNewPdf.table1_background_letter_A,
      energyUse: energyUse_heating?.qualifier === "A" ? energyUse_heating.amount?.toFixed(2) + " " + energyUse_heating.unitOfMeasure : "",
      globalEmissions: energyUse_CO2?.qualifier === "A" ? energyUse_CO2.amount?.toFixed(2) + " " + energyUse_CO2.unitOfMeasure : "",
    },
    {
      letter: "B",
      backgroundColor: ColorsNewPdf.table1_background_letter_B,
      energyUse: energyUse_heating?.qualifier === "B" ? energyUse_heating.amount?.toFixed(2) + " " + energyUse_heating.unitOfMeasure : "",
      globalEmissions: energyUse_CO2?.qualifier === "B" ? energyUse_CO2.amount?.toFixed(2) + " " + energyUse_CO2.unitOfMeasure : "",
    },
    {
      letter: "C",
      backgroundColor: ColorsNewPdf.table1_background_letter_C,
      energyUse: energyUse_heating?.qualifier === "C" ? energyUse_heating.amount?.toFixed(2) + " " + energyUse_heating.unitOfMeasure : "",
      globalEmissions: energyUse_CO2?.qualifier === "C" ? energyUse_CO2.amount?.toFixed(2) + " " + energyUse_CO2.unitOfMeasure : "",
    },
    {
      letter: "D",
      backgroundColor: ColorsNewPdf.table1_background_letter_D,
      energyUse: energyUse_heating?.qualifier === "D" ? energyUse_heating.amount?.toFixed(2) + " " + energyUse_heating.unitOfMeasure : "",
      globalEmissions: energyUse_CO2?.qualifier === "D" ? energyUse_CO2.amount?.toFixed(2) + " " + energyUse_CO2.unitOfMeasure : "",
    },
    {
      letter: "E",
      backgroundColor: ColorsNewPdf.table1_background_letter_E,
      energyUse: energyUse_heating?.qualifier === "E" ? energyUse_heating.amount?.toFixed(2) + " " + energyUse_heating.unitOfMeasure : "",
      globalEmissions: energyUse_CO2?.qualifier === "E" ? energyUse_CO2.amount?.toFixed(2) + " " + energyUse_CO2.unitOfMeasure : "",
    },
    {
      letter: "F",
      backgroundColor: ColorsNewPdf.table1_background_letter_F,
      energyUse: energyUse_heating?.qualifier === "F" ? energyUse_heating.amount?.toFixed(2) + " " + energyUse_heating.unitOfMeasure : "",
      globalEmissions: energyUse_CO2?.qualifier === "F" ? energyUse_CO2.amount?.toFixed(2) + " " + energyUse_CO2.unitOfMeasure : "",
    },
    {
      letter: "G",
      backgroundColor: ColorsNewPdf.table1_background_letter_G,
      energyUse: energyUse_heating?.qualifier === "G" ? energyUse_heating.amount?.toFixed(2) + " " + energyUse_heating.unitOfMeasure : "",
      globalEmissions: energyUse_CO2?.qualifier === "G" ? energyUse_CO2.amount?.toFixed(2) + " " + energyUse_CO2.unitOfMeasure : "",
    },
  ];
  return (
    <>
      <TitleWithBlueBorder title={t("pdf.energyEfficiency.buildingQualification")} />

      <View style={{ width: "80%", margin: "auto", marginTop: "10pt", marginBottom: "20pt" }}>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={[styles.bold_tableHeaderBuildingEfficiency, { fontFamily: fontsNewPdf.RobotoBold, width: "92pt" }]}>
            <Text style={{ width: "77pt" }}>{t("pdf.energyEfficiency.buildingEnergyQualification.header1")}</Text>
          </View>

          <View style={[styles.bold_tableHeaderBuildingEfficiency, { fontFamily: fontsNewPdf.RobotoRegular, width: "133pt" }]}>
            <Text>{t("pdf.energyEfficiency.buildingEnergyQualification.header2")}</Text>
          </View>

          <View style={[styles.bold_tableHeaderBuildingEfficiency, { fontFamily: fontsNewPdf.RobotoRegular, width: "133pt" }]}>
            <Text>{t("pdf.energyEfficiency.buildingEnergyQualification.header3")}</Text>
          </View>
        </View>

        {buildingEfficiencyData.map(({ letter, backgroundColor, energyUse, globalEmissions }, index) => (
          <View key={letter} style={{ display: "flex", flexDirection: "row" }}>
            <View style={[styles.headerRow, { backgroundColor, width: "92pt", color: ColorsNewPdf.white }]}>
              <Text>{t(`pdf.energyEfficiency.buildingEnergyQualification.${letter}`)}</Text>
            </View>
            <View
              style={[styles.headerRow, { backgroundColor: index % 2 === 0 ? ColorsNewPdf.grey_table_background : ColorsNewPdf.white, width: "133pt" }]}
            >
              <Text>{energyUse.replace(".", ",")}</Text>
            </View>
            <View
              style={[styles.headerRow, { backgroundColor: index % 2 === 0 ? ColorsNewPdf.grey_table_background : ColorsNewPdf.white, width: "133pt" }]}
            >
              <Text>{globalEmissions.replace(".", ",")}</Text>
            </View>
          </View>
        ))}
      </View>
    </>
  );
};
