import React from "react";
import { TableBuildingEnergyQualification } from "./tableBuildingEnergyQualification";
import { PartialQualification } from "./partialQualification";
import { PartialQualificationNoRenewable } from "./partialQualificationNoRenewable";
import { View } from "@react-pdf/renderer";
import { EnergyEfficiencyCertificatePrintedDTO } from "clients";

interface EnergyEfficiencyViewProps {
  energyCertificate?: EnergyEfficiencyCertificatePrintedDTO;
}

const EnergyEfficiencyView: React.FC<EnergyEfficiencyViewProps> = ({ energyCertificate }) => (
  <View style={{ width: "100%", display: "flex", flexDirection: "column" }}>
    {energyCertificate && <TableBuildingEnergyQualification data={energyCertificate} />}
    {energyCertificate?.energyEfficiencyCertificateDTO && <PartialQualification data={energyCertificate.energyEfficiencyCertificateDTO} />}
    {energyCertificate && <PartialQualificationNoRenewable data={energyCertificate} />}
  </View>
);

export default EnergyEfficiencyView;
