/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillAddressDTO,
    BillAddressDTOFromJSON,
    BillAddressDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface CreateBillAddressRequest {
    technicianId: string;
    billAddressDTO?: BillAddressDTO;
}

export interface DeleteBillAddressRequest {
    id: string;
}

export interface GetBillAddressesByTechnicianIdRequest {
    technicianId: string;
}

export interface UpdateBillAddressRequest {
    billAddressDTO?: BillAddressDTO;
}

/**
 * 
 */
export class BillAddressApi extends runtime.BaseAPI {

    /**
     * Create a new BillAddress.
     * Create a BillAddress.
     */
    async createBillAddressRaw(requestParameters: CreateBillAddressRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillAddressDTO>> {
        if (requestParameters.technicianId === null || requestParameters.technicianId === undefined) {
            throw new runtime.RequiredError('technicianId','Required parameter requestParameters.technicianId was null or undefined when calling createBillAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billAddresses/{technicianId}`.replace(`{${"technicianId"}}`, encodeURIComponent(String(requestParameters.technicianId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillAddressDTOToJSON(requestParameters.billAddressDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillAddressDTOFromJSON(jsonValue));
    }

    /**
     * Create a new BillAddress.
     * Create a BillAddress.
     */
    async createBillAddress(requestParameters: CreateBillAddressRequest, initOverrides?: RequestInit): Promise<BillAddressDTO> {
        const response = await this.createBillAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing BillAddress, deletes it, and returns the new object.
     * Delete an existing BillAddress by id.
     */
    async deleteBillAddressRaw(requestParameters: DeleteBillAddressRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillAddressDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBillAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billAddresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillAddressDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing BillAddress, deletes it, and returns the new object.
     * Delete an existing BillAddress by id.
     */
    async deleteBillAddress(requestParameters: DeleteBillAddressRequest, initOverrides?: RequestInit): Promise<BillAddressDTO> {
        const response = await this.deleteBillAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the billAddresses by technician ID.
     * Get all billAddresses objects by technician ID.
     */
    async getBillAddressesByTechnicianIdRaw(requestParameters: GetBillAddressesByTechnicianIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BillAddressDTO>>> {
        if (requestParameters.technicianId === null || requestParameters.technicianId === undefined) {
            throw new runtime.RequiredError('technicianId','Required parameter requestParameters.technicianId was null or undefined when calling getBillAddressesByTechnicianId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billAddresses/technician/{technicianId}`.replace(`{${"technicianId"}}`, encodeURIComponent(String(requestParameters.technicianId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BillAddressDTOFromJSON));
    }

    /**
     * Returns all the billAddresses by technician ID.
     * Get all billAddresses objects by technician ID.
     */
    async getBillAddressesByTechnicianId(requestParameters: GetBillAddressesByTechnicianIdRequest, initOverrides?: RequestInit): Promise<Array<BillAddressDTO>> {
        const response = await this.getBillAddressesByTechnicianIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing BillAddress object, updates it, and returns the BillAddress.
     * Update an existing BillAddress.
     */
    async updateBillAddressRaw(requestParameters: UpdateBillAddressRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillAddressDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billAddresses`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BillAddressDTOToJSON(requestParameters.billAddressDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillAddressDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing BillAddress object, updates it, and returns the BillAddress.
     * Update an existing BillAddress.
     */
    async updateBillAddress(requestParameters: UpdateBillAddressRequest, initOverrides?: RequestInit): Promise<BillAddressDTO> {
        const response = await this.updateBillAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
