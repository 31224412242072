import { decorate, inject, injectable } from 'inversify';
import AdminActionsRepository from './data/repositories/impl/admin-actions-repository';
import { BookRepository } from './data/repositories/impl/book-repository';
import BuildingRepository from './data/repositories/impl/building-repository';
import CadastralRepository from './data/repositories/impl/cadastral-repository';
import CollegeAdminActionRepository from './data/repositories/impl/college-admin-repository';
import CollegeAdministratorRepository from './data/repositories/impl/college-administrator-repository';
import CollegeRepository from './data/repositories/impl/college-repository';
import CommunityRepository from './data/repositories/impl/community-repository';
import ImprovementRepository from './data/repositories/impl/improvements-actions-repository';
import MapRepository from './data/repositories/impl/map-repository';
import { MultimediaRepository } from './data/repositories/impl/multimedia-repository';
import ParcelsRepository from './data/repositories/impl/parcel-repository';
import PropertiesRepository from './data/repositories/impl/properties-repository';
import ProvincesRepository from './data/repositories/impl/provinces-repository';
import { TechniciansRepository } from './data/repositories/impl/technicians-repository';
import TownRepository from './data/repositories/impl/town-repository';
import UserManagementRepository from './data/repositories/impl/user-management-repository';
import UsersProfileRepository from './data/repositories/impl/users-profile-repository';
import RepositoriesLoginRepository from './data/repositories/login-repository';
import LoginRepository from './infrastructure/data/repositories/login-repository';
import ConfigurationService from './services/configuration-service';
import LocalStorageService from './services/local-storage-service';
import MapService from './services/map-service';
import UserContextService from './services/user-context-service';
import BuildingDetailViewModel from './viewmodels/buildings/building-detail-view-model';
import BuildingViewModel from './viewmodels/buildings/building-view-model';
import DashboardViewModel from './viewmodels/dashboard/dashboard-view-model';
import LandingViewModel from './viewmodels/landing/landing-view-model';
import LoginViewModel from './viewmodels/login/login-view-model';
import ProfileViewModel from './viewmodels/profile/profile-view-model';
import RegisterViewModel from './viewmodels/register/register-view-model';
import ReportDetailViewModel from './viewmodels/report/report-detail-view-model';
import ReportViewModel from './viewmodels/report/report-view-model';
import ResetViewModel from './viewmodels/reset/reset-view-model';
import RolesViewModel from './viewmodels/roles-view-model';
import RootViewModel from './viewmodels/root-view-model';
import UserDetailViewModel from './viewmodels/users/user-detail-view-model';
import UsersViewModel from './viewmodels/users/users-view-model';
import MaintenanceViewModel from './viewmodels/maintenance/maintenance-view-model';
import PaymentGatewayRepository from './data/repositories/impl/payment-gateway-repository';
import FeeRepository from './data/repositories/impl/fee-repository';

//view models
decorate(injectable(), DashboardViewModel);
decorate(injectable(), ReportViewModel);
decorate(injectable(), ReportDetailViewModel);
decorate(injectable(), LoginViewModel);
decorate(injectable(), RegisterViewModel);
decorate(injectable(), LandingViewModel);
decorate(injectable(), RolesViewModel);
decorate(injectable(), UsersViewModel);
decorate(injectable(), BuildingViewModel);
decorate(injectable(), ResetViewModel);
decorate(injectable(), ProfileViewModel);
decorate(injectable(), MaintenanceViewModel);
decorate(injectable(), RootViewModel);
//services
decorate(injectable(), MapService);
decorate(injectable(), ConfigurationService);
decorate(injectable(), UserContextService);
decorate(injectable(), LocalStorageService);
//repositories
decorate(injectable(), LoginRepository);
decorate(injectable(), RepositoriesLoginRepository);
decorate(injectable(), MapRepository);
decorate(injectable(), UsersProfileRepository);
decorate(injectable(), BuildingRepository);
decorate(injectable(), ParcelsRepository);
decorate(injectable(), CommunityRepository);
decorate(injectable(), ProvincesRepository);
decorate(injectable(), BookRepository);
decorate(injectable(), ImprovementRepository);
decorate(injectable(), UserManagementRepository);
decorate(injectable(), TownRepository);
decorate(injectable(), CollegeRepository);
decorate(injectable(), MultimediaRepository);
decorate(injectable(), CadastralRepository);
decorate(injectable(), PropertiesRepository);
decorate(injectable(), TechniciansRepository);
decorate(injectable(), CollegeAdminActionRepository);
decorate(injectable(), CollegeAdministratorRepository);
decorate(injectable(), AdminActionsRepository);
decorate(injectable(), PaymentGatewayRepository);
decorate(injectable(), FeeRepository);

decorate(inject(UsersProfileRepository) as any, UsersViewModel, 0);
decorate(inject(UserDetailViewModel.Factory) as any, UsersViewModel, 1);

decorate(inject(BuildingRepository) as any, BuildingViewModel, 0);
decorate(inject(BuildingDetailViewModel.Factory) as any, BuildingViewModel, 1);

decorate(inject(ConfigurationService) as any, ReportViewModel, 0);
decorate(inject(BuildingRepository) as any, ReportViewModel, 1);
decorate(inject(ParcelsRepository) as any, ReportViewModel, 2);
decorate(inject(CommunityRepository) as any, ReportViewModel, 3);
decorate(inject(ProvincesRepository) as any, ReportViewModel, 4);
decorate(inject(BookRepository) as any, ReportViewModel, 5);
decorate(inject(ImprovementRepository) as any, ReportViewModel, 6);
decorate(inject(MultimediaRepository) as any, ReportViewModel, 7);
decorate(inject(UserManagementRepository) as any, ReportViewModel, 8);
decorate(inject(TownRepository) as any, ReportViewModel, 9);
decorate(inject(TechniciansRepository) as any, ReportViewModel, 10);
decorate(inject(ReportDetailViewModel.Factory) as any, ReportViewModel, 11);
decorate(inject(CadastralRepository) as any, ReportViewModel, 12);
decorate(inject(PropertiesRepository) as any, ReportViewModel, 13);
decorate(inject(PaymentGatewayRepository) as any, ReportViewModel, 14);

decorate(inject(UsersProfileRepository) as any, ProfileViewModel, 0);
decorate(inject(BookRepository) as any, ProfileViewModel, 1);
decorate(inject(TechniciansRepository) as any, ProfileViewModel, 2);
decorate(inject(UserManagementRepository) as any, ProfileViewModel, 3);
decorate(inject(CollegeAdminActionRepository) as any, ProfileViewModel, 4)
decorate(inject(CollegeAdministratorRepository) as any, ProfileViewModel, 5)
decorate(inject(AdminActionsRepository) as any, ProfileViewModel, 6)
decorate(inject(PaymentGatewayRepository) as any, ProfileViewModel, 7);

decorate(inject(DashboardViewModel) as any, RootViewModel, 8);
decorate(inject(RolesViewModel) as any, RootViewModel, 1);
decorate(inject(ReportViewModel) as any, RootViewModel, 2);
decorate(inject(UsersViewModel) as any, RootViewModel, 3);
decorate(inject(MaintenanceViewModel) as any, RootViewModel, 4);
decorate(inject(ProfileViewModel) as any, RootViewModel, 5);
decorate(inject(LoginViewModel) as any, RootViewModel, 6);
decorate(inject(RegisterViewModel) as any, RootViewModel, 7);
decorate(inject(ResetViewModel) as any, RootViewModel, 0);
decorate(inject(LandingViewModel) as any, RootViewModel, 9);

decorate(inject(CommunityRepository) as any, RootViewModel, 10);
decorate(inject(ProvincesRepository) as any, RootViewModel, 11);
decorate(inject(CollegeRepository) as any, RootViewModel, 12);
decorate(inject(UserContextService) as any, RootViewModel, 13);
decorate(inject(ConfigurationService) as any, RootViewModel, 14);
decorate(inject(TownRepository) as any, RootViewModel, 15);
decorate(inject(TechniciansRepository) as any, RootViewModel, 16);
decorate(inject(FeeRepository) as any, RootViewModel, 17);

decorate(inject(MapRepository) as any, MapService, 0);
decorate(inject(MapRepository) as any, DashboardViewModel, 0);

decorate(inject(LoginRepository) as any, LoginViewModel, 0);
decorate(inject(UserContextService) as any, LoginViewModel, 1);
decorate(inject(UserManagementRepository) as any, LoginViewModel, 2);
decorate(inject(TownRepository) as any, LoginViewModel, 3);

decorate(inject(UserManagementRepository) as any, RegisterViewModel, 0);
decorate(inject(TownRepository) as any, RegisterViewModel, 1);

decorate(inject(LocalStorageService) as any, UserContextService, 0);
