/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminParticipationListItemDTO,
    AdminParticipationListItemDTOFromJSON,
    AdminParticipationListItemDTOToJSON,
    BasicVoucherFilter,
    BasicVoucherFilterFromJSON,
    BasicVoucherFilterToJSON,
    BookDTO,
    BookDTOFromJSON,
    BookDTOToJSON,
    BookFilterDTO,
    BookFilterDTOFromJSON,
    BookFilterDTOToJSON,
    CustomAdminParticipationListItemDTOPageDTO,
    CustomAdminParticipationListItemDTOPageDTOFromJSON,
    CustomAdminParticipationListItemDTOPageDTOToJSON,
    ExtendedSignupTechnician,
    ExtendedSignupTechnicianFromJSON,
    ExtendedSignupTechnicianToJSON,
    FilterDTO,
    FilterDTOFromJSON,
    FilterDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    PaymentDTO,
    PaymentDTOFromJSON,
    PaymentDTOToJSON,
    StatisticsDTO,
    StatisticsDTOFromJSON,
    StatisticsDTOToJSON,
    TechnicianDTO,
    TechnicianDTOFromJSON,
    TechnicianDTOToJSON,
    TechnicianListItemDTO,
    TechnicianListItemDTOFromJSON,
    TechnicianListItemDTOToJSON,
    VoucherFilter,
    VoucherFilterFromJSON,
    VoucherFilterToJSON,
    VoucherListItemDTO,
    VoucherListItemDTOFromJSON,
    VoucherListItemDTOToJSON,
    VouchersPageDTO,
    VouchersPageDTOFromJSON,
    VouchersPageDTOToJSON,
} from '../models';

export interface CreateFreeVoucherForCredentialsIdRequest {
    credentialsId: string;
}

export interface DeleteUserForAdminRequest {
    id: string;
}

export interface EditUserForAdminRequest {
    id: string;
    technicianDTO?: TechnicianDTO;
}

export interface GetAllVouchersRequest {
    basicVoucherFilter?: BasicVoucherFilter;
}

export interface GetAllVouchersFilteredRequest {
    voucherFilter?: VoucherFilter;
}

export interface GetBookByPropertyCodeRequest {
    propertyReference: string;
    province: string;
}

export interface GetBooksByFilterRequest {
    bookFilterDTO?: BookFilterDTO;
}

export interface GetBooksByTechniciansForAdminRequest {
    requestBody?: Array<string>;
}

export interface GetBooksByTechniciansForAdminPaginatedRequest {
    limit: number;
    offset: number;
    requestBody?: Array<string>;
}

export interface GetStatisticsByTechnicianForAdminRequest {
    requestBody?: Array<string>;
}

export interface GetUserByIdForAdminRequest {
    id: string;
}

export interface GetUserListForAdminByFilterRequest {
    filterDTO?: FilterDTO;
}

/**
 * 
 */
export class AdminActionsApi extends runtime.BaseAPI {

    /**
     * Creates free voucher for credentials id
     */
    async createFreeVoucherForCredentialsIdRaw(requestParameters: CreateFreeVoucherForCredentialsIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PaymentDTO>> {
        if (requestParameters.credentialsId === null || requestParameters.credentialsId === undefined) {
            throw new runtime.RequiredError('credentialsId','Required parameter requestParameters.credentialsId was null or undefined when calling createFreeVoucherForCredentialsId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/vouchers/{credentialsId}`.replace(`{${"credentialsId"}}`, encodeURIComponent(String(requestParameters.credentialsId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentDTOFromJSON(jsonValue));
    }

    /**
     * Creates free voucher for credentials id
     */
    async createFreeVoucherForCredentialsId(requestParameters: CreateFreeVoucherForCredentialsIdRequest, initOverrides?: RequestInit): Promise<PaymentDTO> {
        const response = await this.createFreeVoucherForCredentialsIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete user for admin
     * Delete user for admin
     */
    async deleteUserForAdminRaw(requestParameters: DeleteUserForAdminRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserForAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Delete user for admin
     * Delete user for admin
     */
    async deleteUserForAdmin(requestParameters: DeleteUserForAdminRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.deleteUserForAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit user by id for admin
     * Edit user by id for admin
     */
    async editUserForAdminRaw(requestParameters: EditUserForAdminRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editUserForAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TechnicianDTOToJSON(requestParameters.technicianDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Edit user by id for admin
     * Edit user by id for admin
     */
    async editUserForAdmin(requestParameters: EditUserForAdminRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.editUserForAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all vouchers
     */
    async getAllVouchersRaw(requestParameters: GetAllVouchersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<VoucherListItemDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/vouchers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BasicVoucherFilterToJSON(requestParameters.basicVoucherFilter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VoucherListItemDTOFromJSON));
    }

    /**
     * Get all vouchers
     */
    async getAllVouchers(requestParameters: GetAllVouchersRequest, initOverrides?: RequestInit): Promise<Array<VoucherListItemDTO>> {
        const response = await this.getAllVouchersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all vouchers successful filtered
     */
    async getAllVouchersFilteredRaw(requestParameters: GetAllVouchersFilteredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VouchersPageDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/vouchers/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VoucherFilterToJSON(requestParameters.voucherFilter),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VouchersPageDTOFromJSON(jsonValue));
    }

    /**
     * Get all vouchers successful filtered
     */
    async getAllVouchersFiltered(requestParameters: GetAllVouchersFilteredRequest, initOverrides?: RequestInit): Promise<VouchersPageDTO> {
        const response = await this.getAllVouchersFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a book by property code.
     * Return a book by property code.
     */
    async getBookByPropertyCodeRaw(requestParameters: GetBookByPropertyCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BookDTO>> {
        if (requestParameters.propertyReference === null || requestParameters.propertyReference === undefined) {
            throw new runtime.RequiredError('propertyReference','Required parameter requestParameters.propertyReference was null or undefined when calling getBookByPropertyCode.');
        }

        if (requestParameters.province === null || requestParameters.province === undefined) {
            throw new runtime.RequiredError('province','Required parameter requestParameters.province was null or undefined when calling getBookByPropertyCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/books/property/{propertyReference}/province/{province}`.replace(`{${"propertyReference"}}`, encodeURIComponent(String(requestParameters.propertyReference))).replace(`{${"province"}}`, encodeURIComponent(String(requestParameters.province))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookDTOFromJSON(jsonValue));
    }

    /**
     * Get a book by property code.
     * Return a book by property code.
     */
    async getBookByPropertyCode(requestParameters: GetBookByPropertyCodeRequest, initOverrides?: RequestInit): Promise<BookDTO> {
        const response = await this.getBookByPropertyCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books by filter.
     * Get books by filter.
     */
    async getBooksByFilterRaw(requestParameters: GetBooksByFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AdminParticipationListItemDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/booksByFilter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BookFilterDTOToJSON(requestParameters.bookFilterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminParticipationListItemDTOFromJSON));
    }

    /**
     * Get books by filter.
     * Get books by filter.
     */
    async getBooksByFilter(requestParameters: GetBooksByFilterRequest, initOverrides?: RequestInit): Promise<Array<AdminParticipationListItemDTO>> {
        const response = await this.getBooksByFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books for technicians.
     * Get books for technicians.
     */
    async getBooksByTechniciansForAdminRaw(requestParameters: GetBooksByTechniciansForAdminRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AdminParticipationListItemDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/books`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminParticipationListItemDTOFromJSON));
    }

    /**
     * Get books for technicians.
     * Get books for technicians.
     */
    async getBooksByTechniciansForAdmin(requestParameters: GetBooksByTechniciansForAdminRequest, initOverrides?: RequestInit): Promise<Array<AdminParticipationListItemDTO>> {
        const response = await this.getBooksByTechniciansForAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books by technicians paginated.
     * Get books by technicians paginated.
     */
    async getBooksByTechniciansForAdminPaginatedRaw(requestParameters: GetBooksByTechniciansForAdminPaginatedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomAdminParticipationListItemDTOPageDTO>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getBooksByTechniciansForAdminPaginated.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getBooksByTechniciansForAdminPaginated.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/books/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomAdminParticipationListItemDTOPageDTOFromJSON(jsonValue));
    }

    /**
     * Get books by technicians paginated.
     * Get books by technicians paginated.
     */
    async getBooksByTechniciansForAdminPaginated(requestParameters: GetBooksByTechniciansForAdminPaginatedRequest, initOverrides?: RequestInit): Promise<CustomAdminParticipationListItemDTOPageDTO> {
        const response = await this.getBooksByTechniciansForAdminPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get statistics for technicians.
     * Get statistics for technicians.
     */
    async getStatisticsByTechnicianForAdminRaw(requestParameters: GetStatisticsByTechnicianForAdminRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StatisticsDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/books/statistics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatisticsDTOFromJSON(jsonValue));
    }

    /**
     * Get statistics for technicians.
     * Get statistics for technicians.
     */
    async getStatisticsByTechnicianForAdmin(requestParameters: GetStatisticsByTechnicianForAdminRequest, initOverrides?: RequestInit): Promise<StatisticsDTO> {
        const response = await this.getStatisticsByTechnicianForAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user by id for admin
     * Get user by id for admin
     */
    async getUserByIdForAdminRaw(requestParameters: GetUserByIdForAdminRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtendedSignupTechnician>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserByIdForAdmin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedSignupTechnicianFromJSON(jsonValue));
    }

    /**
     * Get user by id for admin
     * Get user by id for admin
     */
    async getUserByIdForAdmin(requestParameters: GetUserByIdForAdminRequest, initOverrides?: RequestInit): Promise<ExtendedSignupTechnician> {
        const response = await this.getUserByIdForAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get users list for admin
     * Get users list for admin
     */
    async getUserListForAdminRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TechnicianListItemDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TechnicianListItemDTOFromJSON));
    }

    /**
     * Get users list for admin
     * Get users list for admin
     */
    async getUserListForAdmin(initOverrides?: RequestInit): Promise<Array<TechnicianListItemDTO>> {
        const response = await this.getUserListForAdminRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users list for admin by filter
     * Get users list for admin by filter
     */
    async getUserListForAdminByFilterRaw(requestParameters: GetUserListForAdminByFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TechnicianListItemDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/admin/usersByFilter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FilterDTOToJSON(requestParameters.filterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TechnicianListItemDTOFromJSON));
    }

    /**
     * Get users list for admin by filter
     * Get users list for admin by filter
     */
    async getUserListForAdminByFilter(requestParameters: GetUserListForAdminByFilterRequest, initOverrides?: RequestInit): Promise<Array<TechnicianListItemDTO>> {
        const response = await this.getUserListForAdminByFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
