/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomTechnicianPageDTO,
    CustomTechnicianPageDTOFromJSON,
    CustomTechnicianPageDTOToJSON,
    ExtendedSignupTechnician,
    ExtendedSignupTechnicianFromJSON,
    ExtendedSignupTechnicianToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    TechnicianDTO,
    TechnicianDTOFromJSON,
    TechnicianDTOToJSON,
    TechnicianFilterDTO,
    TechnicianFilterDTOFromJSON,
    TechnicianFilterDTOToJSON,
} from '../models';

export interface GetAllTechnicianRequest {
    offset?: number;
    limit?: number;
}

export interface GetAllTechnicianPaginatedUsingPOSTRequest {
    limit: number;
    offset?: number;
    sortField?: GetAllTechnicianPaginatedUsingPOSTSortFieldEnum;
    direction?: GetAllTechnicianPaginatedUsingPOSTDirectionEnum;
    technicianFilterDTO?: TechnicianFilterDTO;
}

export interface GetTechnicianByCredentialsIDRequest {
    credentials?: string;
}

export interface GetTechnicianByIDRequest {
    id: string;
}

export interface SearchTechnicianRequest {
    idNumber?: string;
}

/**
 * 
 */
export class TechniciansApi extends runtime.BaseAPI {

    /**
     * Returns all the Technicians.
     * Get all Technician objects.
     */
    async getAllTechnicianRaw(requestParameters: GetAllTechnicianRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TechnicianDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/technicians`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TechnicianDTOFromJSON));
    }

    /**
     * Returns all the Technicians.
     * Get all Technician objects.
     */
    async getAllTechnician(requestParameters: GetAllTechnicianRequest, initOverrides?: RequestInit): Promise<Array<TechnicianDTO>> {
        const response = await this.getAllTechnicianRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllTechnicianPaginated
     */
    async getAllTechnicianPaginatedUsingPOSTRaw(requestParameters: GetAllTechnicianPaginatedUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomTechnicianPageDTO>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllTechnicianPaginatedUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/technicians/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TechnicianFilterDTOToJSON(requestParameters.technicianFilterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomTechnicianPageDTOFromJSON(jsonValue));
    }

    /**
     * getAllTechnicianPaginated
     */
    async getAllTechnicianPaginatedUsingPOST(requestParameters: GetAllTechnicianPaginatedUsingPOSTRequest, initOverrides?: RequestInit): Promise<CustomTechnicianPageDTO> {
        const response = await this.getAllTechnicianPaginatedUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one technician object by credential id.
     * Get a technician object by credential id.
     */
    async getTechnicianByCredentialsIDRaw(requestParameters: GetTechnicianByCredentialsIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtendedSignupTechnician>> {
        const queryParameters: any = {};

        if (requestParameters.credentials !== undefined) {
            queryParameters['credentials'] = requestParameters.credentials;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/technicians/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedSignupTechnicianFromJSON(jsonValue));
    }

    /**
     * Returns one technician object by credential id.
     * Get a technician object by credential id.
     */
    async getTechnicianByCredentialsID(requestParameters: GetTechnicianByCredentialsIDRequest, initOverrides?: RequestInit): Promise<ExtendedSignupTechnician> {
        const response = await this.getTechnicianByCredentialsIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one Technician object by id.
     * Get a Technician object by id.
     */
    async getTechnicianByIDRaw(requestParameters: GetTechnicianByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTechnicianByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/technicians/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Returns one Technician object by id.
     * Get a Technician object by id.
     */
    async getTechnicianByID(requestParameters: GetTechnicianByIDRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.getTechnicianByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one Technician object by DNI.
     * Get a Technician object by DNI.
     */
    async searchTechnicianRaw(requestParameters: SearchTechnicianRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        const queryParameters: any = {};

        if (requestParameters.idNumber !== undefined) {
            queryParameters['idNumber'] = requestParameters.idNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/technicians/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Returns one Technician object by DNI.
     * Get a Technician object by DNI.
     */
    async searchTechnician(requestParameters: SearchTechnicianRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.searchTechnicianRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAllTechnicianPaginatedUsingPOSTSortFieldEnum {
    CollegeName = 'COLLEGE_NAME',
    Name = 'NAME',
    Nif = 'NIF',
    Role = 'ROLE',
    StreetName = 'STREET_NAME',
    Surname = 'SURNAME'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllTechnicianPaginatedUsingPOSTDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}
