/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuildingIdentificationPrintedDTO
 */
export interface BuildingIdentificationPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    bookSubject?: BuildingIdentificationPrintedDTOBookSubjectEnum;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    cadastralReferenceDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuildingIdentificationPrintedDTO
     */
    cadastralReferences?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    commonElements?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    community?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingIdentificationPrintedDTO
     */
    typeBuilding?: BuildingIdentificationPrintedDTOTypeBuildingEnum;
}

/**
* @export
* @enum {string}
*/
export enum BuildingIdentificationPrintedDTOBookSubjectEnum {
    Otros = 'OTROS',
    Plurifamiliar = 'PLURIFAMILIAR',
    Unifamiliar = 'UNIFAMILIAR'
}/**
* @export
* @enum {string}
*/
export enum BuildingIdentificationPrintedDTOTypeBuildingEnum {
    ClosedBlockBuilding = 'CLOSED_BLOCK_BUILDING',
    IsolatedBuilding = 'ISOLATED_BUILDING'
}

export function BuildingIdentificationPrintedDTOFromJSON(json: any): BuildingIdentificationPrintedDTO {
    return BuildingIdentificationPrintedDTOFromJSONTyped(json, false);
}

export function BuildingIdentificationPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingIdentificationPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookSubject': !exists(json, 'bookSubject') ? undefined : json['bookSubject'],
        'cadastralReferenceDescription': !exists(json, 'cadastralReferenceDescription') ? undefined : json['cadastralReferenceDescription'],
        'cadastralReferences': !exists(json, 'cadastralReferences') ? undefined : json['cadastralReferences'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'commonElements': !exists(json, 'commonElements') ? undefined : json['commonElements'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'typeBuilding': !exists(json, 'typeBuilding') ? undefined : json['typeBuilding'],
    };
}

export function BuildingIdentificationPrintedDTOToJSON(value?: BuildingIdentificationPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookSubject': value.bookSubject,
        'cadastralReferenceDescription': value.cadastralReferenceDescription,
        'cadastralReferences': value.cadastralReferences,
        'city': value.city,
        'commonElements': value.commonElements,
        'community': value.community,
        'number': value.number,
        'postalCode': value.postalCode,
        'province': value.province,
        'street': value.street,
        'typeBuilding': value.typeBuilding,
    };
}

