/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SummaryDTO
 */
export interface SummaryDTO {
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    accessibility?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    actionRegistries?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    bestPractices?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    ieeDocuments?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    improvements?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    inspectionShortcomingsAttachments?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    iteDocuments?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    licenses?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    maintenanceContracts?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    otherDocuments?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof SummaryDTO
     */
    proceedings?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {string}
     * @memberof SummaryDTO
     */
    summary?: string;
}

export function SummaryDTOFromJSON(json: any): SummaryDTO {
    return SummaryDTOFromJSONTyped(json, false);
}

export function SummaryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibility': !exists(json, 'accessibility') ? undefined : ((json['accessibility'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'actionRegistries': !exists(json, 'actionRegistries') ? undefined : ((json['actionRegistries'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'bestPractices': !exists(json, 'bestPractices') ? undefined : ((json['bestPractices'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'ieeDocuments': !exists(json, 'ieeDocuments') ? undefined : ((json['ieeDocuments'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'improvements': !exists(json, 'improvements') ? undefined : ((json['improvements'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'inspectionShortcomingsAttachments': !exists(json, 'inspectionShortcomingsAttachments') ? undefined : ((json['inspectionShortcomingsAttachments'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'iteDocuments': !exists(json, 'iteDocuments') ? undefined : ((json['iteDocuments'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'licenses': !exists(json, 'licenses') ? undefined : ((json['licenses'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'maintenanceContracts': !exists(json, 'maintenanceContracts') ? undefined : ((json['maintenanceContracts'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'otherDocuments': !exists(json, 'otherDocuments') ? undefined : ((json['otherDocuments'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'proceedings': !exists(json, 'proceedings') ? undefined : ((json['proceedings'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
    };
}

export function SummaryDTOToJSON(value?: SummaryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibility': value.accessibility === undefined ? undefined : ((value.accessibility as Array<any>).map(MediaPrintedDTOToJSON)),
        'actionRegistries': value.actionRegistries === undefined ? undefined : ((value.actionRegistries as Array<any>).map(MediaPrintedDTOToJSON)),
        'bestPractices': value.bestPractices === undefined ? undefined : ((value.bestPractices as Array<any>).map(MediaPrintedDTOToJSON)),
        'ieeDocuments': value.ieeDocuments === undefined ? undefined : ((value.ieeDocuments as Array<any>).map(MediaPrintedDTOToJSON)),
        'improvements': value.improvements === undefined ? undefined : ((value.improvements as Array<any>).map(MediaPrintedDTOToJSON)),
        'inspectionShortcomingsAttachments': value.inspectionShortcomingsAttachments === undefined ? undefined : ((value.inspectionShortcomingsAttachments as Array<any>).map(MediaPrintedDTOToJSON)),
        'iteDocuments': value.iteDocuments === undefined ? undefined : ((value.iteDocuments as Array<any>).map(MediaPrintedDTOToJSON)),
        'licenses': value.licenses === undefined ? undefined : ((value.licenses as Array<any>).map(MediaPrintedDTOToJSON)),
        'maintenanceContracts': value.maintenanceContracts === undefined ? undefined : ((value.maintenanceContracts as Array<any>).map(MediaPrintedDTOToJSON)),
        'otherDocuments': value.otherDocuments === undefined ? undefined : ((value.otherDocuments as Array<any>).map(MediaPrintedDTOToJSON)),
        'proceedings': value.proceedings === undefined ? undefined : ((value.proceedings as Array<any>).map(MediaPrintedDTOToJSON)),
        'summary': value.summary,
    };
}

