import { StyleSheet } from "@react-pdf/renderer";
import { Colors, ColorsNewPdf, FontSizes } from "../../../constants";
import { fontsNewPdf } from "../fonts/fonts";

export const PdfNewStyles = StyleSheet.create({
  signs_component_container: {
    border: "2pt dashed #427BB0",
    maxWidth: "50%",
    padding: "10pt",
    marginRight: "40pt",
    marginTop: "5pt",
    height: "155pt",
  },
  signs_component_texts: {
    minWidth: "200pt",
    color: ColorsNewPdf.grey_table_header,
    fontSize: FontSizes.pdfP,
    padding: "10pt",
  },
  index_section_title_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10%",
    marginBottom: "5%",
  },
  index_section_title_acronym_box: {
    height: "50pt",
    width: "50pt",
  },
  index_section_title_acronym_text: {
    margin: "auto",
    color: "white",
    fontFamily: fontsNewPdf.RobotoBold,
  },
  index_section_title_text: {
    marginLeft: "1.5%",
    width: "70%",
    fontFamily: fontsNewPdf.PoppinsRegular,
  },
  table_cadastral: {
    //@ts-ignore
    display: "table",
    textAlign: "center",
    marginVertical: "30pt",
    alignItems: "center",
  },
  tableRowsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "0pt",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCell: {
    height: "100%",
    paddingBottom: "3pt",
  },
  pdftable_header_col: {
    backgroundColor: ColorsNewPdf.grey_table_header,
    fontSize: 9.5,
    alignItems: "center",
    justifyContent: "center",
  },
  pdftable_header_cell: {
    fontSize: 9.5,
    padding: 5,
    alignSelf: "center",
    height: 35,
  },
  tableCol: {
    width: "20%",
  },
  pdftable_tableCell_usage: {
    fontSize: 8.5,
    alignContent: "center",
    alignItems: "center",
    padding: 5,
    minHeight: "20pt",
  },
  pdftable_tableCell: {
    fontSize: 8.5,
    alignContent: "center",
    alignItems: "center",
    padding: 10,
    minHeight: "40pt",
  },
  general_data: {
    paddingVertical: 2,
    color: ColorsNewPdf.black,
    fontSize: FontSizes.pdfP2,
  },
  general_data_subtitle: {
    paddingVertical: 8,
    marginVertical: 4,
    color: ColorsNewPdf.grey_text,
    fontSize: FontSizes.pdfH3,
    borderBottom: "1pt solid " + ColorsNewPdf.grey_text,
  },
  general_data_subtitle_no_bottom: {
    paddingVertical: 8,
    marginVertical: 4,
    color: ColorsNewPdf.grey_text,
    fontSize: FontSizes.pdfH3,
  },
  general_data_input: {
    color: ColorsNewPdf.black,
    fontSize: FontSizes.pdfH3,
  },
  valueRowData: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: 10,
    marginVertical: 3,
  },
  horizontal_row: {
    color: ColorsNewPdf.grey_text,
    marginTop: 3,
    borderBottom: "1pt solid " + ColorsNewPdf.grey_text,
  },
  cadastralBuildingInfo_horizontal_row: {
    display: "flex",
    flexDirection: "row",
  },
  cadastralBuildingInfo_horizontal_row_texts: {
    display: "flex",
    flexDirection: "row",
    textAlign: "left",
    width: "40%",
  },
  cadastralBuildingInfo_vertical_container: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20pt",
  },
  propietaryData_container: {
    width: "49%",
    paddingLeft: "20pt",
    justifyContent: "space-evenly",
    display: "flex",
    flexDirection: "column",
    fontSize: "11pt",
  },
  bottomText_fullWidth_container: {
    width: "95%",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
  },
  greyBox_30width_container: {
    backgroundColor: ColorsNewPdf.background_containers,
    width: "30%",
    padding: "8pt",
  },
  entryTitle: {
    fontFamily: fontsNewPdf.RobotoBold,
  },
  index_title: {
    fontSize: 64,
    fontWeight: "bold",
    fontFamily: fontsNewPdf.RobotoBold,
    color: ColorsNewPdf.grey_text,
  },
  block1_general_data_row_container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: 10,
  },
  block1_general_data_4_containers: {
    color: ColorsNewPdf.black,
    fontSize: FontSizes.pdfP2,
    width: "23%",
    backgroundColor: ColorsNewPdf.background_containers_block1_alternative,
    paddingVertical: 10,
  },
  block1_general_data_3_containers: {
    color: ColorsNewPdf.black,
    fontSize: FontSizes.pdfP2,
    width: "31%",
    backgroundColor: ColorsNewPdf.background_containers_block1_alternative,
    paddingVertical: 10,
  },
  block1_general_data_other_element_viewer: {
    width: "100%",
    paddingVertical: 10,
    backgroundColor: ColorsNewPdf.grey_table_background,
    marginTop: 10,
  },
  block1_general_partial_assesment_other_element_viewer: {
    marginTop: 10,
  },
  block1_general_data_other_element_text: {
    paddingVertical: 2,
    color: ColorsNewPdf.black,
    fontSize: FontSizes.pdfP2,
    paddingLeft: 10,
  },
  block1_general_data_double_table_container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  block1_general_data_small_table: {
    width: "47%",
    marginTop: "10pt",
    display: "flex",
    flexDirection: "column",
    fontSize: 10,
    textAlign: "center",
  },
  block1_general_data_header: {
    backgroundColor: ColorsNewPdf.background_containers_block1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    height: "40pt",
  },
  block1_general_data_header_text: {
    textAlign: "center",
    alignContent: "center",
    justifyContent: "center",
    fontFamily: fontsNewPdf.RobotoBold,
  },
  block1_general_data_40_cell: {
    width: "40%",
    padding: "5pt",
  },
  block1_general_data_20_cell: {
    width: "20%",
    padding: "5pt",
    borderLeft: "1pt solid " + ColorsNewPdf.dark_blue_text_background_lines,
    borderRight: "1pt solid " + ColorsNewPdf.dark_blue_text_background_lines,
  },
  block1_general_data_65_cell: {
    width: "65%",
    borderRight: "1pt solid " + ColorsNewPdf.dark_blue_text_background_lines,
    padding: "5pt",
  },
  block1_general_data_35_cell: {
    width: "35%",
    textAlign: "center",
    padding: "5pt",
  },
  block1_general_data_cell_container: {
    width: "100%",
    flexDirection: "row",
  },
  block1_general_data_tableCellHeader: {
    fontFamily: fontsNewPdf.RobotoBold,
  },
  diamond_image: {
    height: 5,
    width: 5,
    marginRight: 3,
    marginTop: 2,
  },
  hollow_circle: {
    width: "12pt",
    height: "12pt",
    border: "2px solid black",
    position: "relative",
    borderRadius: "50%",
    marginRight: 5,
  },
  inner_circle: {
    width: "5pt",
    height: "5pt",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-2.5%,-2.5%)",
    backgroundColor: "black",
    borderRadius: "50%",
  },
  block1_conservationStatus_diamond_container: {
    display: "flex",
    flexDirection: "row",
    margin: 3,
  },
  block1_constructive_description_viewer_general_3_container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 10,
  },
  block1_constructive_description_viewer_column_container: {
    width: "32%",
    display: "flex",
    flexDirection: "column",
    fontSize: 9,
  },
  block1_constructive_description_viewer_column_container_short: {
    width: "24%",
    display: "flex",
    flexDirection: "column",
  },
  block1_constructive_description_column_Title: {
    fontFamily: fontsNewPdf.RobotoBold,
    fontSize: 10,
    paddingVertical: 4,
  },
  block1_constructive_description_unknown_Text: {
    marginLeft: 5,
    fontSize: 10,
  },
  block1_constructive_description_dashed_viewer: {
    border: "1pt dashed blue",
    paddingHorizontal: 12,
    paddingVertical: 6,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginBottom: 6,
  },
  block1_constructive_description_50_column: {
    display: "flex",
    flexDirection: "column",
    width: "51%",
    fontSize: 9,
    marginVertical: 8,
  },
  block1_valueRowData_full: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginVertical: 10,
  },
  block1_description_introduce_text: {
    fontSize: 12,
    fontFamily: fontsNewPdf.RobotoLight,
    width: "100%",
    paddingLeft: 8,
    borderBottom: "1pt solid " + ColorsNewPdf.dark_blue_text_background_lines,
    marginVertical: 10,
    height: "15pt",
  },
  block1_normalized_description_subtitle: {
    fontSize: 13,
    fontFamily: fontsNewPdf.RobotoBold,
    width: "100%",
    paddingLeft: 8,
    borderBottom: "1pt solid " + ColorsNewPdf.dark_blue_text_background_lines,
    marginVertical: 10,
  },
  block1_description_introduce_subtitle_text: {
    fontSize: 11,
    fontFamily: fontsNewPdf.RobotoLight,
    width: "100%",
  },
  header_text: {
    fontSize: 8.5,
    color: Colors.primary,
  },
  footer_text: {
    fontSize: 8.5,
    color: Colors.pdfPrimaryText,
  },
  block2_improvement_checkboxes_title_column: {
    color: "green",
    fontFamily: fontsNewPdf.RobotoBold,
    fontSize: 9,
    paddingBottom: 4,
  },
  block1_building_table_cell: {
    width: "50%",
    paddingLeft: 6,
    paddingVertical: 10,
  },
  block1_building_table_row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "1pt solid " + ColorsNewPdf.lines_subtitle_section,
  },
  block1_building_table_cell_container: {
    width: "49%",
    borderRight: "1pt solid " + ColorsNewPdf.lines_subtitle_section,
  },
  block2_improvement_table_cell_35: {
    height: "100%",
    width: "35%",
    padding: 10,
    textAlign: "left",
  },
  summary_cell: {
    paddingVertical: 8,
    paddingHorizontal: 4,
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    width: "16.5%",
  },
  history_inspection_text: {
    paddingLeft: "10pt",
    marginVertical: 2,
  },
  history_inspection_container: {
    marginVertical: "8pt",
    padding: "4pt",
    border: "1pt blue solid",
    display: "flex",
    flexDirection: "column",
    fontSize: 11,
    textAlign: "left",
  },
  general_partial_assesment_notes: {
    marginLeft: 5,
    textAlign: "justify",
  },
});
