/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyEfficiencyImprovementDataDTO,
    EnergyEfficiencyImprovementDataDTOFromJSON,
    EnergyEfficiencyImprovementDataDTOFromJSONTyped,
    EnergyEfficiencyImprovementDataDTOToJSON,
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImprovementDTO
 */
export interface ImprovementDTO {
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    benefitDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ImprovementDTO
     */
    conceptOfImprovementAccordingToGuide?: number;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    constructionDurationInMonths?: string;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    difficulty?: ImprovementDTODifficultyEnum;
    /**
     * 
     * @type {EnergyEfficiencyImprovementDataDTO}
     * @memberof ImprovementDTO
     */
    energyEfficiencyMeasureData?: EnergyEfficiencyImprovementDataDTO;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    estimatedCost?: ImprovementDTOEstimatedCostEnum;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof ImprovementDTO
     */
    files?: Array<MediaDTO>;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ImprovementDTO
     */
    initialNonRenewablePrimaryEnergyConsumptionReduction?: number;
    /**
     * 
     * @type {number}
     * @memberof ImprovementDTO
     */
    initialNonRenewablePrimaryEnergyConsumptionReductionPercent?: number;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    maximumEconomicAidToGet?: string;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    measureDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ImprovementDTO
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    phase?: ImprovementDTOPhaseEnum;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    priority?: ImprovementDTOPriorityEnum;
    /**
     * 
     * @type {number}
     * @memberof ImprovementDTO
     */
    recommendedStartDateOfConstructions?: number;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    referenceStandard?: string;
    /**
     * 
     * @type {number}
     * @memberof ImprovementDTO
     */
    saving?: number;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    scopeOfConstructions?: string;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    status?: ImprovementDTOStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ImprovementDTO
     */
    timestampOfExecution?: number;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    type?: ImprovementDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    useRationalizationIndicationAndBuildingManagement?: string;
    /**
     * 
     * @type {string}
     * @memberof ImprovementDTO
     */
    userBenefits?: string;
}

/**
* @export
* @enum {string}
*/
export enum ImprovementDTODifficultyEnum {
    High = 'HIGH',
    Low = 'LOW',
    Medium = 'MEDIUM',
    None = 'NONE'
}/**
* @export
* @enum {string}
*/
export enum ImprovementDTOEstimatedCostEnum {
    MoreThan1000000 = 'MORE_THAN_1000000',
    Until500 = 'UNTIL_500',
    _100001150000 = '_100001_150000',
    _1000125000 = '_10001_25000',
    _100110000 = '_1001_10000',
    _150001200000 = '_150001_200000',
    _201000250000 = '_201000_250000',
    _250001300000 = '_250001_300000',
    _2500150000 = '_25001_50000',
    _300000500000 = '_300000_500000',
    _500001750000 = '_500001_750000',
    _50001100000 = '_50001_100000',
    _5011000 = '_501_1000',
    _7500001000000 = '_750000_1000000'
}/**
* @export
* @enum {string}
*/
export enum ImprovementDTOPhaseEnum {
    NoScheduled = 'NO_SCHEDULED',
    Phase1 = 'PHASE_1',
    Phase2 = 'PHASE_2',
    Phase3 = 'PHASE_3',
    Phase4 = 'PHASE_4',
    Phase5 = 'PHASE_5'
}/**
* @export
* @enum {string}
*/
export enum ImprovementDTOPriorityEnum {
    High = 'HIGH',
    Low = 'LOW',
    Medium = 'MEDIUM',
    None = 'NONE'
}/**
* @export
* @enum {string}
*/
export enum ImprovementDTOStatusEnum {
    Applied = 'APPLIED',
    Proposed = 'PROPOSED',
    Rejected = 'REJECTED'
}/**
* @export
* @enum {string}
*/
export enum ImprovementDTOTypeEnum {
    EnergyAgeOfInstallation = 'ENERGY_AGE_OF_INSTALLATION',
    EnergyAutomaticSystems = 'ENERGY_AUTOMATIC_SYSTEMS',
    EnergyCirculatingPumps = 'ENERGY_CIRCULATING_PUMPS',
    EnergyConsumptionAccounting = 'ENERGY_CONSUMPTION_ACCOUNTING',
    EnergyEnergyPerformanceAnalysisOnRealConditions = 'ENERGY_ENERGY_PERFORMANCE_ANALYSIS_ON_REAL_CONDITIONS',
    EnergyEnergySavingLightingOnCommonZones = 'ENERGY_ENERGY_SAVING_LIGHTING_ON_COMMON_ZONES',
    EnergyHighEfficiencyElevator = 'ENERGY_HIGH_EFFICIENCY_ELEVATOR',
    EnergyLightingControlSystem = 'ENERGY_LIGHTING_CONTROL_SYSTEM',
    EnergyOthers = 'ENERGY_OTHERS',
    EnergyPipeIsolation = 'ENERGY_PIPE_ISOLATION',
    EnergySetOfMeasures = 'ENERGY_SET_OF_MEASURES',
    FirePartitioningOfSpecialRiskLocations = 'FIRE_PARTITIONING_OF_SPECIAL_RISK_LOCATIONS',
    FireProtections = 'FIRE_PROTECTIONS',
    FireReactionCommonZones = 'FIRE_REACTION_COMMON_ZONES',
    FireReactionOnFacade = 'FIRE_REACTION_ON_FACADE',
    FireStairwayProtectionLevel = 'FIRE_STAIRWAY_PROTECTION_LEVEL',
    HealthinessCommonZonesVentilation = 'HEALTHINESS_COMMON_ZONES_VENTILATION',
    HealthinessDripIrrigation = 'HEALTHINESS_DRIP_IRRIGATION',
    HealthinessDwellingsVentilation = 'HEALTHINESS_DWELLINGS_VENTILATION',
    HealthinessGaragesVentilation = 'HEALTHINESS_GARAGES_VENTILATION',
    HealthinessIndividualWaterMetering = 'HEALTHINESS_INDIVIDUAL_WATER_METERING',
    HealthinessPoolWaterTreatment = 'HEALTHINESS_POOL_WATER_TREATMENT',
    HealthinessProtectionLevelOnRiskZones = 'HEALTHINESS_PROTECTION_LEVEL_ON_RISK_ZONES',
    HealthinessSingularElementsWaterTreatment = 'HEALTHINESS_SINGULAR_ELEMENTS_WATER_TREATMENT',
    HealthinessSmellOnWasteStorage = 'HEALTHINESS_SMELL_ON_WASTE_STORAGE',
    HealthinessWasteStorageOnDoors = 'HEALTHINESS_WASTE_STORAGE_ON_DOORS',
    HealthinessWasteStorageVentilation = 'HEALTHINESS_WASTE_STORAGE_VENTILATION',
    HealthinessWaterDwellingSupply = 'HEALTHINESS_WATER_DWELLING_SUPPLY',
    HealthinessWaterSavingDevicesCommonZones = 'HEALTHINESS_WATER_SAVING_DEVICES_COMMON_ZONES',
    HealthinessWaterSavingDevicesDwellings = 'HEALTHINESS_WATER_SAVING_DEVICES_DWELLINGS',
    HealthinessWaterTreatmentSystem = 'HEALTHINESS_WATER_TREATMENT_SYSTEM',
    NoiseFacilities = 'NOISE_FACILITIES',
    NoiseInteriorExterior = 'NOISE_INTERIOR_EXTERIOR',
    Other = 'OTHER',
    SecurityAccessibility = 'SECURITY_ACCESSIBILITY',
    SecurityBarrier = 'SECURITY_BARRIER',
    SecurityDoor = 'SECURITY_DOOR',
    SecurityGlazing = 'SECURITY_GLAZING',
    SecurityLightning = 'SECURITY_LIGHTNING',
    SecurityPavement = 'SECURITY_PAVEMENT',
    SecurityPool = 'SECURITY_POOL',
    Shortcoming = 'SHORTCOMING'
}

export function ImprovementDTOFromJSON(json: any): ImprovementDTO {
    return ImprovementDTOFromJSONTyped(json, false);
}

export function ImprovementDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImprovementDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'benefitDescription': !exists(json, 'benefitDescription') ? undefined : json['benefitDescription'],
        'conceptOfImprovementAccordingToGuide': !exists(json, 'conceptOfImprovementAccordingToGuide') ? undefined : json['conceptOfImprovementAccordingToGuide'],
        'constructionDurationInMonths': !exists(json, 'constructionDurationInMonths') ? undefined : json['constructionDurationInMonths'],
        'difficulty': !exists(json, 'difficulty') ? undefined : json['difficulty'],
        'energyEfficiencyMeasureData': !exists(json, 'energyEfficiencyMeasureData') ? undefined : EnergyEfficiencyImprovementDataDTOFromJSON(json['energyEfficiencyMeasureData']),
        'estimatedCost': !exists(json, 'estimatedCost') ? undefined : json['estimatedCost'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(MediaDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'initialNonRenewablePrimaryEnergyConsumptionReduction': !exists(json, 'initialNonRenewablePrimaryEnergyConsumptionReduction') ? undefined : json['initialNonRenewablePrimaryEnergyConsumptionReduction'],
        'initialNonRenewablePrimaryEnergyConsumptionReductionPercent': !exists(json, 'initialNonRenewablePrimaryEnergyConsumptionReductionPercent') ? undefined : json['initialNonRenewablePrimaryEnergyConsumptionReductionPercent'],
        'maximumEconomicAidToGet': !exists(json, 'maximumEconomicAidToGet') ? undefined : json['maximumEconomicAidToGet'],
        'measureDescription': !exists(json, 'measureDescription') ? undefined : json['measureDescription'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'phase': !exists(json, 'phase') ? undefined : json['phase'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'recommendedStartDateOfConstructions': !exists(json, 'recommendedStartDateOfConstructions') ? undefined : json['recommendedStartDateOfConstructions'],
        'referenceStandard': !exists(json, 'referenceStandard') ? undefined : json['referenceStandard'],
        'saving': !exists(json, 'saving') ? undefined : json['saving'],
        'scopeOfConstructions': !exists(json, 'scopeOfConstructions') ? undefined : json['scopeOfConstructions'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'timestampOfExecution': !exists(json, 'timestampOfExecution') ? undefined : json['timestampOfExecution'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'useRationalizationIndicationAndBuildingManagement': !exists(json, 'useRationalizationIndicationAndBuildingManagement') ? undefined : json['useRationalizationIndicationAndBuildingManagement'],
        'userBenefits': !exists(json, 'userBenefits') ? undefined : json['userBenefits'],
    };
}

export function ImprovementDTOToJSON(value?: ImprovementDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'benefitDescription': value.benefitDescription,
        'conceptOfImprovementAccordingToGuide': value.conceptOfImprovementAccordingToGuide,
        'constructionDurationInMonths': value.constructionDurationInMonths,
        'difficulty': value.difficulty,
        'energyEfficiencyMeasureData': EnergyEfficiencyImprovementDataDTOToJSON(value.energyEfficiencyMeasureData),
        'estimatedCost': value.estimatedCost,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(MediaDTOToJSON)),
        'id': value.id,
        'initialNonRenewablePrimaryEnergyConsumptionReduction': value.initialNonRenewablePrimaryEnergyConsumptionReduction,
        'initialNonRenewablePrimaryEnergyConsumptionReductionPercent': value.initialNonRenewablePrimaryEnergyConsumptionReductionPercent,
        'maximumEconomicAidToGet': value.maximumEconomicAidToGet,
        'measureDescription': value.measureDescription,
        'name': value.name,
        'order': value.order,
        'phase': value.phase,
        'priority': value.priority,
        'recommendedStartDateOfConstructions': value.recommendedStartDateOfConstructions,
        'referenceStandard': value.referenceStandard,
        'saving': value.saving,
        'scopeOfConstructions': value.scopeOfConstructions,
        'status': value.status,
        'timestampOfExecution': value.timestampOfExecution,
        'type': value.type,
        'useRationalizationIndicationAndBuildingManagement': value.useRationalizationIndicationAndBuildingManagement,
        'userBenefits': value.userBenefits,
    };
}

