/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessibilityEvaluationMediaDTO,
    AccessibilityEvaluationMediaDTOFromJSON,
    AccessibilityEvaluationMediaDTOFromJSONTyped,
    AccessibilityEvaluationMediaDTOToJSON,
    AccessibleElementsDTO,
    AccessibleElementsDTOFromJSON,
    AccessibleElementsDTOFromJSONTyped,
    AccessibleElementsDTOToJSON,
    FunctionalConditionsDTO,
    FunctionalConditionsDTOFromJSON,
    FunctionalConditionsDTOFromJSONTyped,
    FunctionalConditionsDTOToJSON,
    InformationAndSignsDTO,
    InformationAndSignsDTOFromJSON,
    InformationAndSignsDTOFromJSONTyped,
    InformationAndSignsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccessibilityEvaluationDTO
 */
export interface AccessibilityEvaluationDTO {
    /**
     * 
     * @type {AccessibleElementsDTO}
     * @memberof AccessibilityEvaluationDTO
     */
    accessibleElementsDTO?: AccessibleElementsDTO;
    /**
     * 
     * @type {boolean}
     * @memberof AccessibilityEvaluationDTO
     */
    excludeDataToFill?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessibilityEvaluationDTO
     */
    finalResult?: AccessibilityEvaluationDTOFinalResultEnum;
    /**
     * 
     * @type {FunctionalConditionsDTO}
     * @memberof AccessibilityEvaluationDTO
     */
    functionalConditionsDTO?: FunctionalConditionsDTO;
    /**
     * 
     * @type {InformationAndSignsDTO}
     * @memberof AccessibilityEvaluationDTO
     */
    informationAndSignsDTO?: InformationAndSignsDTO;
    /**
     * 
     * @type {Array<AccessibilityEvaluationMediaDTO>}
     * @memberof AccessibilityEvaluationDTO
     */
    media?: Array<AccessibilityEvaluationMediaDTO>;
}

/**
* @export
* @enum {string}
*/
export enum AccessibilityEvaluationDTOFinalResultEnum {
    Approval = 'APPROVAL',
    MinorDeficienciesApproval = 'MINOR_DEFICIENCIES_APPROVAL',
    SeriousShortcomingsRejection = 'SERIOUS_SHORTCOMINGS_REJECTION',
    VerySeriousShortcomingsRejection = 'VERY_SERIOUS_SHORTCOMINGS_REJECTION'
}

export function AccessibilityEvaluationDTOFromJSON(json: any): AccessibilityEvaluationDTO {
    return AccessibilityEvaluationDTOFromJSONTyped(json, false);
}

export function AccessibilityEvaluationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessibilityEvaluationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibleElementsDTO': !exists(json, 'accessibleElementsDTO') ? undefined : AccessibleElementsDTOFromJSON(json['accessibleElementsDTO']),
        'excludeDataToFill': !exists(json, 'excludeDataToFill') ? undefined : json['excludeDataToFill'],
        'finalResult': !exists(json, 'finalResult') ? undefined : json['finalResult'],
        'functionalConditionsDTO': !exists(json, 'functionalConditionsDTO') ? undefined : FunctionalConditionsDTOFromJSON(json['functionalConditionsDTO']),
        'informationAndSignsDTO': !exists(json, 'informationAndSignsDTO') ? undefined : InformationAndSignsDTOFromJSON(json['informationAndSignsDTO']),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(AccessibilityEvaluationMediaDTOFromJSON)),
    };
}

export function AccessibilityEvaluationDTOToJSON(value?: AccessibilityEvaluationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibleElementsDTO': AccessibleElementsDTOToJSON(value.accessibleElementsDTO),
        'excludeDataToFill': value.excludeDataToFill,
        'finalResult': value.finalResult,
        'functionalConditionsDTO': FunctionalConditionsDTOToJSON(value.functionalConditionsDTO),
        'informationAndSignsDTO': InformationAndSignsDTOToJSON(value.informationAndSignsDTO),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(AccessibilityEvaluationMediaDTOToJSON)),
    };
}

