/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EnergyEfficiencyImprovementDTO,
    EnergyEfficiencyImprovementDTOFromJSON,
    EnergyEfficiencyImprovementDTOToJSON,
    FireSafetyImprovementDTO,
    FireSafetyImprovementDTOFromJSON,
    FireSafetyImprovementDTOToJSON,
    HealthinessImprovementDTO,
    HealthinessImprovementDTOFromJSON,
    HealthinessImprovementDTOToJSON,
    ImprovementActionDTO,
    ImprovementActionDTOFromJSON,
    ImprovementActionDTOToJSON,
    ImprovementContainerDTO,
    ImprovementContainerDTOFromJSON,
    ImprovementContainerDTOToJSON,
    ImprovementDTO,
    ImprovementDTOFromJSON,
    ImprovementDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    NoiseImprovementDTO,
    NoiseImprovementDTOFromJSON,
    NoiseImprovementDTOToJSON,
    OtherImprovementDTO,
    OtherImprovementDTOFromJSON,
    OtherImprovementDTOToJSON,
    PhaseDTO,
    PhaseDTOFromJSON,
    PhaseDTOToJSON,
    SecurityImprovementDTO,
    SecurityImprovementDTOFromJSON,
    SecurityImprovementDTOToJSON,
} from '../models';

export interface AddImprovementToImprovementActionRequest {
    id: string;
    improvementDTO?: ImprovementDTO;
}

export interface AddPhaseToImprovementActionRequest {
    id: string;
    phaseDTO?: PhaseDTO;
}

export interface DeleteImprovementToImprovementActionRequest {
    id: string;
    improvementId: string;
}

export interface DeletePhaseFromImprovementActionRequest {
    id: string;
    phaseId: string;
}

export interface SetFireSafetyImprovementRequest {
    id: string;
    fireSafetyImprovementDTO?: FireSafetyImprovementDTO;
}

export interface SetHealthinessImprovementRequest {
    id: string;
    healthinessImprovementDTO?: HealthinessImprovementDTO;
}

export interface SetNoisesImprovementRequest {
    id: string;
    noiseImprovementDTO?: NoiseImprovementDTO;
}

export interface SetOtherImprovementRequest {
    id: string;
    otherImprovementDTO?: OtherImprovementDTO;
}

export interface SetSavingEnergyImprovementRequest {
    id: string;
    energyEfficiencyImprovementDTO?: EnergyEfficiencyImprovementDTO;
}

export interface SetSecurityImprovementRequest {
    id: string;
    securityImprovementDTO?: SecurityImprovementDTO;
}

export interface UpdateMultipleImprovementsRequest {
    id: string;
    improvementContainerDTO?: ImprovementContainerDTO;
}

/**
 * 
 */
export class ImprovementActionsApi extends runtime.BaseAPI {

    /**
     * Adds an improvement to and improvement action.
     * Adds or edits existing improvement to an improvement action.
     */
    async addImprovementToImprovementActionRaw(requestParameters: AddImprovementToImprovementActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addImprovementToImprovementAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/improvements`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImprovementDTOToJSON(requestParameters.improvementDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Adds an improvement to and improvement action.
     * Adds or edits existing improvement to an improvement action.
     */
    async addImprovementToImprovementAction(requestParameters: AddImprovementToImprovementActionRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.addImprovementToImprovementActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds phase to improvement action.
     * Adds a phase to improvement action.
     */
    async addPhaseToImprovementActionRaw(requestParameters: AddPhaseToImprovementActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addPhaseToImprovementAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/phases`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PhaseDTOToJSON(requestParameters.phaseDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Adds phase to improvement action.
     * Adds a phase to improvement action.
     */
    async addPhaseToImprovementAction(requestParameters: AddPhaseToImprovementActionRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.addPhaseToImprovementActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete an improvement to and improvement action.
     * Delete an improvement from an improvement action.
     */
    async deleteImprovementToImprovementActionRaw(requestParameters: DeleteImprovementToImprovementActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteImprovementToImprovementAction.');
        }

        if (requestParameters.improvementId === null || requestParameters.improvementId === undefined) {
            throw new runtime.RequiredError('improvementId','Required parameter requestParameters.improvementId was null or undefined when calling deleteImprovementToImprovementAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/improvements/{improvementId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"improvementId"}}`, encodeURIComponent(String(requestParameters.improvementId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * delete an improvement to and improvement action.
     * Delete an improvement from an improvement action.
     */
    async deleteImprovementToImprovementAction(requestParameters: DeleteImprovementToImprovementActionRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.deleteImprovementToImprovementActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete a phase from improvement action.
     * Delete a phase from improvement action.
     */
    async deletePhaseFromImprovementActionRaw(requestParameters: DeletePhaseFromImprovementActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePhaseFromImprovementAction.');
        }

        if (requestParameters.phaseId === null || requestParameters.phaseId === undefined) {
            throw new runtime.RequiredError('phaseId','Required parameter requestParameters.phaseId was null or undefined when calling deletePhaseFromImprovementAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/phases/{phaseId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"phaseId"}}`, encodeURIComponent(String(requestParameters.phaseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * delete a phase from improvement action.
     * Delete a phase from improvement action.
     */
    async deletePhaseFromImprovementAction(requestParameters: DeletePhaseFromImprovementActionRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.deletePhaseFromImprovementActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set fire safety improvements
     * Set fire safety improvements
     */
    async setFireSafetyImprovementRaw(requestParameters: SetFireSafetyImprovementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setFireSafetyImprovement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/fire-safety`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FireSafetyImprovementDTOToJSON(requestParameters.fireSafetyImprovementDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Set fire safety improvements
     * Set fire safety improvements
     */
    async setFireSafetyImprovement(requestParameters: SetFireSafetyImprovementRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.setFireSafetyImprovementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set healthiness improvements
     * Set healthiness improvements
     */
    async setHealthinessImprovementRaw(requestParameters: SetHealthinessImprovementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setHealthinessImprovement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/healthiness`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HealthinessImprovementDTOToJSON(requestParameters.healthinessImprovementDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Set healthiness improvements
     * Set healthiness improvements
     */
    async setHealthinessImprovement(requestParameters: SetHealthinessImprovementRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.setHealthinessImprovementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set noises improvements
     * Set noises improvements
     */
    async setNoisesImprovementRaw(requestParameters: SetNoisesImprovementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setNoisesImprovement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/noises`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NoiseImprovementDTOToJSON(requestParameters.noiseImprovementDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Set noises improvements
     * Set noises improvements
     */
    async setNoisesImprovement(requestParameters: SetNoisesImprovementRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.setNoisesImprovementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets other improvements
     * Sets other improvements
     */
    async setOtherImprovementRaw(requestParameters: SetOtherImprovementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setOtherImprovement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/others`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OtherImprovementDTOToJSON(requestParameters.otherImprovementDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Sets other improvements
     * Sets other improvements
     */
    async setOtherImprovement(requestParameters: SetOtherImprovementRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.setOtherImprovementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the saving energy improvements
     * Set the saving energy improvements
     */
    async setSavingEnergyImprovementRaw(requestParameters: SetSavingEnergyImprovementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setSavingEnergyImprovement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/energy-efficiency`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnergyEfficiencyImprovementDTOToJSON(requestParameters.energyEfficiencyImprovementDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Set the saving energy improvements
     * Set the saving energy improvements
     */
    async setSavingEnergyImprovement(requestParameters: SetSavingEnergyImprovementRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.setSavingEnergyImprovementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set security improvements
     * Set security improvements
     */
    async setSecurityImprovementRaw(requestParameters: SetSecurityImprovementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setSecurityImprovement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/security`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SecurityImprovementDTOToJSON(requestParameters.securityImprovementDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Set security improvements
     * Set security improvements
     */
    async setSecurityImprovement(requestParameters: SetSecurityImprovementRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.setSecurityImprovementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a list of improvements.
     * Updates a list of improvements.
     */
    async updateMultipleImprovementsRaw(requestParameters: UpdateMultipleImprovementsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImprovementActionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMultipleImprovements.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/improvement-actions/{id}/multiple-improvements`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImprovementContainerDTOToJSON(requestParameters.improvementContainerDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImprovementActionDTOFromJSON(jsonValue));
    }

    /**
     * Updates a list of improvements.
     * Updates a list of improvements.
     */
    async updateMultipleImprovements(requestParameters: UpdateMultipleImprovementsRequest, initOverrides?: RequestInit): Promise<ImprovementActionDTO> {
        const response = await this.updateMultipleImprovementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
