/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewCadastralLocationDTO,
    NewCadastralLocationDTOFromJSON,
    NewCadastralLocationDTOFromJSONTyped,
    NewCadastralLocationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewUsageDTO
 */
export interface NewUsageDTO {
    /**
     * 
     * @type {NewCadastralLocationDTO}
     * @memberof NewUsageDTO
     */
    cadastralLocation?: NewCadastralLocationDTO;
    /**
     * 
     * @type {string}
     * @memberof NewUsageDTO
     */
    cadastralReference?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsageDTO
     */
    cadastralSurface?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUsageDTO
     */
    cadastralUse?: string;
}

export function NewUsageDTOFromJSON(json: any): NewUsageDTO {
    return NewUsageDTOFromJSONTyped(json, false);
}

export function NewUsageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUsageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cadastralLocation': !exists(json, 'cadastralLocation') ? undefined : NewCadastralLocationDTOFromJSON(json['cadastralLocation']),
        'cadastralReference': !exists(json, 'cadastralReference') ? undefined : json['cadastralReference'],
        'cadastralSurface': !exists(json, 'cadastralSurface') ? undefined : json['cadastralSurface'],
        'cadastralUse': !exists(json, 'cadastralUse') ? undefined : json['cadastralUse'],
    };
}

export function NewUsageDTOToJSON(value?: NewUsageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cadastralLocation': NewCadastralLocationDTOToJSON(value.cadastralLocation),
        'cadastralReference': value.cadastralReference,
        'cadastralSurface': value.cadastralSurface,
        'cadastralUse': value.cadastralUse,
    };
}

