import { ColorsNewPdf } from "../../../../../constants";

export const selectBgColorDependingOnEnergyLetter = (value: string) => {
  switch (value) {
    case "A":
      return { backgroundColor: ColorsNewPdf.table1_background_letter_A };
    case "B":
      return { backgroundColor: ColorsNewPdf.table1_background_letter_B };
    case "C":
      return { backgroundColor: ColorsNewPdf.table1_background_letter_C };
    case "D":
      return { backgroundColor: ColorsNewPdf.table1_background_letter_D };
    case "E":
      return { backgroundColor: ColorsNewPdf.table1_background_letter_E };
    case "F":
      return { backgroundColor: ColorsNewPdf.table1_background_letter_F };
    case "G":
      return { backgroundColor: ColorsNewPdf.table1_background_letter_G };
    default:
      return { backgroundColor: ColorsNewPdf.table1_background_letter_A };
  }
};
