export function checkChecked(index: number, value?: number): boolean {
  if (value) {
    const b = reverseString(value.toString(2));
    if (parseInt(b.toString().charAt(index)) === 1) return true;
    return false;
  } else {
    return false;
  }
}

function reverseString(str: string) {
  return str.split("").reverse().join("");
}
