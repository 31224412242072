import {
  AccessibilityEvaluationDTO,
  AddActionRegistryDocumentationRequest,
  AddAgentByIdRequest,
  AgentParticipationDTO,
  BookDTO,
  BooksApi,
  BuildingCompositionDTO,
  BuildingConstructionDescriptionDTO,
  BuildingDTO,
  BuildingLocationInfoDTO,
  CecaSignatureResponse,
  CustomTechnicianParticipationPageDTO,
  DeleteAgentByIdRequest,
  EditAgentByIdRequest,
  EnergyEfficiencyCertificateDTO,
  GetBookByIdRequest,
  GetBooksByTechnicianByFilterRequest,
  GetNewPrintingBookByIdRequest,
  GetPolygonsByBookIdRequest,
  GetUsagePageByBookIdRequest,
  GetUsagesByBookIdRequest,
  ImprovementActionDTO,
  InitBooManualRequest,
  InitBookRequest,
  InitPaymentRequest,
  InspectionDTO,
  LegalDataDTO,
  LicenseDTO,
  MaintenancePlanDTO,
  MediaDTO,
  NewPrintingBookDTO,
  PolygonInfoDTO,
  PrintingBookDTO,
  ProceedingsDTO,
  ShortcomingDTO,
  TechnicianParticipationDTO,
  TechnicianStatisticsDTO,
  UpdatePropertiesRequest,
  UrbanDataDTO,
  UsageDTO,
  UsagePageDTO,
  UsagePlanDTO,
  ViewAdminTechnicianParticipationPageDTO,
} from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";
import { IBookApi } from "../ibook-api";

export class BookApi extends BaseAPI implements IBookApi {
  apiClient: BooksApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new BooksApi(configuration);
  }
  async getPolygonsByBookId(requestParameters: GetPolygonsByBookIdRequest, initOverrides?: RequestInit): Promise<PolygonInfoDTO> {
    return await this.apiClient.getPolygonsByBookId(requestParameters);
  }

  async initBooManual(init: InitBooManualRequest): Promise<BookDTO> {
    return await this.apiClient.initBooManual(init);
  }

  async deleteLicenseById(id: string, licenseId: string): Promise<boolean> {
    return await this.apiClient.deleteLicenseById({ id: id, licenseId: licenseId });
  }

  async deleteProceedingById(id: string, proceedingId: string): Promise<boolean> {
    return await this.apiClient.deleteProceedingById({ id: id, proceedingId: proceedingId });
  }

  async deleteAgentById(requestParameters: DeleteAgentByIdRequest): Promise<boolean> {
    return await this.apiClient.deleteAgentById(requestParameters);
  }

  async deleteMediaByIdFromBook(id: string, mediaId: string): Promise<boolean> {
    return await this.apiClient.deleteMediaByIdFromBook({ id: id, mediaId: mediaId });
  }

  async initPayment(initPaymentRequest: InitPaymentRequest): Promise<CecaSignatureResponse> {
    return await this.apiClient.initPayment(initPaymentRequest);
  }

  async addIeeDocumentation(id: string, mediaDTO: MediaDTO): Promise<boolean> {
    return await this.apiClient.addIeeDocumentation({ id: id, mediaDTO: mediaDTO });
  }

  async updateProperties(requestParameters: UpdatePropertiesRequest, initOverrides?: RequestInit): Promise<boolean> {
    return await this.apiClient.updateProperties(requestParameters);
  }

  async addIteDocumentation(id: string, mediaDTO: MediaDTO): Promise<boolean> {
    return await this.apiClient.addIteDocumentation({ id: id, mediaDTO: mediaDTO });
  }

  async addAccessibilityEvaluation(id: string, accesibilityEvaluation: AccessibilityEvaluationDTO): Promise<boolean> {
    return await this.apiClient.addAccessibilityEvaluation({ id: id, accessibilityEvaluationDTO: accesibilityEvaluation });
  }

  async addAgentById(agentParticipationDto: AddAgentByIdRequest): Promise<AgentParticipationDTO> {
    return await this.apiClient.addAgentById(agentParticipationDto);
  }

  async editAgentById(agentParticipationDto: EditAgentByIdRequest): Promise<AgentParticipationDTO> {
    return await this.apiClient.editAgentById(agentParticipationDto);
  }

  async addMaintenanceContractDocumentation(c: AddActionRegistryDocumentationRequest): Promise<boolean> {
    return await this.apiClient.addMaintenanceContractDocumentation(c);
  }
  async addBestPracticeDocumentation(c: AddActionRegistryDocumentationRequest): Promise<boolean> {
    return await this.apiClient.addBestPracticeDocumentation(c);
  }

  async addActionRegistryDocumentation(c: AddActionRegistryDocumentationRequest): Promise<boolean> {
    return await this.apiClient.addActionRegistryDocumentation(c);
  }

  async addBuildingNormalizedDescription(id: string, buildingConstructionDescriptionDto: BuildingConstructionDescriptionDTO): Promise<boolean> {
    return await this.apiClient.addBuildingNormalizedDescription({ id: id, buildingConstructionDescriptionDTO: buildingConstructionDescriptionDto });
  }

  async addEnergyEfficiencyCertificate(id: string, energyEfficiencyCertificateDto: EnergyEfficiencyCertificateDTO): Promise<boolean> {
    return await this.apiClient.addEnergyEfficiencyCertificate({ id: id, energyEfficiencyCertificateDTO: energyEfficiencyCertificateDto });
  }

  async addImprovementAction(id: string, improvementActionDto: ImprovementActionDTO): Promise<boolean> {
    return await this.apiClient.addImprovementAction({ id: id, improvementActionDTO: improvementActionDto });
  }

  async addInspection(id: string, inspectionDTO: InspectionDTO): Promise<boolean> {
    return await this.apiClient.addInspection({ id: id, inspectionDTO: inspectionDTO });
  }

  async addLicense(id: string, licenseDto: LicenseDTO): Promise<boolean> {
    return await this.apiClient.addLicense({ id: id, licenseDTO: licenseDto });
  }

  async addOtherDocumentation(id: string, bookMediaDto: MediaDTO): Promise<boolean> {
    return await this.apiClient.addOtherDocumentation({ id: id, mediaDTO: bookMediaDto });
  }

  async addProceedings(id: string, proceedingsDto: ProceedingsDTO): Promise<boolean> {
    return await this.apiClient.addProceedings({ id: id, proceedingsDTO: proceedingsDto });
  }

  async addShortcomingToInspection(id: string, inspectionId: string, shortcomingDTO: ShortcomingDTO): Promise<BookDTO> {
    return await this.apiClient.addShortcomingToInspection({ id: id, inspectionId: inspectionId, shortcomingDTO: shortcomingDTO });
  }

  async addTechnician(id: string, technician: TechnicianParticipationDTO): Promise<boolean> {
    return await this.apiClient.addTechnician({ id: id, technicianParticipationDTO: technician });
  }

  async deleteTechnician(id: string, credentialsId: string): Promise<boolean> {
    return await this.apiClient.deleteTechnicianParticipationById({ id: id, credentialsId: credentialsId });
  }

  async getBookById(req: GetBookByIdRequest): Promise<BookDTO> {
    return await this.apiClient.getBookById(req);
  }

  async getBooksByTechnician(offset: number, limit: number): Promise<CustomTechnicianParticipationPageDTO> {
    return await this.apiClient.getBooksByTechnicianWithPagination({ offset: offset, limit: limit });
  }

  async getBooksByTechnicianByFilter(params: GetBooksByTechnicianByFilterRequest): Promise<ViewAdminTechnicianParticipationPageDTO> {
    return await this.apiClient.getBooksByTechnicianByFilter(params);
  }

  async getPrintingBookById(id: string): Promise<PrintingBookDTO> {
    return await this.apiClient.getPrintingBookById({ id: id });
  }

  async initBook(requestParameters: InitBookRequest): Promise<BookDTO> {
    return await this.apiClient.initBook(requestParameters);
  }

  async setBuildingComposition(id: string, buildingCompositionDTO: BuildingCompositionDTO): Promise<boolean> {
    return await this.apiClient.setBuildingComposition({ id: id, buildingCompositionDTO: buildingCompositionDTO });
  }

  async setLegalData(id: string, legalDataDto: LegalDataDTO): Promise<boolean> {
    return await this.apiClient.setLegalData({ id: id, legalDataDTO: legalDataDto });
  }

  async setLocation(id: string, buildingLocationInfoDto: BuildingLocationInfoDTO): Promise<boolean> {
    return await this.apiClient.setLocation({ id: id, buildingLocationInfoDTO: buildingLocationInfoDto });
  }

  async setUrbanData(id: string, urbanDataDto: UrbanDataDTO): Promise<boolean> {
    return await this.apiClient.setUrbanData({ id: id, urbanDataDTO: urbanDataDto });
  }

  async validate(buildingDto: BuildingDTO): Promise<BookDTO> {
    return await this.apiClient.validate({ buildingDTO: buildingDto });
  }

  async getStatisticsFromTechnicianId(id: string): Promise<TechnicianStatisticsDTO> {
    return await this.apiClient.getStatisticsFromTechnicianId({ id: id });
  }

  async getUsagesByBookId(requestParameters: GetUsagesByBookIdRequest): Promise<Array<UsageDTO>> {
    return await this.apiClient.getUsagesByBookId(requestParameters);
  }

  async getNewPrintingBookById(requestParameters: GetNewPrintingBookByIdRequest, initOverrides?: RequestInit): Promise<NewPrintingBookDTO> {
    return await this.apiClient.getNewPrintingBookById(requestParameters);
  }
  async deleteBook(id: string): Promise<boolean> {
    return await this.apiClient.deleteBookById({ id: id });
  }

  async setMaintenance(id: string, maintenancePlanDto: MaintenancePlanDTO): Promise<boolean> {
    return await this.apiClient.addMaintenancePlan({ id: id, maintenancePlanDTO: maintenancePlanDto });
  }

  async addUsagePlan(id: string, usagePlan: UsagePlanDTO): Promise<boolean> {
    return await this.apiClient.addUsagePlan({ id: id, usagePlanDTO: usagePlan });
  }

  async getUsagePageByBookId(requestParameters: GetUsagePageByBookIdRequest, initOverrides?: RequestInit): Promise<UsagePageDTO> {
    return await this.apiClient.getUsagePageByBookId(requestParameters);
  }
}
