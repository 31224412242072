/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShortcomingDTO
 */
export interface ShortcomingDTO {
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    constructionEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    constructionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ShortcomingDTO
     */
    fieldId?: number;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof ShortcomingDTO
     */
    files?: Array<MediaDTO>;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    priority?: ShortcomingDTOPriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    section?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    status?: ShortcomingDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    subsection?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingDTO
     */
    testsMade?: string;
}

/**
* @export
* @enum {string}
*/
export enum ShortcomingDTOPriorityEnum {
    High = 'HIGH',
    Low = 'LOW',
    Medium = 'MEDIUM'
}/**
* @export
* @enum {string}
*/
export enum ShortcomingDTOStatusEnum {
    Favorable = 'FAVORABLE',
    Unfavorable = 'UNFAVORABLE'
}

export function ShortcomingDTOFromJSON(json: any): ShortcomingDTO {
    return ShortcomingDTOFromJSONTyped(json, false);
}

export function ShortcomingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShortcomingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'constructionEndDate': !exists(json, 'constructionEndDate') ? undefined : json['constructionEndDate'],
        'constructionStartDate': !exists(json, 'constructionStartDate') ? undefined : json['constructionStartDate'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fieldId': !exists(json, 'fieldId') ? undefined : json['fieldId'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(MediaDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'subsection': !exists(json, 'subsection') ? undefined : json['subsection'],
        'testsMade': !exists(json, 'testsMade') ? undefined : json['testsMade'],
    };
}

export function ShortcomingDTOToJSON(value?: ShortcomingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'constructionEndDate': value.constructionEndDate,
        'constructionStartDate': value.constructionStartDate,
        'description': value.description,
        'fieldId': value.fieldId,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(MediaDTOToJSON)),
        'id': value.id,
        'location': value.location,
        'name': value.name,
        'notes': value.notes,
        'priority': value.priority,
        'section': value.section,
        'status': value.status,
        'subsection': value.subsection,
        'testsMade': value.testsMade,
    };
}

