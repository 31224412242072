import { StyleSheet } from "@react-pdf/renderer";
import { fontsNewPdf } from "pdf/_common/fonts/fonts";

export const finalValuationStyles = StyleSheet.create({
  table: {
    display: "flex",
    flexDirection: "column",
    marginVertical: 10,
  },

  row: {
    flexDirection: "row",
    width: "100%",
  },

  cell: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1pt solid black",
    width: "50%",
    paddingHorizontal: "4px",
  },

  value_text: {
    fontFamily: fontsNewPdf.RobotoBold,
  },
});
