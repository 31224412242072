import { Circle, Path, Svg, View } from "@react-pdf/renderer";
import { AccessibleForwardPdfIcon } from "./AccesiblePdfIcon";
import { EngineeringPdfIcon } from "./EngineeringPdfIcon";

interface IconCircleProps {
  icon: "accesible" | "engineering";
  color: string;
  circleRadius?: number;
  left?: string;
  top?: string;
}

export const IconCircle = (props: IconCircleProps) => {
  const { icon, color, circleRadius = 12, left, top } = props;

  const selectedIconPath = icon === "accesible" ? <AccessibleForwardPdfIcon size={16} /> : <EngineeringPdfIcon size={16} />;
  return (
    <View style={{ position: "relative", left, top }}>
      <Svg width={24} height={24}>
        <Circle cx={circleRadius} cy={circleRadius} r={circleRadius} fill={color} fillOpacity="0.5" />
      </Svg>

      <View style={{ position: "absolute", top: "4px", left: "4px" }}>{selectedIconPath}</View>
    </View>
  );
};
