/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Values
 */
export interface Values {
    /**
     * 
     * @type {number}
     * @memberof Values
     */
    endDateTimestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof Values
     */
    startDateTimestamp?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Values
     */
    status?: Array<ValuesStatusEnum>;
}

/**
* @export
* @enum {string}
*/
export enum ValuesStatusEnum {
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
    Requested = 'REQUESTED',
    Used = 'USED'
}

export function ValuesFromJSON(json: any): Values {
    return ValuesFromJSONTyped(json, false);
}

export function ValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Values {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endDateTimestamp': !exists(json, 'endDateTimestamp') ? undefined : json['endDateTimestamp'],
        'startDateTimestamp': !exists(json, 'startDateTimestamp') ? undefined : json['startDateTimestamp'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function ValuesToJSON(value?: Values | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endDateTimestamp': value.endDateTimestamp,
        'startDateTimestamp': value.startDateTimestamp,
        'status': value.status,
    };
}

