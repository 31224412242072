/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicantDTO
 */
export interface ApplicantDTO {
    /**
     * 
     * @type {string}
     * @memberof ApplicantDTO
     */
    city?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicantDTO
     */
    dateCreated?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicantDTO
     */
    names?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApplicantDTO
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicantDTO
     */
    street?: string;
}

export function ApplicantDTOFromJSON(json: any): ApplicantDTO {
    return ApplicantDTOFromJSONTyped(json, false);
}

export function ApplicantDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicantDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': !exists(json, 'city') ? undefined : json['city'],
        'dateCreated': !exists(json, 'dateCreated') ? undefined : (new Date(json['dateCreated'])),
        'names': !exists(json, 'names') ? undefined : json['names'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'street': !exists(json, 'street') ? undefined : json['street'],
    };
}

export function ApplicantDTOToJSON(value?: ApplicantDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'dateCreated': value.dateCreated === undefined ? undefined : (value.dateCreated.toISOString()),
        'names': value.names,
        'number': value.number,
        'postalCode': value.postalCode,
        'street': value.street,
    };
}

