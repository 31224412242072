import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import IRepositoryPaymentGateway from "../../../infrastructure/data/repositories/irepository-payment-gateway";

export default class PaymentGatewayRepository extends BaseRepository<IRepositoryPaymentGateway> {

    constructor() { super(Api.PaymentGatewayApi, false); }
  
    async userHasVouchers(): Promise<Boolean> {
      const client = await this.apiClient;
      return await client.userHasVouchers();
    }

    async transactionIdsByPendingVouchers(): Promise<Array<string>> {
      return (await this.apiClient).transactionIdsByPendingVouchers();
    }
  }