import {
  AccessibilityEvaluationDTOFinalResultEnum,
  InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum,
  ShortcomingPrintedDTOPriorityEnum,
} from "clients";
import { ColorsNewPdf } from "../../../../../constants";
import { t } from "pdf/documents/book/book-v2/book-helpers";

//TODO: REFACTOR: Change translation file and get enum values using keys
export const getDeficiencyPriorityIndicatorProperties = (priority?: ShortcomingPrintedDTOPriorityEnum) => {
  let title: string;
  let bgColor: string;
  switch (priority) {
    case ShortcomingPrintedDTOPriorityEnum.Low:
      bgColor = ColorsNewPdf.text_yellow_info_table;
      title = t("pdf.block1.shortcomings.radio.label.low");
      break;
    case ShortcomingPrintedDTOPriorityEnum.Medium:
      bgColor = ColorsNewPdf.text_orange_info_table;
      title = t("pdf.block1.shortcomings.radio.label.medium");
      break;
    case ShortcomingPrintedDTOPriorityEnum.High:
      bgColor = ColorsNewPdf.text_red_info_table;
      title = t("pdf.block1.shortcomings.radio.label.high");
      break;
    default:
      bgColor = "white";
      title = "";
      break;
  }
  return { title, bgColor };
};

export const getPartialValorationIndicatorProperties = (result?: string) => {
  let color: string;
  let keyPrefix: string = "pdf.final_valorations.deficiencies.finalresult.";
  let title: string = result ? t(keyPrefix + result) : "-";
  switch (result) {
    case InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum.Approval:
      color = ColorsNewPdf.green_text;
      break;
    case InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum.MinorDeficienciesApproval:
      color = ColorsNewPdf.text_yellow_info_table;
      break;
    case InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum.SeriousShortcomingsRejection:
      color = ColorsNewPdf.text_orange_info_table;
      break;
    case InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum.VerySeriousShortcomingsRejection:
      color = ColorsNewPdf.text_red_info_table;
      break;
    default:
      color = "white";
      break;
  }
  return { title, color };
};

export const getFinalAccessibilityValorationIndicatorProperties = (accessibilityFinalResult?: string) => {
  let color: string;
  const keyPrefix: string = "pdf.final_valorations.deficiencies.finalresult.";
  let title: string = accessibilityFinalResult ? t(keyPrefix + accessibilityFinalResult) : "-";
  switch (accessibilityFinalResult) {
    case AccessibilityEvaluationDTOFinalResultEnum.Approval:
      color = ColorsNewPdf.green_text;
      break;

    case AccessibilityEvaluationDTOFinalResultEnum.MinorDeficienciesApproval:
      color = ColorsNewPdf.text_yellow_info_table;
      break;

    case AccessibilityEvaluationDTOFinalResultEnum.SeriousShortcomingsRejection:
      color = ColorsNewPdf.text_orange_info_table;
      break;

    case AccessibilityEvaluationDTOFinalResultEnum.VerySeriousShortcomingsRejection:
      color = ColorsNewPdf.text_red_info_table;
      break;

    default:
      color = "white";
      break;
  }

  return { title, color };
};
