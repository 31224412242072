/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApplicantDTO,
    ApplicantDTOFromJSON,
    ApplicantDTOFromJSONTyped,
    ApplicantDTOToJSON,
    GeneralBuildingDataPrintedDTO,
    GeneralBuildingDataPrintedDTOFromJSON,
    GeneralBuildingDataPrintedDTOFromJSONTyped,
    GeneralBuildingDataPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PrintingBookDTO
 */
export interface PrintingBookDTO {
    /**
     * 
     * @type {ApplicantDTO}
     * @memberof PrintingBookDTO
     */
    applicant?: ApplicantDTO;
    /**
     * 
     * @type {GeneralBuildingDataPrintedDTO}
     * @memberof PrintingBookDTO
     */
    generalBuildingData?: GeneralBuildingDataPrintedDTO;
}

export function PrintingBookDTOFromJSON(json: any): PrintingBookDTO {
    return PrintingBookDTOFromJSONTyped(json, false);
}

export function PrintingBookDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrintingBookDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicant': !exists(json, 'applicant') ? undefined : ApplicantDTOFromJSON(json['applicant']),
        'generalBuildingData': !exists(json, 'generalBuildingData') ? undefined : GeneralBuildingDataPrintedDTOFromJSON(json['generalBuildingData']),
    };
}

export function PrintingBookDTOToJSON(value?: PrintingBookDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicant': ApplicantDTOToJSON(value.applicant),
        'generalBuildingData': GeneralBuildingDataPrintedDTOToJSON(value.generalBuildingData),
    };
}

