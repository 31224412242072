/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
    UsageDTO,
    UsageDTOFromJSON,
    UsageDTOFromJSONTyped,
    UsageDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuildingCompositionDTO
 */
export interface BuildingCompositionDTO {
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    builtArea?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    builtOnYear?: number;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof BuildingCompositionDTO
     */
    complementaryFiles?: Array<MediaDTO>;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    dwellings?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    dwellingsByFloor?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    elevatorsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    floorsOverGround?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    floorsUnderGround?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    heightOverGround?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    lastRehabYear?: number;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof BuildingCompositionDTO
     */
    media?: Array<MediaDTO>;
    /**
     * 
     * @type {string}
     * @memberof BuildingCompositionDTO
     */
    otherElements?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    parcelArea?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    parkingCountInBuilding?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    parkingPlacesInBuilding?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    premisesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    sharedPremisesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    stairsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingCompositionDTO
     */
    storageRoomsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingCompositionDTO
     */
    typology?: BuildingCompositionDTOTypologyEnum;
    /**
     * 
     * @type {Array<UsageDTO>}
     * @memberof BuildingCompositionDTO
     */
    usages?: Array<UsageDTO>;
}

/**
* @export
* @enum {string}
*/
export enum BuildingCompositionDTOTypologyEnum {
    ClosedBlockBuilding = 'CLOSED_BLOCK_BUILDING',
    IsolatedBuilding = 'ISOLATED_BUILDING'
}

export function BuildingCompositionDTOFromJSON(json: any): BuildingCompositionDTO {
    return BuildingCompositionDTOFromJSONTyped(json, false);
}

export function BuildingCompositionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingCompositionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'builtArea': !exists(json, 'builtArea') ? undefined : json['builtArea'],
        'builtOnYear': !exists(json, 'builtOnYear') ? undefined : json['builtOnYear'],
        'complementaryFiles': !exists(json, 'complementaryFiles') ? undefined : ((json['complementaryFiles'] as Array<any>).map(MediaDTOFromJSON)),
        'dwellings': !exists(json, 'dwellings') ? undefined : json['dwellings'],
        'dwellingsByFloor': !exists(json, 'dwellingsByFloor') ? undefined : json['dwellingsByFloor'],
        'elevatorsCount': !exists(json, 'elevatorsCount') ? undefined : json['elevatorsCount'],
        'floorsOverGround': !exists(json, 'floorsOverGround') ? undefined : json['floorsOverGround'],
        'floorsUnderGround': !exists(json, 'floorsUnderGround') ? undefined : json['floorsUnderGround'],
        'heightOverGround': !exists(json, 'heightOverGround') ? undefined : json['heightOverGround'],
        'lastRehabYear': !exists(json, 'lastRehabYear') ? undefined : json['lastRehabYear'],
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(MediaDTOFromJSON)),
        'otherElements': !exists(json, 'otherElements') ? undefined : json['otherElements'],
        'parcelArea': !exists(json, 'parcelArea') ? undefined : json['parcelArea'],
        'parkingCountInBuilding': !exists(json, 'parkingCountInBuilding') ? undefined : json['parkingCountInBuilding'],
        'parkingPlacesInBuilding': !exists(json, 'parkingPlacesInBuilding') ? undefined : json['parkingPlacesInBuilding'],
        'premisesCount': !exists(json, 'premisesCount') ? undefined : json['premisesCount'],
        'sharedPremisesCount': !exists(json, 'sharedPremisesCount') ? undefined : json['sharedPremisesCount'],
        'stairsCount': !exists(json, 'stairsCount') ? undefined : json['stairsCount'],
        'storageRoomsCount': !exists(json, 'storageRoomsCount') ? undefined : json['storageRoomsCount'],
        'typology': !exists(json, 'typology') ? undefined : json['typology'],
        'usages': !exists(json, 'usages') ? undefined : ((json['usages'] as Array<any>).map(UsageDTOFromJSON)),
    };
}

export function BuildingCompositionDTOToJSON(value?: BuildingCompositionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'builtArea': value.builtArea,
        'builtOnYear': value.builtOnYear,
        'complementaryFiles': value.complementaryFiles === undefined ? undefined : ((value.complementaryFiles as Array<any>).map(MediaDTOToJSON)),
        'dwellings': value.dwellings,
        'dwellingsByFloor': value.dwellingsByFloor,
        'elevatorsCount': value.elevatorsCount,
        'floorsOverGround': value.floorsOverGround,
        'floorsUnderGround': value.floorsUnderGround,
        'heightOverGround': value.heightOverGround,
        'lastRehabYear': value.lastRehabYear,
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(MediaDTOToJSON)),
        'otherElements': value.otherElements,
        'parcelArea': value.parcelArea,
        'parkingCountInBuilding': value.parkingCountInBuilding,
        'parkingPlacesInBuilding': value.parkingPlacesInBuilding,
        'premisesCount': value.premisesCount,
        'sharedPremisesCount': value.sharedPremisesCount,
        'stairsCount': value.stairsCount,
        'storageRoomsCount': value.storageRoomsCount,
        'typology': value.typology,
        'usages': value.usages === undefined ? undefined : ((value.usages as Array<any>).map(UsageDTOToJSON)),
    };
}

