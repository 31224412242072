/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessibilityEvaluationTechnicianDTO,
    AccessibilityEvaluationTechnicianDTOFromJSON,
    AccessibilityEvaluationTechnicianDTOFromJSONTyped,
    AccessibilityEvaluationTechnicianDTOToJSON,
    AccessibleElementsDTO,
    AccessibleElementsDTOFromJSON,
    AccessibleElementsDTOFromJSONTyped,
    AccessibleElementsDTOToJSON,
    FunctionalConditionsDTO,
    FunctionalConditionsDTOFromJSON,
    FunctionalConditionsDTOFromJSONTyped,
    FunctionalConditionsDTOToJSON,
    InformationAndSignsDTO,
    InformationAndSignsDTOFromJSON,
    InformationAndSignsDTOFromJSONTyped,
    InformationAndSignsDTOToJSON,
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewAccessibilityEvaluationsDTO
 */
export interface NewAccessibilityEvaluationsDTO {
    /**
     * 
     * @type {AccessibleElementsDTO}
     * @memberof NewAccessibilityEvaluationsDTO
     */
    accessibleElements?: AccessibleElementsDTO;
    /**
     * 
     * @type {FunctionalConditionsDTO}
     * @memberof NewAccessibilityEvaluationsDTO
     */
    buildingFunctionalConditions?: FunctionalConditionsDTO;
    /**
     * 
     * @type {boolean}
     * @memberof NewAccessibilityEvaluationsDTO
     */
    excludeDataToFill?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewAccessibilityEvaluationsDTO
     */
    finalResult?: string;
    /**
     * 
     * @type {InformationAndSignsDTO}
     * @memberof NewAccessibilityEvaluationsDTO
     */
    infoAndSigns?: InformationAndSignsDTO;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof NewAccessibilityEvaluationsDTO
     */
    media?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {AccessibilityEvaluationTechnicianDTO}
     * @memberof NewAccessibilityEvaluationsDTO
     */
    technicianIdentification?: AccessibilityEvaluationTechnicianDTO;
}

export function NewAccessibilityEvaluationsDTOFromJSON(json: any): NewAccessibilityEvaluationsDTO {
    return NewAccessibilityEvaluationsDTOFromJSONTyped(json, false);
}

export function NewAccessibilityEvaluationsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewAccessibilityEvaluationsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibleElements': !exists(json, 'accessibleElements') ? undefined : AccessibleElementsDTOFromJSON(json['accessibleElements']),
        'buildingFunctionalConditions': !exists(json, 'buildingFunctionalConditions') ? undefined : FunctionalConditionsDTOFromJSON(json['buildingFunctionalConditions']),
        'excludeDataToFill': !exists(json, 'excludeDataToFill') ? undefined : json['excludeDataToFill'],
        'finalResult': !exists(json, 'finalResult') ? undefined : json['finalResult'],
        'infoAndSigns': !exists(json, 'infoAndSigns') ? undefined : InformationAndSignsDTOFromJSON(json['infoAndSigns']),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'technicianIdentification': !exists(json, 'technicianIdentification') ? undefined : AccessibilityEvaluationTechnicianDTOFromJSON(json['technicianIdentification']),
    };
}

export function NewAccessibilityEvaluationsDTOToJSON(value?: NewAccessibilityEvaluationsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibleElements': AccessibleElementsDTOToJSON(value.accessibleElements),
        'buildingFunctionalConditions': FunctionalConditionsDTOToJSON(value.buildingFunctionalConditions),
        'excludeDataToFill': value.excludeDataToFill,
        'finalResult': value.finalResult,
        'infoAndSigns': InformationAndSignsDTOToJSON(value.infoAndSigns),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(MediaPrintedDTOToJSON)),
        'technicianIdentification': AccessibilityEvaluationTechnicianDTOToJSON(value.technicianIdentification),
    };
}

