import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { ImprovementDTO } from "clients";
import { checkboxDefinitions } from "pdf/documents/book/book-v2/components/table-checkboxes/checkbox-definitions";
import React from "react";
import { checkChecked } from "../../utils/render-checked-values-helper";
import { ColorsNewPdf } from "../../../../../../constants";

interface ImprovementIconsRowProps {
  improvements?: ImprovementDTO[];
}

export const ImprovementIconsRow: React.FC<ImprovementIconsRowProps> = ({ improvements }) => {
  const allConceptDefinitions = { ...checkboxDefinitions.termic, ...checkboxDefinitions.instalation, ...checkboxDefinitions.others };

  return <View style={styles.row}>{improvements ? getCheckedIcons() : null}</View>;

  function getCheckedIcons(): JSX.Element[] {
    return Object.entries(allConceptDefinitions)
      .filter(([key]) => improvements?.some((improvement) => checkChecked(Number(key), improvement.conceptOfImprovementAccordingToGuide)))
      .map(([key]) => (
        <View key={key} style={styles.icon}>
          <Text style={styles.text}>{key}</Text>
        </View>
      ));
  }
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
  },
  icon: {
    width: 20,
    height: 20,
    borderRadius: 15,
    borderColor: "black",
    borderWidth: 0.5,
    backgroundColor: ColorsNewPdf.eec_table_background,
    justifyContent: "center",
    alignItems: "center",
    margin: 5,
  },
  text: {
    color: "black",
    fontSize: 12,
  },
});
