/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillingEntityDTO,
    BillingEntityDTOFromJSON,
    BillingEntityDTOFromJSONTyped,
    BillingEntityDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BillDTO
 */
export interface BillDTO {
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    billNumber?: string;
    /**
     * 
     * @type {BillingEntityDTO}
     * @memberof BillDTO
     */
    billingEntity?: BillingEntityDTO;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    bookId?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    cadastralReference?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof BillDTO
     */
    paymentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    propertyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    receptorBillingAddress?: string;
    /**
     * 
     * @type {number}
     * @memberof BillDTO
     */
    receptorBillingPostalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    receptorBillingProvince?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    receptorBillingTown?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    receptorIdNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    receptorName?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    receptorSurname?: string;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    status?: BillDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BillDTO
     */
    type?: BillDTOTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof BillDTO
     */
    vat?: number;
}

/**
* @export
* @enum {string}
*/
export enum BillDTOStatusEnum {
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
    Requested = 'REQUESTED'
}/**
* @export
* @enum {string}
*/
export enum BillDTOTypeEnum {
    Maintenance = 'MAINTENANCE',
    Purchase = 'PURCHASE'
}

export function BillDTOFromJSON(json: any): BillDTO {
    return BillDTOFromJSONTyped(json, false);
}

export function BillDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'billNumber': !exists(json, 'billNumber') ? undefined : json['billNumber'],
        'billingEntity': !exists(json, 'billingEntity') ? undefined : BillingEntityDTOFromJSON(json['billingEntity']),
        'bookId': !exists(json, 'bookId') ? undefined : json['bookId'],
        'cadastralReference': !exists(json, 'cadastralReference') ? undefined : json['cadastralReference'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'paymentDate': !exists(json, 'paymentDate') ? undefined : (new Date(json['paymentDate'])),
        'propertyAddress': !exists(json, 'propertyAddress') ? undefined : json['propertyAddress'],
        'receptorBillingAddress': !exists(json, 'receptorBillingAddress') ? undefined : json['receptorBillingAddress'],
        'receptorBillingPostalCode': !exists(json, 'receptorBillingPostalCode') ? undefined : json['receptorBillingPostalCode'],
        'receptorBillingProvince': !exists(json, 'receptorBillingProvince') ? undefined : json['receptorBillingProvince'],
        'receptorBillingTown': !exists(json, 'receptorBillingTown') ? undefined : json['receptorBillingTown'],
        'receptorIdNumber': !exists(json, 'receptorIdNumber') ? undefined : json['receptorIdNumber'],
        'receptorName': !exists(json, 'receptorName') ? undefined : json['receptorName'],
        'receptorSurname': !exists(json, 'receptorSurname') ? undefined : json['receptorSurname'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
    };
}

export function BillDTOToJSON(value?: BillDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'billNumber': value.billNumber,
        'billingEntity': BillingEntityDTOToJSON(value.billingEntity),
        'bookId': value.bookId,
        'cadastralReference': value.cadastralReference,
        'id': value.id,
        'paymentDate': value.paymentDate === undefined ? undefined : (value.paymentDate.toISOString()),
        'propertyAddress': value.propertyAddress,
        'receptorBillingAddress': value.receptorBillingAddress,
        'receptorBillingPostalCode': value.receptorBillingPostalCode,
        'receptorBillingProvince': value.receptorBillingProvince,
        'receptorBillingTown': value.receptorBillingTown,
        'receptorIdNumber': value.receptorIdNumber,
        'receptorName': value.receptorName,
        'receptorSurname': value.receptorSurname,
        'status': value.status,
        'type': value.type,
        'vat': value.vat,
    };
}

