/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    MultimediaDTO,
    MultimediaDTOFromJSON,
    MultimediaDTOToJSON,
} from '../models';

export interface CreateExternalMultimediaRequest {
    multimediaDTO?: MultimediaDTO;
}

export interface DeleteMultimediaRequest {
    id: string;
    maintainFile: boolean;
}

export interface GetAllMultimediaRequest {
    offset?: number;
    limit?: number;
}

export interface GetMultimediaByIDRequest {
    id: string;
}

export interface MultimediaUploadRequest {
    body: Blob;
}

export interface UpdateMultimediaRequest {
    id: string;
    maintainFile: boolean;
    body: Blob;
}

/**
 * 
 */
export class MediaApi extends runtime.BaseAPI {

    /**
     * Create external multimedia resource.
     * Create external multimedia resource.
     */
    async createExternalMultimediaRaw(requestParameters: CreateExternalMultimediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MultimediaDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media/external`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultimediaDTOToJSON(requestParameters.multimediaDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Create external multimedia resource.
     * Create external multimedia resource.
     */
    async createExternalMultimedia(requestParameters: CreateExternalMultimediaRequest, initOverrides?: RequestInit): Promise<MultimediaDTO> {
        const response = await this.createExternalMultimediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing Multimedia, deletes it, and returns the new object.
     * Delete an existing Multimedia by id.
     */
    async deleteMultimediaRaw(requestParameters: DeleteMultimediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MultimediaDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMultimedia.');
        }

        if (requestParameters.maintainFile === null || requestParameters.maintainFile === undefined) {
            throw new runtime.RequiredError('maintainFile','Required parameter requestParameters.maintainFile was null or undefined when calling deleteMultimedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.maintainFile !== undefined) {
            queryParameters['maintainFile'] = requestParameters.maintainFile;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing Multimedia, deletes it, and returns the new object.
     * Delete an existing Multimedia by id.
     */
    async deleteMultimedia(requestParameters: DeleteMultimediaRequest, initOverrides?: RequestInit): Promise<MultimediaDTO> {
        const response = await this.deleteMultimediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the multimedia.
     * Get all Multimedia objects.
     */
    async getAllMultimediaRaw(requestParameters: GetAllMultimediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MultimediaDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MultimediaDTOFromJSON));
    }

    /**
     * Returns all the multimedia.
     * Get all Multimedia objects.
     */
    async getAllMultimedia(requestParameters: GetAllMultimediaRequest, initOverrides?: RequestInit): Promise<Array<MultimediaDTO>> {
        const response = await this.getAllMultimediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one Multimedia object by id.
     * Get a Multimedia object by id.
     */
    async getMultimediaByIDRaw(requestParameters: GetMultimediaByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MultimediaDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMultimediaByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Returns one Multimedia object by id.
     * Get a Multimedia object by id.
     */
    async getMultimediaByID(requestParameters: GetMultimediaByIDRequest, initOverrides?: RequestInit): Promise<MultimediaDTO> {
        const response = await this.getMultimediaByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads a file and returns a multimedia object.
     * Uploads a file.
     */
    async multimediaUploadRaw(requestParameters: MultimediaUploadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MultimediaDTO>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling multimediaUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/media`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Uploads a file and returns a multimedia object.
     * Uploads a file.
     */
    async multimediaUpload(requestParameters: MultimediaUploadRequest, initOverrides?: RequestInit): Promise<MultimediaDTO> {
        const response = await this.multimediaUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing Multimedia object, updates it, and returns the object.
     * Update an existing Multimedia.
     */
    async updateMultimediaRaw(requestParameters: UpdateMultimediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MultimediaDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMultimedia.');
        }

        if (requestParameters.maintainFile === null || requestParameters.maintainFile === undefined) {
            throw new runtime.RequiredError('maintainFile','Required parameter requestParameters.maintainFile was null or undefined when calling updateMultimedia.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateMultimedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.maintainFile !== undefined) {
            queryParameters['maintain-file'] = requestParameters.maintainFile;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/media/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MultimediaDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing Multimedia object, updates it, and returns the object.
     * Update an existing Multimedia.
     */
    async updateMultimedia(requestParameters: UpdateMultimediaRequest, initOverrides?: RequestInit): Promise<MultimediaDTO> {
        const response = await this.updateMultimediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
