/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface HealthinessImprovementPrintedDTO
 */
export interface HealthinessImprovementPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    commonWasteStorage?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    commonWasteStorageMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    commonZonesVentilation?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    commonZonesVentilationMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    dripIrrigation?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    dripIrrigationMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    garageAiring?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    garageAiringMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    houseAiring?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    houseAiringMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    poolWaterTreatment?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    poolWaterTreatmentMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    protectionMeasuresOnRiskZones?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    protectionMeasuresOnRiskZonesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    singularElementsWaterTreatment?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    singularElementsWaterTreatmentMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    smellOnCommonWasteStorage?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    smellOnCommonWasteStorageMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    storageRoomAiring?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    storageRoomAiringMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    wasteWarehouseAiring?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    wasteWarehouseAiringMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterMeteringIndividualDevices?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterMeteringIndividualDevicesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterSavingDevices?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterSavingDevicesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterSavingDevicesOnHouses?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterSavingDevicesOnHousesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterSupplyConditions?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterSupplyConditionsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterTreatmentSystems?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof HealthinessImprovementPrintedDTO
     */
    waterTreatmentSystemsMedia?: MediaPrintedDTO;
}

export function HealthinessImprovementPrintedDTOFromJSON(json: any): HealthinessImprovementPrintedDTO {
    return HealthinessImprovementPrintedDTOFromJSONTyped(json, false);
}

export function HealthinessImprovementPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthinessImprovementPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commonWasteStorage': !exists(json, 'commonWasteStorage') ? undefined : json['commonWasteStorage'],
        'commonWasteStorageMedia': !exists(json, 'commonWasteStorageMedia') ? undefined : MediaPrintedDTOFromJSON(json['commonWasteStorageMedia']),
        'commonZonesVentilation': !exists(json, 'commonZonesVentilation') ? undefined : json['commonZonesVentilation'],
        'commonZonesVentilationMedia': !exists(json, 'commonZonesVentilationMedia') ? undefined : MediaPrintedDTOFromJSON(json['commonZonesVentilationMedia']),
        'dripIrrigation': !exists(json, 'dripIrrigation') ? undefined : json['dripIrrigation'],
        'dripIrrigationMedia': !exists(json, 'dripIrrigationMedia') ? undefined : MediaPrintedDTOFromJSON(json['dripIrrigationMedia']),
        'garageAiring': !exists(json, 'garageAiring') ? undefined : json['garageAiring'],
        'garageAiringMedia': !exists(json, 'garageAiringMedia') ? undefined : MediaPrintedDTOFromJSON(json['garageAiringMedia']),
        'houseAiring': !exists(json, 'houseAiring') ? undefined : json['houseAiring'],
        'houseAiringMedia': !exists(json, 'houseAiringMedia') ? undefined : MediaPrintedDTOFromJSON(json['houseAiringMedia']),
        'poolWaterTreatment': !exists(json, 'poolWaterTreatment') ? undefined : json['poolWaterTreatment'],
        'poolWaterTreatmentMedia': !exists(json, 'poolWaterTreatmentMedia') ? undefined : MediaPrintedDTOFromJSON(json['poolWaterTreatmentMedia']),
        'protectionMeasuresOnRiskZones': !exists(json, 'protectionMeasuresOnRiskZones') ? undefined : json['protectionMeasuresOnRiskZones'],
        'protectionMeasuresOnRiskZonesMedia': !exists(json, 'protectionMeasuresOnRiskZonesMedia') ? undefined : MediaPrintedDTOFromJSON(json['protectionMeasuresOnRiskZonesMedia']),
        'singularElementsWaterTreatment': !exists(json, 'singularElementsWaterTreatment') ? undefined : json['singularElementsWaterTreatment'],
        'singularElementsWaterTreatmentMedia': !exists(json, 'singularElementsWaterTreatmentMedia') ? undefined : MediaPrintedDTOFromJSON(json['singularElementsWaterTreatmentMedia']),
        'smellOnCommonWasteStorage': !exists(json, 'smellOnCommonWasteStorage') ? undefined : json['smellOnCommonWasteStorage'],
        'smellOnCommonWasteStorageMedia': !exists(json, 'smellOnCommonWasteStorageMedia') ? undefined : MediaPrintedDTOFromJSON(json['smellOnCommonWasteStorageMedia']),
        'storageRoomAiring': !exists(json, 'storageRoomAiring') ? undefined : json['storageRoomAiring'],
        'storageRoomAiringMedia': !exists(json, 'storageRoomAiringMedia') ? undefined : MediaPrintedDTOFromJSON(json['storageRoomAiringMedia']),
        'wasteWarehouseAiring': !exists(json, 'wasteWarehouseAiring') ? undefined : json['wasteWarehouseAiring'],
        'wasteWarehouseAiringMedia': !exists(json, 'wasteWarehouseAiringMedia') ? undefined : MediaPrintedDTOFromJSON(json['wasteWarehouseAiringMedia']),
        'waterMeteringIndividualDevices': !exists(json, 'waterMeteringIndividualDevices') ? undefined : json['waterMeteringIndividualDevices'],
        'waterMeteringIndividualDevicesMedia': !exists(json, 'waterMeteringIndividualDevicesMedia') ? undefined : MediaPrintedDTOFromJSON(json['waterMeteringIndividualDevicesMedia']),
        'waterSavingDevices': !exists(json, 'waterSavingDevices') ? undefined : json['waterSavingDevices'],
        'waterSavingDevicesMedia': !exists(json, 'waterSavingDevicesMedia') ? undefined : MediaPrintedDTOFromJSON(json['waterSavingDevicesMedia']),
        'waterSavingDevicesOnHouses': !exists(json, 'waterSavingDevicesOnHouses') ? undefined : json['waterSavingDevicesOnHouses'],
        'waterSavingDevicesOnHousesMedia': !exists(json, 'waterSavingDevicesOnHousesMedia') ? undefined : MediaPrintedDTOFromJSON(json['waterSavingDevicesOnHousesMedia']),
        'waterSupplyConditions': !exists(json, 'waterSupplyConditions') ? undefined : json['waterSupplyConditions'],
        'waterSupplyConditionsMedia': !exists(json, 'waterSupplyConditionsMedia') ? undefined : MediaPrintedDTOFromJSON(json['waterSupplyConditionsMedia']),
        'waterTreatmentSystems': !exists(json, 'waterTreatmentSystems') ? undefined : json['waterTreatmentSystems'],
        'waterTreatmentSystemsMedia': !exists(json, 'waterTreatmentSystemsMedia') ? undefined : MediaPrintedDTOFromJSON(json['waterTreatmentSystemsMedia']),
    };
}

export function HealthinessImprovementPrintedDTOToJSON(value?: HealthinessImprovementPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commonWasteStorage': value.commonWasteStorage,
        'commonWasteStorageMedia': MediaPrintedDTOToJSON(value.commonWasteStorageMedia),
        'commonZonesVentilation': value.commonZonesVentilation,
        'commonZonesVentilationMedia': MediaPrintedDTOToJSON(value.commonZonesVentilationMedia),
        'dripIrrigation': value.dripIrrigation,
        'dripIrrigationMedia': MediaPrintedDTOToJSON(value.dripIrrigationMedia),
        'garageAiring': value.garageAiring,
        'garageAiringMedia': MediaPrintedDTOToJSON(value.garageAiringMedia),
        'houseAiring': value.houseAiring,
        'houseAiringMedia': MediaPrintedDTOToJSON(value.houseAiringMedia),
        'poolWaterTreatment': value.poolWaterTreatment,
        'poolWaterTreatmentMedia': MediaPrintedDTOToJSON(value.poolWaterTreatmentMedia),
        'protectionMeasuresOnRiskZones': value.protectionMeasuresOnRiskZones,
        'protectionMeasuresOnRiskZonesMedia': MediaPrintedDTOToJSON(value.protectionMeasuresOnRiskZonesMedia),
        'singularElementsWaterTreatment': value.singularElementsWaterTreatment,
        'singularElementsWaterTreatmentMedia': MediaPrintedDTOToJSON(value.singularElementsWaterTreatmentMedia),
        'smellOnCommonWasteStorage': value.smellOnCommonWasteStorage,
        'smellOnCommonWasteStorageMedia': MediaPrintedDTOToJSON(value.smellOnCommonWasteStorageMedia),
        'storageRoomAiring': value.storageRoomAiring,
        'storageRoomAiringMedia': MediaPrintedDTOToJSON(value.storageRoomAiringMedia),
        'wasteWarehouseAiring': value.wasteWarehouseAiring,
        'wasteWarehouseAiringMedia': MediaPrintedDTOToJSON(value.wasteWarehouseAiringMedia),
        'waterMeteringIndividualDevices': value.waterMeteringIndividualDevices,
        'waterMeteringIndividualDevicesMedia': MediaPrintedDTOToJSON(value.waterMeteringIndividualDevicesMedia),
        'waterSavingDevices': value.waterSavingDevices,
        'waterSavingDevicesMedia': MediaPrintedDTOToJSON(value.waterSavingDevicesMedia),
        'waterSavingDevicesOnHouses': value.waterSavingDevicesOnHouses,
        'waterSavingDevicesOnHousesMedia': MediaPrintedDTOToJSON(value.waterSavingDevicesOnHousesMedia),
        'waterSupplyConditions': value.waterSupplyConditions,
        'waterSupplyConditionsMedia': MediaPrintedDTOToJSON(value.waterSupplyConditionsMedia),
        'waterTreatmentSystems': value.waterTreatmentSystems,
        'waterTreatmentSystemsMedia': MediaPrintedDTOToJSON(value.waterTreatmentSystemsMedia),
    };
}

