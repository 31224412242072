/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewInspectionDocumentationDTO
 */
export interface NewInspectionDocumentationDTO {
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    commonBuildingFacilitiesDeficienciesFinalResult?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    commonBuildingFacilitiesDeficienciesNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    finalResult?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    finalResultNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    foundationDeficienciesFinalResult?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    foundationDeficienciesNotes?: string;
    /**
     * 
     * @type {number}
     * @memberof NewInspectionDocumentationDTO
     */
    homeInspected?: number;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    obstacle?: string;
    /**
     * 
     * @type {number}
     * @memberof NewInspectionDocumentationDTO
     */
    otherElementsInspected?: number;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    roofsAndCoveringsDeficienciesFinalResult?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    roofsAndCoveringsDeficienciesNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    securityMeasures?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    structuralDeficienciesFinalResult?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    structuralDeficienciesNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    testsDone?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    usedTools?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    verticalEnclosuresDeficienciesFinalResult?: string;
    /**
     * 
     * @type {string}
     * @memberof NewInspectionDocumentationDTO
     */
    verticalEnclosuresDeficienciesNotes?: string;
}

export function NewInspectionDocumentationDTOFromJSON(json: any): NewInspectionDocumentationDTO {
    return NewInspectionDocumentationDTOFromJSONTyped(json, false);
}

export function NewInspectionDocumentationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewInspectionDocumentationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commonBuildingFacilitiesDeficienciesFinalResult': !exists(json, 'commonBuildingFacilitiesDeficienciesFinalResult') ? undefined : json['commonBuildingFacilitiesDeficienciesFinalResult'],
        'commonBuildingFacilitiesDeficienciesNotes': !exists(json, 'commonBuildingFacilitiesDeficienciesNotes') ? undefined : json['commonBuildingFacilitiesDeficienciesNotes'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'finalResult': !exists(json, 'finalResult') ? undefined : json['finalResult'],
        'finalResultNotes': !exists(json, 'finalResultNotes') ? undefined : json['finalResultNotes'],
        'foundationDeficienciesFinalResult': !exists(json, 'foundationDeficienciesFinalResult') ? undefined : json['foundationDeficienciesFinalResult'],
        'foundationDeficienciesNotes': !exists(json, 'foundationDeficienciesNotes') ? undefined : json['foundationDeficienciesNotes'],
        'homeInspected': !exists(json, 'homeInspected') ? undefined : json['homeInspected'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'obstacle': !exists(json, 'obstacle') ? undefined : json['obstacle'],
        'otherElementsInspected': !exists(json, 'otherElementsInspected') ? undefined : json['otherElementsInspected'],
        'roofsAndCoveringsDeficienciesFinalResult': !exists(json, 'roofsAndCoveringsDeficienciesFinalResult') ? undefined : json['roofsAndCoveringsDeficienciesFinalResult'],
        'roofsAndCoveringsDeficienciesNotes': !exists(json, 'roofsAndCoveringsDeficienciesNotes') ? undefined : json['roofsAndCoveringsDeficienciesNotes'],
        'securityMeasures': !exists(json, 'securityMeasures') ? undefined : json['securityMeasures'],
        'structuralDeficienciesFinalResult': !exists(json, 'structuralDeficienciesFinalResult') ? undefined : json['structuralDeficienciesFinalResult'],
        'structuralDeficienciesNotes': !exists(json, 'structuralDeficienciesNotes') ? undefined : json['structuralDeficienciesNotes'],
        'testsDone': !exists(json, 'testsDone') ? undefined : json['testsDone'],
        'usedTools': !exists(json, 'usedTools') ? undefined : json['usedTools'],
        'verticalEnclosuresDeficienciesFinalResult': !exists(json, 'verticalEnclosuresDeficienciesFinalResult') ? undefined : json['verticalEnclosuresDeficienciesFinalResult'],
        'verticalEnclosuresDeficienciesNotes': !exists(json, 'verticalEnclosuresDeficienciesNotes') ? undefined : json['verticalEnclosuresDeficienciesNotes'],
    };
}

export function NewInspectionDocumentationDTOToJSON(value?: NewInspectionDocumentationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commonBuildingFacilitiesDeficienciesFinalResult': value.commonBuildingFacilitiesDeficienciesFinalResult,
        'commonBuildingFacilitiesDeficienciesNotes': value.commonBuildingFacilitiesDeficienciesNotes,
        'date': value.date,
        'finalResult': value.finalResult,
        'finalResultNotes': value.finalResultNotes,
        'foundationDeficienciesFinalResult': value.foundationDeficienciesFinalResult,
        'foundationDeficienciesNotes': value.foundationDeficienciesNotes,
        'homeInspected': value.homeInspected,
        'notes': value.notes,
        'obstacle': value.obstacle,
        'otherElementsInspected': value.otherElementsInspected,
        'roofsAndCoveringsDeficienciesFinalResult': value.roofsAndCoveringsDeficienciesFinalResult,
        'roofsAndCoveringsDeficienciesNotes': value.roofsAndCoveringsDeficienciesNotes,
        'securityMeasures': value.securityMeasures,
        'structuralDeficienciesFinalResult': value.structuralDeficienciesFinalResult,
        'structuralDeficienciesNotes': value.structuralDeficienciesNotes,
        'testsDone': value.testsDone,
        'usedTools': value.usedTools,
        'verticalEnclosuresDeficienciesFinalResult': value.verticalEnclosuresDeficienciesFinalResult,
        'verticalEnclosuresDeficienciesNotes': value.verticalEnclosuresDeficienciesNotes,
    };
}

