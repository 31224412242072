/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CollegeDTO,
    CollegeDTOFromJSON,
    CollegeDTOToJSON,
    CollegeFilterDTO,
    CollegeFilterDTOFromJSON,
    CollegeFilterDTOToJSON,
    FullCollegeDTO,
    FullCollegeDTOFromJSON,
    FullCollegeDTOToJSON,
    FullCollegePageDTO,
    FullCollegePageDTOFromJSON,
    FullCollegePageDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface CreateCollegeRequest {
    collegeDTO?: CollegeDTO;
}

export interface DeleteCollegeRequest {
    id: string;
}

export interface FindCollegesByIDsRequest {
    requestBody?: Array<string>;
}

export interface GetAllCollegeRequest {
    offset?: number;
    limit?: number;
}

export interface GetAllCollegeByFilterRequest {
    offset: number;
    limit: number;
    sortField?: GetAllCollegeByFilterSortFieldEnum;
    direction?: GetAllCollegeByFilterDirectionEnum;
    collegeFilterDTO?: CollegeFilterDTO;
}

export interface GetCollegeByFeeIdRequest {
    feeId: string;
}

export interface GetCollegeByIDRequest {
    id: string;
}

export interface SearchCollegeRequest {
    name?: string;
    type?: SearchCollegeTypeEnum;
}

export interface UpdateCollegeRequest {
    collegeDTO?: CollegeDTO;
}

/**
 * 
 */
export class CollegesApi extends runtime.BaseAPI {

    /**
     * Create a new College.
     * Create a College.
     */
    async createCollegeRaw(requestParameters: CreateCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FullCollegeDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollegeDTOToJSON(requestParameters.collegeDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullCollegeDTOFromJSON(jsonValue));
    }

    /**
     * Create a new College.
     * Create a College.
     */
    async createCollege(requestParameters: CreateCollegeRequest, initOverrides?: RequestInit): Promise<FullCollegeDTO> {
        const response = await this.createCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing College, deletes it, and returns the new object.
     * Delete an existing College by id.
     */
    async deleteCollegeRaw(requestParameters: DeleteCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FullCollegeDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCollege.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullCollegeDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing College, deletes it, and returns the new object.
     * Delete an existing College by id.
     */
    async deleteCollege(requestParameters: DeleteCollegeRequest, initOverrides?: RequestInit): Promise<FullCollegeDTO> {
        const response = await this.deleteCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a College list object by ids.
     * Get a College list object by ids.
     */
    async findCollegesByIDsRaw(requestParameters: FindCollegesByIDsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FullCollegeDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/find-many-by-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FullCollegeDTOFromJSON));
    }

    /**
     * Returns a College list object by ids.
     * Get a College list object by ids.
     */
    async findCollegesByIDs(requestParameters: FindCollegesByIDsRequest, initOverrides?: RequestInit): Promise<Array<FullCollegeDTO>> {
        const response = await this.findCollegesByIDsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the College.
     * Get all College objects.
     */
    async getAllCollegeRaw(requestParameters: GetAllCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FullCollegeDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FullCollegeDTOFromJSON));
    }

    /**
     * Returns all the College.
     * Get all College objects.
     */
    async getAllCollege(requestParameters: GetAllCollegeRequest, initOverrides?: RequestInit): Promise<Array<FullCollegeDTO>> {
        const response = await this.getAllCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the College by filter.
     * Get all College objects by filter.
     */
    async getAllCollegeByFilterRaw(requestParameters: GetAllCollegeByFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FullCollegePageDTO>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getAllCollegeByFilter.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllCollegeByFilter.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortField !== undefined) {
            queryParameters['sortField'] = requestParameters.sortField;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/paginated`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollegeFilterDTOToJSON(requestParameters.collegeFilterDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullCollegePageDTOFromJSON(jsonValue));
    }

    /**
     * Returns all the College by filter.
     * Get all College objects by filter.
     */
    async getAllCollegeByFilter(requestParameters: GetAllCollegeByFilterRequest, initOverrides?: RequestInit): Promise<FullCollegePageDTO> {
        const response = await this.getAllCollegeByFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a College list object by fee id.
     * Get a College list object by fee id.
     */
    async getCollegeByFeeIdRaw(requestParameters: GetCollegeByFeeIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FullCollegeDTO>>> {
        if (requestParameters.feeId === null || requestParameters.feeId === undefined) {
            throw new runtime.RequiredError('feeId','Required parameter requestParameters.feeId was null or undefined when calling getCollegeByFeeId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/fee/{feeId}`.replace(`{${"feeId"}}`, encodeURIComponent(String(requestParameters.feeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FullCollegeDTOFromJSON));
    }

    /**
     * Returns a College list object by fee id.
     * Get a College list object by fee id.
     */
    async getCollegeByFeeId(requestParameters: GetCollegeByFeeIdRequest, initOverrides?: RequestInit): Promise<Array<FullCollegeDTO>> {
        const response = await this.getCollegeByFeeIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one College object by id.
     * Get a College object by id.
     */
    async getCollegeByIDRaw(requestParameters: GetCollegeByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FullCollegeDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCollegeByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullCollegeDTOFromJSON(jsonValue));
    }

    /**
     * Returns one College object by id.
     * Get a College object by id.
     */
    async getCollegeByID(requestParameters: GetCollegeByIDRequest, initOverrides?: RequestInit): Promise<FullCollegeDTO> {
        const response = await this.getCollegeByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a College list object by name.
     * Get a College list object by name.
     */
    async searchCollegeRaw(requestParameters: SearchCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FullCollegeDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FullCollegeDTOFromJSON));
    }

    /**
     * Returns a College list object by name.
     * Get a College list object by name.
     */
    async searchCollege(requestParameters: SearchCollegeRequest, initOverrides?: RequestInit): Promise<Array<FullCollegeDTO>> {
        const response = await this.searchCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing College object, updates it, and returns the Fee.
     * Update an existing College.
     */
    async updateCollegeRaw(requestParameters: UpdateCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FullCollegeDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CollegeDTOToJSON(requestParameters.collegeDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullCollegeDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing College object, updates it, and returns the Fee.
     * Update an existing College.
     */
    async updateCollege(requestParameters: UpdateCollegeRequest, initOverrides?: RequestInit): Promise<FullCollegeDTO> {
        const response = await this.updateCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAllCollegeByFilterSortFieldEnum {
    FeeName = 'FEE_NAME',
    Name = 'NAME',
    Type = 'TYPE',
    Vatapplied = 'VATAPPLIED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAllCollegeByFilterDirectionEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchCollegeTypeEnum {
    ArchitectsCollege = 'ARCHITECTS_COLLEGE',
    RiggersAndTechnicalArchitects = 'RIGGERS_AND_TECHNICAL_ARCHITECTS'
}
