/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressDTO0
 */
export interface AddressDTO0 {
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    block?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    community?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    portalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    portalNumberLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDTO0
     */
    postalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    secondaryPortalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    secondaryPortalNumberLetter?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    stair?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    streetCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    streetType?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDTO0
     */
    townName?: string;
}

export function AddressDTO0FromJSON(json: any): AddressDTO0 {
    return AddressDTO0FromJSONTyped(json, false);
}

export function AddressDTO0FromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDTO0 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'door': !exists(json, 'door') ? undefined : json['door'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'portalNumber': !exists(json, 'portalNumber') ? undefined : json['portalNumber'],
        'portalNumberLetter': !exists(json, 'portalNumberLetter') ? undefined : json['portalNumberLetter'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'secondaryPortalNumber': !exists(json, 'secondaryPortalNumber') ? undefined : json['secondaryPortalNumber'],
        'secondaryPortalNumberLetter': !exists(json, 'secondaryPortalNumberLetter') ? undefined : json['secondaryPortalNumberLetter'],
        'stair': !exists(json, 'stair') ? undefined : json['stair'],
        'streetCode': !exists(json, 'streetCode') ? undefined : json['streetCode'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetType': !exists(json, 'streetType') ? undefined : json['streetType'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'townName': !exists(json, 'townName') ? undefined : json['townName'],
    };
}

export function AddressDTO0ToJSON(value?: AddressDTO0 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'community': value.community,
        'door': value.door,
        'floor': value.floor,
        'portalNumber': value.portalNumber,
        'portalNumberLetter': value.portalNumberLetter,
        'postalCode': value.postalCode,
        'province': value.province,
        'secondaryPortalNumber': value.secondaryPortalNumber,
        'secondaryPortalNumberLetter': value.secondaryPortalNumberLetter,
        'stair': value.stair,
        'streetCode': value.streetCode,
        'streetName': value.streetName,
        'streetType': value.streetType,
        'town': value.town,
        'townName': value.townName,
    };
}

