/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessibilityEvaluationDTO,
    AccessibilityEvaluationDTOFromJSON,
    AccessibilityEvaluationDTOFromJSONTyped,
    AccessibilityEvaluationDTOToJSON,
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AccEvaluationsPrintedDTO
 */
export interface AccEvaluationsPrintedDTO {
    /**
     * 
     * @type {AccessibilityEvaluationDTO}
     * @memberof AccEvaluationsPrintedDTO
     */
    evaluation?: AccessibilityEvaluationDTO;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof AccEvaluationsPrintedDTO
     */
    multimedias?: Array<MediaPrintedDTO>;
}

export function AccEvaluationsPrintedDTOFromJSON(json: any): AccEvaluationsPrintedDTO {
    return AccEvaluationsPrintedDTOFromJSONTyped(json, false);
}

export function AccEvaluationsPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccEvaluationsPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evaluation': !exists(json, 'evaluation') ? undefined : AccessibilityEvaluationDTOFromJSON(json['evaluation']),
        'multimedias': !exists(json, 'multimedias') ? undefined : ((json['multimedias'] as Array<any>).map(MediaPrintedDTOFromJSON)),
    };
}

export function AccEvaluationsPrintedDTOToJSON(value?: AccEvaluationsPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evaluation': AccessibilityEvaluationDTOToJSON(value.evaluation),
        'multimedias': value.multimedias === undefined ? undefined : ((value.multimedias as Array<any>).map(MediaPrintedDTOToJSON)),
    };
}

