/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillingEntityDTO,
    BillingEntityDTOFromJSON,
    BillingEntityDTOFromJSONTyped,
    BillingEntityDTOToJSON,
    FeeDTO,
    FeeDTOFromJSON,
    FeeDTOFromJSONTyped,
    FeeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FullCollegeDTO
 */
export interface FullCollegeDTO {
    /**
     * 
     * @type {BillingEntityDTO}
     * @memberof FullCollegeDTO
     */
    billingEntityDTO?: BillingEntityDTO;
    /**
     * 
     * @type {string}
     * @memberof FullCollegeDTO
     */
    billingEntityId?: string;
    /**
     * 
     * @type {FeeDTO}
     * @memberof FullCollegeDTO
     */
    fee?: FeeDTO;
    /**
     * 
     * @type {string}
     * @memberof FullCollegeDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FullCollegeDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FullCollegeDTO
     */
    type?: FullCollegeDTOTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FullCollegeDTO
     */
    vatApplied?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum FullCollegeDTOTypeEnum {
    ArchitectsCollege = 'ARCHITECTS_COLLEGE',
    RiggersAndTechnicalArchitects = 'RIGGERS_AND_TECHNICAL_ARCHITECTS'
}

export function FullCollegeDTOFromJSON(json: any): FullCollegeDTO {
    return FullCollegeDTOFromJSONTyped(json, false);
}

export function FullCollegeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullCollegeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingEntityDTO': !exists(json, 'billingEntityDTO') ? undefined : BillingEntityDTOFromJSON(json['billingEntityDTO']),
        'billingEntityId': !exists(json, 'billingEntityId') ? undefined : json['billingEntityId'],
        'fee': !exists(json, 'fee') ? undefined : FeeDTOFromJSON(json['fee']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'vatApplied': !exists(json, 'vatApplied') ? undefined : json['vatApplied'],
    };
}

export function FullCollegeDTOToJSON(value?: FullCollegeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingEntityDTO': BillingEntityDTOToJSON(value.billingEntityDTO),
        'billingEntityId': value.billingEntityId,
        'fee': FeeDTOToJSON(value.fee),
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'vatApplied': value.vatApplied,
    };
}

