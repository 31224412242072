import { NewPrintingBookDTO } from "clients";
import { BookRepository } from "data/repositories/impl/book-repository";
import { useEffect, useState } from "react";

export const useBookGeneratorViewModel = (bookId: string, setIsLoadingState?: React.Dispatch<React.SetStateAction<boolean>>) => {
  const [book, setBook] = useState<NewPrintingBookDTO | null>(null);

  useEffect(() => {
    fetchBook(bookId);
  }, [bookId]);

  const fetchBook = async (id: string) => {
    setIsLoadingState && setIsLoadingState(true);
    const repository = new BookRepository();
    repository
      .getNewPrintingBookById({ id: id })
      .then(setBook)
      .catch(() => console.log("BookFetchError"))
      .finally(() => setIsLoadingState && setIsLoadingState(false));
  };
  return {
    book,
  };
};
