import { t } from "../../../book-v2/book-helpers";
import { Text, View } from "@react-pdf/renderer";
import { TitleWithBlueBorder } from "../../../book-v2/components/titles/titles";
import { energyEfficiencyStyles } from "./energyEfficiency.styles";
import { ColorsNewPdf } from "../../../../../../constants";
import * as RegexHelper from "../../../../../../utils/regexHelper";
import { EnergyEfficiencyCertificatePrintedDTO } from "../../../../../../clients";
import React from "react";
import { EnergyCertificateView } from "../energy-certificate-view/energy-certificate-view";

export const PartialQualificationNoRenewable: React.FC<{ data: EnergyEfficiencyCertificatePrintedDTO }> = ({ data: energyInitial }) => {
  const styles = energyEfficiencyStyles;

  return (
    <>
      <TitleWithBlueBorder title={t("pdf.energyEfficiency.partialQualificationNoRenewable")} />

      <Text style={styles.descriptionText}>{t("pdf.energyEfficiency.partialQualificationNoRenewable.description")}</Text>

      <View style={styles.tableGlobalIndicator}>
        <View style={styles.tableHeader}>
          <Text style={[styles.titleGlobal, styles.titleHeaderText]}>{t("pdf.energyEfficiency.partialQualification.globalIndicator")}</Text>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            border: `1pt solid ${ColorsNewPdf.background_containers_block1}`,
          }}
        >
          <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            <View style={styles.subTitleContainer}>
              <Text style={styles.titleGlobal}>{t("pdf.energyEfficiency.partialQualificationRenewable.firstCell")}</Text>
              <Text style={styles.titleGlobal}>{t("pdf.energyEfficiency.partialQualificationRenewable.secondCell")}</Text>
              <Text style={styles.titleGlobal}>{t("pdf.energyEfficiency.partialQualificationRenewable.thirdCell")}</Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", height: "28pt", padding: "7pt 0 7pt 0" }}>
              <Text style={[styles.dataGlobal, styles.dataEmissionsHeat]}>
                {energyInitial?.energyEfficiencyCertificateDTO && energyInitial.energyEfficiencyCertificateDTO!.climaticZone}
              </Text>
              <Text style={[styles.dataGlobal, styles.dataEmissionsHeat]}>
                {energyInitial?.energyEfficiencyCertificateDTO && energyInitial.energyEfficiencyCertificateDTO!.usage}
              </Text>
              <Text style={[styles.dataGlobal, styles.dataEmissionsHeat]}>
                {String(energyInitial?.energyEfficiencyCertificateDTO && energyInitial.energyEfficiencyCertificateDTO!.dailyACSDemand?.toFixed(2))
                  .replace(".", ",")
                  .replaceAll(RegexHelper.checkNumberOver1000, ".")}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <Text style={styles.descriptionText}>{t("pdf.energy.confirm.text")}</Text>
      <EnergyCertificateView certificateImage={energyInitial?.certificateImage} />
    </>
  );
};
