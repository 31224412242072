import { PaymentGatewayApi as PaymentGatewayClientApi} from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";
import IPaymentGatewayApi from "../payment-gateway-api";

export class PaymentGatewayApi extends BaseAPI implements IPaymentGatewayApi {
  apiClient: PaymentGatewayClientApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new PaymentGatewayClientApi(configuration);
  }

  async userHasVouchers(): Promise<Boolean> {
    const response = await this.apiClient.userHasVouchers(); //this endpoint return a text not a json, so the boolean value has to be evaluated as string
    return response.toString() === "true";
  }

  transactionIdsByPendingVouchers(): Promise<Array<string>> {
    return this.apiClient.transactionIdsByPendingVouchers();
  }
}
