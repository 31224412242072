import { StyleSheet } from "@react-pdf/renderer";
import { fontsNewPdf } from "../../../../_common/fonts/fonts";
import { ColorsNewPdf } from "../../../../../constants";

const styles = StyleSheet.create({
  page: {
    paddingBottom: 40,
  },
  header: {
    fontSize: 12,
    textAlign: "center",
    marginBottom:20,
  },
  body: {
    paddingHorizontal: 40,
    fontSize: 12,
    fontFamily: fontsNewPdf.RobotoRegular,
    color: ColorsNewPdf.black,
  },
  footer: {
    width: "100vw",
    height: 40,
    position: "absolute",
    bottom: 0,
    fontSize: 9,
    color: "black",
  },
  pageNumberContainer: {
    backgroundColor: ColorsNewPdf.footer_background,
    width: "60pt",
    height: "17pt",
    justifyContent: "center",
    alignItems: "center",
  },
  pageNumber: {
    fontSize: 12,
  },

  sectionTitle: {
    color: ColorsNewPdf.lines_subtitle_section,
    fontSize: 18,
    paddingBottom: 10,
    paddingLeft:14
  },
  subSectionTitle: {
    color: ColorsNewPdf.lines_subtitle_section,
    fontSize: 14,
    paddingBottom: 10,
    paddingLeft:14
  },
  paragraph: {
    color: ColorsNewPdf.black,
    fontSize: 12,
    lineHeight: 1.5,
    paddingBottom: 8,
  },
});

export default styles;
