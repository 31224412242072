/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ParcelDTO,
    ParcelDTOFromJSON,
    ParcelDTOFromJSONTyped,
    ParcelDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface MediaDTO
 */
export interface MediaDTO {
    /**
     * 
     * @type {string}
     * @memberof MediaDTO
     */
    format?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDTO
     */
    name?: string;
    /**
     * 
     * @type {ParcelDTO}
     * @memberof MediaDTO
     */
    parcel?: ParcelDTO;
    /**
     * 
     * @type {string}
     * @memberof MediaDTO
     */
    url?: string;
}

export function MediaDTOFromJSON(json: any): MediaDTO {
    return MediaDTOFromJSONTyped(json, false);
}

export function MediaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format': !exists(json, 'format') ? undefined : json['format'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parcel': !exists(json, 'parcel') ? undefined : ParcelDTOFromJSON(json['parcel']),
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function MediaDTOToJSON(value?: MediaDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format': value.format,
        'id': value.id,
        'name': value.name,
        'parcel': ParcelDTOToJSON(value.parcel),
        'url': value.url,
    };
}

