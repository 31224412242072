import React, { createContext, useState, ReactNode, useContext } from 'react';

interface SectionContextProps {
  sectionNumber: number;
  subSectionNumber: number;
  incrementSection: () => void;
  incrementSubSection: () => void;
}

const SectionContext = createContext<SectionContextProps | null>(null);

interface SectionProviderProps {
  children: ReactNode;
}

const SectionCountProvider: React.FC<SectionProviderProps> = ({ children }) => {
  const [sectionNumber, setSectionNumber] = useState(0);
  const [subSectionNumber, setSubSectionNumber] = useState(1);

  const incrementSection = () => {
    setSectionNumber(prev => prev + 1);
    setSubSectionNumber(1);
  };

  const incrementSubSection = () => {
    setSubSectionNumber(prev => prev + 1);
  };

  return (
    <SectionContext.Provider value={{ sectionNumber, subSectionNumber, incrementSection, incrementSubSection }}>
      {children}
    </SectionContext.Provider>
  );
};

const useSectionContext = () => {
    return useContext(SectionContext);
  };

export {SectionCountProvider, useSectionContext};

