import React, { FC } from "react";
import { FrontpageDTO, NewTechnicianDTO } from "../../../../../../clients";
import { Page, View, Text } from "@react-pdf/renderer";
import { ColorsNewPdf, NewPdfPaddingsPage } from "../../../../../../constants";
import { newFullAddress, t } from "pdf/documents/book/book-v2/book-helpers";
import { Footer, ImagePDF } from "pdf/documents/book/book-v2/commons";
import { CoverStyles } from "pdf/documents/book/book-v2/pages/cover/cover.styles";
import { ImageMedia } from "pdf/documents/book/_common/types/image-media";
import { fontsNewPdf } from "pdf/_common/fonts/fonts";

interface FrontpageProps {
  data?: FrontpageDTO;
  constructionYear?: string;
  image?: ImageMedia;
}

//TODO: Refactor, unify this component methods with those of the book cover
export const Frontpage: React.FC<FrontpageProps> = ({ data, constructionYear, image }) => {
  const portraitImageUrl =
    data?.image?.url ??
    `http://ovc.catastro.meh.es/OVCServWeb/OVCWcfLibres/OVCFotoFachada.svc/RecuperarFotoFachadaGet?ReferenciaCatastral=${data?.cadastralReference}`;
  return (
    <Page size="A4" style={NewPdfPaddingsPage} wrap>
      <MainTitle title={"RESUMEN DEL LIBRO DEL EDIFICIO EXISTENTE"} />
      <View style={{marginTop:10}}>
        <Text style={{fontFamily: fontsNewPdf.RobotoRegular, color: ColorsNewPdf.grey_text}}>PASAPORTE DE RENOVACIÓN DEL EDIFICIO</Text>
      </View>
      <BuildingYear>
        <BuildingYearValue>
          <BuildingYearLabel />
          {constructionYear ? constructionYear : <></>}
        </BuildingYearValue>
      </BuildingYear>
      <CadastralReference>
        <CadastralReferenceValue>
          <CadastralReferenceLabel />
          {data?.cadastralReference ? data?.cadastralReference : <></>}
        </CadastralReferenceValue>
      </CadastralReference>
      <View style={{ overflow: "hidden" }}>
        <ImagePDF
          url={process.env.REACT_APP_PROXY_URL! + "/" + portraitImageUrl || process.env.REACT_APP_PROXY_URL! + "/" + image?.url}
          transform={image?.transform || "rotate(0)"}
          styles={{ maxHeight: "400px" }}
        />
      </View>
      <Address>{newFullAddress(data?.address)}</Address>
      {data?.technicians ? <WritingTechniciansData technicians={data.technicians} /> : <></>}
      <Footer />
    </Page>
  );
};

const styles = CoverStyles;

const MainTitle: FC<{ title: string }> = ({ title }) => {
  return <Text style={{...styles.main_title, fontSize: "44pt",}}>{title.toUpperCase()}</Text>;
};
const BuildingYear: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <View style={styles.cover_subtitle_container}>{children}</View>;
};
const BuildingYearLabel: FC = () => {
  return <Text style={styles.cover_subtitle}>{t("pdf.frontpage.building.year.label") + " "}</Text>;
};
const BuildingYearValue: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <Text style={styles.texts_values_cover}>{children}</Text>;
};
const CadastralReference: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <View style={styles.cover_cadastralReference_container}>{children}</View>;
};
const CadastralReferenceLabel: FC = () => {
  return <Text style={styles.cover_cadastralReference_label}>{t("pdf.frontpage.cadastral.reference.label") + " "}</Text>;
};
const CadastralReferenceValue: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <Text style={styles.texts_values_cover}>{children}</Text>;
};



const Address: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <View style={styles.cover_address}>
      <Text style={styles.cover_address_text}>{children}</Text>
    </View>
  );
};

const WritingTechnicians: FC<{ technicians?: NewTechnicianDTO[] }> = ({ technicians }) => {
  return (
    <View style={styles.cover_writingTechnicians_views}>
      <Text style={[styles.cover_writingTechnicians_header_text, { paddingBottom: 11 }]}>{t("pdf.frontpage.writing.technician")}</Text>
      {technicians?.map((item, index) => {
        return (
          <View key={index}>
            {technicians.indexOf(item) < technicians.length - 1 ? (
              <View style={styles.cover_writingTechnicians_body_container}>
                <Text style={styles.cover_writingTechnicians_body}>
                  {item?.name?.toUpperCase()} {item?.surname?.toUpperCase()}
                </Text>
                <Text style={styles.cover_writingTechnicians_body}>{item.email}</Text>
              </View>
            ) : (
              <View>
                <Text style={styles.cover_writingTechnicians_body}>
                  {item?.name?.toUpperCase()} {item?.surname?.toUpperCase()}
                </Text>
                <Text style={styles.cover_writingTechnicians_body}>{item.email}</Text>
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

const WritingTechniciansCollegiatedTitle: FC<{ technicians?: NewTechnicianDTO[] }> = ({ technicians }) => {
  return (
    <View style={styles.cover_writingTechnicians_views}>
      <Text style={[styles.cover_writingTechnicians_header_text, { paddingBottom: 11 }]}>{t("pdf.frontpage.technician.title")}</Text>
      {technicians?.map((item, index) => {
        return (
          <View key={index}>
            {technicians.indexOf(item) < technicians.length - 1 ? (
              <View style={styles.cover_writingTechnicians_body_container}>
                {item.collegiateTitle?.map((value, index) => (
                  <View key={index}>
                    <Text style={styles.cover_writingTechnicians_body}>
                      {t("technician.participation.profesion." + (value !== null && value !== undefined ? value : "undefined"))}
                    </Text>
                  </View>
                ))}
              </View>
            ) : (
              <View>
                {item.collegiateTitle?.map((value, index) => (
                  <Text key={index} style={styles.cover_writingTechnicians_body}>
                    {t("technician.participation.profesion." + (value !== null && value !== undefined ? value : "undefined"))}
                  </Text>
                ))}
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

const WritingTechniciansTitles: FC<{ technicians?: NewTechnicianDTO[] }> = ({ technicians }) => {
  return (
    <View style={styles.cover_writingTechnicians_views}>
      <Text style={styles.cover_writingTechnicians_header_text}>{t("pdf.frontpage.collegiated")}</Text>
      {technicians?.map((item, index) => {
        return (
          <View key={index}>
            {technicians.indexOf(item) < technicians.length - 1 ? (
              <View style={styles.cover_writingTechnicians_body_container}>
                <Text style={styles.cover_writingTechnicians_body}>{item.collegiateNumber}</Text>
              </View>
            ) : (
              <View>
                <Text style={styles.cover_writingTechnicians_body}>{item.collegiateNumber}</Text>
              </View>
            )}
          </View>
        );
      })}
    </View>
  );
};

const WritingTechniciansData: FC<{ technicians?: NewTechnicianDTO[] }> = ({ technicians }) => {
  return (
    <View style={styles.cover_writingTechnicians_body_content}>
      <WritingTechnicians technicians={technicians} />
      <WritingTechniciansCollegiatedTitle technicians={technicians} />
      <WritingTechniciansTitles technicians={technicians} />
    </View>
  );
};
