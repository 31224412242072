/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IncompletePropertiesDTOInManager
 */
export interface IncompletePropertiesDTOInManager {
    /**
     * 
     * @type {Array<string>}
     * @memberof IncompletePropertiesDTOInManager
     */
    incompleteUUIDs?: Array<string>;
}

export function IncompletePropertiesDTOInManagerFromJSON(json: any): IncompletePropertiesDTOInManager {
    return IncompletePropertiesDTOInManagerFromJSONTyped(json, false);
}

export function IncompletePropertiesDTOInManagerFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncompletePropertiesDTOInManager {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'incompleteUUIDs': !exists(json, 'incompleteUUIDs') ? undefined : json['incompleteUUIDs'],
    };
}

export function IncompletePropertiesDTOInManagerToJSON(value?: IncompletePropertiesDTOInManager | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'incompleteUUIDs': value.incompleteUUIDs,
    };
}

