import { Image as PDFImage, StyleSheet, Text, View as Viewer } from "@react-pdf/renderer";
import React from "react";
import { ColorsNewPdf, FontSizes } from "../../../../constants";
import { t } from "./book-helpers";
import { fontsNewPdf } from "../../../_common/fonts/fonts";
import { GreenRectangle } from "../../../../assets/images/new_pdf/Green_rectangle";
import { NewTechnicianDTO } from "../../../../clients";
import { PdfNewStyles } from "pdf/_common/styles/new-pdf-styles";

const FooterStyles = StyleSheet.create({
  footer: {
    width: "100vw",
    position: "absolute",
    bottom: "20pt",
    fontSize: 9,
    color: "black",
  },
});

export const ImageStyles = StyleSheet.create({
  image_fullWidth: {
    width: "523pt",
    height: "329pt",
    objectFit: "contain",
    marginVertical: "5px",
    overflow: "hidden",
  },
  image_fullHeight: {
    width: "400pt",
    height: "730pt",
    marginVertical: "5px",
    overflow: "hidden",
  },
});

const SignatureStyles = StyleSheet.create({
  signs_component_container: {
    border: `2pt dashed ${ColorsNewPdf.dark_blue_text_background_lines}`,
    maxWidth: "50%",
    padding: "10pt",
    marginRight: "40pt",
    marginTop: "5pt",
    height: "155pt",
  },
  signs_component_texts: {
    minWidth: "200pt",
    color: ColorsNewPdf.black,
    fontSize: FontSizes.pdfP,
    padding: "10pt",
  },
});

export const Footer: React.FC = () => {
  return (
    <Viewer
      style={FooterStyles.footer}
      fixed
      render={({ pageNumber }) => {
        const style = pageNumber % 2 === 0 ? "flex-start" : "flex-end";
        return (
          <div
            style={{
              backgroundColor: ColorsNewPdf.footer_background,
              alignSelf: style,
              width: "60pt",
              height: "17pt",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={{ textAlign: "center", fontSize: 12 }}>{`${pageNumber}`}</Text>
          </div>
        );
      }}
    />
  );
};

export const ImagePDF: React.FC<{ url: string; transform: string; styles?: any }> = ({ url, transform, styles }) => {
  if (!url.startsWith("blob:") || url === "") {
    return (
      <PDFImage
        src={{
          uri: url,
          method: "GET",
          headers: { "Cache-Control": "no-cache" },
          body: "",
        }}
        style={[{ objectFit: "contain", transform: transform ?? "", overflow: "hidden", paddingVertical: "5pt", maxHeight: 300, ...styles }]}
      />
    );
  } else
    return (
      <PDFImage
        src={{
          uri: url,
          method: "GET",
          headers: { "Cache-Control": "no-cache" },
          body: "",
        }}
        style={[ImageStyles.image_fullWidth, { objectFit: "contain", overflow: "hidden" }]}
      />
    );
};

export const ImageCEE: React.FC<{ url: string }> = ({ url }) => {
  if (!url.startsWith("blob:") || url === "") {
    return (
      <PDFImage
        src={{
          uri: url,
          method: "GET",
          headers: { "Cache-Control": "no-cache" },
          body: "",
        }}
        style={ImageStyles.image_fullHeight}
      />
    );
  } else
    return (
      <PDFImage
        src={{
          uri: url,
          method: "GET",
          headers: { "Cache-Control": "no-cache" },
          body: "",
        }}
        style={ImageStyles.image_fullHeight}
      />
    );
};

export const Signature: React.FC<{ city: string; date: string; owner: boolean; technicians?: NewTechnicianDTO[] }> = ({
  city,
  date,
  owner,
  technicians,
}) => {
  return (
    <Viewer wrap={false} style={[SignatureStyles.signs_component_container, { width: "47%", height: "auto", alignSelf: "flex-end" }]}>
      <Viewer style={{ marginBottom: 60 }}>
        <Text style={SignatureStyles.signs_component_texts}> {`${t("pdf.finalSigns.in")} ${city}, a ${date}`} </Text>
      </Viewer>
      <Viewer>
        <Text style={SignatureStyles.signs_component_texts}>
          {t("pdf.final_valorations.signed") + " " + (owner === true ? t("pdf.finalSigns.owner") : t("pdf.finalSigns.technician"))}
        </Text>
        {technicians?.map((technician) => {
          return (
            <Text key={technician.name} style={{ minWidth: "200pt", color: ColorsNewPdf.black, fontSize: FontSizes.pdfP, marginTop: 5 }}>{`${technician.name + " " + technician.surname
              }`}</Text>
          );
        })}
      </Viewer>
    </Viewer>
  );
};

export const RowBoldText: React.FC<{ labelTitle: string; labelValue: string }> = ({ labelTitle, labelValue }) => {
  return (
    <Viewer wrap={false} style={{ alignItems: "flex-end", display: "flex", flexDirection: "row" }}>
      <Text style={{ fontFamily: fontsNewPdf.RobotoBold, textAlign: "left" }}>{labelTitle}</Text>
      <Text style={{ fontFamily: fontsNewPdf.RobotoRegular, textAlign: "left", justifyContent: "flex-end", height: "auto" }}>{labelValue}</Text>
    </Viewer>
  );
};

export const RowBoldTextColumn: React.FC<{ labelTitle: string; labelValue: string }> = ({ labelTitle, labelValue }) => {
  return (
    <Viewer wrap={false} style={{ alignItems: "flex-start", display: "flex", flexDirection: "column" }}>
      <Text style={{ fontFamily: fontsNewPdf.RobotoBold, textAlign: "left" }}>{labelTitle}</Text>
      <Text style={{ fontFamily: fontsNewPdf.RobotoRegular, textAlign: "left", justifyContent: "flex-end", height: "auto" }}>{labelValue}</Text>
    </Viewer>
  );
};

export const ColumnBoldText: React.FC<{ labelTitle: string; labelValue: string }> = ({ labelTitle, labelValue }) => {
  return (
    <Viewer wrap={false} style={{ display: "flex", flexDirection: "column" }}>
      <Text style={{ fontFamily: fontsNewPdf.RobotoBold, textAlign: "left" }}>{labelTitle}</Text>
      <Text style={{ fontFamily: fontsNewPdf.RobotoRegular, textAlign: "justify" }}>{labelValue}</Text>
    </Viewer>
  );
};

export const ConstructiveElement: React.FC<{ labelTitle: string }> = ({ labelTitle }) => {
  return (
    <Viewer wrap={false} style={{ display: "flex", flexDirection: "row", fontSize: 10 }}>
      <Dot rotate={false} />
      <Text>{labelTitle}</Text>
    </Viewer>
  );
};

export const NoDataComponent: React.FC<{ text?: string }> = ({ text = "pdf.NoData" }) => {
  return (
    <Viewer wrap={false} style={{ margin: 5, width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
      <Viewer
        style={{
          justifyContent: "center",
          alignItems: "center",
          fontSize: "48pt",
          width: "15pt",
          height: "15pt",
          border: "2pt solid black",
          borderRadius: "50%",
          marginTop: -2,
          marginRight: 5,
        }}
      >
        <Text style={{ fontSize: 10, fontWeight: 600 }}>i</Text>
      </Viewer>
      <Text style={{ fontSize: 10 }}>{t(text)}</Text>
    </Viewer>
  );
};

export const NoImprovements: React.FC = () => {
  return (
    <Viewer wrap={false} style={{ margin: 5, width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignContent: "center" }}>
      <Viewer
        style={{
          justifyContent: "center",
          alignItems: "center",
          fontSize: "48pt",
          width: "15pt",
          height: "15pt",
          border: "2pt solid black",
          borderRadius: "50%",
          marginTop: -2,
          marginRight: 5,
        }}
      >
        <Text style={{ fontSize: 10, fontWeight: 600 }}>i</Text>
      </Viewer>
      <Text style={{ fontSize: 10 }}>{t("pdf.no.improvement.data")}</Text>
    </Viewer>
  );
};

export const ImprovementsCheckbox: React.FC<{ labelTitle: string }> = ({ labelTitle }) => {
  return (
    <Viewer wrap={false} style={{ display: "flex", flexDirection: "row", fontSize: 10, alignItems: "center", marginBottom: 3 }}>
      <GreenRectangle />
      <Text style={{ marginLeft: 5 }}>{labelTitle}</Text>
    </Viewer>
  );
};
export const Dot: React.FC<{ rotate: boolean }> = (rotate) => {
  return <Viewer style={[PdfNewStyles.diamond_image, { backgroundColor: "black", transform: rotate ? "rotate(45deg)" : "rotate(0deg)" }]} />;
};

export const Block2DataElement: React.FC<{ labelTitle: string; dataString: string }> = ({ labelTitle, dataString }) => {
  return (
    <Viewer wrap={false} style={{ display: "flex", flexDirection: "column", fontSize: 9, marginVertical: 8 }}>
      <Viewer style={{ display: "flex", flexDirection: "row" }}>
        {/* <Dot rotate={true} /> */}
        <Text style={{ fontFamily: fontsNewPdf.RobotoBold }}>{labelTitle}</Text>
      </Viewer>
      <Viewer style={{ paddingLeft: 24, display: "flex", flexDirection: "column" }}>
        {dataString && <Text style={{ fontStyle: "italic" }}>{"- " + dataString}</Text>}
      </Viewer>
    </Viewer>
  );
};
