/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OtherImprovementDTO
 */
export interface OtherImprovementDTO {
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    ageOfInstallations?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    ageOfInstallationsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    asbestosPresence?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    asbestosPresenceMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    consumptionAccounting?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    consumptionAccountingMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    darkZonesOnFacade?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    darkZonesOnFacadeMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    digitizationAndMonitoring?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    digitizationAndMonitoringMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    electricCondition?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    electricConditionMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    facadeDeficiencies?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    facadeDeficienciesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    greenRoof?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    greenRoofMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    parkSituation?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    parkSituationMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    permeableExteriorSurfaces?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    permeableExteriorSurfacesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    rainWaterSaving?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    rainWaterSavingMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    solarProtectionZones?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    solarProtectionZonesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    telecomConditions?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    telecomConditionsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    zoneOfElectricCharge?: string;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementDTO
     */
    zoneOfElectricChargeMedia?: string;
}

export function OtherImprovementDTOFromJSON(json: any): OtherImprovementDTO {
    return OtherImprovementDTOFromJSONTyped(json, false);
}

export function OtherImprovementDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherImprovementDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageOfInstallations': !exists(json, 'ageOfInstallations') ? undefined : json['ageOfInstallations'],
        'ageOfInstallationsMedia': !exists(json, 'ageOfInstallationsMedia') ? undefined : json['ageOfInstallationsMedia'],
        'asbestosPresence': !exists(json, 'asbestosPresence') ? undefined : json['asbestosPresence'],
        'asbestosPresenceMedia': !exists(json, 'asbestosPresenceMedia') ? undefined : json['asbestosPresenceMedia'],
        'consumptionAccounting': !exists(json, 'consumptionAccounting') ? undefined : json['consumptionAccounting'],
        'consumptionAccountingMedia': !exists(json, 'consumptionAccountingMedia') ? undefined : json['consumptionAccountingMedia'],
        'darkZonesOnFacade': !exists(json, 'darkZonesOnFacade') ? undefined : json['darkZonesOnFacade'],
        'darkZonesOnFacadeMedia': !exists(json, 'darkZonesOnFacadeMedia') ? undefined : json['darkZonesOnFacadeMedia'],
        'digitizationAndMonitoring': !exists(json, 'digitizationAndMonitoring') ? undefined : json['digitizationAndMonitoring'],
        'digitizationAndMonitoringMedia': !exists(json, 'digitizationAndMonitoringMedia') ? undefined : json['digitizationAndMonitoringMedia'],
        'electricCondition': !exists(json, 'electricCondition') ? undefined : json['electricCondition'],
        'electricConditionMedia': !exists(json, 'electricConditionMedia') ? undefined : json['electricConditionMedia'],
        'facadeDeficiencies': !exists(json, 'facadeDeficiencies') ? undefined : json['facadeDeficiencies'],
        'facadeDeficienciesMedia': !exists(json, 'facadeDeficienciesMedia') ? undefined : json['facadeDeficienciesMedia'],
        'greenRoof': !exists(json, 'greenRoof') ? undefined : json['greenRoof'],
        'greenRoofMedia': !exists(json, 'greenRoofMedia') ? undefined : json['greenRoofMedia'],
        'parkSituation': !exists(json, 'parkSituation') ? undefined : json['parkSituation'],
        'parkSituationMedia': !exists(json, 'parkSituationMedia') ? undefined : json['parkSituationMedia'],
        'permeableExteriorSurfaces': !exists(json, 'permeableExteriorSurfaces') ? undefined : json['permeableExteriorSurfaces'],
        'permeableExteriorSurfacesMedia': !exists(json, 'permeableExteriorSurfacesMedia') ? undefined : json['permeableExteriorSurfacesMedia'],
        'rainWaterSaving': !exists(json, 'rainWaterSaving') ? undefined : json['rainWaterSaving'],
        'rainWaterSavingMedia': !exists(json, 'rainWaterSavingMedia') ? undefined : json['rainWaterSavingMedia'],
        'solarProtectionZones': !exists(json, 'solarProtectionZones') ? undefined : json['solarProtectionZones'],
        'solarProtectionZonesMedia': !exists(json, 'solarProtectionZonesMedia') ? undefined : json['solarProtectionZonesMedia'],
        'telecomConditions': !exists(json, 'telecomConditions') ? undefined : json['telecomConditions'],
        'telecomConditionsMedia': !exists(json, 'telecomConditionsMedia') ? undefined : json['telecomConditionsMedia'],
        'zoneOfElectricCharge': !exists(json, 'zoneOfElectricCharge') ? undefined : json['zoneOfElectricCharge'],
        'zoneOfElectricChargeMedia': !exists(json, 'zoneOfElectricChargeMedia') ? undefined : json['zoneOfElectricChargeMedia'],
    };
}

export function OtherImprovementDTOToJSON(value?: OtherImprovementDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageOfInstallations': value.ageOfInstallations,
        'ageOfInstallationsMedia': value.ageOfInstallationsMedia,
        'asbestosPresence': value.asbestosPresence,
        'asbestosPresenceMedia': value.asbestosPresenceMedia,
        'consumptionAccounting': value.consumptionAccounting,
        'consumptionAccountingMedia': value.consumptionAccountingMedia,
        'darkZonesOnFacade': value.darkZonesOnFacade,
        'darkZonesOnFacadeMedia': value.darkZonesOnFacadeMedia,
        'digitizationAndMonitoring': value.digitizationAndMonitoring,
        'digitizationAndMonitoringMedia': value.digitizationAndMonitoringMedia,
        'electricCondition': value.electricCondition,
        'electricConditionMedia': value.electricConditionMedia,
        'facadeDeficiencies': value.facadeDeficiencies,
        'facadeDeficienciesMedia': value.facadeDeficienciesMedia,
        'greenRoof': value.greenRoof,
        'greenRoofMedia': value.greenRoofMedia,
        'parkSituation': value.parkSituation,
        'parkSituationMedia': value.parkSituationMedia,
        'permeableExteriorSurfaces': value.permeableExteriorSurfaces,
        'permeableExteriorSurfacesMedia': value.permeableExteriorSurfacesMedia,
        'rainWaterSaving': value.rainWaterSaving,
        'rainWaterSavingMedia': value.rainWaterSavingMedia,
        'solarProtectionZones': value.solarProtectionZones,
        'solarProtectionZonesMedia': value.solarProtectionZonesMedia,
        'telecomConditions': value.telecomConditions,
        'telecomConditionsMedia': value.telecomConditionsMedia,
        'zoneOfElectricCharge': value.zoneOfElectricCharge,
        'zoneOfElectricChargeMedia': value.zoneOfElectricChargeMedia,
    };
}

