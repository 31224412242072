/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BioFuelEnergyReportDTO
 */
export interface BioFuelEnergyReportDTO {
    /**
     * 
     * @type {number}
     * @memberof BioFuelEnergyReportDTO
     */
    acs?: number;
    /**
     * 
     * @type {number}
     * @memberof BioFuelEnergyReportDTO
     */
    cooling?: number;
    /**
     * 
     * @type {number}
     * @memberof BioFuelEnergyReportDTO
     */
    global?: number;
    /**
     * 
     * @type {number}
     * @memberof BioFuelEnergyReportDTO
     */
    heating?: number;
    /**
     * 
     * @type {number}
     * @memberof BioFuelEnergyReportDTO
     */
    lighting?: number;
}

export function BioFuelEnergyReportDTOFromJSON(json: any): BioFuelEnergyReportDTO {
    return BioFuelEnergyReportDTOFromJSONTyped(json, false);
}

export function BioFuelEnergyReportDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BioFuelEnergyReportDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acs': !exists(json, 'acs') ? undefined : json['acs'],
        'cooling': !exists(json, 'cooling') ? undefined : json['cooling'],
        'global': !exists(json, 'global') ? undefined : json['global'],
        'heating': !exists(json, 'heating') ? undefined : json['heating'],
        'lighting': !exists(json, 'lighting') ? undefined : json['lighting'],
    };
}

export function BioFuelEnergyReportDTOToJSON(value?: BioFuelEnergyReportDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acs': value.acs,
        'cooling': value.cooling,
        'global': value.global,
        'heating': value.heating,
        'lighting': value.lighting,
    };
}

