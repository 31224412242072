/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomFeePageDTO,
    CustomFeePageDTOFromJSON,
    CustomFeePageDTOToJSON,
    FeeDTO,
    FeeDTOFromJSON,
    FeeDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface CreateFeeRequest {
    feeDTO?: FeeDTO;
}

export interface DeleteFeeRequest {
    id: string;
}

export interface GetAllFeePaginatedUsingGETRequest {
    offset: number;
    limit: number;
}

export interface GetFeeByCollegeIdRequest {
    collegeId: string;
}

export interface GetFeeByIdRequest {
    id: string;
}

export interface UpdateFeeRequest {
    feeDTO?: FeeDTO;
}

/**
 * 
 */
export class FeeApi extends runtime.BaseAPI {

    /**
     * Create a new Fee.
     * Create a Fee.
     */
    async createFeeRaw(requestParameters: CreateFeeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeeDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/fees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeeDTOToJSON(requestParameters.feeDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeeDTOFromJSON(jsonValue));
    }

    /**
     * Create a new Fee.
     * Create a Fee.
     */
    async createFee(requestParameters: CreateFeeRequest, initOverrides?: RequestInit): Promise<FeeDTO> {
        const response = await this.createFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing Fee, deletes it, and returns the new object.
     * Delete an existing Fee by id.
     */
    async deleteFeeRaw(requestParameters: DeleteFeeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeeDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/fees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeeDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing Fee, deletes it, and returns the new object.
     * Delete an existing Fee by id.
     */
    async deleteFee(requestParameters: DeleteFeeRequest, initOverrides?: RequestInit): Promise<FeeDTO> {
        const response = await this.deleteFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the fees.
     * Get all fee objects.
     */
    async getAllFeeRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FeeDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/fees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FeeDTOFromJSON));
    }

    /**
     * Returns all the fees.
     * Get all fee objects.
     */
    async getAllFee(initOverrides?: RequestInit): Promise<Array<FeeDTO>> {
        const response = await this.getAllFeeRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllFeePaginated
     */
    async getAllFeePaginatedUsingGETRaw(requestParameters: GetAllFeePaginatedUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomFeePageDTO>> {
        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getAllFeePaginatedUsingGET.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getAllFeePaginatedUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/fees/paginated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFeePageDTOFromJSON(jsonValue));
    }

    /**
     * getAllFeePaginated
     */
    async getAllFeePaginatedUsingGET(requestParameters: GetAllFeePaginatedUsingGETRequest, initOverrides?: RequestInit): Promise<CustomFeePageDTO> {
        const response = await this.getAllFeePaginatedUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one Fee object by college id.
     * Get a fee object by college id.
     */
    async getFeeByCollegeIdRaw(requestParameters: GetFeeByCollegeIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeeDTO>> {
        if (requestParameters.collegeId === null || requestParameters.collegeId === undefined) {
            throw new runtime.RequiredError('collegeId','Required parameter requestParameters.collegeId was null or undefined when calling getFeeByCollegeId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/fees/college/{collegeId}`.replace(`{${"collegeId"}}`, encodeURIComponent(String(requestParameters.collegeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeeDTOFromJSON(jsonValue));
    }

    /**
     * Returns one Fee object by college id.
     * Get a fee object by college id.
     */
    async getFeeByCollegeId(requestParameters: GetFeeByCollegeIdRequest, initOverrides?: RequestInit): Promise<FeeDTO> {
        const response = await this.getFeeByCollegeIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a fee object by id.
     * Get a fee object by id.
     */
    async getFeeByIdRaw(requestParameters: GetFeeByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeeDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFeeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/fees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeeDTOFromJSON(jsonValue));
    }

    /**
     * Get a fee object by id.
     * Get a fee object by id.
     */
    async getFeeById(requestParameters: GetFeeByIdRequest, initOverrides?: RequestInit): Promise<FeeDTO> {
        const response = await this.getFeeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing Fee object, updates it, and returns the Fee.
     * Update an existing Fee.
     */
    async updateFeeRaw(requestParameters: UpdateFeeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeeDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/fees`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FeeDTOToJSON(requestParameters.feeDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeeDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing Fee object, updates it, and returns the Fee.
     * Update an existing Fee.
     */
    async updateFee(requestParameters: UpdateFeeRequest, initOverrides?: RequestInit): Promise<FeeDTO> {
        const response = await this.updateFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
