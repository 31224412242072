import React, { useRef } from "react";
import { View, Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import styles from "../../styles/resume-styles";
import { useSectionContext } from "../../context/section-context";

interface SectionTitleProps {
  textKey: string;
  showNumber?: boolean;
}

export const SectionTitle: React.FC<SectionTitleProps> = ({ textKey, showNumber = false }) => {
  const { t } = useTranslation("bookResume");
  const context = useSectionContext();
  const sectionNumberRef = useRef(context?.sectionNumber);

  return (
    <View>
      <Text style={styles.sectionTitle}>
        {showNumber && context && `${sectionNumberRef.current}. `}
        {t(textKey)}
      </Text>
    </View>
  );
};
