import { StyleSheet } from "@react-pdf/renderer";
import { ColorsNewPdf } from "../../../../../../constants";
import { fontsNewPdf } from "../../../../../_common/fonts/fonts";

export const CoverStyles = StyleSheet.create({
  main_title: {
    fontSize: "56pt",
    fontFamily: fontsNewPdf.RobotoBlack,
    lineHeight: 0.9,
  },
  cover_subtitle_container: {
    borderTop: `3pt solid ${ColorsNewPdf.blue_text_background}`,
    width: "65%",
    marginLeft: "-2pt",
    marginTop: "15pt",
  },
  cover_subtitle: {
    color: ColorsNewPdf.blue_text_background,
    fontFamily: fontsNewPdf.RobotoBold,
    fontSize: "13pt",
  },
  cover_cadastralReference_container: {
    marginTop: 25,
    backgroundColor: ColorsNewPdf.blue_text_background,
    textAlign: "right",
    color: ColorsNewPdf.white,
    width: 340,
    alignSelf: "flex-end",
    padding: "4pt",
  },
  cover_cadastralReference_label: {
    fontSize: "13pt",
    marginBottom: "5pt",
    fontFamily: fontsNewPdf.RobotoBold,
  },
  texts_values_cover: {
    fontSize: "13pt",
    fontFamily: fontsNewPdf.RobotoRegular,
  },
  cover_address: {
    padding: "15pt",
    backgroundColor: ColorsNewPdf.blue_text_background,
    color: ColorsNewPdf.white,
    width: "524pt",
    height: "48pt",
  },
  cover_address_text: {
    fontSize: "13pt",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: fontsNewPdf.RobotoRegular,
  },
  cover_writingTechnicians_views: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
   cover_writingTechnicians_header_text: {
    fontSize: "11pt",
    textTransform: "uppercase",
    color: ColorsNewPdf.blue_text_background,
    fontFamily: fontsNewPdf.RobotoBold,
  },
  cover_writingTechnicians__text: {
    fontSize: "11pt",
    textTransform: "uppercase",
    color: ColorsNewPdf.blue_text_background,
    fontFamily: fontsNewPdf.RobotoBold,
  },
  cover_writingTechnicians_body_container: {
    borderBottom: `1pt dashed ${ColorsNewPdf.blue_text_background}`,
    height: "20pt",
  },
  cover_writingTechnicians_body: {
    fontSize: "9pt", 
    fontFamily: fontsNewPdf.RobotoRegular
  },
  cover_writingTechnicians_body_content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    margin: "16pt auto",
  },
});
