/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoiseImprovementDTO
 */
export interface NoiseImprovementDTO {
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    noiseOnTheDay?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    noiseOnTheDayMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    noisesFromFacilities?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    noisesFromFacilitiesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    noisesFromOtherBuildings?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    noisesFromOtherBuildingsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    noisesFromOtherVehicles?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    noisesFromOtherVehiclesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    otherNoises?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    otherNoisesFromOutside?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    otherNoisesFromOutsideMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementDTO
     */
    otherNoisesMedia?: string;
}

export function NoiseImprovementDTOFromJSON(json: any): NoiseImprovementDTO {
    return NoiseImprovementDTOFromJSONTyped(json, false);
}

export function NoiseImprovementDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoiseImprovementDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noiseOnTheDay': !exists(json, 'noiseOnTheDay') ? undefined : json['noiseOnTheDay'],
        'noiseOnTheDayMedia': !exists(json, 'noiseOnTheDayMedia') ? undefined : json['noiseOnTheDayMedia'],
        'noisesFromFacilities': !exists(json, 'noisesFromFacilities') ? undefined : json['noisesFromFacilities'],
        'noisesFromFacilitiesMedia': !exists(json, 'noisesFromFacilitiesMedia') ? undefined : json['noisesFromFacilitiesMedia'],
        'noisesFromOtherBuildings': !exists(json, 'noisesFromOtherBuildings') ? undefined : json['noisesFromOtherBuildings'],
        'noisesFromOtherBuildingsMedia': !exists(json, 'noisesFromOtherBuildingsMedia') ? undefined : json['noisesFromOtherBuildingsMedia'],
        'noisesFromOtherVehicles': !exists(json, 'noisesFromOtherVehicles') ? undefined : json['noisesFromOtherVehicles'],
        'noisesFromOtherVehiclesMedia': !exists(json, 'noisesFromOtherVehiclesMedia') ? undefined : json['noisesFromOtherVehiclesMedia'],
        'otherNoises': !exists(json, 'otherNoises') ? undefined : json['otherNoises'],
        'otherNoisesFromOutside': !exists(json, 'otherNoisesFromOutside') ? undefined : json['otherNoisesFromOutside'],
        'otherNoisesFromOutsideMedia': !exists(json, 'otherNoisesFromOutsideMedia') ? undefined : json['otherNoisesFromOutsideMedia'],
        'otherNoisesMedia': !exists(json, 'otherNoisesMedia') ? undefined : json['otherNoisesMedia'],
    };
}

export function NoiseImprovementDTOToJSON(value?: NoiseImprovementDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'noiseOnTheDay': value.noiseOnTheDay,
        'noiseOnTheDayMedia': value.noiseOnTheDayMedia,
        'noisesFromFacilities': value.noisesFromFacilities,
        'noisesFromFacilitiesMedia': value.noisesFromFacilitiesMedia,
        'noisesFromOtherBuildings': value.noisesFromOtherBuildings,
        'noisesFromOtherBuildingsMedia': value.noisesFromOtherBuildingsMedia,
        'noisesFromOtherVehicles': value.noisesFromOtherVehicles,
        'noisesFromOtherVehiclesMedia': value.noisesFromOtherVehiclesMedia,
        'otherNoises': value.otherNoises,
        'otherNoisesFromOutside': value.otherNoisesFromOutside,
        'otherNoisesFromOutsideMedia': value.otherNoisesFromOutsideMedia,
        'otherNoisesMedia': value.otherNoisesMedia,
    };
}

