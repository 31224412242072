/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
    NewAgentDTO,
    NewAgentDTOFromJSON,
    NewAgentDTOFromJSONTyped,
    NewAgentDTOToJSON,
    NewBuildingIdentificationDTO,
    NewBuildingIdentificationDTOFromJSON,
    NewBuildingIdentificationDTOFromJSONTyped,
    NewBuildingIdentificationDTOToJSON,
    NewTechnicianDTO,
    NewTechnicianDTOFromJSON,
    NewTechnicianDTOFromJSONTyped,
    NewTechnicianDTOToJSON,
    NewUrbanDataDTO,
    NewUrbanDataDTOFromJSON,
    NewUrbanDataDTOFromJSONTyped,
    NewUrbanDataDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewGeneralDataDTO
 */
export interface NewGeneralDataDTO {
    /**
     * 
     * @type {Array<NewAgentDTO>}
     * @memberof NewGeneralDataDTO
     */
    agents?: Array<NewAgentDTO>;
    /**
     * 
     * @type {NewBuildingIdentificationDTO}
     * @memberof NewGeneralDataDTO
     */
    buildingIdentification?: NewBuildingIdentificationDTO;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof NewGeneralDataDTO
     */
    images?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<NewTechnicianDTO>}
     * @memberof NewGeneralDataDTO
     */
    technicians?: Array<NewTechnicianDTO>;
    /**
     * 
     * @type {NewUrbanDataDTO}
     * @memberof NewGeneralDataDTO
     */
    urbanData?: NewUrbanDataDTO;
}

export function NewGeneralDataDTOFromJSON(json: any): NewGeneralDataDTO {
    return NewGeneralDataDTOFromJSONTyped(json, false);
}

export function NewGeneralDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewGeneralDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agents': !exists(json, 'agents') ? undefined : ((json['agents'] as Array<any>).map(NewAgentDTOFromJSON)),
        'buildingIdentification': !exists(json, 'buildingIdentification') ? undefined : NewBuildingIdentificationDTOFromJSON(json['buildingIdentification']),
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'technicians': !exists(json, 'technicians') ? undefined : ((json['technicians'] as Array<any>).map(NewTechnicianDTOFromJSON)),
        'urbanData': !exists(json, 'urbanData') ? undefined : NewUrbanDataDTOFromJSON(json['urbanData']),
    };
}

export function NewGeneralDataDTOToJSON(value?: NewGeneralDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agents': value.agents === undefined ? undefined : ((value.agents as Array<any>).map(NewAgentDTOToJSON)),
        'buildingIdentification': NewBuildingIdentificationDTOToJSON(value.buildingIdentification),
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(MediaPrintedDTOToJSON)),
        'technicians': value.technicians === undefined ? undefined : ((value.technicians as Array<any>).map(NewTechnicianDTOToJSON)),
        'urbanData': NewUrbanDataDTOToJSON(value.urbanData),
    };
}

