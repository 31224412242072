import { DocumentPage, SectionTitle, Spacer, SubSectionTitle, Paragraph } from "pdf/documents/book/resume/components";
import React from "react";
import { DeficienciesTable, DeficienciesDiagram } from "../components";
import { NewConservationStatusDTO, ShortcomingPrintedDTO } from "clients";

interface DeficiencyPageProps {
  conservationStatus?: NewConservationStatusDTO;
}

export const DeficiencyPage: React.FC<DeficiencyPageProps> = ({ conservationStatus }) => {
  const documentation = conservationStatus?.documentation;
  const lastInspection = conservationStatus?.previousInspections?.[conservationStatus?.previousInspections?.length - 1];
  const tableData: ShortcomingPrintedDTO[] | undefined = lastInspection?.inspection?.shortcomings;

  return (
    <DocumentPage>
      <SectionTitle textKey="resume.section.1.title" />
      <Spacer />
      <SubSectionTitle textKey="resume.sub.section.1.1.title" />
      <Paragraph textKey="resume.sub.section.1.1.description" />
      <Spacer />
      {tableData && <DeficienciesTable deficiencies={tableData} />}
      {/* <DeficienciesDiagram documentation={documentation} /> */}
    </DocumentPage>
  );
};
