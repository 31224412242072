/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildingPolygonDTO,
    BuildingPolygonDTOFromJSON,
    BuildingPolygonDTOFromJSONTyped,
    BuildingPolygonDTOToJSON,
    CadastralBasicPropertyDTO,
    CadastralBasicPropertyDTOFromJSON,
    CadastralBasicPropertyDTOFromJSONTyped,
    CadastralBasicPropertyDTOToJSON,
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
    PolygonDTO,
    PolygonDTOFromJSON,
    PolygonDTOFromJSONTyped,
    PolygonDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ParcelDTO
 */
export interface ParcelDTO {
    /**
     * 
     * @type {number}
     * @memberof ParcelDTO
     */
    area?: number;
    /**
     * 
     * @type {Array<BuildingPolygonDTO>}
     * @memberof ParcelDTO
     */
    buildingPolygons?: Array<BuildingPolygonDTO>;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    cadastralTown?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    community?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelDTO
     */
    dwellings?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelDTO
     */
    floorsAboveGround?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelDTO
     */
    floorsUnderGround?: number;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ParcelDTO
     */
    longitude?: number;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof ParcelDTO
     */
    media?: Array<MediaDTO>;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    pc1?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    pc2?: string;
    /**
     * 
     * @type {Array<PolygonDTO>}
     * @memberof ParcelDTO
     */
    polygons?: Array<PolygonDTO>;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    portalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    portalNumberLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelDTO
     */
    postalCode?: number;
    /**
     * 
     * @type {Array<CadastralBasicPropertyDTO>}
     * @memberof ParcelDTO
     */
    properties?: Array<CadastralBasicPropertyDTO>;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    secondaryPortalLetter?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    secondaryPortalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    streetType?: string;
    /**
     * 
     * @type {string}
     * @memberof ParcelDTO
     */
    town?: string;
    /**
     * 
     * @type {number}
     * @memberof ParcelDTO
     */
    year?: number;
}

export function ParcelDTOFromJSON(json: any): ParcelDTO {
    return ParcelDTOFromJSONTyped(json, false);
}

export function ParcelDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParcelDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'area': !exists(json, 'area') ? undefined : json['area'],
        'buildingPolygons': !exists(json, 'buildingPolygons') ? undefined : ((json['buildingPolygons'] as Array<any>).map(BuildingPolygonDTOFromJSON)),
        'cadastralTown': !exists(json, 'cadastralTown') ? undefined : json['cadastralTown'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'dwellings': !exists(json, 'dwellings') ? undefined : json['dwellings'],
        'floorsAboveGround': !exists(json, 'floorsAboveGround') ? undefined : json['floorsAboveGround'],
        'floorsUnderGround': !exists(json, 'floorsUnderGround') ? undefined : json['floorsUnderGround'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(MediaDTOFromJSON)),
        'pc1': !exists(json, 'pc1') ? undefined : json['pc1'],
        'pc2': !exists(json, 'pc2') ? undefined : json['pc2'],
        'polygons': !exists(json, 'polygons') ? undefined : ((json['polygons'] as Array<any>).map(PolygonDTOFromJSON)),
        'portalNumber': !exists(json, 'portalNumber') ? undefined : json['portalNumber'],
        'portalNumberLetter': !exists(json, 'portalNumberLetter') ? undefined : json['portalNumberLetter'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'properties': !exists(json, 'properties') ? undefined : ((json['properties'] as Array<any>).map(CadastralBasicPropertyDTOFromJSON)),
        'province': !exists(json, 'province') ? undefined : json['province'],
        'secondaryPortalLetter': !exists(json, 'secondaryPortalLetter') ? undefined : json['secondaryPortalLetter'],
        'secondaryPortalNumber': !exists(json, 'secondaryPortalNumber') ? undefined : json['secondaryPortalNumber'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetType': !exists(json, 'streetType') ? undefined : json['streetType'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function ParcelDTOToJSON(value?: ParcelDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'area': value.area,
        'buildingPolygons': value.buildingPolygons === undefined ? undefined : ((value.buildingPolygons as Array<any>).map(BuildingPolygonDTOToJSON)),
        'cadastralTown': value.cadastralTown,
        'community': value.community,
        'dwellings': value.dwellings,
        'floorsAboveGround': value.floorsAboveGround,
        'floorsUnderGround': value.floorsUnderGround,
        'id': value.id,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(MediaDTOToJSON)),
        'pc1': value.pc1,
        'pc2': value.pc2,
        'polygons': value.polygons === undefined ? undefined : ((value.polygons as Array<any>).map(PolygonDTOToJSON)),
        'portalNumber': value.portalNumber,
        'portalNumberLetter': value.portalNumberLetter,
        'postalCode': value.postalCode,
        'properties': value.properties === undefined ? undefined : ((value.properties as Array<any>).map(CadastralBasicPropertyDTOToJSON)),
        'province': value.province,
        'secondaryPortalLetter': value.secondaryPortalLetter,
        'secondaryPortalNumber': value.secondaryPortalNumber,
        'street': value.street,
        'streetName': value.streetName,
        'streetType': value.streetType,
        'town': value.town,
        'year': value.year,
    };
}

