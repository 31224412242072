/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShortcomingPrintedDTO
 */
export interface ShortcomingPrintedDTO {
    /**
     * 
     * @type {number}
     * @memberof ShortcomingPrintedDTO
     */
    constructionEndDate?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortcomingPrintedDTO
     */
    constructionStartDate?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ShortcomingPrintedDTO
     */
    fieldId?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    id?: string;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof ShortcomingPrintedDTO
     */
    images?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    priority?: ShortcomingPrintedDTOPriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    section?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    status?: ShortcomingPrintedDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    subsection?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortcomingPrintedDTO
     */
    testsMade?: string;
}

/**
* @export
* @enum {string}
*/
export enum ShortcomingPrintedDTOPriorityEnum {
    High = 'HIGH',
    Low = 'LOW',
    Medium = 'MEDIUM'
}/**
* @export
* @enum {string}
*/
export enum ShortcomingPrintedDTOStatusEnum {
    Favorable = 'FAVORABLE',
    Unfavorable = 'UNFAVORABLE'
}

export function ShortcomingPrintedDTOFromJSON(json: any): ShortcomingPrintedDTO {
    return ShortcomingPrintedDTOFromJSONTyped(json, false);
}

export function ShortcomingPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShortcomingPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'constructionEndDate': !exists(json, 'constructionEndDate') ? undefined : json['constructionEndDate'],
        'constructionStartDate': !exists(json, 'constructionStartDate') ? undefined : json['constructionStartDate'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'fieldId': !exists(json, 'fieldId') ? undefined : json['fieldId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'location': !exists(json, 'location') ? undefined : json['location'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'subsection': !exists(json, 'subsection') ? undefined : json['subsection'],
        'testsMade': !exists(json, 'testsMade') ? undefined : json['testsMade'],
    };
}

export function ShortcomingPrintedDTOToJSON(value?: ShortcomingPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'constructionEndDate': value.constructionEndDate,
        'constructionStartDate': value.constructionStartDate,
        'description': value.description,
        'fieldId': value.fieldId,
        'id': value.id,
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(MediaPrintedDTOToJSON)),
        'location': value.location,
        'name': value.name,
        'notes': value.notes,
        'priority': value.priority,
        'section': value.section,
        'status': value.status,
        'subsection': value.subsection,
        'testsMade': value.testsMade,
    };
}

