/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillDTO,
    BillDTOFromJSON,
    BillDTOFromJSONTyped,
    BillDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CecaSignatureResponse
 */
export interface CecaSignatureResponse {
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    acquirerBIN?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    cifrado?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    descripcion?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    exencionSCA?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    exponente?: string;
    /**
     * 
     * @type {BillDTO}
     * @memberof CecaSignatureResponse
     */
    factura?: BillDTO;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    importe?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    merchantID?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    numOperacion?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    signature?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    terminalID?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    tipoMoneda?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    urlNOK?: string;
    /**
     * 
     * @type {string}
     * @memberof CecaSignatureResponse
     */
    urlOK?: string;
}

export function CecaSignatureResponseFromJSON(json: any): CecaSignatureResponse {
    return CecaSignatureResponseFromJSONTyped(json, false);
}

export function CecaSignatureResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CecaSignatureResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acquirerBIN': !exists(json, 'acquirerBIN') ? undefined : json['acquirerBIN'],
        'cifrado': !exists(json, 'cifrado') ? undefined : json['cifrado'],
        'descripcion': !exists(json, 'descripcion') ? undefined : json['descripcion'],
        'exencionSCA': !exists(json, 'exencionSCA') ? undefined : json['exencionSCA'],
        'exponente': !exists(json, 'exponente') ? undefined : json['exponente'],
        'factura': !exists(json, 'factura') ? undefined : BillDTOFromJSON(json['factura']),
        'importe': !exists(json, 'importe') ? undefined : json['importe'],
        'merchantID': !exists(json, 'merchantID') ? undefined : json['merchantID'],
        'numOperacion': !exists(json, 'numOperacion') ? undefined : json['numOperacion'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'terminalID': !exists(json, 'terminalID') ? undefined : json['terminalID'],
        'tipoMoneda': !exists(json, 'tipoMoneda') ? undefined : json['tipoMoneda'],
        'urlNOK': !exists(json, 'urlNOK') ? undefined : json['urlNOK'],
        'urlOK': !exists(json, 'urlOK') ? undefined : json['urlOK'],
    };
}

export function CecaSignatureResponseToJSON(value?: CecaSignatureResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acquirerBIN': value.acquirerBIN,
        'cifrado': value.cifrado,
        'descripcion': value.descripcion,
        'exencionSCA': value.exencionSCA,
        'exponente': value.exponente,
        'factura': BillDTOToJSON(value.factura),
        'importe': value.importe,
        'merchantID': value.merchantID,
        'numOperacion': value.numOperacion,
        'signature': value.signature,
        'terminalID': value.terminalID,
        'tipoMoneda': value.tipoMoneda,
        'urlNOK': value.urlNOK,
        'urlOK': value.urlOK,
    };
}

