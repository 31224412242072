/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Pageable,
    PageableFromJSON,
    PageableFromJSONTyped,
    PageableToJSON,
    ParticipationListItemDTO,
    ParticipationListItemDTOFromJSON,
    ParticipationListItemDTOFromJSONTyped,
    ParticipationListItemDTOToJSON,
    Sort,
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomTechnicianParticipationPageDTO
 */
export interface CustomTechnicianParticipationPageDTO {
    /**
     * 
     * @type {Array<ParticipationListItemDTO>}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    content?: Array<ParticipationListItemDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTechnicianParticipationPageDTO
     */
    totalPages?: number;
}

export function CustomTechnicianParticipationPageDTOFromJSON(json: any): CustomTechnicianParticipationPageDTO {
    return CustomTechnicianParticipationPageDTOFromJSONTyped(json, false);
}

export function CustomTechnicianParticipationPageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomTechnicianParticipationPageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(ParticipationListItemDTOFromJSON)),
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableFromJSON(json['pageable']),
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
    };
}

export function CustomTechnicianParticipationPageDTOToJSON(value?: CustomTechnicianParticipationPageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(ParticipationListItemDTOToJSON)),
        'first': value.first,
        'last': value.last,
        'number': value.number,
        'numberOfElements': value.numberOfElements,
        'pageable': PageableToJSON(value.pageable),
        'size': value.size,
        'sort': SortToJSON(value.sort),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

