import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { useTranslation } from 'react-i18next';
import styles from "../../styles/resume-styles";

interface ParagraphProps {
    textKey: string;
}

export const Paragraph: React.FC<ParagraphProps> = ({textKey}) => {
  const { t } = useTranslation('bookResume');
  return (
      <View>
        <Text style={styles.paragraph}>{t(textKey)}</Text>
      </View>
  );
};
