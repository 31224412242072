import React, { useEffect } from "react";
import { View, Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import styles from "../../styles/resume-styles";
import { useSectionContext } from "../../context/section-context";

interface SubSectionTitleProps {
  textKey: string;
  showNumber?: boolean;
}

export const SubSectionTitle: React.FC<SubSectionTitleProps> = ({ textKey, showNumber = false }) => {
  const { t } = useTranslation("bookResume");
  const context = useSectionContext();

  useEffect(() => {
    context && context.incrementSubSection();
  }, []);

  return (
    <View>
      <Text style={styles.subSectionTitle}>
        {showNumber && context && `${context.sectionNumber}.${context.subSectionNumber} `}
        {t(textKey)}
      </Text>
    </View>
  );
};
