import { View, Text, Image as PdfImage } from "@react-pdf/renderer";
import { MediaPrintedDTO } from "clients";
import { validImageExtensions } from "../../../../../../constants";
import { fontsNewPdf } from "pdf/_common/fonts/fonts";
import { t } from "pdf/documents/book/book-v2/book-helpers";
import { TitleWithBlueBorder } from "pdf/documents/book/book-v2/components/titles/titles";
import React from "react";

interface EnergyCertificateViewProps {
  certificateImage?: MediaPrintedDTO;
}

export const EnergyCertificateView: React.FC<EnergyCertificateViewProps> = ({ certificateImage }) => {
  const isValid: boolean =
    typeof certificateImage?.url === "string" && validImageExtensions.some((ext) => certificateImage?.url?.toLowerCase().endsWith(ext));

  return certificateImage?.url ? (
    <View break>
      <TitleWithBlueBorder title={t("pdf.energyEfficiency.initialCertificate.title")} />
      <Text style={{ fontSize: "8pt", fontFamily: fontsNewPdf.RobotoRegular, paddingTop: 4 }}>{t("pdf.energyEfficiency.initialCertificate.text")}</Text>
      <View style={{ height: "600pt" }}>
        {isValid ? (
          <PdfImage
            src={{
              uri: certificateImage?.url ?? "",
              method: "GET",
              headers: { "Cache-Control": "no-cache" },
              body: "",
            }}
            style={{ objectFit: "contain" }}
          />
        ) : null}
      </View>
    </View>
  ) : (
    <></>
  );
};
