import { Text, View } from "@react-pdf/renderer";
import { InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum, NewConservationStatusDTO } from "../../../../../../../clients";
import { t } from "../../../book-helpers";
import { FontSizes } from "../../../../../../../constants";
import { TitleWithBlueBorder } from "../../../components/titles/titles";
import { PdfNewStyles } from "pdf/_common/styles/new-pdf-styles";

export const PartialAssesments: React.FC<{ data: NewConservationStatusDTO }> = ({ data }) => {
  return true ? (
    <>
      <PartialAssesment
        title={t("pdf.finalValoration.partialValoration.cimentacion")}
        notes={data.documentation?.foundationDeficienciesNotes}
        partialResult={data.documentation?.foundationDeficienciesFinalResult}
      />
      <PartialAssesment
        title={t("pdf.finalValoration.partialValoration.estructura")}
        notes={data.documentation?.structuralDeficienciesNotes}
        partialResult={data.documentation?.structuralDeficienciesFinalResult}
      />
      <PartialAssesment
        title={t("pdf.finalValoration.partialValoration.cerramientosVerticales")}
        notes={data.documentation?.verticalEnclosuresDeficienciesNotes}
        partialResult={data.documentation?.verticalEnclosuresDeficienciesFinalResult}
      />
      <PartialAssesment
        title={t("pdf.finalValoration.partialValoration.azoteasCubiertas")}
        notes={data.documentation?.roofsAndCoveringsDeficienciesNotes}
        partialResult={data.documentation?.roofsAndCoveringsDeficienciesFinalResult}
      />
      <PartialAssesment
        title={t("pdf.finalValoration.partialValoration.installations")}
        notes={data.documentation?.commonBuildingFacilitiesDeficienciesNotes}
        partialResult={data.documentation?.commonBuildingFacilitiesDeficienciesFinalResult}
      />
    </>
  ) : (
    <></>
  );
};

export const PartialAssesment: React.FC<{ title: string; notes?: string; partialResult?: string }> = ({ title = "", notes = "", partialResult }) => {
  const renderResultOptions = () => {
    return Object.values(InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum).map((k) => (
      <View key={k} style={{ width: 150, flexDirection: "row", height: 30 }}>
        <View style={[PdfNewStyles.hollow_circle]}>{partialResult === k ? <View style={[PdfNewStyles.inner_circle]} /> : null}</View>
        <Text style={{ fontSize: FontSizes.pdfP }}>{t("pdf.final_valorations.deficiencies.finalresult." + k)}</Text>
      </View>
    ));
  };

  return (
    <View style={[PdfNewStyles.block1_general_partial_assesment_other_element_viewer, { textAlign: "left" }]}>
      <View wrap={false}>
        {title && (
          <View style={{ marginVertical: 6 }}>
            <TitleWithBlueBorder title={title} />
          </View>
        )}
        <View style={{ flexDirection: "row", padding: "5pt", justifyContent: "flex-start", alignItems: "flex-start" }}>
          <Text style={{ fontSize: FontSizes.pdfP, fontWeight: 600 }}>Valoración: </Text>
        </View>
        <View style={{ flexDirection: "row", padding: "5pt", justifyContent: "flex-start", alignItems: "flex-start", marginTop: "1pt" }}>
          {renderResultOptions()}
        </View>
      </View>
      <>
        <Text style={{ fontSize: FontSizes.pdfP }}>{t("pdf.home.notes")}</Text>
        <Text style={{ fontSize: FontSizes.pdfP, ...PdfNewStyles.general_partial_assesment_notes }}>{notes}</Text>
      </>
    </View>
  );
};
