/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildingPolygonDTO,
    BuildingPolygonDTOFromJSON,
    BuildingPolygonDTOFromJSONTyped,
    BuildingPolygonDTOToJSON,
    PolygonDTO,
    PolygonDTOFromJSON,
    PolygonDTOFromJSONTyped,
    PolygonDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PolygonInfoDTO
 */
export interface PolygonInfoDTO {
    /**
     * 
     * @type {Array<BuildingPolygonDTO>}
     * @memberof PolygonInfoDTO
     */
    buildingPolygon?: Array<BuildingPolygonDTO>;
    /**
     * 
     * @type {Array<PolygonDTO>}
     * @memberof PolygonInfoDTO
     */
    parcelPolygon?: Array<PolygonDTO>;
}

export function PolygonInfoDTOFromJSON(json: any): PolygonInfoDTO {
    return PolygonInfoDTOFromJSONTyped(json, false);
}

export function PolygonInfoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PolygonInfoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildingPolygon': !exists(json, 'buildingPolygon') ? undefined : ((json['buildingPolygon'] as Array<any>).map(BuildingPolygonDTOFromJSON)),
        'parcelPolygon': !exists(json, 'parcelPolygon') ? undefined : ((json['parcelPolygon'] as Array<any>).map(PolygonDTOFromJSON)),
    };
}

export function PolygonInfoDTOToJSON(value?: PolygonInfoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildingPolygon': value.buildingPolygon === undefined ? undefined : ((value.buildingPolygon as Array<any>).map(BuildingPolygonDTOToJSON)),
        'parcelPolygon': value.parcelPolygon === undefined ? undefined : ((value.parcelPolygon as Array<any>).map(PolygonDTOToJSON)),
    };
}

