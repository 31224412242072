/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtendedMediaPrintedDTO,
    ExtendedMediaPrintedDTOFromJSON,
    ExtendedMediaPrintedDTOFromJSONTyped,
    ExtendedMediaPrintedDTOToJSON,
    MaintenancePlanActionDTO,
    MaintenancePlanActionDTOFromJSON,
    MaintenancePlanActionDTOFromJSONTyped,
    MaintenancePlanActionDTOToJSON,
    UsagePlanDTO,
    UsagePlanDTOFromJSON,
    UsagePlanDTOFromJSONTyped,
    UsagePlanDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewUseAndMaintenanceDTO
 */
export interface NewUseAndMaintenanceDTO {
    /**
     * 
     * @type {Array<ExtendedMediaPrintedDTO>}
     * @memberof NewUseAndMaintenanceDTO
     */
    actionRegistries?: Array<ExtendedMediaPrintedDTO>;
    /**
     * 
     * @type {Array<ExtendedMediaPrintedDTO>}
     * @memberof NewUseAndMaintenanceDTO
     */
    bestPractices?: Array<ExtendedMediaPrintedDTO>;
    /**
     * 
     * @type {Array<ExtendedMediaPrintedDTO>}
     * @memberof NewUseAndMaintenanceDTO
     */
    contracts?: Array<ExtendedMediaPrintedDTO>;
    /**
     * 
     * @type {Array<MaintenancePlanActionDTO>}
     * @memberof NewUseAndMaintenanceDTO
     */
    maintenanceManual?: Array<MaintenancePlanActionDTO>;
    /**
     * 
     * @type {Array<UsagePlanDTO>}
     * @memberof NewUseAndMaintenanceDTO
     */
    useManual?: Array<UsagePlanDTO>;
}

export function NewUseAndMaintenanceDTOFromJSON(json: any): NewUseAndMaintenanceDTO {
    return NewUseAndMaintenanceDTOFromJSONTyped(json, false);
}

export function NewUseAndMaintenanceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUseAndMaintenanceDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionRegistries': !exists(json, 'actionRegistries') ? undefined : ((json['actionRegistries'] as Array<any>).map(ExtendedMediaPrintedDTOFromJSON)),
        'bestPractices': !exists(json, 'bestPractices') ? undefined : ((json['bestPractices'] as Array<any>).map(ExtendedMediaPrintedDTOFromJSON)),
        'contracts': !exists(json, 'contracts') ? undefined : ((json['contracts'] as Array<any>).map(ExtendedMediaPrintedDTOFromJSON)),
        'maintenanceManual': !exists(json, 'maintenanceManual') ? undefined : ((json['maintenanceManual'] as Array<any>).map(MaintenancePlanActionDTOFromJSON)),
        'useManual': !exists(json, 'useManual') ? undefined : ((json['useManual'] as Array<any>).map(UsagePlanDTOFromJSON)),
    };
}

export function NewUseAndMaintenanceDTOToJSON(value?: NewUseAndMaintenanceDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionRegistries': value.actionRegistries === undefined ? undefined : ((value.actionRegistries as Array<any>).map(ExtendedMediaPrintedDTOToJSON)),
        'bestPractices': value.bestPractices === undefined ? undefined : ((value.bestPractices as Array<any>).map(ExtendedMediaPrintedDTOToJSON)),
        'contracts': value.contracts === undefined ? undefined : ((value.contracts as Array<any>).map(ExtendedMediaPrintedDTOToJSON)),
        'maintenanceManual': value.maintenanceManual === undefined ? undefined : ((value.maintenanceManual as Array<any>).map(MaintenancePlanActionDTOToJSON)),
        'useManual': value.useManual === undefined ? undefined : ((value.useManual as Array<any>).map(UsagePlanDTOToJSON)),
    };
}

