import { NewAddressDTO } from "../../../../clients";
import { Address } from "./book-model";
import translations from "./translations.json";

export const fullAddress = (address: Address): string => {
  return (
    (address.streetType ?? "") + "/ " + (address.streetName ?? "") + ", " + (address.block ?? "") + " " + (address.portalNumber ?? "") + " - " + (address.postalCode ?? "") + " " + (address.town ?? "")
  );
};
export const newFullAddress = (address?: NewAddressDTO): string => {
  return (
    (address?.streetType ?? "") + "/ " + (address?.streetName ?? "") + ", " + (address?.block ?? "") + " " + (address?.portalNumber ?? "") + " - " + (address?.postalCode ?? "") + " " + (address?.town ?? "")
  );
};
export const t = (key: string): string => {
  return (translations as any)[key] ?? "Not set";
};
