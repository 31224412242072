import { HashNavigationAdapter, Router } from "@frui.ts/screens";
import { View } from "@frui.ts/views";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import es from "date-fns/locale/es";
import { Container } from "inversify";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom";
import "./app.css";
import { AdminActionsApi, CollegeAdminActionsApi } from "./clients";
import { BuildingsApi } from "./data/repositories/api/BuildingApi";
import { MapApi } from "./data/repositories/api/MapApi";
import { ParcelsApi } from "./data/repositories/api/ParcelApi";
import { PropertiesApi } from "./data/repositories/api/PropertiesApi";
import { ProvincesApi } from "./data/repositories/api/ProvincesApi";
import { TownApi } from "./data/repositories/api/TownApi";
import { BookApi } from "./data/repositories/api/book-api";
import { CadastralApi } from "./data/repositories/api/cadastral-api";
import { CollegeAdministratorApi } from "./data/repositories/api/college-administrator-api";
import { CollegeApi } from "./data/repositories/api/college-api";
import { CommunityApi } from "./data/repositories/api/community-api";
import { ImprovementActions } from "./data/repositories/api/improvement-actions-api";
import { MediaApi } from "./data/repositories/api/multimedia-api";
import { TechniciansApi } from "./data/repositories/api/technicians-api";
import { UserManagementApi } from "./data/repositories/api/user-management-api";
import { UsersApi } from "./data/repositories/api/users-api";
import ApiClient, { Api } from "./infrastructure/data/api-client";
import theme from "./theme";
import RootViewModel from "./viewmodels/root-view-model";
import { BillApi } from "./data/repositories/api/bill-api";
import { PaymentGatewayApi } from "./data/repositories/api/PaymentGatewayApi";
import { FeeApi } from "./data/repositories/api/fee-api";

ApiClient.register(Api.BillApi, new BillApi(), false);
ApiClient.register(Api.MapApi, new MapApi(), false);
ApiClient.register(Api.PropertiesApi, new PropertiesApi(), false);
ApiClient.register(Api.CadastralApi, new CadastralApi(), false);
ApiClient.register(Api.TechniciansApi, new TechniciansApi(), false);
ApiClient.register(Api.UsersApi, new CadastralApi(), false);
ApiClient.register(Api.BuildingApi, new BuildingsApi(), false);
ApiClient.register(Api.ParcelsApi, new ParcelsApi(), false);
ApiClient.register(Api.CommunityApi, new CommunityApi(), false);
ApiClient.register(Api.ProvincesApi, new ProvincesApi(), false);
ApiClient.register(Api.BookApi, new BookApi(), false);
ApiClient.register(Api.TownApi, new TownApi(), false);
ApiClient.register(Api.CollegeApi, new CollegeApi(), false);
ApiClient.register(Api.ImprovementActionsApi, new ImprovementActions(), false);
ApiClient.register(Api.UserManagementApi, new UserManagementApi(), false);
ApiClient.register(Api.CollegeAdministratorApi, new CollegeAdministratorApi(), false);
ApiClient.register(Api.MediaApi, new MediaApi(), false);
ApiClient.register(Api.UsersProfileApi, new UsersApi(), false);
ApiClient.register(Api.CollegeAdminActionsApi, new CollegeAdminActionsApi(), false);
ApiClient.register(Api.AdminActionsApi, new AdminActionsApi(), false);
ApiClient.register(Api.PaymentGatewayApi, new PaymentGatewayApi(), false);
ApiClient.register(Api.FeeApi, new FeeApi(), false);

export function runApp(container: Container) {
  let viewModel = container.get(RootViewModel);
  viewModel.activate();
  const urlAdapter = new HashNavigationAdapter();
  urlAdapter.start(viewModel);
  const router = new Router();
  router.start(viewModel);
  ReactDOM.render(
    <SnackbarProvider maxSnack={3}>
      <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <View vm={viewModel} useLifecycle />
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </SnackbarProvider>,
    document.getElementById("root")
  );
}
