import { View, Text } from "@react-pdf/renderer";
import { ImprovementDTO } from "clients";
import { ColorsNewPdf } from "../../../../../../constants";
import { fontsNewPdf } from "pdf/_common/fonts/fonts";
import { t } from "pdf/documents/book/book-v2/book-helpers";

interface ImprovementsTableProps {
  improvements: ImprovementDTO[];
  hideDifficultyColumn?: boolean;
  hidePriorityColumn?: boolean;
  showBenefitColumn?: boolean;
}

export const ImprovementsTable: React.FC<ImprovementsTableProps> = ({ improvements, hideDifficultyColumn = false, hidePriorityColumn = false, showBenefitColumn= false }) => {
  return (
    <View>
      {/* TableHeader */}
      <View wrap={false} style={{ flexDirection: "row", borderTop: `1pt solid ${ColorsNewPdf.phases_table_border}` }}>
        <View
          style={{
            width: "25% !important",
            fontSize: "9pt",
            textAlign: "center",
            borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            fontFamily: fontsNewPdf.RobotoBold,
            height: "43pt",
            justifyContent: "center",
          }}
        >
          <Text>{t("profile.document.pdf.improvement.name")}</Text>
        </View>
        <View
          style={{
            width: hidePriorityColumn ? "50% !important" : "40% !important",
            fontSize: "9pt",
            textAlign: "center",
            borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            justifyContent: "center",
            fontFamily: fontsNewPdf.RobotoBold,
            height: "43pt",
          }}
        >
          <Text>{t("profile.document.pdf.improvement.description")}</Text>
        </View>
        {!hideDifficultyColumn && (
          <View
            style={{
              width: "12.5% !important",
              fontSize: "9pt",
              borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
              borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
              textAlign: "center",
              fontFamily: fontsNewPdf.RobotoBold,
              height: "43pt",
              justifyContent: "center",
            }}
          >
            <Text>{t("report.block2.actions.table.dificulty")}</Text>
          </View>
        )}
        <View
          style={{
            width: hideDifficultyColumn ? "25% !important" : "12.5% !important",
            fontSize: "9pt",
            borderRight: showBenefitColumn || !hidePriorityColumn ? `1pt solid ${ColorsNewPdf.phases_table_border}`: "none",
            borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            textAlign: "center",
            fontFamily: fontsNewPdf.RobotoBold,
            height: "43pt",
            justifyContent: "center",
          }}
        >
          <Text>{t("report.block2.actions.table.estimatedCost")}</Text>
        </View>
        {!hidePriorityColumn && (
          <View
            style={{
              width: "10% !important",
              fontSize: "9pt",
              borderRight: showBenefitColumn ? `1pt solid ${ColorsNewPdf.phases_table_border}`: "none",
              borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
              textAlign: "center",
              fontFamily: fontsNewPdf.RobotoBold,
              height: "43pt",
              justifyContent: "center",
            }}
          >
            <Text>{t("report.block2.actions.table.priority")}</Text>
          </View>
        )}
        {showBenefitColumn && (
          <View
            style={{
              width: "25% !important",
              fontSize: "9pt",
              borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
              textAlign: "center",
              fontFamily: fontsNewPdf.RobotoBold,
              height: "43pt",
              justifyContent: "center",
            }}
          >
            <Text>{t("report.block2.actions.table.benefit")}</Text>
          </View>
        )}
      </View>
      {/* TableRows */}
      {improvements?.map((improvement, index) => (
        <View wrap={false} style={{ flexDirection: "row" }} key={index}>
          <View
            wrap={false}
            style={{
              backgroundColor: index % 2 === 0 ? ColorsNewPdf.eec_table_background : "transparent",
              width: "25%",
              borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
              borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
              textAlign: "center",
            }}
          >
            <Text style={{ fontSize: "8pt", fontFamily: fontsNewPdf.RobotoRegular, padding: "5pt" }}>{improvement.name ?? "-"}</Text>
          </View>
          <View
            wrap={false}
            style={{
              width: hidePriorityColumn ? "50%" : "40%",
              backgroundColor: index % 2 === 0 ? ColorsNewPdf.eec_table_background : "transparent",
              borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
              borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            }}
          >
            <Text style={{ fontSize: "8pt", fontFamily: fontsNewPdf.RobotoRegular, padding: "5pt" }}>{improvement.measureDescription ?? "-"}</Text>
          </View>
          {!hideDifficultyColumn && (
            <View
              wrap={false}
              style={{
                backgroundColor: index % 2 === 0 ? ColorsNewPdf.eec_table_background : "transparent",
                width: "12.5%",
                borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
                borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: "8pt", fontFamily: fontsNewPdf.RobotoRegular, padding: "5pt" }}>
                {t("report.block2.improvement.difficulty." + improvement.difficulty)}
              </Text>
            </View>
          )}
          <View
            wrap={false}
            style={{
              backgroundColor: index % 2 === 0 ? ColorsNewPdf.eec_table_background : "transparent",
              width: hideDifficultyColumn ? "25%" : "12.5%",
              borderRight: showBenefitColumn || !hidePriorityColumn ? `1pt solid ${ColorsNewPdf.phases_table_border}`: "none",
              borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
              textAlign: "center",
            }}
          >
            <Text style={{ fontSize: "8pt", fontFamily: fontsNewPdf.RobotoRegular, padding: "5pt" }}>
              {t("report.block2.improvement.cost." + improvement.estimatedCost)}
            </Text>
          </View>
          {!hidePriorityColumn && (
            <View
              wrap={false}
              style={{
                backgroundColor: index % 2 === 0 ? ColorsNewPdf.eec_table_background : "transparent",
                width: "10%",
                borderRight: showBenefitColumn ? `1pt solid ${ColorsNewPdf.phases_table_border}`: "none",
                borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: "8pt", fontFamily: fontsNewPdf.RobotoRegular, padding: "5pt" }}>
                {t("report.block2.improvement.priority." + improvement.priority)}
              </Text>
            </View>
          )}
          {showBenefitColumn && (
            <View
              wrap={false}
              style={{
                backgroundColor: index % 2 === 0 ? ColorsNewPdf.eec_table_background : "transparent",
                width: "25%",
                borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
                textAlign: "center",
              }}
            >
              <Text style={{ fontSize: "8pt", fontFamily: fontsNewPdf.RobotoRegular, padding: "5pt" }}>
                {improvement.benefitDescription}
              </Text>
            </View>
          )}

        </View>
      ))}
    </View>
  );
};
