/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AgentDTO,
    AgentDTOFromJSON,
    AgentDTOToJSON,
    ExtendedSignupTechnician,
    ExtendedSignupTechnicianFromJSON,
    ExtendedSignupTechnicianToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    NewPassword,
    NewPasswordFromJSON,
    NewPasswordToJSON,
    TechnicianDTO,
    TechnicianDTOFromJSON,
    TechnicianDTOToJSON,
    TechnicianInviteDTO,
    TechnicianInviteDTOFromJSON,
    TechnicianInviteDTOToJSON,
} from '../models';

export interface ActivateAgentRequest {
    id: string;
}

export interface ActivateTechnicianRequest {
    id: string;
}

export interface DeactivateAgentRequest {
    id: string;
}

export interface DeactivateTechnicianRequest {
    id: string;
}

export interface GetTechnicianByCredentialsIdUMRequest {
    credentialsId: string;
}

export interface ResetPasswordRequest {
    newPassword?: NewPassword;
}

export interface SearchAgentRequest {
    idNumber?: string;
    username?: string;
}

export interface SearchAgentByCredentialsIdRequest {
    credentialsId: string;
}

export interface SendUsernameToRecoverRequest {
    body?: string;
}

export interface TechnicianDeletionRequest {
    id: string;
}

export interface TechnicianInvitationRequest {
    technicianInviteDTO?: TechnicianInviteDTO;
}

export interface TechnicianSignUpApprovalRequest {
    id: string;
}

export interface TechnicianSignUpRejectionRequest {
    id: string;
    body?: string;
}

export interface TechnicianSignUpRequestRequest {
    extendedSignupTechnician?: ExtendedSignupTechnician;
}

export interface TechnicianUpdateRequest {
    technicianDTO?: TechnicianDTO;
}

export interface UpdateAgentRequest {
    agentDTO?: AgentDTO;
}

/**
 * 
 */
export class UserManagementApi extends runtime.BaseAPI {

    /**
     * Agent activate.
     * Agent activate. (as logical undelete)
     */
    async activateAgentRaw(requestParameters: ActivateAgentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgentDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activateAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/agents/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentDTOFromJSON(jsonValue));
    }

    /**
     * Agent activate.
     * Agent activate. (as logical undelete)
     */
    async activateAgent(requestParameters: ActivateAgentRequest, initOverrides?: RequestInit): Promise<AgentDTO> {
        const response = await this.activateAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Technician activate.
     * Technician activate. (as logical undelete)
     */
    async activateTechnicianRaw(requestParameters: ActivateTechnicianRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activateTechnician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/technicians/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Technician activate.
     * Technician activate. (as logical undelete)
     */
    async activateTechnician(requestParameters: ActivateTechnicianRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.activateTechnicianRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Agent deactivate.
     * Agent deactivate. (as logical delete)
     */
    async deactivateAgentRaw(requestParameters: DeactivateAgentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgentDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deactivateAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/agents/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentDTOFromJSON(jsonValue));
    }

    /**
     * Agent deactivate.
     * Agent deactivate. (as logical delete)
     */
    async deactivateAgent(requestParameters: DeactivateAgentRequest, initOverrides?: RequestInit): Promise<AgentDTO> {
        const response = await this.deactivateAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Technician deactivate.
     * Technician deactivate. (as logical delete)
     */
    async deactivateTechnicianRaw(requestParameters: DeactivateTechnicianRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deactivateTechnician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/technicians/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Technician deactivate.
     * Technician deactivate. (as logical delete)
     */
    async deactivateTechnician(requestParameters: DeactivateTechnicianRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.deactivateTechnicianRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Technicians by college of the user token
     * Get all Technicians by college of the user token
     */
    async getAllTechniciansByCollegeRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ExtendedSignupTechnician>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/college`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExtendedSignupTechnicianFromJSON));
    }

    /**
     * Get all Technicians by college of the user token
     * Get all Technicians by college of the user token
     */
    async getAllTechniciansByCollege(initOverrides?: RequestInit): Promise<Array<ExtendedSignupTechnician>> {
        const response = await this.getAllTechniciansByCollegeRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get technician by credentialsId
     * Get technician by credentialsId
     */
    async getTechnicianByCredentialsIdUMRaw(requestParameters: GetTechnicianByCredentialsIdUMRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtendedSignupTechnician>> {
        if (requestParameters.credentialsId === null || requestParameters.credentialsId === undefined) {
            throw new runtime.RequiredError('credentialsId','Required parameter requestParameters.credentialsId was null or undefined when calling getTechnicianByCredentialsIdUM.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/{credentialsId}`.replace(`{${"credentialsId"}}`, encodeURIComponent(String(requestParameters.credentialsId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendedSignupTechnicianFromJSON(jsonValue));
    }

    /**
     * Get technician by credentialsId
     * Get technician by credentialsId
     */
    async getTechnicianByCredentialsIdUM(requestParameters: GetTechnicianByCredentialsIdUMRequest, initOverrides?: RequestInit): Promise<ExtendedSignupTechnician> {
        const response = await this.getTechnicianByCredentialsIdUMRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reset a password for a user
     * Reset a password for a user
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/recovery/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPasswordToJSON(requestParameters.newPassword),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Reset a password for a user
     * Reset a password for a user
     */
    async resetPassword(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Agent find by idNumber or username.
     * Agent find by idNumber or username.
     */
    async searchAgentRaw(requestParameters: SearchAgentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgentDTO>> {
        const queryParameters: any = {};

        if (requestParameters.idNumber !== undefined) {
            queryParameters['idNumber'] = requestParameters.idNumber;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/agents/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentDTOFromJSON(jsonValue));
    }

    /**
     * Agent find by idNumber or username.
     * Agent find by idNumber or username.
     */
    async searchAgent(requestParameters: SearchAgentRequest, initOverrides?: RequestInit): Promise<AgentDTO> {
        const response = await this.searchAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * find agent by credentials id
     * Find agent by credentials id
     */
    async searchAgentByCredentialsIdRaw(requestParameters: SearchAgentByCredentialsIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgentDTO>> {
        if (requestParameters.credentialsId === null || requestParameters.credentialsId === undefined) {
            throw new runtime.RequiredError('credentialsId','Required parameter requestParameters.credentialsId was null or undefined when calling searchAgentByCredentialsId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/agents/credentials/{credentialsId}`.replace(`{${"credentialsId"}}`, encodeURIComponent(String(requestParameters.credentialsId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentDTOFromJSON(jsonValue));
    }

    /**
     * find agent by credentials id
     * Find agent by credentials id
     */
    async searchAgentByCredentialsId(requestParameters: SearchAgentByCredentialsIdRequest, initOverrides?: RequestInit): Promise<AgentDTO> {
        const response = await this.searchAgentByCredentialsIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send an username to reset its password
     * Send username to init password reset request.
     */
    async sendUsernameToRecoverRaw(requestParameters: SendUsernameToRecoverRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/recovery/username`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Send an username to reset its password
     * Send username to init password reset request.
     */
    async sendUsernameToRecover(requestParameters: SendUsernameToRecoverRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.sendUsernameToRecoverRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Technician Deletion.
     * Technician Deletion.
     */
    async technicianDeletionRaw(requestParameters: TechnicianDeletionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling technicianDeletion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Technician Deletion.
     * Technician Deletion.
     */
    async technicianDeletion(requestParameters: TechnicianDeletionRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.technicianDeletionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Technician invitation.
     * Technician invitation.
     */
    async technicianInvitationRaw(requestParameters: TechnicianInvitationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/technicians/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TechnicianInviteDTOToJSON(requestParameters.technicianInviteDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Technician invitation.
     * Technician invitation.
     */
    async technicianInvitation(requestParameters: TechnicianInvitationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.technicianInvitationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Technician sign up Approval.
     * Technician sign up Approval.
     */
    async technicianSignUpApprovalRaw(requestParameters: TechnicianSignUpApprovalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling technicianSignUpApproval.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/signup/approval/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Technician sign up Approval.
     * Technician sign up Approval.
     */
    async technicianSignUpApproval(requestParameters: TechnicianSignUpApprovalRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.technicianSignUpApprovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Technician Sign Up Rejection
     * Technician Sign Up Rejection
     */
    async technicianSignUpRejectionRaw(requestParameters: TechnicianSignUpRejectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling technicianSignUpRejection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/signup/rejection/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Technician Sign Up Rejection
     * Technician Sign Up Rejection
     */
    async technicianSignUpRejection(requestParameters: TechnicianSignUpRejectionRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.technicianSignUpRejectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Technician Sign Up Request
     * Technician Sign Up Request
     */
    async technicianSignUpRequestRaw(requestParameters: TechnicianSignUpRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExtendedSignupTechnicianToJSON(requestParameters.extendedSignupTechnician),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Technician Sign Up Request
     * Technician Sign Up Request
     */
    async technicianSignUpRequest(requestParameters: TechnicianSignUpRequestRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.technicianSignUpRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Technician update
     * Technician Update
     */
    async technicianUpdateRaw(requestParameters: TechnicianUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TechnicianDTOToJSON(requestParameters.technicianDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianDTOFromJSON(jsonValue));
    }

    /**
     * Technician update
     * Technician Update
     */
    async technicianUpdate(requestParameters: TechnicianUpdateRequest, initOverrides?: RequestInit): Promise<TechnicianDTO> {
        const response = await this.technicianUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an agent object
     * Update an agent
     */
    async updateAgentRaw(requestParameters: UpdateAgentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgentDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/agents`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AgentDTOToJSON(requestParameters.agentDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentDTOFromJSON(jsonValue));
    }

    /**
     * Update an agent object
     * Update an agent
     */
    async updateAgent(requestParameters: UpdateAgentRequest, initOverrides?: RequestInit): Promise<AgentDTO> {
        const response = await this.updateAgentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
