import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";
import { action, observable } from "mobx";
import type { ExtendedSignupTechnician } from "../../clients";
import { ExtendedSignupTechnicianRoleEnum, ExtendedSignupTechnicianTypeEnum } from "../../clients";
import Town from "../../data/entities/town-entity";
import TownRepository from "../../data/repositories/impl/town-repository";
import UserManagementRepository from "../../data/repositories/impl/user-management-repository";
import i18n from "../../infrastructure/localization/i18n";
import { FormValidator } from "../../views/reports/reportComponents/block0/urbanData/components/FormValidator";
import RootViewModel from "../root-view-model";

@Router.registerRoute({ name: Router.Self, route: "register" })
export default class RegisterViewModel extends ConductorOneChildActive<ScreenBase> {
  @observable registerDialog = false;
  @observable town?: Town[] = undefined;
  @observable item: ExtendedSignupTechnician = {
    name: "",
    surname: "",
    collegiated: [],
    representer: undefined,
    representing: undefined,
    credentials: undefined,
    email: "",
    idNumber: "",
    mobilePhone: "",
    username: "",
    password: "",
    phone: "",
    address: {
      town: "Zaragoza",
      floor: "",
      community: "",
      door: "",
      block: "",
      portalNumber: "",
      portalNumberLetter: "",
      postalCode: 0,
      province: "",
      secondaryPortalNumber: "",
      secondaryPortalNumberLetter: "",
      stair: "",
      streetCode: "12",
      streetName: "",
      streetType: "",
    },
    role: ExtendedSignupTechnicianRoleEnum.Technician,
    tenant: "c401c348-c859-4ef5-97c7-915de9caee13",
    type: ExtendedSignupTechnicianTypeEnum.Legal,
  };
  parent: RootViewModel = this.parent;
  validatorVM = new FormValidator();

  constructor(private userManagementRepository: UserManagementRepository, public townRepository: TownRepository) {
    super();
    this.navigationName = "register";
    this.nameValue = "section.register";
  }

  translate(key: string): string {
    return i18n.t(key) ? i18n.t(key) : key;
  }

  @action.bound
  async registry(technicianParameter: ExtendedSignupTechnician) {
    const mainCollegeId = technicianParameter?.collegiated?.at(0)?.college?.id;
    const originalItem: ExtendedSignupTechnician = { ...technicianParameter, mainCollege: mainCollegeId };
    await this.userManagementRepository.technicianSignUpRequest({ extendedSignupTechnician: originalItem }).then((res) => {
      res && (this.registerDialog = false);
    });
  }

  async onActivate() {
    super.onActivate();
  }
}
