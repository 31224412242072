import { Colors } from '../../constants'
import { createStyles, makeStyles } from '@mui/styles'

export const ProfileStyle = makeStyles(() => createStyles({
  profile_grid: {
    height: 150,
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    marginRight:5,
    alignItems: "center",
    backgroundColor: Colors.white,
    border: "1px solid " + Colors.hr,
    borderRightColor: Colors.hr,
    boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 21%);"
  },
  profile_grid2: {
    height: 210,
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    marginRight: 5,
    alignItems: "center",
    backgroundColor: Colors.white,
    border: "1px solid " + Colors.hr,
    borderRightColor: Colors.hr,
    boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 21%);"
  },
  profile_email: {
    overflowWrap: 'anywhere',
    marginLeft: '5px',
  },
  profile_displayGrid: {
    display: "grid", marginTop: 5
  },

  profile_generalDataGrid: {
    display: "flex",
    justifyContent: "flex-start"
  },

  profile_gridClose: {
    height: 150,
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    backgroundColor: Colors.white,
   boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 21%);"
  },
  profile_gridClose2: {
    height: 210,
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    backgroundColor: Colors.white,
    boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 21%);"
  },
  profile_paperUser: {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: 300,
    width:300,
    display: "flex",
    backgroundColor: Colors.white,
    marginRight:40
   // boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 31%);"
  },
  profile_paperUser2: {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: 210,
    width: 300,
    display: "flex",
    backgroundColor: Colors.white,
    marginRight: 40
    // boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 31%);"
  },
  profile_paperProfileUser: {
    backgroundColor: "white",
    //boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 31%);"
  },

  profile_button: {
    marginTop: 16,
  },
  profile_header: {
    padding: 15,
    paddingBottom: 0
  },
  profile_gridForm: {
    display: 'flex',
    textAlign: "center"
  },
  profile_gridItemRight: {
    textAlign: "end",
    marginRight: 20
  },
  profile_gridItemLeft: {
    textAlign: "start",
    marginLeft: 20
  },
  profile_information: {
    display: "flex",
    flexDirection: "column",
    textAlign: "justify",
  },
  profile_buttonSave: {
    padding: "0px 0px 10px 0px"
  }
}))