/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDTO0,
    AddressDTO0FromJSON,
    AddressDTO0FromJSONTyped,
    AddressDTO0ToJSON,
} from './';

/**
 * 
 * @export
 * @interface TechnicianListItemDTO
 */
export interface TechnicianListItemDTO {
    /**
     * 
     * @type {boolean}
     * @memberof TechnicianListItemDTO
     */
    active?: boolean;
    /**
     * 
     * @type {AddressDTO0}
     * @memberof TechnicianListItemDTO
     */
    address?: AddressDTO0;
    /**
     * 
     * @type {Array<string>}
     * @memberof TechnicianListItemDTO
     */
    collegeName?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TechnicianListItemDTO
     */
    credentialsId?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianListItemDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianListItemDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianListItemDTO
     */
    role?: TechnicianListItemDTORoleEnum;
    /**
     * 
     * @type {string}
     * @memberof TechnicianListItemDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianListItemDTO
     */
    technicianId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicianListItemDTO
     */
    valid?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum TechnicianListItemDTORoleEnum {
    BackofficeManager = 'BACKOFFICE_MANAGER',
    CollegeAdministrator = 'COLLEGE_ADMINISTRATOR',
    HomeownersPresident = 'HOMEOWNERS_PRESIDENT',
    Owner = 'OWNER',
    PropertyManager = 'PROPERTY_MANAGER',
    Representative = 'REPRESENTATIVE',
    SystemAdministrator = 'SYSTEM_ADMINISTRATOR',
    Technician = 'TECHNICIAN'
}

export function TechnicianListItemDTOFromJSON(json: any): TechnicianListItemDTO {
    return TechnicianListItemDTOFromJSONTyped(json, false);
}

export function TechnicianListItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicianListItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'address': !exists(json, 'address') ? undefined : AddressDTO0FromJSON(json['address']),
        'collegeName': !exists(json, 'collegeName') ? undefined : json['collegeName'],
        'credentialsId': !exists(json, 'credentialsId') ? undefined : json['credentialsId'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'technicianId': !exists(json, 'technicianId') ? undefined : json['technicianId'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
    };
}

export function TechnicianListItemDTOToJSON(value?: TechnicianListItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'address': AddressDTO0ToJSON(value.address),
        'collegeName': value.collegeName,
        'credentialsId': value.credentialsId,
        'idNumber': value.idNumber,
        'name': value.name,
        'role': value.role,
        'surname': value.surname,
        'technicianId': value.technicianId,
        'valid': value.valid,
    };
}

