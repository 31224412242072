import { ITableColors, ITableHeaderMargins } from "@movicoders/movicoders-components";

export const Colors = {
  white: "#FFFFFF",
  yellow: "#ffae36",
  textGray: "rgb(191, 191, 191, 0.50)",
  grayRadioButton: "rgb(191, 191, 191, 0.99)",
  infoColor: "rgba(0, 0, 0, 0.54)",
  hr: "#e6e6e6",
  error: "rgba(225, 62, 62, 0.8)",

  pdfPrimaryText: "#363431",
  pdfShadowColor: "#E2E5EA",
  pdfDarkColor: "#9ea3aa",
  pdfDarkerColor: "#0b0c0c",

  primary: "#0d2b52",
  primaryOpacity: "rgba(44, 101, 191, 0.06)",
  primaryBackground: "#F7F7F7",

  buttonPrimary: "#0d2b52",
  primaryAlternative: "#2d2a52",
  buttonSecondary: "rgb(154, 205, 50)",
  buttonHighlighted: "#82D173",

  textTitle: "rgb(133,111,25)",
  textPrimaryHeaders: "rgba(255, 165, 0, 1)",
  textSecondaryHeaders: "#0d2b52",
  textPrimary: "#999999",
  textSecondary: "rgba(0, 0, 0, 0.54)",
};

export const TableColors: ITableColors = {
  textHeaderColor: Colors.primary,
  textCellColor: Colors.textPrimary,
  textTitleColor: Colors.primary,
};

export const TableMargins: ITableHeaderMargins = {
  leftMargin: "2px !important",
  rightMargin: "2px !important",
};

/**
 * * INFO: Use this styles for all the pages of the new pdf
 */
export const NewPdfPaddingsPage = {
  paddingTop: "24px",
  paddingBottom: "29px",
  paddingHorizontal: "35px",
};
export const ColorsNewPdf = {
  black: "#000000",
  white: "#FFFFFF",
  /* cover */
  blue_text_background: "#008FCC",
  cover_title: "#020203",
  /* index */
  grey_text: "#52545C",
  green_text: "#3FA535",
  /* general data */
  grey_text_background_text: "#52545C",
  grey_table_header: "#BABBBE",
  grey_table_background: "#E9EBED",
  grey_table_lines_map_background: "#909DA5",
  text_header: "#1A1A1A",
  background_containers: "#C9CFD3",
  /** block 1 */
  background_containers_block1: "#D7E3EF",
  dark_blue_text_background_lines: "#3A75AD",
  table_lines_map_background: "#E9EBED",
  table_total_background: "#C9CFD3",
  background_containers_block1_alternative: "#9EBBD7",
  background_title_section: "#9DBAD6",
  icon_border: "#3B75AD",
  background_subtitle_section: "#EBF1F7",
  lines_subtitle_section: "#3976AE",
  red_no_info: "#CD1719",
  background_table_subsection: "#373636",
  background_table_dates: "#C7CED2",
  text_table_subsection: "#909DA5",
  text_red_report_table: "#E50000",
  text_yellow_info_table: "#FFD23E",
  text_orange_info_table: "#FF6600",
  text_red_info_table: "#CD1719",
  grey_background: "#E9EBED",
  /** block 1 - energy certificates */
  background_letter_D: "#FFED00",
  background_letter_B: "#21B24B",
  table1_background_letter_A: "#017630",
  table1_background_letter_B: "#26A62F",
  table1_background_letter_C: "#99CA3B",
  table1_background_letter_D: "#F2E701",
  table1_background_letter_E: "#F1B900",
  table1_background_letter_F: "#DE7A00",
  table1_background_letter_G: "#CD1719",
  table1_lines: "#75AB4A",
  /* block 2 */
  title_block_background_texts: "#3FA535",
  background_containers_block2: "#F1F6EC",
  background_table: "#E3EEDB",
  background_table_subsection_block2: "#373636",
  table_lines: "#75AB4A",
  background_iv: "#E9EBED",
  lines_table_iv: "#F4F5F6",
  lines_table_iv_alternative: "#909DA5",
  table_i_background_letter_A: "#0F7E32",
  table_i_background_letter_B: "#10B322",
  table_i_background_letter_C: "#B1D501",
  table_i_background_letter_D: "#F0E31B",
  table_i_background_letter_E: "#F3B700",
  table_i_background_letter_F: "#E0700A",
  table_i_background_letter_G: "#DB3424",
  table_energy_background_table: "#e9ebed",
  eec_table_background: "#E2EDD9",
  phases_table_border: "#96d29b",

  /* footer */
  footer_background: "#D7E3EF",
};

export const FontSizes = {
  h1: "1.3rem",
  h2: "1.1rem",
  h3: "1rem",
  h4: "0.9rem",
  p: "0.8rem",
  p2: "0.7rem",

  pdfH1: "18px",
  pdfH2: "14px",
  pdfH3: "12px",
  pdfP: "11px",
  pdfP2: "10px",
  pdfSmall: "8px",
};

export const IMPROVEMENT_URLS_COMMENTS = {
  "file.improvements.firesafety.stairsProtection.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_001_EVAC_ESCALERAS_COMENTARIO.pdf",
  "file.improvements.firesafety.fireReactionWithMaterialsOnTheFacade.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_002_CLIM_PI_FACHADA_CUBIERTA_COMENTARIO.pdf",
  "file.improvements.firesafety.fireReactionWithMaterialsOnTheCommonPlaces.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_003_CLIM_PI_ZCOMUN_COMENTARIO.pdf",
  "file.improvements.firesafety.partitioningOfSpecialRiskLocations.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_004_CLIM_PI_LOC_COMENTARIO.pdf",
  "file.improvements.firesafety.fireProtections.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_005_CIP_INSTALACIONES_COMENTARIO.pdf",
  "file.improvements.healthiness.housingVentilation.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_001_VENT_VIV_COMENTARIO.pdf",
  "file.improvements.healthiness.parkingVentilation.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_002_VENT_GJE_COMENTARIO.pdf",
  "file.improvements.healthiness.storeroomVentilation.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_003_VENT_TRAST_COMENTARIO.pdf",
  "file.improvements.healthiness.wasteStorageVentilation.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_004_VENT_ALM_COMENTARIO.pdf",
  "file.improvements.healthiness.commonZonesVentilation.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_005_VENT_ZC_COMENTARIO.pdf",
  "file.improvements.healthiness.commonWasteStorage.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_006_AC_COMENTARIO.pdf",
  "file.improvements.healthiness.smellOnCommonWasteStorage.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_007_OL_COMENTARIO.pdf",
  "file.improvements.healthiness.protectionMeasuresOnRiskZones.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_008_ZR_COMENTARIO.pdf",
  "file.improvements.healthiness.waterSupplyConditions.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_009_AGUA_COMENTARIO.pdf",
  "file.improvements.healthiness.waterSavingDevices.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_010_AHAZC_COMENTARIO.pdf",
  "file.improvements.healthiness.waterMeteringIndividualDevices.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_011_CONT_COMENTARIO.pdf",
  "file.improvements.healthiness.waterTreatmentSystems.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_012_EVA_COMENTARIO.pdf",
  "file.improvements.healthiness.waterSavingDevicesOnHouses.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_013_AHAV_COMENTARIO.pdf",
  "file.improvements.healthiness.poolWaterTreatment.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_014_PISCINA_COMENTARIO.pdf",
  "file.improvements.healthiness.dripIrrigation.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_015_RIEGO_COMENTARIO.pdf",
  "file.improvements.healthiness.singularElementsWaterTreatment.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_016_SINGULARES_COMENTARIO.pdf",
  "file.improvements.energyefficiency.energyPerformanceAnalysisOnRealConditions.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_001_COMPORT_COMENTARIO.pdf",
  "file.improvements.energyefficiency.highEfficiencyElevator.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_002_ASCENSOR_COMENTARIO.pdf",
  "file.improvements.energyefficiency.lightingControlSystems.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_003_ILUM_ZC_COMENTARIO.pdf",
  "file.improvements.energyefficiency.energySavingLightingOnCommonZones.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_004_ILUM_BC_ZC_COMENTARIO.pdf",
  "file.improvements.energyefficiency.pipeIsolation.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_005_AIS_TUB_COMENTARIO.pdf",
  "file.improvements.energyefficiency.circulatingPumps.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_006_BOM_CIR_COMENTARIO.pdf",
  "file.improvements.energyefficiency.ageOfInstallation.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_007_ANTIG_COMENTARIO.pdf",
  "file.improvements.energyefficiency.consumptionAccounting.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_008_CONS_COMENTARIO.pdf",
  "file.improvements.energyefficiency.automaticSystems.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_009_AUT_COMENTARIO.pdf",
  "file.improvements.energyefficiency.renewableSources.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_010_RENOV_COMENTARIO.pdf",
  "file.improvements.noise.noiseOnTheDay.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_001_RUIDO_DIA_COMENTARIO.pdf",
  "file.improvements.noise.noisesFromOtherBuildings.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_002_RUIDO_MOL_EXT_COMENTARIO.pdf",
  "file.improvements.noise.noisesFromOtherVehicles.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_003_RUIDO_MOL_GJE_COMENTARIO.pdf",
  "file.improvements.noise.otherNoisesFromOutside.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_004_RUIDO_MOL_OTRO_COMENTARIO.pdf",
  "file.improvements.noise.noisesFromFacilities.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_005_RUIDO_MOL_MAQ_COMENTARIO.pdf",
  "file.improvements.noise.otherNoises.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_006_RUIDO_MOL_INS_COMENTARIO.pdf",
  "file.improvements.other.asbestosPresence.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_001_AMIANTO_COMENTARIO.pdf",
  "file.improvements.other.facadeDeficiencies.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_002_CUB_COMENTARIO.pdf",
  "file.improvements.other.electricCondition.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_003_CGP_COMENTARIO.pdf",
  "file.improvements.other.telecomConditions.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_004_TELECO_COMENTARIO.pdf",
  "file.improvements.other.digitizationAndMonitoring.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_005_DIG_COMENTARIO.pdf",
  "file.improvements.other.darkZonesOnFacade.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_008_ISLA_COMENTARIO.pdf",
  "file.improvements.other.ageOfInstallations.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_006_ANT_INST_COMENTARIO.pdf",
  "file.improvements.other.consumptionAccounting.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_007_CONT_CONS_COMENTARIO.pdf",
  "file.improvements.other.parkSituation.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_009_VMP_COMENTARIO.pdf",
  "file.improvements.other.zoneOfElectricCharge.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_010_PUN_CARGA_COMENTARIO.pdf",
  "file.improvements.other.solarProtectionZones.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_011_VEG_COMENTARIO.pdf",
  "file.improvements.other.permeableExteriorSurfaces.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_012_SEP_COMENTARIO.pdf",
  "file.improvements.other.greenRoof.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_013_CUB_VERDE_COMENTARIO.pdf",
  "file.improvements.other.rainWaterSaving.comments": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_014_REC_%20AP_COMENTARIO.pdf",
  "file.improvements.security.functionalConditions.help":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_%20CONDICIONES_FUNCIONALES_AYUDA.pdf",
  "file.improvements.security.flooringAgainstRiskOfFalling.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_001_ELEM_PAVIMENTO_COMENTARIO.pdf",
  "file.improvements.security.doorsAgainstRiskOfImpact.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_002_ELEM_PUERTAS_COMENTARIO.pdf",
  "file.improvements.security.glazingAgainstRiskInItsCleaning.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_003_ELEM_ACRISTALAMIENTO_COMENTARIO.pdf",
  "file.improvements.security.fallProtectionBarriers.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_004_ELEM_BARRERAS_PROTECCION_COMENTARIO.pdf",
  "file.improvements.security.stairsAgainstFallingRisk.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_005_ELEM_ESCALERAS_PROTECCION_COMENTARIO.pdf",
  "file.improvements.security.poolsAgainstRiskOfDrowning.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_006_ELEM_PISCINAS_COMENTARIO.pdf",
  "file.improvements.security.lightningStrikeProtection.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_007_ELEM_RAYO_COMENTARIO.pdf",
  "file.improvements.security.accessibilityElements.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_008_ELEM_ACCESIBILIDAD_COMENTARIO.pdf",
  "file.improvements.security.flooringAgainstRiskOfFallingDueToIsolatedSteps.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_009_ELEM_ADECUACION_PAVIMENTO_COMENTARIO.pdf",
  "file.improvements.security.accessibleEntrancePool.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_010_ELEM_ADECUACION_PISCINA_COMENTARIO.pdf",
  "file.improvements.security.othersInProvisionOfElements.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_011_ELEM_ADECUACION_OTROS_COMENTARIO.pdf",
  "file.improvements.security.glazingAgainstRiskOfImpact.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_012_INFO_ACRISTALAMIENTO_COMENTARIO.pdf",
  "file.improvements.security.parkingAgainstRiskOfRunOver.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_013_INFO_APARCAMIENTO_COMENTARIO.pdf",
  "file.improvements.security.accessibilityElementsInformation.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_014_INFO_ELEMENTOS_COMENTARIO.pdf",
  "file.improvements.security.warningSigns.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_015_INFO_ADECUACION_SENALIZACION_COMENTARIO.pdf",
  "file.improvements.security.othersConditions.comments":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_016_INFO_ADECUACION_OTROS_COMENTARIO.pdf",
};

export const IMPROVEMENT_URLS_EXAMPLES = {
  "file.improvements.firesafety.stairsProtection.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_001_EVAC_ESCALERAS_EJEMPLO.pdf",
  "file.improvements.firesafety.fireReactionWithMaterialsOnTheFacade.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_002_CLIM_PI_FACHADA_CUBIERTA_EJEMPLO.pdf",
  "file.improvements.firesafety.fireReactionWithMaterialsOnTheCommonPlaces.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_003_CLIM_PI_ZCOMUN_EJEMPLO.pdf",
  "file.improvements.firesafety.partitioningOfSpecialRiskLocations.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_004_CLIM_PI_LOC_EJEMPLO.pdf",
  "file.improvements.firesafety.fireProtections.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_005_CIP_INSTALACIONES_EJEMPLO.pdf",
  "file.improvements.healthiness.housingVentilation.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_001_VENT_VIV_EJEMPLO.pdf",
  "file.improvements.healthiness.parkingVentilation.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_002_VENT_GJE_EJEMPLO.pdf",
  "file.improvements.healthiness.storeroomVentilation.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_003_VENT_TRAST_EJEMPLO.pdf",
  "file.improvements.healthiness.wasteStorageVentilation.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_004_VENT_ALM_EJEMPLO.pdf",
  "file.improvements.healthiness.commonZonesVentilation.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_005_VENT_ZC_EJEMPLO.pdf",
  "file.improvements.healthiness.commonWasteStorage.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_006_AC_EJEMPLO.pdf",
  "file.improvements.healthiness.smellOnCommonWasteStorage.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_007_OL_EJEMPLO.pdf",
  "file.improvements.healthiness.protectionMeasuresOnRiskZones.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_008_ZR_EJEMPLO.pdf",
  "file.improvements.healthiness.waterSupplyConditions.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_009_AGUA_EJEMPLO.pdf",
  "file.improvements.healthiness.waterSavingDevices.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_010_AHAZC_EJEMPLO.pdf",
  "file.improvements.healthiness.waterMeteringIndividualDevices.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_011_CONT_EJEMPLO.pdf",
  "file.improvements.healthiness.waterTreatmentSystems.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_012_EVA_EJEMPLO.pdf",
  "file.improvements.healthiness.waterSavingDevicesOnHouses.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_013_AHAV_EJEMPLO.pdf",
  "file.improvements.healthiness.poolWaterTreatment.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_014_PISCINA_EJEMPLO.pdf",
  "file.improvements.healthiness.dripIrrigation.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_015_RIEGO_EJEMPLO.pdf",
  "file.improvements.healthiness.singularElementsWaterTreatment.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_016_SINGULARES_EJEMPLO.pdf",
  "file.improvements.energyefficiency.energyPerformanceAnalysisOnRealConditions.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_001_COMPORT_EJEMPLO.pdf",
  "file.improvements.energyefficiency.highEfficiencyElevator.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_002_ASCENSOR_EJEMPLO.pdf",
  "file.improvements.energyefficiency.lightingControlSystems.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_003_ILUM_ZC_EJEMPLO.pdf",
  "file.improvements.energyefficiency.energySavingLightingOnCommonZones.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_004_ILUM_BC_ZC_EJEMPLO.pdf",
  "file.improvements.energyefficiency.pipeIsolation.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_005_AIS_TUB_EJEMPLO.pdf",
  "file.improvements.energyefficiency.circulatingPumps.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_006_BOM_CIR_EJEMPLO.pdf",
  "file.improvements.energyefficiency.ageOfInstallation.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_007_ANTIG_EJEMPLO.pdf",
  "file.improvements.energyefficiency.consumptionAccounting.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_008_CONS_EJEMPLO.pdf",
  "file.improvements.energyefficiency.automaticSystems.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_009_AUT_EJEMPLO.pdf",
  "file.improvements.energyefficiency.renewableSources.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_010_RENOV_EJEMPLO.pdf",
  "file.improvements.noise.noiseOnTheDay.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_001_RUIDO_DIA_EJEMPLO.pdf",
  "file.improvements.noise.noisesFromOtherBuildings.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_002_RUIDO_MOL_EXT_EJEMPLO.pdf",
  "file.improvements.noise.noisesFromOtherVehicles.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_003_RUIDO_MOL_GJE_EJEMPLO.pdf",
  "file.improvements.noise.otherNoisesFromOutside.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_004_RUIDO_MOL_OTRO_EJEMPLO.pdf",
  "file.improvements.noise.noisesFromFacilities.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_005_RUIDO_MOL_MAQ_EJEMPLO.pdf",
  "file.improvements.noise.otherNoises.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_006_RUIDO_MOL_INS_EJEMPLO.pdf",
  "file.improvements.other.asbestosPresence.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_001_AMIANTO_EJEMPLO.pdf",
  "file.improvements.other.facadeDeficiencies.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_002_CUB_EJEMPLO.pdf",
  "file.improvements.other.electricCondition.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_003_CGP_EJEMPLO.pdf",
  "file.improvements.other.telecomConditions.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_004_TELECO_EJEMPLO.pdf",
  "file.improvements.other.digitizationAndMonitoring.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_005_DIG_EJEMPLO.pdf",
  "file.improvements.other.darkZonesOnFacade.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_006_ANT_INST_EJEMPLO.pdf",
  "file.improvements.other.ageOfInstallations.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_007_CONT_CONS_EJEMPLO.pdf",
  "file.improvements.other.consumptionAccounting.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_008_ISLA_EJEMPLO.pdf",
  "file.improvements.other.parkSituation.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_009_VMP_EJEMPLO.pdf",
  "file.improvements.other.zoneOfElectricCharge.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_010_PUN_CARGA_EJEMPLO.pdf",
  "file.improvements.other.solarProtectionZones.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_011_VEG_EJEMPLO.pdf",
  "file.improvements.other.permeableExteriorSurfaces.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_012_SEP_EJEMPLO.pdf",
  "file.improvements.other.greenRoof.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_013_CUB_VERDE_EJEMPLO.pdf",
  "file.improvements.other.rainWaterSaving.examples": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_014_REC_ AP_EJEMPLO.pdf",
  "file.improvements.security.flooringAgainstRiskOfFalling.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_001_ELEM_PAVIMENTO_EJEMPLO.pdf",
  "file.improvements.security.doorsAgainstRiskOfImpact.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDASUA_DOT_002_ELEM_PUERTAS_EJEMPLO.pdf",
  "file.improvements.security.glazingAgainstRiskInItsCleaning.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_003_ELEM_ACRISTALAMIENTO_EJEMPLO.pdf",
  "file.improvements.security.fallProtectionBarriers.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_004_ELEM_BARRERAS_PROTECCION_EJEMPLO.pdf",
  "file.improvements.security.stairsAgainstFallingRisk.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_005_ELEM_ESCALERAS_PROTECCION_EJEMPLO.pdf",
  "file.improvements.security.poolsAgainstRiskOfDrowning.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_006_ELEM_PISCINAS_EJEMPLO.pdf",
  "file.improvements.security.lightningStrikeProtection.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_007_ELEM_RAYO_EJEMPLO.pdf",
  "file.improvements.security.accessibilityElements.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDADSUA_DOT_008_ELEM_ACCESIBILIDAD_EJEMPLO.pdf",
  "file.improvements.security.flooringAgainstRiskOfFallingDueToIsolatedSteps.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_009_ELEM_ADECUACION_PAVIMENTO_EJEMPLO.pdf",
  "file.improvements.security.accessibleEntrancePool.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_010_ELEM_ADECUACION_PISCINA_EJEMPLO.pdf",
  "file.improvements.security.othersInProvisionOfElements.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_011_ELEM_ADECUACION_OTROS_EJEMPLO.pdf",
  "file.improvements.security.glazingAgainstRiskOfImpact.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_012_INFO_ACRISTALAMIENTO_EJEMPLO.pdf",
  "file.improvements.security.parkingAgainstRiskOfRunOver.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_013_INFO_APARCAMIENTO_EJEMPLO.pdf",
  "file.improvements.security.accessibilityElementsInformation.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_014_INFO_ELEMENTOS_EJEMPLO.pdf",
  "file.improvements.security.warningSigns.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_015_INFO_ADECUACION_SENALIZACION_EJEMPLO.pdf",
  "file.improvements.security.othersConditions.examples":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_016_INFO_ADECUACION_OTROS_EJEMPLO.pdf",
};

export const IMPROVEMENT_URLS_NORMATIVE = {
  "file.improvements.firesafety.stairsProtection.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_001_EVAC_ESCALERAS_NORMA.pdf",
  "file.improvements.firesafety.fireReactionWithMaterialsOnTheFacade.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_002_CLIM_PI_FACHADA_CUBIERTA_NORMA.pdf",
  "file.improvements.firesafety.fireReactionWithMaterialsOnTheCommonPlaces.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_003_CLIM_PI_ZCOMUN_NORMA.pdf",
  "file.improvements.firesafety.partitioningOfSpecialRiskLocations.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_004_CLIM_PI_LOC_NORMA.pdf",
  "file.improvements.firesafety.fireProtections.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.2%20SEGURIDAD%20CONTRA%20INCENDIOS/SCI_005_CIP_INSTALACIONES_NORMA.pdf",
  "file.improvements.healthiness.housingVentilation.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_001_VENT_VIV_NORMA.pdf",
  "file.improvements.healthiness.parkingVentilation.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_002_VENT_GJE_NORMA.pdf",
  "file.improvements.healthiness.storeroomVentilation.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_003_VENT_TRAST_NORMA.pdf",
  "file.improvements.healthiness.wasteStorageVentilation.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_004_VENT_ALM_NORMA.pdf",
  "file.improvements.healthiness.commonZonesVentilation.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_005_VENT_ZC_NORMA.pdf",
  "file.improvements.healthiness.commonWasteStorage.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_006_AC_NORMA.pdf",
  "file.improvements.healthiness.smellOnCommonWasteStorage.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_007_OL_NORMA.pdf",
  "file.improvements.healthiness.protectionMeasuresOnRiskZones.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_008_ZR_NORMA.pdf",
  "file.improvements.healthiness.waterSupplyConditions.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_009_AGUA_NORMA.pdf",
  "file.improvements.healthiness.waterSavingDevices.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_010_AHAZC_NORMA.pdf",
  "file.improvements.healthiness.waterMeteringIndividualDevices.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_011_CONT_NORMA.pdf",
  "file.improvements.healthiness.waterTreatmentSystems.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_012_EVA_NORMA.pdf",
  "file.improvements.healthiness.waterSavingDevicesOnHouses.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_013_AHAV_NORMA.pdf",
  "file.improvements.healthiness.poolWaterTreatment.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_014_PISCINA_NORMA.pdf",
  "file.improvements.healthiness.dripIrrigation.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_015_RIEGO_NORMA.pdf",
  "file.improvements.healthiness.singularElementsWaterTreatment.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.3%20SALUBRIDAD/S_016_SINGULARES_NORMA.pdf",
  "file.improvements.energyefficiency.energyPerformanceAnalysisOnRealConditions.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_001_COMPORT_NORMA.pdf",
  "file.improvements.energyefficiency.highEfficiencyElevator.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_002_ASCENSOR_NORMA.pdf",
  "file.improvements.energyefficiency.lightingControlSystems.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_003_ILUM_ZC_NORMA.pdf",
  "file.improvements.energyefficiency.energySavingLightingOnCommonZones.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_004_ILUM_BC_ZC_NORMA.pdf",
  "file.improvements.energyefficiency.pipeIsolation.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_005_AIS_TUB_NORMA.pdf",
  "file.improvements.energyefficiency.circulatingPumps.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_006_BOM_CIR_NORMA.pdf",
  "file.improvements.energyefficiency.ageOfInstallation.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_007_ANTIG_NORMA.pdf",
  "file.improvements.energyefficiency.consumptionAccounting.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_008_CONS_NORMA.pdf",
  "file.improvements.energyefficiency.automaticSystems.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_009_AUT_NORMA.pdf",
  "file.improvements.energyefficiency.renewableSources.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.4%20EFICIENCIA%20ENERGETICA/EE_010_RENOV_NORMA.pdf",
  "file.improvements.noise.noiseOnTheDay.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_001_RUIDO_DIA_NORMA.pdf",
  "file.improvements.noise.noisesFromOtherBuildings.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_002_RUIDO_MOL_EXT_NORMA.pdf",
  "file.improvements.noise.noisesFromOtherVehicles.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_003_RUIDO_MOL_GJE_NORMA.pdf",
  "file.improvements.noise.otherNoisesFromOutside.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_004_RUIDO_MOL_OTRO_NORMA.pdf",
  "file.improvements.noise.noisesFromFacilities.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO//PCR_005_RUIDO_MOL_MAQ_NORMA.pdf",
  "file.improvements.noise.otherNoises.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.5%20PROTECCION%20CONTRA%20EL%20RUIDO/PCR_006_RUIDO_MOL_INS_NORMA.pdf",
  "file.improvements.other.asbestosPresence.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_001_AMIANTO_NORMA.pdf",
  "file.improvements.other.facadeDeficiencies.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_002_CUB_NORMA.pdf",
  "file.improvements.other.electricCondition.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_003_CGP_NORMA.pdf",
  "file.improvements.other.telecomConditions.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_004_TELECO_NORMA.pdf",
  "file.improvements.other.digitizationAndMonitoring.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_005_DIG_NORMA.pdf",
  "file.improvements.other.darkZonesOnFacade.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_006_ANT_INST_NORMA.pdf",
  "file.improvements.other.ageOfInstallations.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_007_CONT_CONS_NORMA.pdf",
  "file.improvements.other.consumptionAccounting.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_008_ISLA_NORMA.pdf",
  "file.improvements.other.parkSituation.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_009_VMP_NORMA.pdf",
  "file.improvements.other.zoneOfElectricCharge.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_010_PUN_CARGA_NORMA.pdf",
  "file.improvements.other.solarProtectionZones.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_011_VEG_NORMA.pdf",
  "file.improvements.other.permeableExteriorSurfaces.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_012_SEP_NORMA.pdf",
  "file.improvements.other.greenRoof.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_013_CUB_VERDE_NORMA.pdf",
  "file.improvements.other.rainWaterSaving.normative": "https://oficinarehabilitacion.com/LEEX/BL2%202.6%20OTROS/OT_014_REC_ AP_NORMA.pdf",
  "file.improvements.security.flooringAgainstRiskOfFalling.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_001_ELEM_PAVIMENTO_NORMA.pdf",
  "file.improvements.security.doorsAgainstRiskOfImpact.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_002_ELEM_PUERTAS_NORMA.pdf",
  "file.improvements.security.glazingAgainstRiskInItsCleaning.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_003_ELEM_ACRISTALAMIENTO_NORMA.pdf",
  "file.improvements.security.fallProtectionBarriers.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_004_ELEM_BARRERAS_PROTECCION_NORMA.pdf",
  "file.improvements.security.stairsAgainstFallingRisk.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_005_ELEM_ESCALERAS_PROTECCION_NORMA.pdf",
  "file.improvements.security.poolsAgainstRiskOfDrowning.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_006_ELEM_PISCINAS_NORMA.pdf",
  "file.improvements.security.lightningStrikeProtection.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_007_ELEM_RAYO_NORMA.pdf",
  "file.improvements.security.accessibilityElements.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_008_ELEM_ACCESIBILIDAD_NORMA.pdf",
  "file.improvements.security.flooringAgainstRiskOfFallingDueToIsolatedSteps.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_009_ELEM_ADECUACION_PAVIMENTO_NORMA.pdf",
  "file.improvements.security.accessibleEntrancePool.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_010_ELEM_ADECUACION_PISCINA_NORMA.pdf",
  "file.improvements.security.othersInProvisionOfElements.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_011_ELEM_ADECUACION_OTROS_NORMA.pdf",
  "file.improvements.security.glazingAgainstRiskOfImpact.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_012_INFO_ACRISTALAMIENTO_NORMA.pdf",
  "file.improvements.security.parkingAgainstRiskOfRunOver.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_013_INFO_APARCAMIENTO_NORMA.pdf",
  "file.improvements.security.accessibilityElementsInformation.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_014_INFO_ELEMENTOS_NORMA.pdf",
  "file.improvements.security.warningSigns.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_015_INFO_ADECUACION_SENALIZACION_NORMA.pdf",
  "file.improvements.security.othersConditions.normative":
    "https://oficinarehabilitacion.com/LEEX/BL2%202.1%20SEGURIDAD%20DE%20UTILIZACION%20Y%20ACCESIBILIDAD/SUA_DOT_016_INFO_ADECUACION_OTROS_NORMA.pdf",
};

export const Communities = {
  NAVARRA: "NAVARRA",
  PAIS_VASCO: "PAÍS VASCO",
};

export const validImageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".webp"];
