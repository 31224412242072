/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MaintenancePlanActionDTO,
    MaintenancePlanActionDTOFromJSON,
    MaintenancePlanActionDTOFromJSONTyped,
    MaintenancePlanActionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface MaintenancePlanDTO
 */
export interface MaintenancePlanDTO {
    /**
     * 
     * @type {Array<MaintenancePlanActionDTO>}
     * @memberof MaintenancePlanDTO
     */
    actions?: Array<MaintenancePlanActionDTO>;
}

export function MaintenancePlanDTOFromJSON(json: any): MaintenancePlanDTO {
    return MaintenancePlanDTOFromJSONTyped(json, false);
}

export function MaintenancePlanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenancePlanDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actions': !exists(json, 'actions') ? undefined : ((json['actions'] as Array<any>).map(MaintenancePlanActionDTOFromJSON)),
    };
}

export function MaintenancePlanDTOToJSON(value?: MaintenancePlanDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actions': value.actions === undefined ? undefined : ((value.actions as Array<any>).map(MaintenancePlanActionDTOToJSON)),
    };
}

