import { Document } from "@react-pdf/renderer";
import React from "react";
import { Frontpage, Summary, EnergyInitialAssessment, RenovationPassport, EnergyFinalAssessment } from "./sections";
import { useBookGeneratorViewModel } from "./view-model/resume-generator-view-model";
import { SectionCountProvider } from "./context/section-context";
import { FinalSummary } from "./sections/5-final-summary/final-summary";
import { ImageMedia } from "../_common/types/image-media";

interface ResumeGeneratorProps {
  bookId: string;
  setIsLoadingState?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResumeGenerator: React.FC<ResumeGeneratorProps> = ({ bookId, setIsLoadingState }) => {
  const { book } = useBookGeneratorViewModel(bookId, setIsLoadingState);

  //TODO: Move all this constants to the viewmodel
  const iEEC = book?.firstBlock?.initialEnergyEfficiencyCertificate;
  const improvementActions = book?.secondBlock?.improvementActions;
  const lastImprovement = improvementActions?.[improvementActions.length - 1];
  const lEEC = book?.firstBlock?.finalEnergyEfficiencyCertificate;
  const conservationStatus = book?.firstBlock?.buildingInspection?.documentation?.conservationStatus;
  const accessibilityEvaluations = book?.firstBlock?.buildingInspection?.accessibilityEvaluations;

  const constructionYear = book?.firstBlock?.buildingInspection?.documentation?.generalData?.constructionYear;
  const imageDTO = book?.generalData?.images?.find((a) => a?.name === "portrait_image");
  const image: ImageMedia = { url: imageDTO?.url || "", backupUrl: imageDTO?.url || "", transform: "" };

  return (
    <Document>
      <SectionCountProvider>
        <Frontpage data={book?.frontpage} constructionYear={constructionYear} image={image} />
        <Summary conservationStatus={conservationStatus} accessibilityEvaluation={accessibilityEvaluations?.at(accessibilityEvaluations.length - 1)} />
        <EnergyInitialAssessment initialEnergyEfficiencyCertificate={iEEC} />
        <RenovationPassport improvementAction={lastImprovement} />
        <EnergyFinalAssessment lastEnergyEfficiencyCertificate={lEEC} />
        {lastImprovement?.summary?.length ? <FinalSummary lastImprovementAction={lastImprovement} /> : null}
      </SectionCountProvider>
    </Document>
  );
};
