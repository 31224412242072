import { GetAllCollegeRequest } from "./../../../clients/apis/CollegesApi";
import ICollegeApi from "../icollege-api";
import { CollegesApi as CollegeServiceApi, CollegeDTO } from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";

export class CollegeApi extends BaseAPI implements ICollegeApi {
  apiClient: CollegeServiceApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new CollegeServiceApi(configuration);
  }

  async getAllCollege(requestParameters: GetAllCollegeRequest): Promise<CollegeDTO[]> {
    const response = (await this.apiClient.getAllCollege(requestParameters)) as CollegeDTO[];
    return response;
  }

  async getCollegeById(id: string): Promise<CollegeDTO> {
    const response = (await this.apiClient.getCollegeByID({ id: id })) as CollegeDTO;
    return response;
  }
  async getCollegeByName(name: string): Promise<CollegeDTO> {
    const response = (await this.apiClient.searchCollege({ name: name })) as CollegeDTO;
    return response;
  }
  /*
    

    async getCollegeByType(type: string): Promise<CollegeDTO> {
        const response = await this.apiClient.getCollegeByType({ type: type });
        return response
    }
*/
}
