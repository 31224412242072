import { Document, Page, Text, View, Image } from "@react-pdf/renderer";
import React from "react";

import logo from "assets/images/logoMovicoders.png";
import styles from "./styles/bill-styles";
import { t } from "i18next";
import { useBillGeneratorViewModel } from "./bill-generator-view-model";

interface BillGeneratorProps {
  transactionId: string;
}

export const BillGenerator: React.FC<BillGeneratorProps> = ({ transactionId }) => {
  const { bill, totalAmountValue, vatAmountValue, totalWithVATValue } = useBillGeneratorViewModel(transactionId);

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          <View style={styles.logoContainer}>
            <Image src={logo} style={styles.logo} />
          </View>
          <View style={styles.invoiceDetails}>
            <Text>
              {t("bill.document.billNumber")}: <Text style={styles.titleText}>{bill.billNumber}</Text>
            </Text>
            <Text>
              {t("bill.document.paymentDate")}: <Text style={styles.titleText}>{bill.paymentDate?.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.clientAndBillingEntity}>
            <View style={styles.clientDetails}>
              <Text style={styles.sectionTitle}>{t("bill.document.client")}</Text>
              <Text style={styles.titleText}>
                {bill.receptorName?.toUpperCase()} {bill.receptorSurname?.toUpperCase()}
              </Text>
              <Text>
                {bill.receptorBillingAddress} - {bill.receptorBillingPostalCode}, {bill.receptorBillingTown}, {bill.receptorBillingProvince}
              </Text>
              <Text style={styles.titleText}>
                {t("bill.document.idNumber")}: {bill.receptorIdNumber}
              </Text>
            </View>
            <View style={styles.billingEntityDetails}>
              <Text style={styles.titleText}>{bill.billingEntity?.name?.toUpperCase()}</Text>
              <Text>{bill.billingEntity?.address}</Text>
              <Text style={styles.titleText}>
                {t("bill.document.idNumber")}: {bill.billingEntity?.cif}
              </Text>
            </View>
          </View>
        </View>
        {bill.bookId && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>{t("bill.document.bookData")}</Text>
            <Text>
              {t("bill.document.bookId")}: <Text style={styles.titleText}>{bill.bookId}</Text>
            </Text>
            <Text>
              {t("bill.document.cadastralReference")}: <Text style={styles.titleText}>{bill.cadastralReference}</Text>
            </Text>
          </View>
        )}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableHeader, styles.conceptColumn]}>{t("bill.document.concept")}</Text>
            <Text style={[styles.tableHeader, styles.quantityColumn]}>{t("bill.document.quantity")}</Text>
            <Text style={[styles.tableHeader, styles.amountColumn]}>{t("bill.document.amount")}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCell, styles.conceptColumn]}>{t("bill.document.item")}:{"\n"}{bill.id}</Text>
            <Text style={[styles.tableCell, styles.quantityColumn]}>1</Text>
            <Text style={[styles.tableCell, styles.amountColumn]}>{totalAmountValue} €</Text>
          </View>
        </View>
        <View style={{ marginBottom: 20 }} />
        <View style={styles.section}>
          <View style={styles.totalContainer}>
            <View style={styles.totalLabelColumn}>
              <Text style={styles.subTotalText}>{t("bill.document.totalAmount")}:</Text>
              <Text style={styles.subTotalText}>
                {t("bill.document.totalVAT")} {bill.vat}%:
              </Text>
              <Text style={styles.totalText}>{t("bill.document.totalWithVAT")}:</Text>
            </View>
            <View style={styles.totalValueColumn}>
              <Text style={styles.subTotalText}>{totalAmountValue} €</Text>
              <Text style={styles.subTotalText}>{vatAmountValue} €</Text>
              <Text style={styles.totalText}>{totalWithVATValue} €</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
