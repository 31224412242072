import { View, Image } from "@react-pdf/renderer";
import React from "react";
import styles from "../../styles/resume-styles";
import header from 'assets/images/book-resume/header.jpg';

export const PageHeader: React.FC = () => {
  return (
      <View style={styles.header} fixed>
        <Image src={header} />
      </View>
  );
};