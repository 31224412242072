/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillDTO,
    BillDTOFromJSON,
    BillDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface DeleteBillRequest {
    id: string;
}

export interface GetBillByBookIdRequest {
    id: string;
}

export interface GetBillByTransactionIdRequest {
    id: string;
}

export interface UpdateBillRequest {
    billDTO?: BillDTO;
}

/**
 * 
 */
export class BillsApi extends runtime.BaseAPI {

    /**
     * Delete a Bill object by id
     */
    async deleteBillRaw(requestParameters: DeleteBillRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/bills/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete a Bill object by id
     */
    async deleteBill(requestParameters: DeleteBillRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.deleteBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get accepted Bill by book id.
     * Get accepted Bill by book id.
     */
    async getBillByBookIdRaw(requestParameters: GetBillByBookIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBillByBookId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/bills/book/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillDTOFromJSON(jsonValue));
    }

    /**
     * Get accepted Bill by book id.
     * Get accepted Bill by book id.
     */
    async getBillByBookId(requestParameters: GetBillByBookIdRequest, initOverrides?: RequestInit): Promise<BillDTO> {
        const response = await this.getBillByBookIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get accepted Bill by transaction id.
     * Get accepted Bill by transaction id.
     */
    async getBillByTransactionIdRaw(requestParameters: GetBillByTransactionIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBillByTransactionId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/bills/transaction/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillDTOFromJSON(jsonValue));
    }

    /**
     * Get accepted Bill by transaction id.
     * Get accepted Bill by transaction id.
     */
    async getBillByTransactionId(requestParameters: GetBillByTransactionIdRequest, initOverrides?: RequestInit): Promise<BillDTO> {
        const response = await this.getBillByTransactionIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit Bill object and returns it
     */
    async updateBillRaw(requestParameters: UpdateBillRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/bills/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BillDTOToJSON(requestParameters.billDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillDTOFromJSON(jsonValue));
    }

    /**
     * Edit Bill object and returns it
     */
    async updateBill(requestParameters: UpdateBillRequest, initOverrides?: RequestInit): Promise<BillDTO> {
        const response = await this.updateBillRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
