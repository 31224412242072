/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UsageDTO,
    UsageDTOFromJSON,
    UsageDTOFromJSONTyped,
    UsageDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PropertyDTO
 */
export interface PropertyDTO {
    /**
     * 
     * @type {number}
     * @memberof PropertyDTO
     */
    antiquity?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    block?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDTO
     */
    builtSurface?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    cadastralTown?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    car?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    cc1?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    cc2?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    community?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDTO
     */
    complete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    mainUsage?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDTO
     */
    participationCoefficient?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    pc1?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    pc2?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    portalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    portalNumberLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDTO
     */
    postalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    secondaryPortalLetter?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    secondaryPortalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    stair?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    streetCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    streetType?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDTO
     */
    town?: string;
    /**
     * 
     * @type {Array<UsageDTO>}
     * @memberof PropertyDTO
     */
    usages?: Array<UsageDTO>;
}

export function PropertyDTOFromJSON(json: any): PropertyDTO {
    return PropertyDTOFromJSONTyped(json, false);
}

export function PropertyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'antiquity': !exists(json, 'antiquity') ? undefined : json['antiquity'],
        'block': !exists(json, 'block') ? undefined : json['block'],
        'builtSurface': !exists(json, 'builtSurface') ? undefined : json['builtSurface'],
        'cadastralTown': !exists(json, 'cadastralTown') ? undefined : json['cadastralTown'],
        'car': !exists(json, 'car') ? undefined : json['car'],
        'cc1': !exists(json, 'cc1') ? undefined : json['cc1'],
        'cc2': !exists(json, 'cc2') ? undefined : json['cc2'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'door': !exists(json, 'door') ? undefined : json['door'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mainUsage': !exists(json, 'mainUsage') ? undefined : json['mainUsage'],
        'participationCoefficient': !exists(json, 'participationCoefficient') ? undefined : json['participationCoefficient'],
        'pc1': !exists(json, 'pc1') ? undefined : json['pc1'],
        'pc2': !exists(json, 'pc2') ? undefined : json['pc2'],
        'portalNumber': !exists(json, 'portalNumber') ? undefined : json['portalNumber'],
        'portalNumberLetter': !exists(json, 'portalNumberLetter') ? undefined : json['portalNumberLetter'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'secondaryPortalLetter': !exists(json, 'secondaryPortalLetter') ? undefined : json['secondaryPortalLetter'],
        'secondaryPortalNumber': !exists(json, 'secondaryPortalNumber') ? undefined : json['secondaryPortalNumber'],
        'stair': !exists(json, 'stair') ? undefined : json['stair'],
        'streetCode': !exists(json, 'streetCode') ? undefined : json['streetCode'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetType': !exists(json, 'streetType') ? undefined : json['streetType'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'usages': !exists(json, 'usages') ? undefined : ((json['usages'] as Array<any>).map(UsageDTOFromJSON)),
    };
}

export function PropertyDTOToJSON(value?: PropertyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'antiquity': value.antiquity,
        'block': value.block,
        'builtSurface': value.builtSurface,
        'cadastralTown': value.cadastralTown,
        'car': value.car,
        'cc1': value.cc1,
        'cc2': value.cc2,
        'community': value.community,
        'complete': value.complete,
        'door': value.door,
        'floor': value.floor,
        'id': value.id,
        'mainUsage': value.mainUsage,
        'participationCoefficient': value.participationCoefficient,
        'pc1': value.pc1,
        'pc2': value.pc2,
        'portalNumber': value.portalNumber,
        'portalNumberLetter': value.portalNumberLetter,
        'postalCode': value.postalCode,
        'province': value.province,
        'secondaryPortalLetter': value.secondaryPortalLetter,
        'secondaryPortalNumber': value.secondaryPortalNumber,
        'stair': value.stair,
        'streetCode': value.streetCode,
        'streetName': value.streetName,
        'streetType': value.streetType,
        'town': value.town,
        'usages': value.usages === undefined ? undefined : ((value.usages as Array<any>).map(UsageDTOToJSON)),
    };
}

