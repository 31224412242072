import React from "react";
import { PhaseWithImprovements } from "../../../../../_common/models/phase-with-improvements";
import { View } from "@react-pdf/renderer";
import { PhaseTable } from "pdf/documents/book/_common/components/phase-table/phase-table";
import { EnergyCertificateView } from "pdf/documents/book/_common/components/energy-certificate-view/energy-certificate-view";
import { ImprovementSummaryView } from "pdf/documents/book/_common/components/improvement-summary-view/improvement-summary-view";

interface PhaseViewProps {
  phaseWithImprovements: PhaseWithImprovements;
}

export const PhaseView: React.FC<PhaseViewProps> = ({ phaseWithImprovements}) => {
  const lastImprovement = phaseWithImprovements.phaseImprovements.at(-1);
  const lastEnergyImprovement = phaseWithImprovements.phaseImprovements.filter(item=> item.type?.toLowerCase().includes("energy")).at(-1);
  const certificateImage = lastEnergyImprovement?.files?.at(-1);
  return <View>
    <PhaseTable phaseWithImprovements={phaseWithImprovements} hideDifficultyColumn hidePriorityColumn showImprovementIconsRow/>
    <EnergyCertificateView certificateImage={certificateImage}/>
    <ImprovementSummaryView improvement={lastImprovement}/>
  </View>;
};
