/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CadastralBasicPropertyDTO
 */
export interface CadastralBasicPropertyDTO {
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    autonomousCommunity?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    block?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    car?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    cc1?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    cc2?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    pc1?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    pc2?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    portalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    portalNumberLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof CadastralBasicPropertyDTO
     */
    postalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    secondaryPortalLetter?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    secondaryPortalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    stair?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof CadastralBasicPropertyDTO
     */
    type?: string;
}

export function CadastralBasicPropertyDTOFromJSON(json: any): CadastralBasicPropertyDTO {
    return CadastralBasicPropertyDTOFromJSONTyped(json, false);
}

export function CadastralBasicPropertyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CadastralBasicPropertyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autonomousCommunity': !exists(json, 'autonomousCommunity') ? undefined : json['autonomousCommunity'],
        'block': !exists(json, 'block') ? undefined : json['block'],
        'car': !exists(json, 'car') ? undefined : json['car'],
        'cc1': !exists(json, 'cc1') ? undefined : json['cc1'],
        'cc2': !exists(json, 'cc2') ? undefined : json['cc2'],
        'door': !exists(json, 'door') ? undefined : json['door'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'pc1': !exists(json, 'pc1') ? undefined : json['pc1'],
        'pc2': !exists(json, 'pc2') ? undefined : json['pc2'],
        'portalNumber': !exists(json, 'portalNumber') ? undefined : json['portalNumber'],
        'portalNumberLetter': !exists(json, 'portalNumberLetter') ? undefined : json['portalNumberLetter'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'secondaryPortalLetter': !exists(json, 'secondaryPortalLetter') ? undefined : json['secondaryPortalLetter'],
        'secondaryPortalNumber': !exists(json, 'secondaryPortalNumber') ? undefined : json['secondaryPortalNumber'],
        'stair': !exists(json, 'stair') ? undefined : json['stair'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function CadastralBasicPropertyDTOToJSON(value?: CadastralBasicPropertyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autonomousCommunity': value.autonomousCommunity,
        'block': value.block,
        'car': value.car,
        'cc1': value.cc1,
        'cc2': value.cc2,
        'door': value.door,
        'floor': value.floor,
        'id': value.id,
        'name': value.name,
        'pc1': value.pc1,
        'pc2': value.pc2,
        'portalNumber': value.portalNumber,
        'portalNumberLetter': value.portalNumberLetter,
        'postalCode': value.postalCode,
        'province': value.province,
        'secondaryPortalLetter': value.secondaryPortalLetter,
        'secondaryPortalNumber': value.secondaryPortalNumber,
        'stair': value.stair,
        'street': value.street,
        'town': value.town,
        'type': value.type,
    };
}

