/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewUrbanDataDTO
 */
export interface NewUrbanDataDTO {
    /**
     * 
     * @type {string}
     * @memberof NewUrbanDataDTO
     */
    classification?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUrbanDataDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUrbanDataDTO
     */
    ordinance?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUrbanDataDTO
     */
    planning?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUrbanDataDTO
     */
    protectedElements?: string;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof NewUrbanDataDTO
     */
    urbanPlan?: Array<MediaPrintedDTO>;
}

export function NewUrbanDataDTOFromJSON(json: any): NewUrbanDataDTO {
    return NewUrbanDataDTOFromJSONTyped(json, false);
}

export function NewUrbanDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUrbanDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'classification': !exists(json, 'classification') ? undefined : json['classification'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'ordinance': !exists(json, 'ordinance') ? undefined : json['ordinance'],
        'planning': !exists(json, 'planning') ? undefined : json['planning'],
        'protectedElements': !exists(json, 'protectedElements') ? undefined : json['protectedElements'],
        'urbanPlan': !exists(json, 'urbanPlan') ? undefined : ((json['urbanPlan'] as Array<any>).map(MediaPrintedDTOFromJSON)),
    };
}

export function NewUrbanDataDTOToJSON(value?: NewUrbanDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'classification': value.classification,
        'notes': value.notes,
        'ordinance': value.ordinance,
        'planning': value.planning,
        'protectedElements': value.protectedElements,
        'urbanPlan': value.urbanPlan === undefined ? undefined : ((value.urbanPlan as Array<any>).map(MediaPrintedDTOToJSON)),
    };
}

