import { useEffect, useState } from "react";
import { BillDTO, BillDTOStatusEnum, BillDTOTypeEnum } from "clients";
import BillRepository from "data/repositories/impl/bill-repository";

export const useBillGeneratorViewModel = (transactionId: string) => {
  const [bill, setBill] = useState(mockedBill);

  useEffect(() => {
    fetchBill(transactionId);
  }, [transactionId]);

  const fetchBill = async (id: string) => {
    const repository = new BillRepository();
    repository.getBillByTransactionId(id).then(setBill)
    .catch(() => console.log('billFetchError'));
  };


  const totalWithVAT = bill.amount ? parseFloat(bill.amount) / 100 : 0; //The amount comes in cents from backend
  const totalAmount = totalWithVAT / (1 + (bill.vat ?? 0) / 100);
  const vatAmount = totalWithVAT - totalAmount;

  const formatAmount = (amount: number) => {
    return amount
    .toFixed(2)
    .replace('.', ',')
    .replace(/\d(?=(\d{3})+,)/g, '$&.');
  };

  const totalAmountValue = formatAmount(totalAmount);
  const vatAmountValue = formatAmount(vatAmount);
  const totalWithVATValue = formatAmount(totalWithVAT);

  return {
    bill,
    totalAmountValue,
    vatAmountValue,
    totalWithVATValue,
  };
};

const mockedBill: BillDTO = {
  amount: "0",
  billNumber: "Cargando...",
  bookId: "Cargando...",
  cadastralReference: "Cargando...",
  id: "Cargando...",
  paymentDate: new Date(),
  propertyAddress: "Cargando...",
  receptorBillingAddress: "Cargando...",
  receptorBillingPostalCode: 0, 
  receptorBillingProvince: "",
  receptorBillingTown: "",
  receptorIdNumber: "Cargando...",
  receptorName: "Cargando...",
  receptorSurname: "",
  status: BillDTOStatusEnum.Requested,
  type: BillDTOTypeEnum.Purchase,
  vat: 0,
  billingEntity: {
    id: "Cargando...",
    name: "Cargando...",
    accountNumber: "Cargando...",
    address: "Cargando...",
    cif: "Cargando...",
    phone: "Cargando...",
  },
};
