/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UsagePlanActionDTO,
    UsagePlanActionDTOFromJSON,
    UsagePlanActionDTOFromJSONTyped,
    UsagePlanActionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UsagePlanDTO
 */
export interface UsagePlanDTO {
    /**
     * 
     * @type {Array<UsagePlanActionDTO>}
     * @memberof UsagePlanDTO
     */
    actions?: Array<UsagePlanActionDTO>;
    /**
     * 
     * @type {string}
     * @memberof UsagePlanDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UsagePlanDTO
     */
    privateAndCommonSpaces?: string;
    /**
     * 
     * @type {number}
     * @memberof UsagePlanDTO
     */
    year?: number;
}

export function UsagePlanDTOFromJSON(json: any): UsagePlanDTO {
    return UsagePlanDTOFromJSONTyped(json, false);
}

export function UsagePlanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePlanDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actions': !exists(json, 'actions') ? undefined : ((json['actions'] as Array<any>).map(UsagePlanActionDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'privateAndCommonSpaces': !exists(json, 'privateAndCommonSpaces') ? undefined : json['privateAndCommonSpaces'],
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function UsagePlanDTOToJSON(value?: UsagePlanDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actions': value.actions === undefined ? undefined : ((value.actions as Array<any>).map(UsagePlanActionDTOToJSON)),
        'id': value.id,
        'privateAndCommonSpaces': value.privateAndCommonSpaces,
        'year': value.year,
    };
}

