/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroundHorizontalStructureDTO
 */
export interface GroundHorizontalStructureDTO {
    /**
     * 
     * @type {number}
     * @memberof GroundHorizontalStructureDTO
     */
    framing?: number;
    /**
     * 
     * @type {number}
     * @memberof GroundHorizontalStructureDTO
     */
    others?: number;
    /**
     * 
     * @type {number}
     * @memberof GroundHorizontalStructureDTO
     */
    sanitaryFraming?: number;
    /**
     * 
     * @type {string}
     * @memberof GroundHorizontalStructureDTO
     */
    unknownOrOthersValue?: string;
}

export function GroundHorizontalStructureDTOFromJSON(json: any): GroundHorizontalStructureDTO {
    return GroundHorizontalStructureDTOFromJSONTyped(json, false);
}

export function GroundHorizontalStructureDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroundHorizontalStructureDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'framing': !exists(json, 'framing') ? undefined : json['framing'],
        'others': !exists(json, 'others') ? undefined : json['others'],
        'sanitaryFraming': !exists(json, 'sanitaryFraming') ? undefined : json['sanitaryFraming'],
        'unknownOrOthersValue': !exists(json, 'unknownOrOthersValue') ? undefined : json['unknownOrOthersValue'],
    };
}

export function GroundHorizontalStructureDTOToJSON(value?: GroundHorizontalStructureDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'framing': value.framing,
        'others': value.others,
        'sanitaryFraming': value.sanitaryFraming,
        'unknownOrOthersValue': value.unknownOrOthersValue,
    };
}

