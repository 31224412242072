import { Button, Icon, IconButton } from "@movicoders/movicoders-components";
import { IconsList } from "@movicoders/movicoders-components";
import { Autocomplete, Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { CollegeDTO, CollegiateDTO, ExtendedSignupTechnicianTypeEnum } from "../../../clients";
import BackDropLoading from "../../../components/backdrop-loading";
import TextFieldGrid from "../../../components/text-fields/textfield-grid";
import { Colors } from "../../../constants";
import { validCif, validEmail } from "../../../utils/regexHelper";
import { addressDTOMock } from "../../../viewmodels/profile/data";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import RegisterViewModel from "../../../viewmodels/register/register-view-model";
import { useAddress } from "../../profile/profile-components/form/form-components/useAddress";
import { ReportStyle } from "../../reports/report-style";
import * as RegexHelper from "../../../utils/regexHelper";

export const RegisterForm: React.FC<{ vm: RegisterViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();
  const [valueCheck, setValueCheck] = useState(false);
  const [collegeSelected, setCollegeSelected] = useState<CollegiateDTO>();
  const [collegiated] = useState<CollegeDTO[]>([]);
  const [collegiatedTransform, setCollegiatedTransform] = useState<CollegiateDTO[]>([]);
  const [acceptCollegiated] = useState<boolean>(false);
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const vmRoot = vm.parent;
  const { registerAddressView: addressView, address } = useAddress(
    vm.parent.children.find((c) => c.navigationName === "profile") as ProfileViewModel,
    addressDTOMock(vm.item.address)
  );

  useEffect(() => {
    (async () => {
      if (vmRoot.activeChild?.navigationName && vmRoot.communities === undefined && vmRoot.provinces === undefined && vmRoot.colleges === undefined) {
        if (valueCheck !== true) {
          setValueCheck(true);
          try {
            if (vmRoot.communities === undefined) {
              vmRoot.communities = await vmRoot.communityRepository.getAllCommunity();
            }
          } catch (error) {
            setValueCheck(false);
          }

          try {
            if (vmRoot.colleges === undefined) {
              vmRoot.colleges = await vmRoot.collegeRepository.getAllCollege({ limit: 0, offset: 0 });
            }
          } catch (error) {
            setValueCheck(false);
            vmRoot.colleges = [];
          }
        }
      }
    })();
  }, [vmRoot.activeChild]);

  useEffect(() => {
    let temp: CollegiateDTO[] = [];
    collegiated.forEach((item) => {
      temp.push({
        college: {
          id: item.id,
          name: item.name,
          type: item.type,
        },
        number: "",
      });
    });
    setCollegiatedTransform(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collegiated]);

  useEffect(() => {
    vm.item.collegiated = [];
    collegiatedTransform.forEach((item) => {
      vm.item!!.collegiated!!.push(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collegiatedTransform]);

  const handleError = async (error: any) => {
    const toStream = new Response(error.body);
    const errorResponse = (await toStream.json()) as any;
    return errorResponse;
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, {
      variant: variants,
      preventDuplicate: true,
      key: snackKey,
      autoHideDuration: 3000,
    });
  };

  const register = async () => {
    validCif.test(vm.item?.idNumber!!) || /^([0-9]{2}:?){3}/.test(vm.item?.idNumber!!)
      ? (vm.item.type = ExtendedSignupTechnicianTypeEnum.Physical)
      : (vm.item.type = ExtendedSignupTechnicianTypeEnum.Legal);
    let response: any;
    try {
      setLoading(true);
      let newUser = { ...vm.item, address: address === null ? undefined : address };
      if (vm.validatorVM.validate("registerform")) {
        await vm.registry(newUser);
        setLoading(false);
      } else {
        handleOpenSnackBar(vm.translate("report.block2.energyEfficiencyImprovement.saveEmptyField"), "error");
      }
    } catch (error) {
      setLoading(false);
      response = await handleError(error);
    } finally {
      if (response) {
        setLoading(false);
        handleOpenSnackBar((response.errors[0]?.errorCode as any) || vm.translate("register.error"), "error");
      } else {
        setLoading(false);
        handleOpenSnackBar(vm.translate("register.success"), "success");
        setLoading(false);
        vm.parent.navigate("login", {});
      }
    }
  };

  return (
    <React.Fragment>
      <BackDropLoading showLoading={loading} />
      {/* eslint no-script-url: "off" */}
      <form
        action="javascript:void(0);"
        onSubmit={() => {
          vm.item.collegiated?.length === 0 ? handleOpenSnackBar(vm.translate("register.no.college"), "error") : register();
        }}
      >
        <Grid container>
          <Grid item xs={12} md={12} className={classes.report_displayGrid}>
            <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
              <TextFieldGrid
                id="registerFormName"
                required
                form="registerform"
                validator={vm.validatorVM}
                title={vm.translate("report.block0.name")}
                value={vm.item?.name}
                onChange={(value: any) => {
                  vm.item!!.name = value.target.value;
                }}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="registerFormSurname"
                required
                form="registerform"
                validator={vm.validatorVM}
                title={vm.translate("report.block0.surname")}
                value={vm.item?.surname}
                onChange={(value: any) => {
                  vm.item!!.surname = value.target.value;
                }}
                gridTextField={{ xs: 6, md: 6 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 10,
              }}
            >
              <TextFieldGrid
                id="registerFormNif"
                required
                form="registerform"
                validator={vm.validatorVM}
                title={vm.translate("report.block0.nif")}
                value={vm.item?.idNumber}
                error={
                  !(RegexHelper.validCif.test(vm.item?.idNumber!.toUpperCase()) || RegexHelper.CIF_REGEX.test(vm.item?.idNumber!.toUpperCase())) ||
                  vm.item?.idNumber!.length !== 9
                }
                onChange={(value: any) => {
                  vm.item!!.idNumber = value.target.value;
                }}
                gridTextField={{ xs: 6, md: 6 }}
              />
            </Grid>

            <Paper style={{ padding: 20 }}>
              <Grid item xs={12} md={12} className={`${classes.report_generalDataGrid} ${classes.report_textFieldGrid}`}>
                <Grid item xs={12} md={12} display="flex" columnSpacing={2}>
                  <Grid item xs={8} md={8}>
                    <Autocomplete
                      disablePortal
                      id="registerFormCollegesAutocomplete"
                      options={vm.parent.colleges || []}
                      loading={vm.parent.colleges === undefined}
                      loadingText={vm.translate("loading")}
                      noOptionsText={vm.translate("no.options")}
                      fullWidth
                      clearIcon={undefined}
                      disabled={acceptCollegiated}
                      getOptionLabel={(option: any) => option.name}
                      renderInput={(params) => (
                        <TextField
                          required
                          className={classes.report_autocomplete}
                          {...params}
                          size="small"
                          label={vm.translate("report.block0.college")}
                        />
                      )}
                      onChange={(event: any, value: any) => {
                        if (value)
                          setCollegeSelected({
                            ...collegeSelected,
                            college: value,
                          });
                      }}
                    />
                  </Grid>

                  <Box component="div" sx={{ marginLeft: "0.3rem" }}>
                    <TextFieldGrid
                      id="registerFormCollegeSelectedNumber"
                      title={vm.translate("launch.number")}
                      value={collegeSelected?.number}
                      gridClassName={""}
                      required
                      form="registerform"
                      validator={vm.validatorVM}
                      style={{ marginTop: "0px !important", marginLeft: 5 }}
                      onChange={(value: any) => {
                        setCollegeSelected({
                          ...collegeSelected,
                          number: value.target.value,
                        });
                      }}
                      gridTextField={{ xs: 12, md: 12 }}
                    />
                  </Box>

                  <Grid item xs={1} md={1} display="flex" justifyContent="end" alignItems={"center"}>
                    <Button
                      id="registerFormAddCollege"
                      text={vm.translate("add")}
                      disabled={collegeSelected?.college && collegeSelected?.number ? false : true}
                      textColor={Colors.white}
                      backgroundColor={!acceptCollegiated ? Colors.buttonPrimary : Colors.error}
                      hoverColor={Colors.buttonPrimary}
                      onClick={() => {
                        vm.item.collegiated?.push(collegeSelected!!);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <div style={{ marginTop: 10 }} />
              {vm.item.collegiated?.length !== 0 && (
                <Grid item xs={12} md={12}>
                  <TableContainer id="registerFormTableContainer" component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{vm.translate("launch.name.college")}</TableCell>
                          <TableCell>{vm.translate("launch.college.type")}</TableCell>
                          <TableCell align="right">{vm.translate("launch.college.number")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vm.item!!.collegiated!!.map((row, index) => {
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {/** Review the style of this th/header */}
                              <TableCell style={{ fontWeight: "bold" }}>{row.college!!.name}</TableCell>
                              <TableCell>{vm.translate(row.college!!.type!!)}</TableCell>
                              <TableCell align="right">{row.number}</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  backgroundColor="transparent"
                                  onClick={() => vm.item!!.collegiated!!.splice(vm.item!!.collegiated!!.indexOf(vm.item!!.collegiated!![index]), 1)}
                                  icon={<Icon color={Colors.error} element={IconsList.Clear} />}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Paper>
            <Grid item xs={12} md={12} className={classes.report_displayGrid} style={{ paddingTop: 10 }}>
              {addressView}

              <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
                <TextFieldGrid
                  id="registerFormEmail"
                  title={vm.translate("report.block0.email")}
                  type={"email"}
                  required
                  form="registerform"
                  validator={vm.validatorVM}
                  value={vm.item?.email}
                  onChange={(value: any) => {
                    vm.item!!.email = value.target.value;
                  }}
                  error={vm.item.email !== "" && !validEmail.test(vm.item?.email!!)}
                  errorText={vm.translate("report.block0.register.incorrect.format")}
                  gridTextField={{ xs: 6, md: 6 }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
                <TextFieldGrid
                  id="registerFormPhone"
                  title={vm.translate("report.block0.phone")}
                  value={vm.item?.phone}
                  onChange={(value: any) => {
                    vm.item!!.phone = value.target.value;
                  }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
                <TextFieldGrid
                  id="registerFormMobile"
                  title={vm.translate("report.block0.mobile")}
                  required
                  form="registerform"
                  validator={vm.validatorVM}
                  value={vm.item?.mobilePhone}
                  onChange={(value: any) => {
                    vm.item!!.mobilePhone = value.target.value;
                  }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
                <TextFieldGrid
                  id="registerFormUsername"
                  title={vm.translate("launch.username")}
                  required
                  form="registerform"
                  validator={vm.validatorVM}
                  value={vm.item?.username}
                  onChange={(value: any) => {
                    vm.item!!.username = value.target.value;
                  }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
                <TextFieldGrid
                  id="registerFormPassword"
                  title={vm.translate("launch.password")}
                  required
                  form="registerform"
                  validator={vm.validatorVM}
                  type={"password"}
                  value={vm.item?.password}
                  onChange={(value: any) => {
                    vm.item!!.password = value.target.value;
                  }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
                <TextFieldGrid
                  id="registerFormrRepeatPassword"
                  title={vm.translate("launch.repeat.password")}
                  required
                  form="registerform"
                  validator={vm.validatorVM}
                  type={"password"}
                  error={vm.item.password !== repeatPassword}
                  errorText={vm.translate("launch.password.coincidence.error")}
                  value={repeatPassword}
                  onChange={(value: any) => {
                    setRepeatPassword(value.target.value);
                  }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} className={classes.report_saveButton}>
              <Button
                id="registerFormRegistryCreate"
                type="submit"
                disabled={
                  repeatPassword !== "" &&
                  vm.item.password !== "" &&
                  repeatPassword === vm.item.password &&
                  vm.item.email !== "" &&
                  validEmail.test(vm.item?.email!!) &&
                  (RegexHelper.validCif.test(vm.item?.idNumber!.toUpperCase()) || RegexHelper.CIF_REGEX.test(vm.item?.idNumber!.toUpperCase())) &&
                  vm.item.idNumber?.length === 9
                    ? false
                    : true
                }
                text={vm.translate("launch.screen.registry.create")}
                onClick={() => {}}
                textColor={Colors.white}
                backgroundColor={Colors.buttonSecondary}
                hoverColor={Colors.buttonSecondary}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
});
