import { registerView, ViewComponent } from "@frui.ts/views";
import { Grid, Paper } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import BackDropLoading from "../../components/backdrop-loading";
import { Colors } from "../../constants";
import LoginViewModel from "../../viewmodels/login/login-view-model";
import { LoginStyles } from "./login-style";
import { LoginFields } from "./login-fields";
import Maintenance from "./../maintenance/maintenance";
import MaintenanceViewModel from "../../viewmodels/maintenance/maintenance-view-model";

const LoginView: ViewComponent<LoginViewModel> = observer(({ vm }) => {
  const classes = LoginStyles();
  /*return <Maintenance vm={{} as MaintenanceViewModel} />*/
  return (
    <React.Fragment>
      <BackDropLoading showLoading={vm.loadingLogin} />
      <Grid container className={classes.login_container}>
        <Grid item xs={12} md={12} className={classes.login_grid}>
          <Paper id="paperLoginView" className={classes.login_paper}>
            <LoginFields vm={vm} />
          </Paper>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          position: "fixed",
          bottom: "0px",
          width: "129%",
          overflow: "hidden",
          marginLeft: "-20%",
          justifyContent: "center",
          zIndex: 45555554444,
          background: Colors.primary,
        }}
      >
        <div
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => window.open("https://ayudalibroedificioexistente.es/terminos-y-condiciones", "_blank")}
        >
          Términos y condiciones
        </div>
        <div
          style={{ color: "white", marginLeft: "1rem", cursor: "pointer" }}
          onClick={() => window.open("https://ayudalibroedificioexistente.es/privacy-policy", "_blank")}
        >
          Politica de privacidad
        </div>
      </div>
    </React.Fragment>
  );
});

registerView(LoginView, LoginViewModel);
