/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewAddressDTO,
    NewAddressDTOFromJSON,
    NewAddressDTOFromJSONTyped,
    NewAddressDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewAgentDTO
 */
export interface NewAgentDTO {
    /**
     * 
     * @type {NewAddressDTO}
     * @memberof NewAgentDTO
     */
    address?: NewAddressDTO;
    /**
     * 
     * @type {string}
     * @memberof NewAgentDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAgentDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAgentDTO
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAgentDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAgentDTO
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAgentDTO
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAgentDTO
     */
    surname?: string;
}

export function NewAgentDTOFromJSON(json: any): NewAgentDTO {
    return NewAgentDTOFromJSONTyped(json, false);
}

export function NewAgentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewAgentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : NewAddressDTOFromJSON(json['address']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
    };
}

export function NewAgentDTOToJSON(value?: NewAgentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': NewAddressDTOToJSON(value.address),
        'email': value.email,
        'idNumber': value.idNumber,
        'mobilePhone': value.mobilePhone,
        'name': value.name,
        'phone': value.phone,
        'role': value.role,
        'surname': value.surname,
    };
}

