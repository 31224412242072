import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { ShortcomingPrintedDTO } from "clients";
import { getDeficiencyPriorityIndicatorProperties } from "pdf/documents/book/_common/utils/render-indicator-helper";
import { fontsNewPdf } from "pdf/_common/fonts/fonts";

interface DeficienciesTableProps {
  deficiencies: ShortcomingPrintedDTO[];
}

export const DeficienciesTable: React.FC<DeficienciesTableProps> = ({ deficiencies }) => {
  return (
    <View style={styles.table}>
      <Text style={styles.header}>Descripción deficiencia detectada</Text>
      {deficiencies.map((deficiency, index) => (
        <DeficienciesTableRow key={index} data={deficiency} />
      ))}
    </View>
  );
};

const DeficienciesTableRow: React.FC<{ data: ShortcomingPrintedDTO }> = ({ data }) => {
  const { title, bgColor } = getDeficiencyPriorityIndicatorProperties(data.priority);
  return (
    <View style={styles.row}>
      <View style={[styles.cell, styles.column1]}>
        <Text style={styles.textLeft}>{data.name}</Text>
      </View>
      <View style={[styles.cell, styles.column2, { backgroundColor: bgColor }]}>
        <Text style={styles.level}>{title}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  table: { width: "100%" },
  header: { fontFamily: fontsNewPdf.RobotoBold },
  row: { flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid #ccc", padding: 8 },
  cell: { flex: 1, justifyContent: "center", alignItems: "center", height: 30 }, 
  column1: { flex: 4, paddingHorizontal: 8, alignItems: "flex-start" }, 
  column2: { flex: 1 },
  textLeft: { textAlign: "left" },
  level: { color: "black", textAlign: "center" },
});
