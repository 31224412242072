/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InformationAndSignsDTO
 */
export interface InformationAndSignsDTO {
    /**
     * 
     * @type {boolean}
     * @memberof InformationAndSignsDTO
     */
    accessibleEntrancesSignpostedWithSIA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InformationAndSignsDTO
     */
    accessibleItinerariesSignpostedWithSIA?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InformationAndSignsDTO
     */
    informationAndSignsNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InformationAndSignsDTO
     */
    liftsSignpostedWithSIA?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InformationAndSignsDTO
     */
    parkingPlacesSignpostedWithSIA?: boolean;
}

export function InformationAndSignsDTOFromJSON(json: any): InformationAndSignsDTO {
    return InformationAndSignsDTOFromJSONTyped(json, false);
}

export function InformationAndSignsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InformationAndSignsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibleEntrancesSignpostedWithSIA': !exists(json, 'accessibleEntrancesSignpostedWithSIA') ? undefined : json['accessibleEntrancesSignpostedWithSIA'],
        'accessibleItinerariesSignpostedWithSIA': !exists(json, 'accessibleItinerariesSignpostedWithSIA') ? undefined : json['accessibleItinerariesSignpostedWithSIA'],
        'informationAndSignsNotes': !exists(json, 'informationAndSignsNotes') ? undefined : json['informationAndSignsNotes'],
        'liftsSignpostedWithSIA': !exists(json, 'liftsSignpostedWithSIA') ? undefined : json['liftsSignpostedWithSIA'],
        'parkingPlacesSignpostedWithSIA': !exists(json, 'parkingPlacesSignpostedWithSIA') ? undefined : json['parkingPlacesSignpostedWithSIA'],
    };
}

export function InformationAndSignsDTOToJSON(value?: InformationAndSignsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibleEntrancesSignpostedWithSIA': value.accessibleEntrancesSignpostedWithSIA,
        'accessibleItinerariesSignpostedWithSIA': value.accessibleItinerariesSignpostedWithSIA,
        'informationAndSignsNotes': value.informationAndSignsNotes,
        'liftsSignpostedWithSIA': value.liftsSignpostedWithSIA,
        'parkingPlacesSignpostedWithSIA': value.parkingPlacesSignpostedWithSIA,
    };
}

