import { t } from "../../../book-v2/book-helpers";
import { observer } from "mobx-react-lite";
import { Text, View } from "@react-pdf/renderer";
import { TitleWithBlueBorder } from "../../../book-v2/components/titles/titles";
import { energyEfficiencyStyles } from "./energyEfficiency.styles";
import { ColorsNewPdf } from "../../../../../../constants";
import { selectBgColorDependingOnEnergyLetter } from "../../../book-v2/pages/utils";
import * as RegexHelper from "../../../../../../utils/regexHelper";
import { EnergyEfficiencyCertificateDTO } from "../../../../../../clients";
import React from "react";
export const PartialQualification: React.FC<{ data: EnergyEfficiencyCertificateDTO }> = observer(({ data }) => {
  const styles = energyEfficiencyStyles;

  return (
    <>
      <TitleWithBlueBorder title={t("pdf.energyEfficiency.partialQualification")} />

      <Text style={styles.descriptionText}>{t("pdf.energyEfficiency.partialQualification.description")}</Text>

      <View style={styles.tableGlobalIndicator}>
        <View style={styles.tableHeader}>
          <Text style={[styles.titleGlobal, styles.titleHeaderText]}>{t("pdf.energyEfficiency.partialQualification.globalIndicator")}</Text>
        </View>

        <View style={styles.subTitleContainer}>
          <Text style={styles.titleGlobal}>{t("pdf.energyEfficiency.partialQualification.globalIndicator.heat")}</Text>
          <Text style={styles.titleGlobal}>{t("pdf.energyEfficiency.partialQualification.globalIndicator.cooling")}</Text>
        </View>

        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <View style={styles.globalEmissionsHeatContainer}>
            <View style={{ borderBottom: `1pt solid ${ColorsNewPdf.background_containers_block1}` }}>
              <Text style={[styles.dataGlobal, styles.dataEmissionsHeat]}>
                {t("pdf.energyEfficiency.partialQualification.globalIndicator.heat.description")}
              </Text>
            </View>
            <Text style={[styles.dataGlobal, styles.dataEmissionsHeat]}>
              {String(data.heatingDemand?.amount?.toFixed(2)).replace(".", ",").replaceAll(RegexHelper.checkNumberOver1000, ".")}
            </Text>
          </View>

          <View style={[styles.dataEmissionsLetter, selectBgColorDependingOnEnergyLetter(data.heatingDemand?.qualifier ?? "F")]}>
            <Text>{data.heatingDemand?.qualifier ?? "F"}</Text>
          </View>

          <View style={styles.globalEmissionsHeatContainer}>
            <View style={{ borderBottom: `1pt solid ${ColorsNewPdf.background_containers_block1}` }}>
              <Text style={[styles.dataGlobal, styles.dataEmissionsHeat]}>
                {t("pdf.energyEfficiency.partialQualification.globalIndicator.cooling.description")}
              </Text>
            </View>
            <Text style={[styles.dataGlobal, styles.dataEmissionsHeat]}>
              {String(data.coolingDemand?.amount?.toFixed(2)).replace(".", ",").replaceAll(RegexHelper.checkNumberOver1000, ".")}
            </Text>
          </View>

          <View style={[styles.dataEmissionsLetter, selectBgColorDependingOnEnergyLetter(data.coolingDemand?.qualifier ?? "F")]}>
            <Text>{data.coolingDemand?.qualifier ?? "F"}</Text>
          </View>
        </View>
      </View>
    </>
  );
});
