import React from "react";
import { Page, View } from "@react-pdf/renderer";
import { PageHeader } from "../page-header/page-header";
import styles from "../../styles/resume-styles";
import { PageFooter } from "../page-footer/page-footer";

export const DocumentPage: React.FC = ({ children }) => {
  return (
    <Page size={"A4"} style={styles.page}>
      <PageHeader />
      <View style={styles.body}>{children}</View>
      <PageFooter />
    </Page>
  );
};
