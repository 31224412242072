/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MailConfigDTO,
    MailConfigDTOFromJSON,
    MailConfigDTOFromJSONTyped,
    MailConfigDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserConfigDTO
 */
export interface UserConfigDTO {
    /**
     * 
     * @type {string}
     * @memberof UserConfigDTO
     */
    id?: string;
    /**
     * 
     * @type {Set<MailConfigDTO>}
     * @memberof UserConfigDTO
     */
    mailConfigList?: Set<MailConfigDTO>;
    /**
     * 
     * @type {string}
     * @memberof UserConfigDTO
     */
    userId?: string;
}

export function UserConfigDTOFromJSON(json: any): UserConfigDTO {
    return UserConfigDTOFromJSONTyped(json, false);
}

export function UserConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mailConfigList': !exists(json, 'mailConfigList') ? undefined : (new Set((json['mailConfigList'] as Array<any>).map(MailConfigDTOFromJSON))),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function UserConfigDTOToJSON(value?: UserConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mailConfigList': value.mailConfigList === undefined ? undefined : (Array.from(value.mailConfigList as Set<any>).map(MailConfigDTOToJSON)),
        'userId': value.userId,
    };
}

