/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildingAddressDTO,
    BuildingAddressDTOFromJSON,
    BuildingAddressDTOFromJSONTyped,
    BuildingAddressDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminTechnicianParticipationDTO
 */
export interface AdminTechnicianParticipationDTO {
    /**
     * 
     * @type {BuildingAddressDTO}
     * @memberof AdminTechnicianParticipationDTO
     */
    addressDTO?: BuildingAddressDTO;
    /**
     * 
     * @type {string}
     * @memberof AdminTechnicianParticipationDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTechnicianParticipationDTO
     */
    college?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminTechnicianParticipationDTO
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminTechnicianParticipationDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminTechnicianParticipationDTO
     */
    modified?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminTechnicianParticipationDTO
     */
    status?: AdminTechnicianParticipationDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminTechnicianParticipationDTO
     */
    technician?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTechnicianParticipationDTO
     */
    transactionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminTechnicianParticipationDTO
     */
    validated?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum AdminTechnicianParticipationDTOStatusEnum {
    Approved = 'APPROVED',
    Finished = 'FINISHED',
    InProgress = 'IN_PROGRESS',
    MaintenanceRequested = 'MAINTENANCE_REQUESTED',
    OnMaintenance = 'ON_MAINTENANCE',
    Open = 'OPEN',
    Rejected = 'REJECTED',
    ValidationRequested = 'VALIDATION_REQUESTED',
    WithIncidents = 'WITH_INCIDENTS'
}

export function AdminTechnicianParticipationDTOFromJSON(json: any): AdminTechnicianParticipationDTO {
    return AdminTechnicianParticipationDTOFromJSONTyped(json, false);
}

export function AdminTechnicianParticipationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminTechnicianParticipationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressDTO': !exists(json, 'addressDTO') ? undefined : BuildingAddressDTOFromJSON(json['addressDTO']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'college': !exists(json, 'college') ? undefined : json['college'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'modified': !exists(json, 'modified') ? undefined : json['modified'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'technician': !exists(json, 'technician') ? undefined : json['technician'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'validated': !exists(json, 'validated') ? undefined : json['validated'],
    };
}

export function AdminTechnicianParticipationDTOToJSON(value?: AdminTechnicianParticipationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressDTO': BuildingAddressDTOToJSON(value.addressDTO),
        'code': value.code,
        'college': value.college,
        'created': value.created,
        'id': value.id,
        'modified': value.modified,
        'status': value.status,
        'technician': value.technician,
        'transactionId': value.transactionId,
        'validated': value.validated,
    };
}

