/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Sorting,
    SortingFromJSON,
    SortingFromJSONTyped,
    SortingToJSON,
    Values,
    ValuesFromJSON,
    ValuesFromJSONTyped,
    ValuesToJSON,
} from './';

/**
 * 
 * @export
 * @interface VoucherFilter
 */
export interface VoucherFilter {
    /**
     * 
     * @type {number}
     * @memberof VoucherFilter
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof VoucherFilter
     */
    offset?: number;
    /**
     * 
     * @type {Sorting}
     * @memberof VoucherFilter
     */
    sorting?: Sorting;
    /**
     * 
     * @type {Values}
     * @memberof VoucherFilter
     */
    values?: Values;
}

export function VoucherFilterFromJSON(json: any): VoucherFilter {
    return VoucherFilterFromJSONTyped(json, false);
}

export function VoucherFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoucherFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'sorting': !exists(json, 'sorting') ? undefined : SortingFromJSON(json['sorting']),
        'values': !exists(json, 'values') ? undefined : ValuesFromJSON(json['values']),
    };
}

export function VoucherFilterToJSON(value?: VoucherFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'offset': value.offset,
        'sorting': SortingToJSON(value.sorting),
        'values': ValuesToJSON(value.values),
    };
}

