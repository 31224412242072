import { StyleSheet } from "@react-pdf/renderer";
import { ColorsNewPdf } from "../../../../../../constants";
import { fontsNewPdf } from "../../../../../_common/fonts/fonts";

export const TitlesStyles = StyleSheet.create({
  pdfTitle: {
    fontSize: 24,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#000",
    marginBottom: 10,
  },
  indexTitle: {
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "capitalize",
    color: "#000",
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: "14pt",
    fontFamily: fontsNewPdf.RobotoBold,
    paddingLeft: "8.5pt",
  },
  titleWithBackground: {
    fontSize: "24pt",
    fontFamily: fontsNewPdf.RobotoLight,
    textTransform: "uppercase",
    color: ColorsNewPdf.white,
    padding: 15,
  },
  titleWithBackgroundLower: {
    fontSize: "14pt",
    color: ColorsNewPdf.white,
    padding: 10,
  },
  deficienciesTitlesStyles: {
    padding: 6,
    marginBottom: 6,
    backgroundColor: ColorsNewPdf.background_table_subsection,
    width: "100%",
    textAlign: "center",
    justifyContent: "center",
    color: ColorsNewPdf.white,
  },
  textWithBorder: {
    borderBottom: `1pt solid ${ColorsNewPdf.lines_subtitle_section}`,
    width: "100%",
    height: "20pt",
    marginBottom: "2pt",
  },
  textAccessibilityOutdoor: {
    fontSize: "14pt",
    fontFamily: fontsNewPdf.RobotoRegular,
    marginLeft: "10pt",
  },
});
