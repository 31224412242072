/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LicenseDTO,
    LicenseDTOFromJSON,
    LicenseDTOFromJSONTyped,
    LicenseDTOToJSON,
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
    ProceedingsDTO,
    ProceedingsDTOFromJSON,
    ProceedingsDTOFromJSONTyped,
    ProceedingsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewBuildingDescriptionDTO
 */
export interface NewBuildingDescriptionDTO {
    /**
     * 
     * @type {Array<LicenseDTO>}
     * @memberof NewBuildingDescriptionDTO
     */
    licenses?: Array<LicenseDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof NewBuildingDescriptionDTO
     */
    otherDocuments?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<ProceedingsDTO>}
     * @memberof NewBuildingDescriptionDTO
     */
    proceedings?: Array<ProceedingsDTO>;
}

export function NewBuildingDescriptionDTOFromJSON(json: any): NewBuildingDescriptionDTO {
    return NewBuildingDescriptionDTOFromJSONTyped(json, false);
}

export function NewBuildingDescriptionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewBuildingDescriptionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'licenses': !exists(json, 'licenses') ? undefined : ((json['licenses'] as Array<any>).map(LicenseDTOFromJSON)),
        'otherDocuments': !exists(json, 'otherDocuments') ? undefined : ((json['otherDocuments'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'proceedings': !exists(json, 'proceedings') ? undefined : ((json['proceedings'] as Array<any>).map(ProceedingsDTOFromJSON)),
    };
}

export function NewBuildingDescriptionDTOToJSON(value?: NewBuildingDescriptionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'licenses': value.licenses === undefined ? undefined : ((value.licenses as Array<any>).map(LicenseDTOToJSON)),
        'otherDocuments': value.otherDocuments === undefined ? undefined : ((value.otherDocuments as Array<any>).map(MediaPrintedDTOToJSON)),
        'proceedings': value.proceedings === undefined ? undefined : ((value.proceedings as Array<any>).map(ProceedingsDTOToJSON)),
    };
}

