import { Grid, TextField, Typography } from "@mui/material";
import { BillAddressDTO, ProvinceDTO, TownDTO } from "../../../../../../clients";
import ProfileViewModel from "../../../../../../viewmodels/profile/profile-view-model";
import { useState } from "react";
import { ReportStyle } from "../../../../../reports/report-style";
import { Icon, IconButton, IconsList } from "@movicoders/movicoders-components";
import { Colors } from "../../../../../../constants";

export type BillingAddressesProps = {
  billingAddresses: BillAddressDTO[];
  onDeleteBillingAddress: (baddresses: BillAddressDTO) => void;
  vm: ProfileViewModel;
};

export const BillingAddresses = (props: BillingAddressesProps) => {
  const [provinces, setProvinces] = useState<ProvinceDTO[] | null>([]);
  const [towns, setTowns] = useState<TownDTO[] | null>([]);
  const reportClasses = ReportStyle();
  const onDeleteBillingAddress = (baddresses: BillAddressDTO) => {
    props.onDeleteBillingAddress(baddresses);
  };

  return (
    <>
      <Grid container xs={12} md={12} style={{ marginTop: 10 }}>
        <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
          <Typography variant="caption">{props.vm.translate("profile.billingAddresses")}</Typography>
        </Grid>
        {props.billingAddresses.map((billingAddress) => (
          <Grid container style={{ marginTop: "1rem" }}>
            <Grid item xs={3}>
              <TextField
                disabled
                value={billingAddress.billingProvince}
                className={reportClasses.report_textField}
                size="small"
                label={props.vm.translate("report.block0.province")}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                disabled
                value={billingAddress.billingTown}
                className={reportClasses.report_textField}
                required
                size="small"
                label={props.vm.translate("report.block0.municipality")}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                sx={{ marginRight: "0.5rem" }}
                className={reportClasses.report_textField}
                disabled
                size="small"
                label={props.vm.translate("report.block0.cp")}
                id="billingAddressPostalCode"
                fullWidth
                value={billingAddress?.billingPostalCode}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginLeft: "0.5rem" }}>
              <TextField
                className={reportClasses.report_textField}
                disabled
                size="small"
                label={props.vm.translate("profile.address")}
                id="billingAddressAddress"
                fullWidth
                value={billingAddress?.billingAddress}
              />
            </Grid>
            
            <Grid item xs={1} sx={{ marginLeft: "2rem" }}>
              <IconButton
                iconButtonSize={40}
                style={{ backgroundColor: Colors.error }}
                onClick={() => onDeleteBillingAddress(billingAddress)}
                icon={<Icon element={IconsList.Delete} size="inherit" color={Colors.white} />}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginTop: "0.5rem" }}>
              <TextField
                className={reportClasses.report_textField}
                disabled
                size="small"
                label={props.vm.translate("profile.address.legalName")}
                id="billingAddressLegalName"
                fullWidth
                value={billingAddress?.billingLegalName}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}>
              <TextField
                className={reportClasses.report_textField}
                disabled
                size="small"
                label={props.vm.translate("profile.address.cif")}
                id="billingAddressCif"
                fullWidth
                value={billingAddress?.billingCif}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
