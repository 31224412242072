import { View, Text } from "@react-pdf/renderer";
import { ImprovementDTO } from "clients";
import { ColorsNewPdf } from "../../../../../../constants";
import { fontsNewPdf } from "pdf/_common/fonts/fonts";
import { t } from "pdf/documents/book/book-v2/book-helpers";
import React from "react";
import * as RegexHelper from "../../../../../../utils/regexHelper";

interface ImprovementSummaryViewProps {
  improvement?: ImprovementDTO;
}

export const ImprovementSummaryView: React.FC<ImprovementSummaryViewProps> = ({ improvement }) => {
  return improvement ? (
    <View wrap={false} style={{ backgroundColor: ColorsNewPdf.background_table_dates, padding: 14, marginVertical: 16 }}>
      <Text style={{ fontSize: "9pt", fontFamily: fontsNewPdf.RobotoBold, marginBottom: "15pt" }}>{t("improvement.summary.title")}</Text>
      <Text style={{ fontSize: "9pt" }}>
        {t("improvement.summary.text1")}
        {improvement?.energyEfficiencyMeasureData?.globalEnergyDemandReductionPercent || "0"}
        {"%"} {t("improvement.summary.text2")}{" "}
        {String(improvement?.energyEfficiencyMeasureData?.initialNonRenewablePrimaryEnergyConsumptionReductionPercent || "0").replace(".", ",") }
        {"% "}
        {t("improvement.summary.text3")} {t(`report.block2.improvement.cost.${improvement?.estimatedCost}`)}
        {t("improvement.summary.text4")}{" "}
        {String(improvement?.energyEfficiencyMeasureData?.maximumFinancialAid || "0").replace(".", ",").replaceAll(RegexHelper.checkNumberOver1000, ".")}{" "}
        {t("improvement.summary.text5")}{" "}
        {String(improvement?.energyEfficiencyMeasureData?.investment || "0").replace(".", ",").replaceAll(RegexHelper.checkNumberOver1000, ".")}
        {"€"}
      </Text>
    </View>
  ) : (
    <></>
  );
};
