/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneralDataDTO
 */
export interface GeneralDataDTO {
    /**
     * 
     * @type {string}
     * @memberof GeneralDataDTO
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralDataDTO
     */
    immediateSecurityMeasuresTakenDuringVisit?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralDataDTO
     */
    impediments?: string;
    /**
     * 
     * @type {number}
     * @memberof GeneralDataDTO
     */
    inspectedHouseHolds?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralDataDTO
     */
    inspectedPremisedOrOtherUsages?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralDataDTO
     */
    meansUsedDuringInspection?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralDataDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralDataDTO
     */
    testsMade?: string;
}

export function GeneralDataDTOFromJSON(json: any): GeneralDataDTO {
    return GeneralDataDTOFromJSONTyped(json, false);
}

export function GeneralDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'immediateSecurityMeasuresTakenDuringVisit': !exists(json, 'immediateSecurityMeasuresTakenDuringVisit') ? undefined : json['immediateSecurityMeasuresTakenDuringVisit'],
        'impediments': !exists(json, 'impediments') ? undefined : json['impediments'],
        'inspectedHouseHolds': !exists(json, 'inspectedHouseHolds') ? undefined : json['inspectedHouseHolds'],
        'inspectedPremisedOrOtherUsages': !exists(json, 'inspectedPremisedOrOtherUsages') ? undefined : json['inspectedPremisedOrOtherUsages'],
        'meansUsedDuringInspection': !exists(json, 'meansUsedDuringInspection') ? undefined : json['meansUsedDuringInspection'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'testsMade': !exists(json, 'testsMade') ? undefined : json['testsMade'],
    };
}

export function GeneralDataDTOToJSON(value?: GeneralDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'immediateSecurityMeasuresTakenDuringVisit': value.immediateSecurityMeasuresTakenDuringVisit,
        'impediments': value.impediments,
        'inspectedHouseHolds': value.inspectedHouseHolds,
        'inspectedPremisedOrOtherUsages': value.inspectedPremisedOrOtherUsages,
        'meansUsedDuringInspection': value.meansUsedDuringInspection,
        'notes': value.notes,
        'testsMade': value.testsMade,
    };
}

