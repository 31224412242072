/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDTO,
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
    BillAddressDTO,
    BillAddressDTOFromJSON,
    BillAddressDTOFromJSONTyped,
    BillAddressDTOToJSON,
    CollegiateDTO,
    CollegiateDTOFromJSON,
    CollegiateDTOFromJSONTyped,
    CollegiateDTOToJSON,
    FeeDTO,
    FeeDTOFromJSON,
    FeeDTOFromJSONTyped,
    FeeDTOToJSON,
    TechnicianDTO,
    TechnicianDTOFromJSON,
    TechnicianDTOFromJSONTyped,
    TechnicianDTOToJSON,
} from './';

/**
 * ExtendedSignupTechnician extends Technician model to provide username and password for credentials generation purposes.
 * @export
 * @interface ExtendedSignupTechnician
 */
export interface ExtendedSignupTechnician {
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedSignupTechnician
     */
    active?: boolean;
    /**
     * 
     * @type {AddressDTO}
     * @memberof ExtendedSignupTechnician
     */
    address?: AddressDTO;
    /**
     * 
     * @type {Array<BillAddressDTO>}
     * @memberof ExtendedSignupTechnician
     */
    billAddresses?: Array<BillAddressDTO>;
    /**
     * 
     * @type {Array<CollegiateDTO>}
     * @memberof ExtendedSignupTechnician
     */
    collegiated?: Array<CollegiateDTO>;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    credentials?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedSignupTechnician
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    email?: string;
    /**
     * 
     * @type {FeeDTO}
     * @memberof ExtendedSignupTechnician
     */
    fee?: FeeDTO;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    mainCollege?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    phone?: string;
    /**
     * 
     * @type {TechnicianDTO}
     * @memberof ExtendedSignupTechnician
     */
    representer?: TechnicianDTO;
    /**
     * 
     * @type {TechnicianDTO}
     * @memberof ExtendedSignupTechnician
     */
    representing?: TechnicianDTO;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    role?: ExtendedSignupTechnicianRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    type?: ExtendedSignupTechnicianTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtendedSignupTechnician
     */
    username?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedSignupTechnician
     */
    valid?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ExtendedSignupTechnicianRoleEnum {
    BackofficeManager = 'BACKOFFICE_MANAGER',
    CollegeAdministrator = 'COLLEGE_ADMINISTRATOR',
    HomeownersPresident = 'HOMEOWNERS_PRESIDENT',
    Owner = 'OWNER',
    PropertyManager = 'PROPERTY_MANAGER',
    Representative = 'REPRESENTATIVE',
    SystemAdministrator = 'SYSTEM_ADMINISTRATOR',
    Technician = 'TECHNICIAN'
}/**
* @export
* @enum {string}
*/
export enum ExtendedSignupTechnicianTypeEnum {
    Legal = 'LEGAL',
    Physical = 'PHYSICAL'
}

export function ExtendedSignupTechnicianFromJSON(json: any): ExtendedSignupTechnician {
    return ExtendedSignupTechnicianFromJSONTyped(json, false);
}

export function ExtendedSignupTechnicianFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedSignupTechnician {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'address': !exists(json, 'address') ? undefined : AddressDTOFromJSON(json['address']),
        'billAddresses': !exists(json, 'billAddresses') ? undefined : ((json['billAddresses'] as Array<any>).map(BillAddressDTOFromJSON)),
        'collegiated': !exists(json, 'collegiated') ? undefined : ((json['collegiated'] as Array<any>).map(CollegiateDTOFromJSON)),
        'credentials': !exists(json, 'credentials') ? undefined : json['credentials'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fee': !exists(json, 'fee') ? undefined : FeeDTOFromJSON(json['fee']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'mainCollege': !exists(json, 'mainCollege') ? undefined : json['mainCollege'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'representer': !exists(json, 'representer') ? undefined : TechnicianDTOFromJSON(json['representer']),
        'representing': !exists(json, 'representing') ? undefined : TechnicianDTOFromJSON(json['representing']),
        'role': !exists(json, 'role') ? undefined : json['role'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
    };
}

export function ExtendedSignupTechnicianToJSON(value?: ExtendedSignupTechnician | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'address': AddressDTOToJSON(value.address),
        'billAddresses': value.billAddresses === undefined ? undefined : ((value.billAddresses as Array<any>).map(BillAddressDTOToJSON)),
        'collegiated': value.collegiated === undefined ? undefined : ((value.collegiated as Array<any>).map(CollegiateDTOToJSON)),
        'credentials': value.credentials,
        'deleted': value.deleted,
        'email': value.email,
        'fee': FeeDTOToJSON(value.fee),
        'id': value.id,
        'idNumber': value.idNumber,
        'mainCollege': value.mainCollege,
        'mobilePhone': value.mobilePhone,
        'name': value.name,
        'password': value.password,
        'phone': value.phone,
        'representer': TechnicianDTOToJSON(value.representer),
        'representing': TechnicianDTOToJSON(value.representing),
        'role': value.role,
        'surname': value.surname,
        'tenant': value.tenant,
        'type': value.type,
        'username': value.username,
        'valid': value.valid,
    };
}

