import { CollegiateDTO } from "clients/models/CollegiateDTO";

export const getMainCollegeName = (mainCollegeId: string | undefined, collegiations: CollegiateDTO[] | undefined): string => {
    if(!mainCollegeId || collegiations === null || collegiations === undefined || collegiations.length === 0){
        return ""
    }
    let collegeName = collegiations.find(c => c.college?.id === mainCollegeId)?.college?.name
    return collegeName ?? ""
}

export const getMainCollegeType = (mainCollegeId: string | undefined, collegiations: CollegiateDTO[] | undefined): string => {
    if(!mainCollegeId || collegiations === null || collegiations === undefined || collegiations.length === 0){
        return ""
    }
    let collegeType = collegiations.find(c => c.college?.id === mainCollegeId)?.college?.type
    return collegeType ?? ""
}