/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildingAddressDTO,
    BuildingAddressDTOFromJSON,
    BuildingAddressDTOFromJSONTyped,
    BuildingAddressDTOToJSON,
    BuildingPolygonDTO,
    BuildingPolygonDTOFromJSON,
    BuildingPolygonDTOFromJSONTyped,
    BuildingPolygonDTOToJSON,
    PolygonDTO,
    PolygonDTOFromJSON,
    PolygonDTOFromJSONTyped,
    PolygonDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuildingLocationInfoDTO
 */
export interface BuildingLocationInfoDTO {
    /**
     * 
     * @type {BuildingAddressDTO}
     * @memberof BuildingLocationInfoDTO
     */
    address?: BuildingAddressDTO;
    /**
     * 
     * @type {string}
     * @memberof BuildingLocationInfoDTO
     */
    buildingOccupancyType?: BuildingLocationInfoDTOBuildingOccupancyTypeEnum;
    /**
     * 
     * @type {Array<BuildingPolygonDTO>}
     * @memberof BuildingLocationInfoDTO
     */
    buildingPolygon?: Array<BuildingPolygonDTO>;
    /**
     * 
     * @type {Array<PolygonDTO>}
     * @memberof BuildingLocationInfoDTO
     */
    parcelPolygon?: Array<PolygonDTO>;
    /**
     * 
     * @type {string}
     * @memberof BuildingLocationInfoDTO
     */
    referenceDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingLocationInfoDTO
     */
    sharesCommonElementsWithContiguousBuildings?: string;
}

/**
* @export
* @enum {string}
*/
export enum BuildingLocationInfoDTOBuildingOccupancyTypeEnum {
    MultyFamily = 'MULTY_FAMILY',
    Others = 'OTHERS',
    SingleFamily = 'SINGLE_FAMILY'
}

export function BuildingLocationInfoDTOFromJSON(json: any): BuildingLocationInfoDTO {
    return BuildingLocationInfoDTOFromJSONTyped(json, false);
}

export function BuildingLocationInfoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingLocationInfoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : BuildingAddressDTOFromJSON(json['address']),
        'buildingOccupancyType': !exists(json, 'buildingOccupancyType') ? undefined : json['buildingOccupancyType'],
        'buildingPolygon': !exists(json, 'buildingPolygon') ? undefined : ((json['buildingPolygon'] as Array<any>).map(BuildingPolygonDTOFromJSON)),
        'parcelPolygon': !exists(json, 'parcelPolygon') ? undefined : ((json['parcelPolygon'] as Array<any>).map(PolygonDTOFromJSON)),
        'referenceDescription': !exists(json, 'referenceDescription') ? undefined : json['referenceDescription'],
        'sharesCommonElementsWithContiguousBuildings': !exists(json, 'sharesCommonElementsWithContiguousBuildings') ? undefined : json['sharesCommonElementsWithContiguousBuildings'],
    };
}

export function BuildingLocationInfoDTOToJSON(value?: BuildingLocationInfoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': BuildingAddressDTOToJSON(value.address),
        'buildingOccupancyType': value.buildingOccupancyType,
        'buildingPolygon': value.buildingPolygon === undefined ? undefined : ((value.buildingPolygon as Array<any>).map(BuildingPolygonDTOToJSON)),
        'parcelPolygon': value.parcelPolygon === undefined ? undefined : ((value.parcelPolygon as Array<any>).map(PolygonDTOToJSON)),
        'referenceDescription': value.referenceDescription,
        'sharesCommonElementsWithContiguousBuildings': value.sharesCommonElementsWithContiguousBuildings,
    };
}

