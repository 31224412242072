import React from "react";
import { View, Image, StyleSheet, Svg, Circle, Text } from "@react-pdf/renderer";
import imgBackground from "assets/images/book-resume/diagram-background-3d.jpg";
import { InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum, NewAccessibilityEvaluationsDTO, NewInspectionDocumentationDTO } from "clients";
import {
  getFinalAccessibilityValorationIndicatorProperties,
  getPartialValorationIndicatorProperties,
} from "pdf/documents/book/_common/utils/render-indicator-helper";
import { IconCircle } from "pdf/documents/book/resume/components/circle/IconCircle";

interface DeficienciesDiagramProps {
  documentation?: NewInspectionDocumentationDTO;
  accessibilityEvaluation?: NewAccessibilityEvaluationsDTO;
}
export const DeficienciesDiagram: React.FC<DeficienciesDiagramProps> = ({ documentation, accessibilityEvaluation }) => {
  const colors = getColors(documentation, accessibilityEvaluation);
  return (
    <View>
      <View style={styles.container}>
        <Image src={imgBackground} style={styles.imageBackground} />
        <View style={styles.overlay}>
          <DiagramCircle left="52%" top="0%" color={colors.roofsAndCoverings} />
          <DiagramCircle left="25.5%" top="32%" color={colors.verticalEnclosures} />
          <DiagramCircle left="46.5%" top="38%" color={colors.structural} />
          <DiagramCircle left="26.5%" top="80%" color={colors.foundation} />
          <DiagramCircle left="80%" top="55%" color={colors.installations} />
          <IconCircle left="80%" top="25%" icon="accesible" color={colors.accessibilityFinalValuation} />
        </View>
      </View>
      <Legend />
    </View>
  );
};

const DiagramCircle: React.FC<{ left: string; top: string; color: string; id?: string }> = ({ left, top, color }) => {
  return (
    <Svg style={{ ...styles.circle, left, top }}>
      <Circle cx={circleRadius} cy={circleRadius} r={circleRadius} fill={color} fillOpacity="0.5" />
    </Svg>
  );
};

const Legend: React.FC = () => {
  return (
    <View style={styles.legend}>
      {Object.values(InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum).map((result) => {
        const { title, color } = getPartialValorationIndicatorProperties(result);
        return <LegendRow key={result} text={title} color={color} />;
      })}
    </View>
  );
};

const LegendRow: React.FC<{ text: string; color: string }> = ({ text, color }) => {
  return (
    <View style={styles.legendRow}>
      <Text style={styles.legendText}>{text}</Text>
      <Svg style={styles.legendCircle}>
        <Circle cx={circleRadius} cy={circleRadius} r={circleRadius} fill={color} fillOpacity="0.5" />
      </Svg>
    </View>
  );
};

const getColors = (documentation?: NewInspectionDocumentationDTO, accessibilityEvaluation?: NewAccessibilityEvaluationsDTO) => {
  return {
    roofsAndCoverings: getPartialValorationIndicatorProperties(documentation?.roofsAndCoveringsDeficienciesFinalResult).color,
    foundation: getPartialValorationIndicatorProperties(documentation?.foundationDeficienciesFinalResult).color,
    verticalEnclosures: getPartialValorationIndicatorProperties(documentation?.verticalEnclosuresDeficienciesFinalResult).color,
    structural: getPartialValorationIndicatorProperties(documentation?.structuralDeficienciesFinalResult).color,
    installations: getPartialValorationIndicatorProperties(documentation?.commonBuildingFacilitiesDeficienciesFinalResult).color,
    accessibilityFinalValuation: getFinalAccessibilityValorationIndicatorProperties(accessibilityEvaluation?.finalResult).color,
  };
};

const circleSize = 25;
const circleRadius = circleSize / 2;

const styles = StyleSheet.create({
  container: {
    width: "400px",
    alignSelf: "center",
  },
  imageBackground: {
    width: "100%",
    aspectRatio: 1.5,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  circle: {
    position: "absolute",
    width: circleSize,
    height: circleSize,
  },
  legend: {
    marginTop: 20,
    width: "100%",
    alignItems: "flex-start",
  },
  legendRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    justifyContent: "space-between",
  },
  legendText: {
    marginRight: 10,
    width: "50%",
  },
  legendCircle: {
    width: circleSize,
    height: circleSize,
  },
});
