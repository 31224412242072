/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillAddressDTO
 */
export interface BillAddressDTO {
    /**
     * 
     * @type {string}
     * @memberof BillAddressDTO
     */
    billingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof BillAddressDTO
     */
    billingCif?: string;
    /**
     * 
     * @type {string}
     * @memberof BillAddressDTO
     */
    billingLegalName?: string;
    /**
     * 
     * @type {number}
     * @memberof BillAddressDTO
     */
    billingPostalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof BillAddressDTO
     */
    billingProvince?: string;
    /**
     * 
     * @type {string}
     * @memberof BillAddressDTO
     */
    billingTown?: string;
    /**
     * 
     * @type {string}
     * @memberof BillAddressDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BillAddressDTO
     */
    technicianId?: string;
}

export function BillAddressDTOFromJSON(json: any): BillAddressDTO {
    return BillAddressDTOFromJSONTyped(json, false);
}

export function BillAddressDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillAddressDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingAddress': !exists(json, 'billingAddress') ? undefined : json['billingAddress'],
        'billingCif': !exists(json, 'billingCif') ? undefined : json['billingCif'],
        'billingLegalName': !exists(json, 'billingLegalName') ? undefined : json['billingLegalName'],
        'billingPostalCode': !exists(json, 'billingPostalCode') ? undefined : json['billingPostalCode'],
        'billingProvince': !exists(json, 'billingProvince') ? undefined : json['billingProvince'],
        'billingTown': !exists(json, 'billingTown') ? undefined : json['billingTown'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'technicianId': !exists(json, 'technicianId') ? undefined : json['technicianId'],
    };
}

export function BillAddressDTOToJSON(value?: BillAddressDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingAddress': value.billingAddress,
        'billingCif': value.billingCif,
        'billingLegalName': value.billingLegalName,
        'billingPostalCode': value.billingPostalCode,
        'billingProvince': value.billingProvince,
        'billingTown': value.billingTown,
        'id': value.id,
        'technicianId': value.technicianId,
    };
}

