/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewSecondaryUsagesDTO
 */
export interface NewSecondaryUsagesDTO {
    /**
     * 
     * @type {number}
     * @memberof NewSecondaryUsagesDTO
     */
    averageSurface?: number;
    /**
     * 
     * @type {string}
     * @memberof NewSecondaryUsagesDTO
     */
    element?: string;
    /**
     * 
     * @type {number}
     * @memberof NewSecondaryUsagesDTO
     */
    number?: number;
}

export function NewSecondaryUsagesDTOFromJSON(json: any): NewSecondaryUsagesDTO {
    return NewSecondaryUsagesDTOFromJSONTyped(json, false);
}

export function NewSecondaryUsagesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewSecondaryUsagesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'averageSurface': !exists(json, 'averageSurface') ? undefined : json['averageSurface'],
        'element': !exists(json, 'element') ? undefined : json['element'],
        'number': !exists(json, 'number') ? undefined : json['number'],
    };
}

export function NewSecondaryUsagesDTOToJSON(value?: NewSecondaryUsagesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'averageSurface': value.averageSurface,
        'element': value.element,
        'number': value.number,
    };
}

