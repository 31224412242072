/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImminentRiskDTO,
    ImminentRiskDTOFromJSON,
    ImminentRiskDTOFromJSONTyped,
    ImminentRiskDTOToJSON,
    InspectionPrintedDTO,
    InspectionPrintedDTOFromJSON,
    InspectionPrintedDTOFromJSONTyped,
    InspectionPrintedDTOToJSON,
    NewInspectionDocumentationDTO,
    NewInspectionDocumentationDTOFromJSON,
    NewInspectionDocumentationDTOFromJSONTyped,
    NewInspectionDocumentationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewConservationStatusDTO
 */
export interface NewConservationStatusDTO {
    /**
     * 
     * @type {NewInspectionDocumentationDTO}
     * @memberof NewConservationStatusDTO
     */
    documentation?: NewInspectionDocumentationDTO;
    /**
     * 
     * @type {ImminentRiskDTO}
     * @memberof NewConservationStatusDTO
     */
    imminentRisk?: ImminentRiskDTO;
    /**
     * 
     * @type {Array<InspectionPrintedDTO>}
     * @memberof NewConservationStatusDTO
     */
    previousInspections?: Array<InspectionPrintedDTO>;
}

export function NewConservationStatusDTOFromJSON(json: any): NewConservationStatusDTO {
    return NewConservationStatusDTOFromJSONTyped(json, false);
}

export function NewConservationStatusDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewConservationStatusDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentation': !exists(json, 'documentation') ? undefined : NewInspectionDocumentationDTOFromJSON(json['documentation']),
        'imminentRisk': !exists(json, 'imminentRisk') ? undefined : ImminentRiskDTOFromJSON(json['imminentRisk']),
        'previousInspections': !exists(json, 'previousInspections') ? undefined : ((json['previousInspections'] as Array<any>).map(InspectionPrintedDTOFromJSON)),
    };
}

export function NewConservationStatusDTOToJSON(value?: NewConservationStatusDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentation': NewInspectionDocumentationDTOToJSON(value.documentation),
        'imminentRisk': ImminentRiskDTOToJSON(value.imminentRisk),
        'previousInspections': value.previousInspections === undefined ? undefined : ((value.previousInspections as Array<any>).map(InspectionPrintedDTOToJSON)),
    };
}

