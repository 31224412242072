/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillingEntityDTO,
    BillingEntityDTOFromJSON,
    BillingEntityDTOToJSON,
    BillingEntityPageDTO,
    BillingEntityPageDTOFromJSON,
    BillingEntityPageDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface CreateBillingEntityRequest {
    billingEntityDTO?: BillingEntityDTO;
}

export interface DeleteBillingEntityRequest {
    id: string;
}

export interface GetBillingEntityByIdRequest {
    id: string;
}

export interface ListBillingEntityPaginatedRequest {
    limit: number;
    offset: number;
}

export interface UpdateBillingEntityRequest {
    billingEntityDTO?: BillingEntityDTO;
}

/**
 * 
 */
export class BillingEntityApi extends runtime.BaseAPI {

    /**
     * Create BillingEntity object and returns it
     */
    async createBillingEntityRaw(requestParameters: CreateBillingEntityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingEntityDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billing-entity/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillingEntityDTOToJSON(requestParameters.billingEntityDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingEntityDTOFromJSON(jsonValue));
    }

    /**
     * Create BillingEntity object and returns it
     */
    async createBillingEntity(requestParameters: CreateBillingEntityRequest, initOverrides?: RequestInit): Promise<BillingEntityDTO> {
        const response = await this.createBillingEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a BillingEntity object by id
     */
    async deleteBillingEntityRaw(requestParameters: DeleteBillingEntityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBillingEntity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billing-entity/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete a BillingEntity object by id
     */
    async deleteBillingEntity(requestParameters: DeleteBillingEntityRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.deleteBillingEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a BillingEntityDTO object by id.
     * Get a BillingEntityDTO object by id.
     */
    async getBillingEntityByIdRaw(requestParameters: GetBillingEntityByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingEntityDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBillingEntityById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billing-entity/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingEntityDTOFromJSON(jsonValue));
    }

    /**
     * Get a BillingEntityDTO object by id.
     * Get a BillingEntityDTO object by id.
     */
    async getBillingEntityById(requestParameters: GetBillingEntityByIdRequest, initOverrides?: RequestInit): Promise<BillingEntityDTO> {
        const response = await this.getBillingEntityByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the list of all registered BillingEntityPageDTO
     */
    async listBillingEntityPaginatedRaw(requestParameters: ListBillingEntityPaginatedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingEntityPageDTO>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling listBillingEntityPaginated.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling listBillingEntityPaginated.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billing-entity/paginated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingEntityPageDTOFromJSON(jsonValue));
    }

    /**
     * Returns the list of all registered BillingEntityPageDTO
     */
    async listBillingEntityPaginated(requestParameters: ListBillingEntityPaginatedRequest, initOverrides?: RequestInit): Promise<BillingEntityPageDTO> {
        const response = await this.listBillingEntityPaginatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit BillingEntity object and returns it
     */
    async updateBillingEntityRaw(requestParameters: UpdateBillingEntityRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingEntityDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/billing-entity/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BillingEntityDTOToJSON(requestParameters.billingEntityDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingEntityDTOFromJSON(jsonValue));
    }

    /**
     * Edit BillingEntity object and returns it
     */
    async updateBillingEntity(requestParameters: UpdateBillingEntityRequest, initOverrides?: RequestInit): Promise<BillingEntityDTO> {
        const response = await this.updateBillingEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
