import { DialogMovicoders } from "@movicoders/movicoders-components";
import { ConfirmDialogStrings } from "@movicoders/movicoders-components";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import {
  CollegeAdministratorDTO,
  FeeDTO,
  TechnicianDTORoleEnum,
  TechnicianListItemDTO,
  TechnicianListItemDTORoleEnum,
} from "../../../clients";
import TextFieldGrid from "../../../components/text-fields/textfield-grid";
import { Colors } from "../../../constants";
import { addressDTOMock } from "../../../viewmodels/profile/data";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import { useAddress } from "../profile-components/form/form-components/useAddress";
import { ProfileStyle } from "../profile-styles";
import * as RegexHelper from "../../../utils/regexHelper";

export const ProfileUserStructureAdmin: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const classes = ProfileStyle();
  const [technician, setTechnician] = useState<TechnicianListItemDTO>(vm.editUserProfileCollegeAdminProfile!);
  const [selectedFee, setSelectedFee] = useState<FeeDTO | null>(null);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

  const { view: addresshookview } = useAddress(vm, addressDTOMock(vm.editUserProfileCollegeAdminProfile?.address));

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const handleClose = () => {
    setDeleteConfirmDialog(false);
  };

  const handleDeleteProfile = () => {
    vm.deactivateTechnicianProfileUser()
      .then(() => {
        vm.editUserProfile = false;
        setDeleteConfirmDialog(false);
        handleOpenSnackBar(vm.translate("profile.success"), "success");
      })
      .catch(() => {
        handleOpenSnackBar(vm.translate("profile.error"), "error");
      });
  };

  const mainFields = (role?: TechnicianDTORoleEnum | TechnicianListItemDTORoleEnum) => {
    switch (role) {
      case TechnicianDTORoleEnum.Technician:
        return (
          <Grid item xs={12} md={12} className={classes.profile_displayGrid} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
              <TextFieldGrid
                id="profileName"
                title={vm.translate("profile.name")}
                required
                value={technician?.name}
                onChange={(e: any) => {
                  setTechnician({ ...technician, name: e.target.value });
                }}
                style={{ marginRight: 5 }}
                gridTextField={{ xs: 12, md: 4 }}
              />
              <TextFieldGrid
                id="profileSurname"
                title={vm.translate("profile.surname")}
                required
                value={technician?.surname}
                onChange={(e: any) => {
                  setTechnician({ ...technician, surname: e.target.value });
                }}
                gridTextField={{ xs: 12, md: 4 }}
              />
            </Grid>

            <Grid item xs={12} md={12} display="flex" className={classes.profile_displayGrid}>
              <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
                <TextFieldGrid
                  id="profileDni"
                  title={vm.translate("report.block0.nif")}
                  value={technician?.idNumber}
                  error={
                    !(RegexHelper.validCif.test(technician?.idNumber!.toUpperCase()) || RegexHelper.CIF_REGEX.test(technician?.idNumber!.toUpperCase())) ||
                    technician?.idNumber!.length !== 9
                  }
                  onChange={(e: any) => {
                    setTechnician({ ...technician, idNumber: e.target.value });
                  }}
                  style={{ marginRight: 5 }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
                <Grid item style={{ width: "183px", marginTop: "10px" }}>
                <Autocomplete
                    id="profileWork"
                    disablePortal
                    size={"small"}
                    options={vm.parent.fees || []}
                    value={selectedFee}
                    onChange={(event: any, selectedOption: any) => {
                      setSelectedFee(selectedOption);
                    }}
                    getOptionLabel={(option: any) =>option.name}
                    renderInput={(params) => <TextField required label={vm.translate("profile.fare")} {...params} size="small" />}
                  />
                </Grid>
              </Grid>
            </Grid>
            {addresshookview}
          </Grid>
        );
      default:
        return (
          <Grid item xs={12} md={12} display="flex" className={classes.profile_displayGrid}>
            <TextFieldGrid
              id="profileEmail"
              title={vm.translate("profile.email")}
              value={(technician as CollegeAdministratorDTO)?.email}
              onChange={(e: any) => {
                setTechnician({ ...technician, email: e.target.value } as CollegeAdministratorDTO);
              }}
              style={{ marginRight: 5 }}
              gridTextField={{ xs: 12, md: 12 }}
            />
            <TextFieldGrid
              id="colllege"
              title={vm.translate((technician as CollegeAdministratorDTO)?.college?.type ?? "")}
              disabled
              value={(technician as CollegeAdministratorDTO)?.college?.name ?? ""}
              onChange={(value: any) => {}}
              gridTextField={{ xs: 12, md: 12 }}
            />
          </Grid>
        );
    }
  };

  const dialogStrings: ConfirmDialogStrings = {
    confirmText: vm.translate("profile.user.deactivate"),
    cancelText: vm.translate("profile.user.cancel"),
    title: vm.translate("profile.user.deregistration"),
    content: <div>{vm.translate("profile.user.deactivate.confirmation")}</div>,
  };

  return (
    /*eslint no-script-url: "off"*/
    <form action="javascript:void(0);" onSubmit={() => {}}>
      <React.Fragment>
        {mainFields(technician?.role)}

        <hr style={{ width: "100%", border: "1px solid " + Colors.hr }} />
        <DialogMovicoders
          open={deleteConfirmDialog}
          draggable={false}
          dialogConfig={dialogStrings}
          onConfirm={() => {
            handleDeleteProfile();
          }}
          onClose={() => handleClose()}
          closeWithX
          maxWidth={"sm"}
          backgroundColorTitle={Colors.primary}
          fullWidth
        />
      </React.Fragment>
    </form>
  );
});
