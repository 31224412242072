/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeeDTO
 */
export interface FeeDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof FeeDTO
     */
    collegesIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FeeDTO
     */
    creationBase?: number;
    /**
     * 
     * @type {string}
     * @memberof FeeDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof FeeDTO
     */
    maintenanceBase?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeDTO
     */
    minimalMaintenance?: number;
    /**
     * 
     * @type {string}
     * @memberof FeeDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof FeeDTO
     */
    priceHousingMaintenance?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeDTO
     */
    vat?: number;
}

export function FeeDTOFromJSON(json: any): FeeDTO {
    return FeeDTOFromJSONTyped(json, false);
}

export function FeeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'collegesIds': !exists(json, 'collegesIds') ? undefined : json['collegesIds'],
        'creationBase': !exists(json, 'creationBase') ? undefined : json['creationBase'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'maintenanceBase': !exists(json, 'maintenanceBase') ? undefined : json['maintenanceBase'],
        'minimalMaintenance': !exists(json, 'minimalMaintenance') ? undefined : json['minimalMaintenance'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'priceHousingMaintenance': !exists(json, 'priceHousingMaintenance') ? undefined : json['priceHousingMaintenance'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
    };
}

export function FeeDTOToJSON(value?: FeeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'collegesIds': value.collegesIds,
        'creationBase': value.creationBase,
        'id': value.id,
        'maintenanceBase': value.maintenanceBase,
        'minimalMaintenance': value.minimalMaintenance,
        'name': value.name,
        'priceHousingMaintenance': value.priceHousingMaintenance,
        'vat': value.vat,
    };
}

