import React, { useEffect, useState } from "react";
import { Button, ConfirmDialogStrings, DialogMovicoders, Icon, IconButton, IconsList } from "@movicoders/movicoders-components";
import {
  Autocomplete,
  Button as ButtonMUI,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import {
  CollegeAdministratorDTO,
  CollegiateDTO,
  ExtendedSignupTechnician,
  ExtendedSignupTechnicianRoleEnum,
  TechnicianDTO,
  TechnicianListItemDTO,
} from "../../../clients";
import TextFieldGrid from "../../../components/text-fields/textfield-grid";
import { Colors } from "../../../constants";
import * as RegexHelper from "../../../utils/regexHelper";
import { addressDTOMock } from "../../../viewmodels/profile/data";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import { useAddress } from "../profile-components/form/form-components/useAddress";
import { ProfileStyle } from "../profile-styles";
import { ReportStyle } from "../../reports/report-style";

export const ProfileUserStructureSystemAdmin: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const classesReport = ReportStyle();
  const classes = ProfileStyle();
  const [technician, setTechnician] = useState<ExtendedSignupTechnician | null>(vm.technicianProfileAdminEdit);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [backup] = useState<ExtendedSignupTechnician | null>(vm.technicianProfileAdminEdit);
  const [openGiftDialog, setOpenGiftDialog] = useState(false);

  const [collegeSelected, setCollegeSelected] = useState<CollegiateDTO>();
  const [acceptCollegiated] = useState<boolean>(false);
  const vmRoot = vm.parent;

  const { view: addresshookview, address } = useAddress(vm, addressDTOMock(vm.technicianProfileAdminEdit?.address));

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const save = async () => {
    vm.loadingBook = true;
    const technician_ = {
      ...technician,
      address,
      collegiated: technician?.collegiated?.map((collegiated) => ({ college: collegiated.college, number: collegiated.number })),
    } as TechnicianDTO;
    vm.editProfileUserAsAdmin(technician_!! as TechnicianDTO)
      .then(() => {
        handleOpenSnackBar(vm.translate("profile.success"), "success");
        const found = vm.items.find((e) => e.technicianId === (backup?.id || ""));
        const merged = { ...found, ...technician_ };
        vm.items.splice(vm.items.indexOf(found as TechnicianListItemDTO), 1, merged as TechnicianListItemDTO);
        vm.loadingBook = false;
      })
      .catch(() => {
        handleOpenSnackBar(vm.translate("profile.error"), "error");
        vm.loadingBook = false;
      })
      .finally(() => {
        vm.editUserProfileCollegeAdmin = false;
        vm.editUserProfileCollegeAdminProfile = null;
        vm.technicianProfileAdminEdit = null;
        vm.loadingBook = false;
      });
  };

  const handleClose = () => {
    setDeleteConfirmDialog(false);
  };

  const handleDeleteProfile = () => {
    vm.deactivateTechnicianProfileUser()
      .then(() => {
        vm.editUserProfile = false;
        setDeleteConfirmDialog(false);
        handleOpenSnackBar(vm.translate("profile.success"), "success");
      })
      .catch(() => {
        setOpenGiftDialog(false);
        handleOpenSnackBar(vm.translate("profile.error"), "error");
      });
  };

  useEffect(() => {
    (async () => {
      try {
        if (vmRoot.fees === undefined) {
          vmRoot.fees = await vmRoot.feeRepository.getAllFee();
        }
        if (vmRoot.colleges === undefined) {
          vmRoot.colleges = await vmRoot.collegeRepository.getAllCollege({ limit: 0, offset: 0 });
        }
      } catch (error) {
        handleOpenSnackBar(vm.translate("error"), "error");
      }
    })();
  }, []);

  const mainFields = (role?: ExtendedSignupTechnicianRoleEnum) => {
    switch (role) {
      case ExtendedSignupTechnicianRoleEnum.Technician:
        return (
          <Grid item xs={12} md={12} className={classes.profile_displayGrid} justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
              <TextFieldGrid
                id="profileName"
                title={vm.translate("profile.name")}
                required
                value={technician?.name}
                onChange={(e: any) => {
                  setTechnician({ ...technician, name: e.target.value });
                }}
                style={{ marginRight: 5 }}
                gridTextField={{ xs: 12, md: 4 }}
              />
              <TextFieldGrid
                id="profileSurname"
                title={vm.translate("profile.surname")}
                required
                value={technician?.surname}
                onChange={(e: any) => {
                  setTechnician({ ...technician, surname: e.target.value });
                }}
                gridTextField={{ xs: 12, md: 4 }}
              />
              <TextFieldGrid
                id="profilePhone"
                title={vm.translate("profile.phone")}
                value={technician?.phone}
                onChange={(e: any) => {
                  setTechnician({ ...technician, phone: e.target.value });
                }}
                gridTextField={{ xs: 12, md: 4 }}
              />
              <TextFieldGrid
                id="profilePhone2"
                title={vm.translate("profile.phone2")}
                required
                value={technician?.mobilePhone}
                onChange={(e: any) => {
                  setTechnician({ ...technician, mobilePhone: e.target.value });
                }}
                gridTextField={{ xs: 12, md: 4 }}
              />
            </Grid>

            <Grid item xs={12} md={12} display="flex" className={classes.profile_displayGrid}>
              <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
                <TextFieldGrid
                  id="profileDni"
                  title={vm.translate("report.block0.nif")}
                  value={technician?.idNumber}
                  error={
                    !(RegexHelper.validCif.test(technician!.idNumber!.toUpperCase()) || RegexHelper.CIF_REGEX.test(technician!.idNumber!.toUpperCase())) ||
                    technician?.idNumber!.length !== 9
                  }
                  onChange={(e: any) => {
                    setTechnician({ ...technician, idNumber: e.target.value });
                  }}
                  style={{ marginRight: 5 }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
                <TextFieldGrid
                  id="profileEmail"
                  title={vm.translate("profile.email")}
                  value={technician?.email}
                  onChange={(e: any) => {
                    setTechnician({ ...technician, email: e.target.value });
                  }}
                  style={{ marginRight: 5 }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
                <Tooltip title="Correspondiente al primer colegio de la lista de colegios" arrow>
                  <div>
                    <TextFieldGrid
                      id="profileWork"
                      title={vm.translate("profile.work")}
                      disabled
                      value={vm.translate("technician.participation.profesion." + technician?.collegiated![0]?.college?.type || "")}
                      onChange={(e: any) => {}}
                      gridTextField={{ xs: 6, md: 6 }}
                    />
                  </div>
                </Tooltip>

                <Grid item style={{ width: "183px", marginTop: "10px" }}>
                  <Autocomplete
                    id="profileWork"
                    disablePortal
                    size={"small"}
                    options={vm.parent.fees || []}
                    value={technician?.fee ?? null}
                    onChange={(event: any, selectedOption: any) => {
                      setTechnician({ ...technician, fee: selectedOption });
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params) => <TextField required label={vm.translate("profile.fare")} {...params} size="small" />}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Address */}
            {addresshookview}

            <Grid item xs={12} md={12}>
              <Paper style={{ padding: 20, marginTop: "10px" }} sx={{ width: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ width: "100%" }}
                  className={`${classesReport.report_generalDataGrid} ${classesReport.report_textFieldGrid}`}
                >
                  <Grid item xs={12} md={12} display="flex" width={"100%"}>
                    <Grid item xs={8} md={8} width={"100%"} marginRight={1}>
                      <Autocomplete
                        disablePortal
                        id="registerFormCollegesAutocomplete"
                        options={vm.parent.colleges || []}
                        fullWidth
                        clearIcon={undefined}
                        disabled={acceptCollegiated}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={(params) => (
                          <TextField className={classesReport.report_autocomplete} {...params} size="small" label={vm.translate("report.block0.college")} />
                        )}
                        onChange={(event: any, value: any) => {
                          setCollegeSelected({
                            college: value,
                            number: "",
                          });
                        }}
                      />
                    </Grid>
                    <TextFieldGrid
                      id="registerFormCollegeSelectedNumber"
                      title={vm.translate("launch.number")}
                      value={collegeSelected?.number}
                      gridClassName={""}
                      form="registerform"
                      style={{ marginTop: "0px !important", marginLeft: 5 }}
                      onChange={(value: any) => {
                        setCollegeSelected({
                          ...collegeSelected,
                          number: value.target.value,
                        });
                      }}
                      gridTextField={{ xs: 3, md: 3 }}
                    />
                    <Grid item xs={1} md={1} display="flex" justifyContent="end" alignItems={"center"}>
                      <Button
                        id="registerFormAddCollege"
                        text={vm.translate("add")}
                        disabled={collegeSelected?.college && collegeSelected?.number ? false : true}
                        textColor={Colors.white}
                        backgroundColor={!acceptCollegiated ? Colors.buttonPrimary : Colors.error}
                        hoverColor={Colors.buttonPrimary}
                        onClick={() => {
                          setTechnician((prevTechnician) => {
                            prevTechnician?.collegiated?.push(collegeSelected!!);
                            return { ...prevTechnician, mainCollege: prevTechnician?.collegiated?.at(0)?.college?.id };
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ marginTop: 10 }} />
                {technician?.collegiated?.length !== 0 && (
                  <Grid item xs={12} md={12}>
                    <TableContainer id="registerFormTableContainer" component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{vm.translate("launch.name.college")}</TableCell>
                            <TableCell>{vm.translate("launch.college.type")}</TableCell>
                            <TableCell align="right">{vm.translate("launch.college.number")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {technician?.collegiated?.map((row, index) => {
                            return (
                              <TableRow
                                key={row.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                {/** Review the style of this th/header */}
                                <TableCell style={{ fontWeight: "bold" }}>{row.college!!.name}</TableCell>
                                <TableCell>{vm.translate(row.college!!.type!!)}</TableCell>
                                <TableCell align="right">{row.number}</TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    backgroundColor="transparent"
                                    onClick={() =>
                                      setTechnician((prevTechnician) => {
                                        prevTechnician?.collegiated!!.splice(
                                          prevTechnician?.collegiated!!.indexOf(prevTechnician?.collegiated!![index]),
                                          1
                                        );
                                        return { ...prevTechnician, mainCollege: prevTechnician?.collegiated?.at(0)?.college?.id };
                                      })
                                    }
                                    icon={<Icon color={Colors.error} element={IconsList.Clear} />}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid item xs={12} md={12} display="flex" className={classes.profile_displayGrid}>
            <TextFieldGrid
              id="profileEmail"
              title={vm.translate("profile.email")}
              value={(technician as CollegeAdministratorDTO)?.email}
              onChange={(e: any) => {
                setTechnician({ ...technician, email: e.target.value } as CollegeAdministratorDTO);
              }}
              style={{ marginRight: 5 }}
              gridTextField={{ xs: 12, md: 12 }}
            />
            <TextFieldGrid
              id="colllege"
              title={vm.translate((technician as CollegeAdministratorDTO)?.college?.type ?? "")}
              disabled
              value={(technician as CollegeAdministratorDTO)?.college?.name ?? ""}
              onChange={(value: any) => {}}
              gridTextField={{ xs: 12, md: 12 }}
            />
          </Grid>
        );
    }
  };

  const dialogStrings: ConfirmDialogStrings = {
    confirmText: vm.translate("profile.user.deactivate"),
    cancelText: vm.translate("profile.user.cancel"),
    title: vm.translate("profile.user.deregistration"),
    content: <div>{vm.translate("profile.user.deactivate.confirmation")}</div>,
  };

  const handleCreateVoucher = () => {
    if (technician?.credentials)
      vm.createFreeVoucherByID(technician?.credentials)
        .then(() => {
          setOpenGiftDialog(false);
          handleOpenSnackBar(vm.translate("voucher.created"), "success");
        })
        .catch(() => {
          setOpenGiftDialog(false);
          handleOpenSnackBar(vm.translate("voucher.error"), "error");
        });
  };

  return (
    /*eslint no-script-url: "off"*/
    <form
      action="javascript:void(0);"
      onSubmit={() => {
        save();
      }}
    >
      <React.Fragment>
        {mainFields(technician?.role)}

        <hr style={{ width: "100%", border: "1px solid " + Colors.hr }} />
        <Grid container item xs={12} className={classes.profile_buttonSave} justifyContent="space-between">
          <Button
            id="profileUserStructureSaveButton"
            type="submit"
            text={vm.translate("profile.button.save")}
            textColor={Colors.white}
            disabled={
              (RegexHelper.validCif.test(technician!.idNumber!.toUpperCase()) || RegexHelper.CIF_REGEX.test(technician!.idNumber!.toUpperCase())) &&
              technician?.idNumber!.length === 9
                ? false
                : true
            }
            backgroundColor={Colors.buttonSecondary}
            hoverColor={Colors.buttonSecondary}
            onClick={() => {}}
          />
          <Button
            id="profileUserStructureGiveToken"
            text={vm.translate("generate.free.voucher")}
            textColor={Colors.white}
            backgroundColor={Colors.primary}
            hoverColor={Colors.buttonSecondary}
            onClick={() => setOpenGiftDialog(true)}
          />
        </Grid>
        <DialogMovicoders
          open={deleteConfirmDialog}
          draggable={false}
          dialogConfig={dialogStrings}
          onConfirm={() => {
            handleDeleteProfile();
          }}
          onClose={() => handleClose()}
          closeWithX
          maxWidth={"sm"}
          backgroundColorTitle={Colors.primary}
          fullWidth
        />
        {openGiftDialog ? (
          <>
            <Dialog open={true} onClose={() => setOpenGiftDialog(false)}>
              <DialogTitle style={{ background: Colors.buttonPrimary, color: Colors.white }} id="alert-dialog-title">
                {vm.translate("profile.user.admin_gift_voucher_text.title")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{vm.translate("profile.user.admin_gift_voucher_text")}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <ButtonMUI onClick={() => setOpenGiftDialog(false)} style={{ background: Colors.primary, color: Colors.white }}>
                  {vm.translate("delete.dialog.cancel")}
                </ButtonMUI>
                {/* @ts-ignore */}
                <ButtonMUI onClick={handleCreateVoucher} autoFocus style={{ background: Colors.primary, color: Colors.white }}>
                  {vm.translate("accept")}
                </ButtonMUI>
              </DialogActions>
            </Dialog>
          </>
        ) : null}
      </React.Fragment>
    </form>
  );
});
