import React from "react";
import { EvaluationPage } from "./pages/evaluation-page";
import { DeficiencyPage } from "./pages/deficiency-page";
import { NewAccessibilityEvaluationsDTO, NewConservationStatusDTO } from "clients";
import { FinalEvaluationPage } from "./pages/final-evaluation-page";

interface SummaryProps {
  conservationStatus?: NewConservationStatusDTO;
  accessibilityEvaluation?: NewAccessibilityEvaluationsDTO;
}

export const Summary: React.FC<SummaryProps> = ({ conservationStatus, accessibilityEvaluation }) => {
  return (
    <>
      <DeficiencyPage conservationStatus={conservationStatus} />
      <EvaluationPage conservationStatus={conservationStatus} />
      <FinalEvaluationPage conservationStatus={conservationStatus} accessibilityEvaluation={accessibilityEvaluation} />
    </>
  );
};
