import { Avatar, Button, Icon, IconsList, TypographyTheme } from "@movicoders/movicoders-components";
import { Box, Grid, Paper } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import movicodersLogo from "../../../assets/images/logoMovicoders.png";
import { Colors, FontSizes } from "../../../constants";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import { ProfileStyle } from "../profile-styles";
import { getMainCollegeName } from "utils/colleges-helper";

export const ProfileAvatar: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const classes = ProfileStyle();
  useEffect(() => {
    vm.getLastPendingVoucher();
  }, []);

  return (
    <Grid my={1}>
      <Paper elevation={3} className={classes.profile_paperUser}>
        <Avatar id="profileAvatar" height={"60px"} width={"60px"} src={movicodersLogo} />
        <TypographyTheme
          id="profileUserName"
          text={vm.profileUser ? vm.profileUser.name + " " + vm.profileUser.surname : ""}
          color={Colors.textPrimaryHeaders}
          bold
          size={FontSizes.h1}
        />
        <Box sx={{ paddingLeft: "14px" }}>
          <TypographyTheme
            id="profileUserCollegiated"
            text={vm.profileUser ? getMainCollegeName(vm.profileUser.mainCollege, vm.profileUser!.collegiated) : ""}
            color={Colors.textPrimary}
            size={FontSizes.h4}
          />
        </Box>
        <hr style={{ width: "100%", border: "1px dashed " + Colors.hr }} />
        <Button
          id="profileAvatarButtonUpdate"
          text={vm.translate("profile.button.update")}
          width={"80%"}
          textColor={Colors.white}
          backgroundColor={Colors.buttonPrimary}
          hoverColor={Colors.buttonPrimary}
          variant={"outlined"}
          classButton={classes.profile_button}
          style={{ paddingLeft: 0, alignContent:"start"}}
          icon={<Icon element={IconsList.AccountCircle} />}
          iconPosition="start"
          onClick={() => {
            vm.editUserProfile = true;
          }}
        />
        {vm.lastVoucher && (
          <Button
            id="profileAvatarButtonVoucherBill"
            text={vm.translate("profile.button.voucher.bill")}
            width={"80%"}
            textColor={Colors.white}
            backgroundColor={Colors.buttonHighlighted}
            hoverColor={Colors.buttonHighlighted}
            style={{ marginTop: 5, marginBottom: 5, textAlign:"start"}}
            icon={<Icon element={IconsList.Description} />}
            iconPosition="start"
            onClick={() => vm.selectedTransactionId = vm.lastVoucher}
          />
        )}
      </Paper>
    </Grid>
  );
});
