import React from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress } from "@mui/material";
import { t } from "i18next";

interface PreviewDialogProps {
  title?: string;
  document: React.ReactElement;
  fileName?: string;
  openState: boolean;
  isLoading?: boolean;
  showDownloadButton?: boolean;
  handleClose: () => void;
}

export const PreviewDialog: React.FC<PreviewDialogProps> = ({
  fileName,
  title,
  document,
  openState,
  isLoading = false,
  showDownloadButton = false,
  handleClose,
}) => {
  return (
    <Dialog open={openState} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>{title || t("pdf.viewer.preview")}</DialogTitle>
      <DialogContent dividers style={{ height: "90vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {isLoading && <CircularProgress />}
        <div style={{ display: isLoading ? "none" : "block", width: "100%", height: "98%" }}>
          <PDFViewer width="100%" height="100%">
            {document}
          </PDFViewer>
        </div>
      </DialogContent>
      <DialogActions>
        {showDownloadButton && <DownloadButton document={document} fileName={fileName} />}
        <Button onClick={handleClose} color="primary">
          {t("pdf.viewer.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DownloadButton: React.FC<{ document: React.ReactElement; fileName?: string }> = ({ document, fileName }) => (
  <PDFDownloadLink document={document} fileName={`${fileName ?? "document"}.pdf`}>
    {({ blob, url, loading, error }) => (
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#0d2b52",
          "&:hover": {
            backgroundColor: "#0a1e3a",
          },
        }}
        disabled={loading}
      >
        {loading ? t("pdf.viewer.loadingDocument") : t("pdf.viewer.downloadPDF")}
      </Button>
    )}
  </PDFDownloadLink>
);
