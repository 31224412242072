
export const checkboxDefinitions = {
    termic: {
      0: "pdf.block2.improvements.intervention.termic1",
      1: "pdf.block2.improvements.intervention.termic2",
      2: "pdf.block2.improvements.intervention.termic3",
      3: "pdf.block2.improvements.intervention.termic4",
      4: "pdf.block2.improvements.intervention.termic5",
      5: "pdf.block2.improvements.intervention.otherSolutions",
    },
    instalation: {
      6: "pdf.block2.improvements.intervention.instalations1",
      7: "pdf.block2.improvements.intervention.instalations2",
      8: "pdf.block2.improvements.intervention.instalations3",
      9: "pdf.block2.improvements.intervention.instalations4",
      10: "pdf.block2.improvements.intervention.instalations5",
      11: "pdf.block2.improvements.intervention.instalations6",
      12: "pdf.block2.improvements.intervention.otherSolutions",
    },
    others: {
      13: "pdf.block2.improvements.intervention1",
      14: "pdf.block2.improvements.intervention2",
      15: "pdf.block2.improvements.intervention3",
      16: "pdf.block2.improvements.intervention4",
      17: "pdf.block2.improvements.intervention5",
    },
  };
  