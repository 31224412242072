/* eslint-disable react-hooks/exhaustive-deps */
import Typography from "@material-ui/core/Typography";
import { ConfirmDialogStrings, DialogMovicoders, Icon, IconButton, IconsList, Popover, TypographyTheme } from "@movicoders/movicoders-components";
import { Backdrop, CircularProgress, FormControlLabel, Grid, Switch, Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import {
  EnergyEfficiencyImprovementPrintedDTO,
  FireSafetyImprovementPrintedDTO,
  HealthinessImprovementPrintedDTO,
  ImprovementActionPrintedDTO,
  MediaDTO,
  MediaPrintedDTO,
  NewPrintingBookDTO,
  NewUsageDTO,
  NoiseImprovementPrintedDTO,
  OtherImprovementPrintedDTO,
  SecurityImprovementPrintedDTO,
  UsageDTO,
  ViewAdminTechnicianParticipationDTO,
} from "../../../clients";
import BackDropLoading from "../../../components/backdrop-loading";
import { Colors } from "../../../constants";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import ReportViewModel from "../../../viewmodels/report/report-view-model";
import { BasicTable } from "../components/table";
import NoticeDialog from "../notice-pdf";
import { PDF_STATUS } from "../system-admin/pdf-worker";
import { useDownloadZips } from "../system-admin/useDownloadZips";
import { PreviewDialog } from "../../../pdf/views/preview-dialog";
import { BillGenerator } from "pdf/documents/bill/bill-generator";
import { ResumeGenerator } from "pdf/documents/book/resume/resume-generator";

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const ProfileTable: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState(0);
  const [showDeleteBook, setShowDeleteBook] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [bookToDelete, setBookToDelete] = useState<string | null>(null);
  const [showNotice, setShowNotice] = useState<{ show: boolean; row: any }>({ show: false, row: null });
  const [loadingResume, setLoadingResume] = useState(false);
  const [resumeBookId, setResumeBookId] = useState<string | null>(null);
  const pdfworker = useMemo(() => new Worker(new URL("../system-admin/pdf-worker", import.meta.url)), []);
  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 8000 });
  };

  useEffect(() => {
    if (vm.profileTableServerSideFilter) (vm.paginationObject?.number ?? 1) === 0 && setPage(0);
  }, [vm.paginationObject?.number]);

  const handleViewBill = (row: any) => {
    vm.selectedTransactionId = row.transactionId;
  };

  const handleClosePreview = () => {
    vm.selectedTransactionId = null;
  };

  const handleViewResume = (row: any) => {
    setResumeBookId(row.id);
  };

  const handleCloseResumePreview = () => {
    setResumeBookId(null);
  };

 const optionPopover = (params: any) => {
    const content = [
        {
            id: "editBookButtonPopover",
            label: vm.translate("profile.edit.book"),
            action: async () => {
                await handleEditBook(params.row.id);
            },
        },
        {
            id: "generatePDFButtonPopover",
            label: vm.translate("profile.download.new"),
            action: async () => {
                setShowNotice({ show: true, row: params.row });
                // download new pdf without notice
                // await handleNewDownloadBook(params.row);
            },
        },
        {
          id: "generateResumeButtonPopover",
          label: vm.translate("Descargar Resumen"),
          action: async () => {
            handleViewResume(params.row);
        },
      },
    ];

    if (params.row.transactionId) {
        content.push({
            id: "viewBillButtonPopover",
            label: "Ver Factura",
            action: async () => {
                handleViewBill(params.row);
            },
        });
    }

    return (
        <Popover
            content={content}
            iconButton={{
                id: params.row.id,
                icon: <Icon element={IconsList.MoreHoriz} />,
                backgroundColor: "transparent",
                color: "black",
            }}
        />
    );
};


  const pdfworkerOnMessage = (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data);
      if (data.status === PDF_STATUS.DONE) {
        const a = document.createElement("a");
        a.href = data.blob;
        a.target = "_blank";
        a.download = `${vm.bookCadastralParcel}.pdf`;
        a.click();
        URL.revokeObjectURL(data.blob);
        a.remove();
        setLoadingPDF(false);
      }
    } catch (e) {
      console.error("Error handling message from worker:", e);
    }
  };

  const handleEditBook = async (id: string) => {
    await vm.editBook(id);
    if (vm.parent.selectedBook) {
      const report = vm.parent.children.find((x) => x.navigationName === "report")! as ReportViewModel;
      report.resetStates();
      vm.parent.editingBook = true;
      vm.parent.tryActivateChild(report);
      report.openDetail(vm.parent.selectedBook);
    } else {
      handleOpenSnackBar(vm.translate("profile.edit.error"), "error");
    }
  };

  const usageDTOtoNewUsageDTO = (usage: UsageDTO): NewUsageDTO => ({
    cadastralLocation: { block: usage.block, door: usage.door, floor: usage.floor, stair: usage.stair },
    cadastralReference: usage.cadastralReference,
    cadastralSurface: usage.surface + "",
    cadastralUse: usage.description,
  });

  const checkBookUsages = (printingBook: NewPrintingBookDTO): NewPrintingBookDTO => {
    if (printingBook?.generalData?.buildingIdentification?.generalDataReferences?.length) return printingBook;
    const usages = vm.parent.selectedBook?.cadastralInfo?.properties?.flatMap((property) => property.usages?.map(usageDTOtoNewUsageDTO)).flat();
    printingBook.generalData!.buildingIdentification!.generalDataReferences = usages as unknown as NewUsageDTO[];
    return printingBook;
  };

  const transformIntoFiles = async (media: MediaDTO) => {
    if (media?.id === null || media?.id === undefined) return null;
    try {
      const multimedia = await (vm.parent.children?.find((a) => a.navigationName === "report") as ReportViewModel).getMultimediaById(media.id);
      const result = await fetch(multimedia?.url ?? "")
        .then((res) => res.blob())
        .catch((e) => new File([], "noname.txt"))
        .then((blob) => {
          return { backupUrl: multimedia.url ?? "no_url", file: new File([blob], multimedia?.name! ?? "noname.txt") };
        });
      return result;
    } catch (error) {
      console.log("error getting multimedia", error);
      return null;
    }
  };

  useEffect(() => {
    pdfworker.onmessage = pdfworkerOnMessage;

    return () => {
      pdfworker.terminate();
    };
  }, [pdfworker]);

  const handleNewDownloadBook = async (row: any) => {
    setLoadingPDF(true);
    await sleep(1000);
    await vm.editBookNewPdf(row.id);
    let printingBook = await vm.bookRepository.getNewPrintingBookById({ id: row.id }).catch(() => null);
    if (printingBook) {
      printingBook = checkBookUsages(printingBook);
      const medias = [...(printingBook.generalData?.images ?? [])];
      printingBook.summary?.inspectionShortcomingsAttachments?.forEach((a) => medias.push(a));

      printingBook.firstBlock?.buildingInspection?.documentation?.conservationStatus?.previousInspections
        ?.map((a) => a.multimedias)
        .flat()
        .forEach((a) => a && medias.push(a));

      printingBook.firstBlock?.buildingInspection?.accessibilityEvaluations
        ?.map((a) => a.media)
        .flat()
        .forEach((a) => a && medias.push(a));

      printingBook.firstBlock?.useAndMaintenance?.actionRegistries?.forEach((a) => {
        if (a.extension?.toLocaleLowerCase() === "jpg" || a.extension?.toLocaleLowerCase() === "jpeg" || a.extension?.toLocaleLowerCase() === "png")
          medias.push(a);
      });
      printingBook.firstBlock?.useAndMaintenance?.bestPractices?.forEach((a) => {
        if (a.extension?.toLocaleLowerCase() === "jpg" || a.extension?.toLocaleLowerCase() === "jpeg" || a.extension?.toLocaleLowerCase() === "png")
          medias.push(a);
      });
      printingBook.firstBlock?.useAndMaintenance?.contracts?.forEach((a) => {
        if (a.extension?.toLocaleLowerCase() === "jpg" || a.extension?.toLocaleLowerCase() === "jpeg" || a.extension?.toLocaleLowerCase() === "png")
          medias.push(a);
      });

      let lastImprovement = printingBook?.secondBlock?.improvementActions?.at(-1);
      const improvementsMultimedias = lastImprovement?.improvements
        ?.map((a) => ({
          media: a?.files?.at(-1),
          improvementId: a.id,
        }))
        .filter((a) => a.media !== undefined);
      const improvementsImageMedias: any[] = [];
      if (improvementsMultimedias) {
        for await (const impr of improvementsMultimedias) {
          if (!impr.media) return;
          const imageMedia = await transformIntoFiles(impr.media);
          if (imageMedia) {
            improvementsImageMedias.push({ ...impr, imageMedia });
          }
        }
      }
      if (lastImprovement) pushMedias(lastImprovement, medias);

      printingBook.firstBlock?.energyEfficiencyCertificates?.[printingBook.firstBlock?.energyEfficiencyCertificates?.length - 1]?.multimedias?.forEach(
        (a) => medias.push(a)
      );

      printingBook.firstBlock?.energyEfficiencyCertificates?.[printingBook.firstBlock?.energyEfficiencyCertificates?.length - 1]?.certificateImage &&
        medias.push(
          printingBook.firstBlock?.energyEfficiencyCertificates[printingBook.firstBlock?.energyEfficiencyCertificates?.length - 1].certificateImage!
        );
      printingBook.firstBlock?.finalEnergyEfficiencyCertificate?.multimedias?.forEach((a) => medias.push(a));

      printingBook?.firstBlock?.finalEnergyEfficiencyCertificate?.certificateImage &&
        medias.push(printingBook.firstBlock?.finalEnergyEfficiencyCertificate.certificateImage);
      printingBook?.firstBlock?.initialEnergyEfficiencyCertificate?.multimedias?.forEach((a) => medias.push(a));

      printingBook?.firstBlock?.initialEnergyEfficiencyCertificate?.certificateImage &&
        medias.push(printingBook.firstBlock?.initialEnergyEfficiencyCertificate.certificateImage);

      const uniqueMedias = Array.from(new Set(medias?.map((a) => a?.url)))
        .map((url) => {
          return medias?.find((a) => a?.url === url);
        })
        .filter(Boolean);

      await (vm.parent.children?.find((a) => a.navigationName === "report") as ReportViewModel)
        .transformIntoFileAndReduceWeight((uniqueMedias as MediaPrintedDTO[]) ?? [])
        .then((compressedImages) => {
          compressedImages = [...compressedImages, ...improvementsImageMedias]?.filter((i) => i !== null) ?? [];
          vm.bookCadastralParcel = vm.parent.selectedBook?.code?.split(".")?.[3] ?? "none";
          if (printingBook !== undefined && printingBook !== null) {
            const { createZips } = useDownloadZips({ vm, printingBook });
            createZips();
            const req = {
              compressedImages,
              printingBook,
              status: PDF_STATUS.IN_PROGRESS,
            };
            pdfworker.postMessage(JSON.stringify(req));
          } else {
            handleOpenSnackBar(vm.translate("profile.download.error"), "error");
            setLoadingPDF(false);
          }
        })
        .catch((e) => {
          console.log("error compressing images", e);
        });
    } else {
      handleOpenSnackBar(vm.translate("profile.download.error"), "error");
      setLoadingPDF(false);
    }
  };

  const handleDeleteBook = async () => {
    try {
      if (bookToDelete) {
        vm.loadingBook = true;
        await vm.bookRepository.deleteBook(bookToDelete);
        await vm.searchProfileBooks(vm.searchOptions);
        handleOpenSnackBar(vm.translate("profile.book.delete.success"), "success");
      } else {
        handleOpenSnackBar(vm.translate("profile.book.delete.error"), "error");
      }
    } catch (e) {
      handleOpenSnackBar(vm.translate("profile.book.delete.error"), "error");
      setShowDeleteBook(false);
      vm.loadingBook = false;
    }
    setShowDeleteBook(false);
    vm.loadingBook = false;
  };

  const dialogStrings: ConfirmDialogStrings = {
    confirmText: vm.translate("accept"),
    cancelText: vm.translate("cancel"),
    title: vm.translate("profile.delete.book"),
    content: <div>{vm.translate("profile.table.book.delete.confirmation")}</div>,
  };

  useEffect(() => {
    setTimeout(() => {
      setTimeoutReached(true);
    }, 10000);
  }, []);

  const changeMode = () => {
    setDeleteMode(!deleteMode);
  };
  return (
    <React.Fragment>
      <BackDropLoading showLoading={vm.technicianParticipation.length === 0 && !timeoutReached} />
      <BackDropLoading showLoading={vm.loadingBook} />
      <Backdrop style={{ zIndex: 1000000, color: "#3f51b5" }} open={loadingPDF}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ backgroundColor: "rgba(255,255,255,0.75)", padding: 10, width: "30%", minWidth: 300, borderRadius: 2 }}
          pt={5}
          pb={5}
        >
          <TypographyTheme variant="h4" color="primary" text={vm.translate("profile.download.generating")} />
          <br />
          <TypographyTheme variant="h6" color="primary" text={vm.translate("profile.download.generating2")} />
          <br />
          <CircularProgress variant="indeterminate" disableShrink color="inherit" />
        </Grid>
      </Backdrop>

      <Grid flex={1} my={1} minWidth={300}>
        {deleteMode
          ? vm.technicianParticipation.length !== 0 && (
            <BasicTable
              page={page}
              columns={[
                {
                  headerAlign: "left",
                  align: "left",
                  field: "cadastralparcelreference",
                  hideSortIcons: true,
                  disableColumnMenu: true,
                  headerName: vm.translate("report.block0.cadastral.reference"),
                  renderCell: (params) => <div>{params.row.cadastralparcelreference}</div>,
                  flex: 0.5,
                },
                {
                  headerAlign: "left",
                  align: "left",
                  field: "streetName",
                  hideSortIcons: true,
                  disableColumnMenu: true,
                  headerName: vm.translate("profile.address"),
                  renderCell: (params) => {
                    return (
                      <div>{`${params.row.streetType} ${params.row.streetName} Nº ${params.row.portalNumber} ${params.row.portalNumberLetter ? params.row.portalNumberLetter : ""
                        } ${params.row.postalCode} ${params.row.province}`}</div>
                    );
                  },
                  flex: 1,
                },
                {
                  headerAlign: "left",
                  align: "right",
                  field: "created",
                  hideSortIcons: true,
                  disableColumnMenu: true,
                  headerName: vm.translate("profile.created"),
                  renderCell: (params) => {
                    var date = new Date(params.row.created).toLocaleDateString("es-ES") ?? "-";
                    return <div>{date}</div>;
                  },
                  flex: 0.2,
                },
                {
                  headerAlign: "left",
                  align: "left",
                  field: "",
                  hideSortIcons: true,
                  headerName: "",
                  flex: 0.06,
                  disableColumnMenu: true,
                  disableReorder: true,
                  sortable: false,
                  renderHeader: () => (
                    <Tooltip title="Modo borrar">
                      <FormControlLabel checked={deleteMode} onChange={changeMode} label={""} control={<Switch color="primary" />} />
                    </Tooltip>
                  ),
                  renderCell: (params) => (
                    <IconButton
                      id="paperGridExampleButton"
                      idToolTip="toolTipExampleButton"
                      backgroundColor="transparent"
                      onClick={() => {
                        setBookToDelete(params.row.id);
                        setShowDeleteBook(true);
                      }}
                      icon={<Icon color={"black"} element={IconsList.DeleteForever} />}
                    />
                  ),
                },
              ]}
              data={
                vm.technicianParticipation
                  ?.map((a) => ({ id: a.bookId, ...a }))
                  ?.sort((a: ViewAdminTechnicianParticipationDTO, b: ViewAdminTechnicianParticipationDTO) => (b?.created ?? 1) - (a?.created ?? 0)) ?? []
              }
              pageSize={pageSize}
              setPageSize={setPageSize}
              headerColor={Colors.primary}
              rowCount={vm.paginationObject?.totalElements!!}
              vm={vm}
              handlePageChange={(page: number) => {
                setPage(page);
              }}
              setPage={setPage}
              title={vm.translate("profile.table.lee.historical")}
              disableCheckboxes
              locale={vm.parent.configurationService.language}
            />
          )
          : vm.technicianParticipation.length !== 0 && (
            <BasicTable
              page={page}
              columns={[
                {
                  headerAlign: "left",
                  align: "left",
                  field: "cadastralparcelreference",
                  hideSortIcons: true,
                  disableColumnMenu: true,
                  headerName: vm.translate("report.block0.cadastral.reference"),
                  renderCell: (params) => <div>{params.row.cadastralparcelreference}</div>,
                  flex: 0.5,
                },
                {
                  headerAlign: "left",
                  align: "left",
                  field: "streetName",
                  hideSortIcons: true,
                  disableColumnMenu: true,
                  headerName: vm.translate("profile.address"),
                  renderCell: (params) => {
                    return (
                      <div>{`${params.row.streetType} ${params.row.streetName} Nº ${params.row.portalNumber} ${params.row.portalNumberLetter ? params.row.portalNumberLetter : ""
                        } ${params.row.postalCode} ${params.row.province}`}</div>
                    );
                  },
                  flex: 1,
                },
                {
                  headerAlign: "left",
                  align: "right",
                  field: "created",
                  hideSortIcons: true,
                  disableColumnMenu: true,
                  headerName: vm.translate("profile.created"),
                  renderCell: (params) => {
                    var date = new Date(params.row.created).toLocaleDateString("es-ES") ?? "-";
                    return <div>{date}</div>;
                  },
                  flex: 0.2,
                },
                {
                  headerAlign: "left",
                  align: "left",
                  field: "",
                  hideSortIcons: true,
                  headerName: "",
                  flex: 0.06,
                  disableColumnMenu: true,
                  disableReorder: true,
                  sortable: false,
                  renderHeader: () => (
                    <Tooltip title="Modo borrar">
                      <FormControlLabel checked={deleteMode} onChange={changeMode} label={""} control={<Switch color="primary" />} />
                    </Tooltip>
                  ),
                  renderCell: optionPopover,
                },
              ]}
              data={
                vm.technicianParticipation
                  ?.map((a) => ({ id: a.bookId, ...a }))
                  ?.sort((a: ViewAdminTechnicianParticipationDTO, b: ViewAdminTechnicianParticipationDTO) => (b?.created ?? 1) - (a?.created ?? 0)) ?? []
              }
              pageSize={pageSize}
              vm={vm}
              setPageSize={setPageSize}
              headerColor={Colors.primary}
              rowCount={vm.paginationObject?.totalElements!!}
              handlePageChange={(page: number) => {
                setPage(page);
              }}
              setPage={setPage}
              title={vm.translate("profile.table.lee.historical")}
              disableCheckboxes
              locale={vm.parent.configurationService.language}
            />
          )}
        {vm.technicianParticipation.length === 0 && (
          <BasicTable
            data={[]}
            vm={vm}
            headerColor={Colors.primary}
            title={vm.translate("profile.table.lee.historical")}
            disableCheckboxes
            columns={[
              {
                field: "cadastralparcelreference",
                headerName: vm.translate("report.block0.cadastral.reference"),
              },
              {
                headerName: vm.translate("profile.street"),
                field: "street",
              },
            ]}
            locale={vm.parent.configurationService.language}
            pageSize={0}
            page={0}
            setPage={() => { }}
            rowCount={0}
            setPageSize={() => { }}
          />
        )}
        {showNotice.show && showNotice.row ? (
          <NoticeDialog
            acceptText={"Aceptar"}
            handleClose={() => {
              setShowNotice({ ...showNotice, show: false });
            }}
            open={showNotice.show}
            save={() => {
              handleNewDownloadBook(showNotice.row);
              setShowNotice({ ...showNotice, show: false });
            }}
            content={
              <>
                <Typography gutterBottom>
                  Desde el 23 de febrero se ha implementado una nueva operatividad que permite simplificar la valoración del estado de conservación de cada
                  uno de los bloques del edificio.
                </Typography>
                <br />
                <Typography gutterBottom>
                  Hasta dicha fecha debía de valorarse como favorable o desfavorable cada una de las incidencias que se detectaban en la inspección del
                  estado actual. Se ha sustituido esta valoración por un apartado específico llamado <b>“Valoraciones parciales”</b> en el que se realiza
                  una valoración para cada uno de los siguientes bloques: cimentación, estructura, fachadas y medianerías, cubierta y azoteas, e
                  instalaciones.
                </Typography>
                <br />
                <Typography gutterBottom>
                  Si la inspección del libro que estas a punto de descargar se realizo con anterioridad a la fecha indicada, deberás acceder al “BLOQUE 1
                  &gt; I.1 DOCUMENTACIÓN DEL EDIFICIO Y SU ESTADO DE CONSERVACIÓN &gt; Evaluación estado de conservación” y pinchar en el icono del lápiz
                  para editar la inspección. Una vez dentro de la inspección, localiza el apartado <b>“Valoraciones parciales”</b> y completa la información
                  de estos 5 subapartados. Por último, pincha en el icono de guardar y vuelve a la ventana de inicio para descargar el pdf actualizado.
                </Typography>
                <Typography gutterBottom>
                  Por favor, no dudes en ponerte en contacto con nosotros a través de <b>soporte@e-leex.com</b>.
                </Typography>
              </>
            }
            title={"Aviso valoraciones parciales de estado"}
          />
        ) : null}
        <DialogMovicoders
          open={showDeleteBook}
          dialogConfig={dialogStrings}
          onConfirm={() => {
            handleDeleteBook();
          }}
          onClose={() => setShowDeleteBook(false)}
          closeWithX
          maxWidth={"sm"}
          backgroundColorTitle={Colors.primary}
          fullWidth
        />
        {resumeBookId && <PreviewDialog
          title="Resumen del libro"
          fileName={"resume"}
          isLoading = {loadingResume}
          document={<ResumeGenerator bookId={resumeBookId} setIsLoadingState={setLoadingResume}/>}
          openState={!!resumeBookId}
          handleClose={handleCloseResumePreview}
        />}
        {vm.selectedTransactionId && (
        <PreviewDialog
          title="Vista previa de la factura"
          fileName={vm.selectedTransactionId}
          document={<BillGenerator transactionId={vm.selectedTransactionId} />}
          openState={!!vm.selectedTransactionId}
          handleClose={handleClosePreview}
        />
      )}
      </Grid>
    </React.Fragment>
  );
});

function pushMedias(lastImprovement: ImprovementActionPrintedDTO, medias: MediaPrintedDTO[]) {
  let enEfficiency = lastImprovement?.enEfficiencyImprovement;
  let fireSafety = lastImprovement?.fireSafetyImprovement;
  let healthines = lastImprovement?.healthinessImprovement;
  let noise = lastImprovement?.noiseImprovement;
  let security = lastImprovement?.securityImprovement;
  let other = lastImprovement?.otherImprovement;

  enEfficiency &&
    Object.getOwnPropertyNames(enEfficiency).forEach((key) => {
      const t = key as keyof EnergyEfficiencyImprovementPrintedDTO;
      if (key.includes("Media")) {
        enEfficiency?.[t] && medias.push(enEfficiency?.[t] as MediaPrintedDTO);
      }
    });

  fireSafety &&
    Object.getOwnPropertyNames(fireSafety).forEach((key) => {
      const t = key as keyof FireSafetyImprovementPrintedDTO;
      if (key.includes("Media")) {
        fireSafety?.[t] && medias.push(fireSafety?.[t] as MediaPrintedDTO);
      }
    });

  healthines &&
    Object.getOwnPropertyNames(healthines).forEach((key) => {
      const t = key as keyof HealthinessImprovementPrintedDTO;
      if (key.includes("Media")) {
        healthines?.[t] && medias.push(healthines?.[t] as MediaPrintedDTO);
      }
    });
  noise &&
    Object.getOwnPropertyNames(noise).forEach((key) => {
      const t = key as keyof NoiseImprovementPrintedDTO;
      if (key.includes("Media")) {
        noise?.[t] && medias.push(noise?.[t] as MediaPrintedDTO);
      }
    });
  security &&
    Object.getOwnPropertyNames(security).forEach((key) => {
      const t = key as keyof SecurityImprovementPrintedDTO;
      if (key.includes("Media")) {
        security?.[t] && medias.push(security?.[t] as MediaPrintedDTO);
      }
    });
  other &&
    Object.getOwnPropertyNames(other).forEach((key) => {
      const t = key as keyof OtherImprovementPrintedDTO;
      if (key.includes("Media")) {
        other?.[t] && medias.push(other?.[t] as MediaPrintedDTO);
      }
    });
}
