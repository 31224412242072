import { Font } from "@react-pdf/renderer";

import RobotoBlack from "assets/fonts/roboto/Roboto-Black.ttf";
import RobotoBold from "assets/fonts/roboto/Roboto-Bold.ttf";
import RobotoRegular from "assets/fonts/roboto/Roboto-Regular.ttf";
import RobotoLight from "assets/fonts/roboto/Roboto-Light.ttf";

import PoppinsBlack from "assets/fonts/poppins/Poppins-Black.ttf";
import PoppinsRegular from "assets/fonts/poppins/Poppins-Regular.ttf";
import PoppinsThin from "assets/fonts/poppins/Poppins-Thin.ttf";

import MontserratBold from "assets/fonts/montserrat/static/Montserrat-Bold.ttf";
import MontserratLight from "assets/fonts/montserrat/static/Montserrat-Light.ttf";

Font.register({ family: "Roboto", format: "truetype", src: RobotoRegular });
Font.register({ family: "RobotoBold", format: "truetype", src: RobotoBold });
Font.register({ family: "RobotoBlack", format: "truetype", src: RobotoBlack });
Font.register({ family: "RobotoLight", format: "truetype", src: RobotoLight });

Font.register({ family: "PoppinsBlack", format: "truetype", src: PoppinsBlack });
Font.register({ family: "PoppinsRegular", format: "truetype", src: PoppinsRegular });
Font.register({ family: "PoppinsThin", format: "truetype", src: PoppinsThin });

Font.register({ family: "MontserratBold", format: "truetype", src: MontserratBold });
Font.register({ family: "MontserratLight", format: "truetype", src: MontserratLight });

Font.registerHyphenationCallback(word => [word]);

export const fontsNewPdf = {
  RobotoBold: "RobotoBold",
  RobotoBlack: "RobotoBlack",
  RobotoRegular: "Roboto",
  RobotoLight: "RobotoLight",

  PoppinsBlack: "PoppinsBlack",
  PoppinsRegular: "PoppinsRegular",
  PoppinsThin: "PoppinsThin",

  MontserratBold: "MontserratBold",
  MontserratLight: "MontserratLight",
};
