import { Container, interfaces } from 'inversify';
import { BookRepository } from './data/repositories/impl/book-repository';
import BuildingRepository from './data/repositories/impl/building-repository';
import CollegeRepository from './data/repositories/impl/college-repository';
import CommunityRepository from './data/repositories/impl/community-repository';
import ImprovementRepository from './data/repositories/impl/improvements-actions-repository';
import MapRepository from './data/repositories/impl/map-repository';
import { MultimediaRepository } from './data/repositories/impl/multimedia-repository';
import ParcelsRepository from './data/repositories/impl/parcel-repository';
import PropertiesRepository from './data/repositories/impl/properties-repository';
import ProvincesRepository from './data/repositories/impl/provinces-repository';
import { TechniciansRepository } from './data/repositories/impl/technicians-repository';
import TownRepository from './data/repositories/impl/town-repository';
import UserManagementRepository from './data/repositories/impl/user-management-repository';
import UsersProfileRepository from './data/repositories/impl/users-profile-repository';
import RepositoriesLoginRepository from './data/repositories/login-repository';
import LoginRepository from './infrastructure/data/repositories/login-repository';
import ConfigurationService from './services/configuration-service';
import LocalStorageService from './services/local-storage-service';
import MapService from './services/map-service';
import UserContextService from './services/user-context-service';
import BuildingDetailViewModel from './viewmodels/buildings/building-detail-view-model';
import BuildingViewModel from './viewmodels/buildings/building-view-model';
import DashboardViewModel from './viewmodels/dashboard/dashboard-view-model';
import LandingViewModel from './viewmodels/landing/landing-view-model';
import LoginViewModel from './viewmodels/login/login-view-model';
import ProfileViewModel from './viewmodels/profile/profile-view-model';
import RegisterViewModel from './viewmodels/register/register-view-model';
import ReportDetailViewModel from './viewmodels/report/report-detail-view-model';
import ReportViewModel from './viewmodels/report/report-view-model';
import ResetViewModel from './viewmodels/reset/reset-view-model';
import RolesViewModel from './viewmodels/roles-view-model';
import RootViewModel from './viewmodels/root-view-model';
import UserDetailViewModel from './viewmodels/users/user-detail-view-model';
import UsersViewModel from './viewmodels/users/users-view-model';
import CadastralRepository from './data/repositories/impl/cadastral-repository';
import CollegeAdminActionRepository from './data/repositories/impl/college-admin-repository';
import CollegeAdministratorRepository from "./data/repositories/impl/college-administrator-repository"
import AdminActionsRepository from './data/repositories/impl/admin-actions-repository';
import MaintenanceViewModel from './viewmodels/maintenance/maintenance-view-model';
import PaymentGatewayRepository from './data/repositories/impl/payment-gateway-repository';
import FeeRepository from './data/repositories/impl/fee-repository';

export default function registerServices(container: Container) {
	//vm
	container.bind<DashboardViewModel>(DashboardViewModel).toSelf();
	container.bind<ResetViewModel>(ResetViewModel).toSelf();
	container.bind<MaintenanceViewModel>(MaintenanceViewModel).toSelf();
	container.bind<ReportViewModel>(ReportViewModel).toSelf();
	container.bind<LoginViewModel>(LoginViewModel).toSelf();
	container.bind<RegisterViewModel>(RegisterViewModel).toSelf();
	container.bind<LandingViewModel>(LandingViewModel).toSelf();
	container.bind<RolesViewModel>(RolesViewModel).toSelf();
	container.bind<UsersViewModel>(UsersViewModel).toSelf();
	container.bind<BuildingViewModel>(BuildingViewModel).toSelf();
	container.bind<ProfileViewModel>(ProfileViewModel).toSelf();
	container.bind<RootViewModel>(RootViewModel).toSelf();
	//detailvm
	container.bind<UserDetailViewModel>(UserDetailViewModel).toSelf();
	container.bind<BuildingDetailViewModel>(BuildingDetailViewModel).toSelf();
	container.bind<ReportDetailViewModel>(ReportDetailViewModel).toSelf();
	//factory
	container
		.bind<interfaces.Factory<UserDetailViewModel>>(UserDetailViewModel.Factory)
		.toFactory(UserDetailViewModel.Factory);
	container
		.bind<interfaces.Factory<BuildingDetailViewModel>>(BuildingDetailViewModel.Factory)
		.toFactory(BuildingDetailViewModel.Factory);
	container
		.bind<interfaces.Factory<ReportDetailViewModel>>(ReportDetailViewModel.Factory)
		.toFactory(ReportDetailViewModel.Factory);
	//service
	container.bind<ConfigurationService>(ConfigurationService).toSelf().inSingletonScope();
	container.bind<MapService>(MapService).toSelf();
	// UserContextService must be always a singletone
	container.bind<UserContextService>(UserContextService).toSelf().inSingletonScope();
	container.bind<LocalStorageService>(LocalStorageService).toSelf();
	//repository
	container.bind<RepositoriesLoginRepository>(RepositoriesLoginRepository).toSelf();
	container.bind<TechniciansRepository>(TechniciansRepository).toSelf();
	container.bind<MapRepository>(MapRepository).toSelf();
	container.bind<UsersProfileRepository>(UsersProfileRepository).toSelf();
	container.bind<BuildingRepository>(BuildingRepository).toSelf();
	container.bind<ParcelsRepository>(ParcelsRepository).toSelf();
	container.bind<PropertiesRepository>(PropertiesRepository).toSelf();
	container.bind<CommunityRepository>(CommunityRepository).toSelf();
	container.bind<ProvincesRepository>(ProvincesRepository).toSelf();
	container.bind<TownRepository>(TownRepository).toSelf();
	container.bind<LoginRepository>(LoginRepository).toSelf();
	container.bind<CadastralRepository>(CadastralRepository).toSelf();
	container.bind<UserManagementRepository>(UserManagementRepository).toSelf();
	container.bind<BookRepository>(BookRepository).toSelf();
	container.bind<ImprovementRepository>(ImprovementRepository).toSelf();
	container.bind<CollegeRepository>(CollegeRepository).toSelf();
	container.bind<MultimediaRepository>(MultimediaRepository).toSelf();
	container.bind<CollegeAdminActionRepository>(CollegeAdminActionRepository).toSelf();
	container.bind<CollegeAdministratorRepository>(CollegeAdministratorRepository).toSelf();
	container.bind<AdminActionsRepository>(AdminActionsRepository).toSelf();
	container.bind<PaymentGatewayRepository>(PaymentGatewayRepository).toSelf();
	container.bind<FeeRepository>(FeeRepository).toSelf();
}
