/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingEntityDTO
 */
export interface BillingEntityDTO {
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    accountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    billSerial?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingEntityDTO
     */
    billsIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    cif?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    gatewayConfigurationId?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingEntityDTO
     */
    type?: BillingEntityDTOTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum BillingEntityDTOTypeEnum {
    ArchitectsCollege = 'ARCHITECTS_COLLEGE',
    RiggersAndTechnicalArchitects = 'RIGGERS_AND_TECHNICAL_ARCHITECTS'
}

export function BillingEntityDTOFromJSON(json: any): BillingEntityDTO {
    return BillingEntityDTOFromJSONTyped(json, false);
}

export function BillingEntityDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingEntityDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountNumber': !exists(json, 'accountNumber') ? undefined : json['accountNumber'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'billSerial': !exists(json, 'billSerial') ? undefined : json['billSerial'],
        'billsIds': !exists(json, 'billsIds') ? undefined : json['billsIds'],
        'cif': !exists(json, 'cif') ? undefined : json['cif'],
        'gatewayConfigurationId': !exists(json, 'gatewayConfigurationId') ? undefined : json['gatewayConfigurationId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function BillingEntityDTOToJSON(value?: BillingEntityDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountNumber': value.accountNumber,
        'address': value.address,
        'billSerial': value.billSerial,
        'billsIds': value.billsIds,
        'cif': value.cif,
        'gatewayConfigurationId': value.gatewayConfigurationId,
        'id': value.id,
        'name': value.name,
        'phone': value.phone,
        'type': value.type,
    };
}

