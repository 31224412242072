/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HorizontalStructureDTO
 */
export interface HorizontalStructureDTO {
    /**
     * 
     * @type {number}
     * @memberof HorizontalStructureDTO
     */
    beams?: number;
    /**
     * 
     * @type {number}
     * @memberof HorizontalStructureDTO
     */
    framing?: number;
    /**
     * 
     * @type {number}
     * @memberof HorizontalStructureDTO
     */
    framingBetweenBeams?: number;
    /**
     * 
     * @type {number}
     * @memberof HorizontalStructureDTO
     */
    others?: number;
    /**
     * 
     * @type {string}
     * @memberof HorizontalStructureDTO
     */
    othersUnknownOthersValue?: string;
}

export function HorizontalStructureDTOFromJSON(json: any): HorizontalStructureDTO {
    return HorizontalStructureDTOFromJSONTyped(json, false);
}

export function HorizontalStructureDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): HorizontalStructureDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beams': !exists(json, 'beams') ? undefined : json['beams'],
        'framing': !exists(json, 'framing') ? undefined : json['framing'],
        'framingBetweenBeams': !exists(json, 'framingBetweenBeams') ? undefined : json['framingBetweenBeams'],
        'others': !exists(json, 'others') ? undefined : json['others'],
        'othersUnknownOthersValue': !exists(json, 'othersUnknownOthersValue') ? undefined : json['othersUnknownOthersValue'],
    };
}

export function HorizontalStructureDTOToJSON(value?: HorizontalStructureDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'beams': value.beams,
        'framing': value.framing,
        'framingBetweenBeams': value.framingBetweenBeams,
        'others': value.others,
        'othersUnknownOthersValue': value.othersUnknownOthersValue,
    };
}

