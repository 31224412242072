/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LegalDataDTO
 */
export interface LegalDataDTO {
    /**
     * 
     * @type {string}
     * @memberof LegalDataDTO
     */
    otherPropertyLegalRegimeValue?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalDataDTO
     */
    propertyLegalRegime?: LegalDataDTOPropertyLegalRegimeEnum;
}

/**
* @export
* @enum {string}
*/
export enum LegalDataDTOPropertyLegalRegimeEnum {
    CommunityOfOwners = 'COMMUNITY_OF_OWNERS',
    Other = 'OTHER',
    SeveralOwners = 'SEVERAL_OWNERS',
    SingleOwner = 'SINGLE_OWNER'
}

export function LegalDataDTOFromJSON(json: any): LegalDataDTO {
    return LegalDataDTOFromJSONTyped(json, false);
}

export function LegalDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LegalDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'otherPropertyLegalRegimeValue': !exists(json, 'otherPropertyLegalRegimeValue') ? undefined : json['otherPropertyLegalRegimeValue'],
        'propertyLegalRegime': !exists(json, 'propertyLegalRegime') ? undefined : json['propertyLegalRegime'],
    };
}

export function LegalDataDTOToJSON(value?: LegalDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'otherPropertyLegalRegimeValue': value.otherPropertyLegalRegimeValue,
        'propertyLegalRegime': value.propertyLegalRegime,
    };
}

