/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TechnicianFilterDTO
 */
export interface TechnicianFilterDTO {
    /**
     * 
     * @type {boolean}
     * @memberof TechnicianFilterDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TechnicianFilterDTO
     */
    collegeName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TechnicianFilterDTO
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TechnicianFilterDTO
     */
    feeName?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianFilterDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianFilterDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianFilterDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianFilterDTO
     */
    surname?: string;
}

export function TechnicianFilterDTOFromJSON(json: any): TechnicianFilterDTO {
    return TechnicianFilterDTOFromJSONTyped(json, false);
}

export function TechnicianFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicianFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'collegeName': !exists(json, 'collegeName') ? undefined : json['collegeName'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'feeName': !exists(json, 'feeName') ? undefined : json['feeName'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
    };
}

export function TechnicianFilterDTOToJSON(value?: TechnicianFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'collegeName': value.collegeName,
        'deleted': value.deleted,
        'feeName': value.feeName,
        'idNumber': value.idNumber,
        'name': value.name,
        'postalCode': value.postalCode,
        'searchText': value.searchText,
        'surname': value.surname,
    };
}

