import { View, Text } from "@react-pdf/renderer";
import React, { FC } from "react";
import { ColorsNewPdf } from "../../../../../../constants";
import { fontsNewPdf } from "../../../../../_common/fonts/fonts";
import { TitlesStyles } from "./styles";

export const PdfTitle: FC<{ title?: string }> = ({ title }) => {
  const styles = TitlesStyles;

  return (
    <Text wrap={false} style={styles.pdfTitle}>
      {title}
    </Text>
  );
};

export const IndexTitle: FC<{ title?: React.ReactNode }> = ({ title }) => {
  const styles = TitlesStyles;

  return (
    <Text wrap={false} style={styles.indexTitle}>
      {title}
    </Text>
  );
};

export const SectionTitle: FC<{ title?: React.ReactNode; color?: string }> = ({ title, color }) => {
  const styles = TitlesStyles;

  return (
    <Text wrap={false} style={{ ...styles.sectionTitle, color: color }}>
      {title}
    </Text>
  );
};

export const SectionTitleAndIcon: FC<{ title?: string; color?: string; iconBorderColor?: string; srcIcon?: string; id?: string }> = ({
  title,
  color,
  children,
  id,
}) => {
  const styles = TitlesStyles;
  return (
    <View
      wrap={false}
      style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginTop: "20pt", marginBottom: "20pt" }}
    >
      {children}
      <Text style={{ ...styles.sectionTitle, color: color }} id={id}>
        {title}
      </Text>
    </View>
  );
};

export const SectionAndBackgroundTitle: FC<{
  title?: React.ReactNode;
  bgColor?: string;
  textAlign?: "left" | "right" | "center" | "justify" | undefined;
  textColor?: string;
  id?: string;
}> = ({ title, bgColor, textAlign, textColor, id }) => {
  const styles = TitlesStyles;
  return (
    <Text
      wrap={false}
      id={id}
      style={{ ...styles.titleWithBackground, marginVertical: "20pt", backgroundColor: bgColor, textAlign: textAlign, color: textColor }}
    >
      {title}
    </Text>
  );
};

export const SectionLowerAndBackgroundTitle: FC<{
  title?: React.ReactNode;
  bgColor?: string;
  textAlign?: "left" | "right" | "center" | "justify" | undefined;
  textColor?: string;
  children?: React.ReactNode;
  brk?: boolean;
  id?: string;
}> = ({ title, bgColor, textAlign, textColor, children, id, brk }) => {
  const styles = TitlesStyles;
  return (
    <View break={brk} wrap>
      <Text
        wrap={false}
        id={id}
        style={{ ...styles.titleWithBackgroundLower, marginVertical: "10pt", backgroundColor: bgColor, textAlign: textAlign, color: textColor }}
      >
        {title}
        {children}
      </Text>
    </View>
  );
};

export const DeficienciesTitle: FC<{ title?: string }> = ({ title }) => {
  const styles = TitlesStyles;
  return (
    <View wrap={false} style={styles.deficienciesTitlesStyles}>
      <Text style={{ fontSize: "14pt" }}>{title?.toUpperCase()}</Text>
    </View>
  );
};

export const TitleWithBlueBorder: FC<{ title?: string }> = ({ title }) => {
  const styles = TitlesStyles;
  return (
    <View wrap={false} style={styles.textWithBorder}>
      <Text style={styles.textAccessibilityOutdoor}>{title}</Text>
    </View>
  );
};

export const TitleTablesWithBlackBorder: FC<{ title?: string }> = ({ title }) => {
  return (
    <>
      <View wrap={false} style={{ backgroundColor: ColorsNewPdf.black }}>
        <Text style={{ color: ColorsNewPdf.white, textAlign: "center", padding: "5pt", fontSize: "10pt", fontFamily: fontsNewPdf.RobotoBold }}>
          {title}
        </Text>
      </View>
      <hr style={{ borderBottom: `2pt solid ${ColorsNewPdf.black}`, marginTop: "10pt" }} />
    </>
  );
};

export const TitleTablesWithBlackBorderLeft: FC<{ prop?: string; title?: string; subtitle?: string }> = ({ prop, title, subtitle }) => {
  return (
    <>
      <View wrap={false} style={{ borderLeft: "6pt black solid", backgroundColor: ColorsNewPdf.table_energy_background_table }}>
        <View wrap={false} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Text style={{ color: ColorsNewPdf.black, textAlign: "left", padding: "5pt", fontSize: "10pt", fontFamily: fontsNewPdf.RobotoBold }}>{prop}</Text>
          <Text style={{ color: ColorsNewPdf.black, textAlign: "left", padding: "5pt", fontSize: "10pt", fontFamily: fontsNewPdf.PoppinsRegular }}>
            {title}
          </Text>
        </View>
        <Text style={{ color: ColorsNewPdf.black, textAlign: "left", padding: "5pt", fontSize: "10pt", fontFamily: fontsNewPdf.RobotoBold }}>
          {subtitle}
        </Text>
      </View>
      <hr
        style={{
          marginTop: "10pt",
          marginBottom: "10pt",
          border: "none",
          width: "414pt",
          alignSelf: "center",
          borderBottom: "6pt dotted grey",
        }}
      />
    </>
  );
};

export const GreenSectionTitle: FC<{ text: string }> = ({ text }) => (
  <Text style={{ color: "green", fontFamily: fontsNewPdf.RobotoRegular, fontSize: 9 }}>{text}</Text>
);
