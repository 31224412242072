import React from "react";
import { DocumentPage } from "../../components/document-page/document-page";
import { SectionTitle } from "../../components";
import { View, Text } from "@react-pdf/renderer";
import { ImprovementActionPrintedDTO } from "clients";

interface FinalSummaryProps {
  lastImprovementAction: ImprovementActionPrintedDTO | undefined;
}

export const FinalSummary: React.FC<FinalSummaryProps> = ({ lastImprovementAction }) => (
  <DocumentPage>
    <SectionTitle textKey="resume.section.5.title" />
    <View style={styles.container}>
      <Text style={styles.text}>{lastImprovementAction?.summary?.length ? lastImprovementAction.summary : "-"}</Text>
    </View>
  </DocumentPage>
);

const styles = {
  container: {
    backgroundColor: "#E4E3E3",
    padding: 22,
    marginVertical: 16,
  },
  text: {
    width: "100%",
    fontSize: 9,
  },
};
