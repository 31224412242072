import { NewConservationStatusDTO } from "clients";
import { PartialAssesments } from "pdf/documents/book/book-v2/pages/block1/buildingInspection/block1-partial-assesments";
import { DocumentPage, SubSectionTitle, Paragraph } from "pdf/documents/book/resume/components";
import React from "react";

interface EvaluationPageProps {
  conservationStatus?: NewConservationStatusDTO;
}
export const EvaluationPage: React.FC<EvaluationPageProps> = ({ conservationStatus }) => {
  return (
    <DocumentPage>
      <SubSectionTitle textKey="resume.sub.section.1.2.title" />
      <Paragraph textKey="resume.sub.section.1.2.description" />
      {conservationStatus && <PartialAssesments data={conservationStatus} />}
    </DocumentPage>
  );
};
