import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { BillAddressDTO, TownDTO } from "../../../../../../clients";
import ProfileViewModel from "../../../../../../viewmodels/profile/profile-view-model";
import { useEffect, useState } from "react";
import { ReportStyle } from "../../../../../reports/report-style";
import { ProfileStyle } from "../../../../profile-styles";
import TextFieldGrid from "../../../../../../components/text-fields/textfield-grid";
import { Button } from "@movicoders/movicoders-components";
import { Colors } from "../../../../../../constants";
import Province from "../../../../../../data/entities/province-entity";

export type PorfileBillingAddressesProps = {
  billingAddresses: BillAddressDTO[];
  onAddBillingAddress: (newBillingAddresses: BillAddressDTO) => void;
  vm: ProfileViewModel;
};

export const AddBillingAddress = (props: PorfileBillingAddressesProps) => {
  const [provinces, setProvinces] = useState<Province[] | null>([]);
  const [towns, setTowns] = useState<TownDTO[] | null>([]);
  const reportClasses = ReportStyle();
  const [newAddress, setNewAddress] = useState<BillAddressDTO>({});
  const classes = ProfileStyle();

  useEffect(() => {
    const getProvinces = async () => {
      const result = (await props.vm.parent.provincesRepository.getProvinces()) ?? [];
      setProvinces(result);
    };
    getProvinces();
  }, []);

  useEffect(() => {
    const getTowns = async () => {
      if (newAddress.billingProvince) {
        const result =
          (await props.vm.parent.provincesRepository.getAllTownByProvinceCode(provinces?.find((p) => p.name === newAddress.billingProvince)?.code || "")) ??
          [];
        //const result = (await props.vm.parent.communityRepository.getAllCommunity()) ?? [];
        setTowns(result);
      }
    };
    getTowns();
  }, [newAddress.billingProvince]);

  const onChangeBillingAddress = (field: keyof BillAddressDTO, value: string | number) => {
    setNewAddress({ ...newAddress, [field]: value });
  };
  const onAddBillingAddress = () => {
    if (
      newAddress.billingTown &&
      newAddress.billingAddress &&
      newAddress.billingProvince &&
      newAddress.billingPostalCode !== undefined &&
      newAddress.billingPostalCode > 0 &&
      newAddress.billingLegalName &&
      newAddress.billingCif
    ) {
      props.onAddBillingAddress(newAddress);
      setNewAddress({});
    }
  };

  return (
    <>
      <Grid container xs={12} md={12} style={{ marginTop: 10 }}>
        <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
          <Typography variant="caption">{props.vm.translate("profile.addBillingAddress")}</Typography>
        </Grid>
        <Grid item xs={5} sx={{ marginRight: "0.5rem" }}>
          <Autocomplete
            disablePortal
            id="billingAddressPronvinces"
            options={provinces?.map((p) => p.name) || []}
            fullWidth
            value={newAddress?.billingProvince || ""}
            clearIcon={<></>}
            getOptionLabel={(option: any) => option}
            renderInput={(params: any) => (
              <TextField {...params} className={reportClasses.report_textField} size="small" label={props.vm.translate("report.block0.province")} />
            )}
            onChange={(event: any, value: any) => {
              onChangeBillingAddress("billingProvince", value);
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            disablePortal
            id="billingAddressTowns"
            options={towns?.map((t) => t.name) || []}
            fullWidth
            value={newAddress?.billingTown || ""}
            clearIcon={<></>}
            getOptionLabel={(option: any) => option}
            renderInput={(params: any) => (
              <TextField {...params} className={reportClasses.report_textField} size="small" label={props.vm.translate("report.block0.municipality")} />
            )}
            onChange={(event: any, value: any) => {
              onChangeBillingAddress("billingTown", value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFieldGrid
            numeric={true}
            title={props.vm.translate("report.block0.cp")}
            id="billingAddressPostalCode"
            value={newAddress?.billingPostalCode !== undefined ? "" + newAddress?.billingPostalCode : ""}
            onChange={(event: any) => {
              onChangeBillingAddress("billingPostalCode", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldGrid
            title={props.vm.translate("profile.address")}
            value={newAddress?.billingAddress || ""}
            onChange={(event: any) => {
              onChangeBillingAddress("billingAddress", event.target.value);
            }}
            id={"billingAddressAddress"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldGrid
            title={props.vm.translate("profile.address.cif")}
            value={newAddress?.billingCif || ""}
            onChange={(event: any) => {
              onChangeBillingAddress("billingCif", event.target.value);
            }}
            id={"billingAddressCif"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldGrid
            title={props.vm.translate("profile.address.legalName")}
            value={newAddress?.billingLegalName || ""}
            onChange={(event: any) => {
              onChangeBillingAddress("billingLegalName", event.target.value);
            }}
            id={"billingLegalName"}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ marginTop: "1rem" }}
            textColor={Colors.white}
            backgroundColor={Colors.buttonSecondary}
            hoverColor={Colors.buttonSecondary}
            disabled={
              !newAddress.billingProvince ||
              !newAddress.billingTown ||
              !newAddress.billingPostalCode ||
              !newAddress.billingAddress ||
              !newAddress.billingCif ||
              !newAddress.billingLegalName
            }
            onClick={() => onAddBillingAddress()}
            text={props.vm.translate("add")}
          />
        </Grid>
      </Grid>
    </>
  );
};
