/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyEfficiencyCertificateDTO,
    EnergyEfficiencyCertificateDTOFromJSON,
    EnergyEfficiencyCertificateDTOFromJSONTyped,
    EnergyEfficiencyCertificateDTOToJSON,
    PolygonDTO,
    PolygonDTOFromJSON,
    PolygonDTOFromJSONTyped,
    PolygonDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BookMarkerDTO
 */
export interface BookMarkerDTO {
    /**
     * 
     * @type {string}
     * @memberof BookMarkerDTO
     */
    cadastralReference?: string;
    /**
     * 
     * @type {string}
     * @memberof BookMarkerDTO
     */
    code?: string;
    /**
     * 
     * @type {EnergyEfficiencyCertificateDTO}
     * @memberof BookMarkerDTO
     */
    energyEfficiency?: EnergyEfficiencyCertificateDTO;
    /**
     * 
     * @type {string}
     * @memberof BookMarkerDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof BookMarkerDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof BookMarkerDTO
     */
    longitude?: number;
    /**
     * 
     * @type {Array<PolygonDTO>}
     * @memberof BookMarkerDTO
     */
    polygon?: Array<PolygonDTO>;
}

export function BookMarkerDTOFromJSON(json: any): BookMarkerDTO {
    return BookMarkerDTOFromJSONTyped(json, false);
}

export function BookMarkerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookMarkerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cadastralReference': !exists(json, 'cadastralReference') ? undefined : json['cadastralReference'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'energyEfficiency': !exists(json, 'energyEfficiency') ? undefined : EnergyEfficiencyCertificateDTOFromJSON(json['energyEfficiency']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'polygon': !exists(json, 'polygon') ? undefined : ((json['polygon'] as Array<any>).map(PolygonDTOFromJSON)),
    };
}

export function BookMarkerDTOToJSON(value?: BookMarkerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cadastralReference': value.cadastralReference,
        'code': value.code,
        'energyEfficiency': EnergyEfficiencyCertificateDTOToJSON(value.energyEfficiency),
        'id': value.id,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'polygon': value.polygon === undefined ? undefined : ((value.polygon as Array<any>).map(PolygonDTOToJSON)),
    };
}

