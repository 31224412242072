import React from "react";
import { PhasePage } from "./pages/phase-page";
import { PassportDescriptionPage } from "./pages/passport-description-page";
import { ImprovementActionPrintedDTO } from "clients";
import { getPhasesFromImprovementActions} from "pdf/documents/book/_common/models/phase-with-improvements";

interface RenovationPassportProps {
  improvementAction?: ImprovementActionPrintedDTO;
}

export const RenovationPassport: React.FC<RenovationPassportProps> = ({ improvementAction }) => {
  if (!improvementAction) {
    return null;
  }

  const phases = getPhasesFromImprovementActions(improvementAction);
  return (
    <>
      <PassportDescriptionPage phaseWithImprovments={phases[0]} />
      {phases.length > 1 && phases.slice(1).map((phase, index) => <PhasePage key={index} phaseWithImprovements={phase} />)}
    </>
  );
};
