import { NewAccessibilityEvaluationsDTO, NewConservationStatusDTO } from "clients";
import { DocumentPage, Paragraph, Spacer, SubSectionTitle } from "../../../components";
import { FinalValuation } from "../components/final-valuation/FinalValuation";
import { DeficienciesDiagram } from "../components";

interface FinalEvaluationPageProps {
  conservationStatus?: NewConservationStatusDTO;
  accessibilityEvaluation?: NewAccessibilityEvaluationsDTO;
}

export const FinalEvaluationPage = (props: FinalEvaluationPageProps) => {
  const { conservationStatus, accessibilityEvaluation } = props;
  const documentation = conservationStatus?.documentation;

  return (
    <DocumentPage>
      <SubSectionTitle textKey="resume.sub.section.1.3.title" />
      <Paragraph textKey="resume.sub.section.1.3.description" />

      <FinalValuation documentation={documentation} accessibilityEvaluation={accessibilityEvaluation} />

      <Spacer />
      <DeficienciesDiagram documentation={documentation} accessibilityEvaluation={accessibilityEvaluation} />
    </DocumentPage>
  );
};
