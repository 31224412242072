import React from "react";
import { View } from "@react-pdf/renderer";

interface SpacerProps {
  verticalSpace?: string | number | undefined;
  horizontalSpace?: string | number | undefined;
}

export const Spacer: React.FC<SpacerProps> = ({ verticalSpace = 20, horizontalSpace = 0}) => {
  return <View style={{ height: verticalSpace, width: horizontalSpace }} />;
};