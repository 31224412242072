
import {
  BillsApi,
} from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";
import { IBillApi } from "../ibill-api";


export class BillApi extends BaseAPI implements IBillApi {
  apiClient: BillsApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new BillsApi(configuration);
  }
  async getBillByBookId(id: string) {
      return await this.apiClient.getBillByBookId({id})
  }
  async getBillByTransactionId(id: string) {
    return await this.apiClient.getBillByTransactionId({id})
}
}
