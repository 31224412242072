import { Button } from "@movicoders/movicoders-components";
import { TypographyTheme } from "@movicoders/movicoders-components";
import { Checkbox, FormControl, Grid, MenuItem, Paper, Select, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../../../../../../../constants";
import { Network } from "../../../../../../../infrastructure/network";
import { getBookMock } from "../../../../../../../viewmodels/profile/data";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import ProfileViewModel from "../../../../../../../viewmodels/profile/profile-view-model";
import { CecaSignatureResponse } from "../../../../../../../clients/models/CecaSignatureResponse";
import { useSnackbar } from "notistack";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { ReportStyle } from "../../../../../report-style";
import * as RegexHelper from "../../../../../../../utils/regexHelper";

export const PaymentForm: React.FC<{ vm: ReportViewModel }> = ({ vm }) => {
  const billingAddresses = vm.parent.technicianData?.billAddresses;
  const defaultBillingAddres = billingAddresses?.[0]?.id ?? "";

  const [needPayment, setNeedPayment] = React.useState(false);
  const [billingAddress, setBillingAddress] = React.useState<string>(defaultBillingAddres);
  const [disableBillingAddress, setDisableBillingAddress] = React.useState<boolean>(false);
  const [cecaParams, setCecaParams] = React.useState<CecaSignatureResponse>();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 8000 });
  };
  const classes = ReportStyle();

  React.useEffect(() => {
    setNeedPayment(vm.paymentButton);
  }, [vm.paymentButton]);

  React.useEffect(() => {
    if (cecaParams !== undefined && cecaParams.merchantID) {
      const submit = document.getElementById("cecaForm") as HTMLFormElement;
      submit.submit();
    }
  }, [cecaParams]);
  /*
  React.useEffect(() => {
    if (vm.cecaSignatureConfiguration !== null) {
      setNeedPayment(true);
    }
  }, [vm.cecaSignatureConfiguration]);
*/
  const freeBook = async () => {
    vm.activeTab = "block0";
    vm.openPaymentForm = false;
    vm.parent.editingBook = false;
    vm.parent.tryActivateChild(vm);
    vm.parent.navigate("report", {});
    vm.parent.selectedBook = getBookMock();
  };

  const paydBook = async () => {
    //init payment here
    try {
      if (billingAddress !== null) {
        let cecaParams = await vm.initPaymentProcess(billingAddress);
        setCecaParams(cecaParams);
      }
    } catch (err) {
      handleOpenSnackBar("payment.button.book.error", "error");
    }
  };

  return (
    <Grid
      container
      minWidth={600}
      minHeight={200}
      alignItems="start"
      style={{ placeContent: "start" }}
      justifyContent={"start"}
      className={classes.report_displayGrid}
    >
      <div style={{ height: 10, width: 1 }} />
      <Grid container style={{ padding: "5px" }}>
        <Grid container>
          <form id="cecaForm" action={Network.CECA_GATEWAY} method="POST" encType="application/x-www-form-urlencoded">
            <input name="MerchantID" type="hidden" value={vm.cecaSignatureConfiguration?.merchantID} />
            <input name="AcquirerBIN" type="hidden" value={vm.cecaSignatureConfiguration?.acquirerBIN} />
            <input name="TerminalID" type="hidden" value={vm.cecaSignatureConfiguration?.terminalID} />
            <input name="URL_OK" type="hidden" value={vm.cecaSignatureConfiguration?.urlOK} />
            <input name="URL_NOK" type="hidden" value={vm.cecaSignatureConfiguration?.urlNOK} />
            <input name="Firma" type="hidden" value={vm.cecaSignatureConfiguration?.signature} />
            <input name="Cifrado" type="hidden" value={vm.cecaSignatureConfiguration?.cifrado} />
            <input name="Num_operacion" type="hidden" value={vm.cecaSignatureConfiguration?.numOperacion} />
            <input name="Importe" type="hidden" value={vm.cecaSignatureConfiguration?.importe} />
            <input name="TipoMoneda" type="hidden" value={vm.cecaSignatureConfiguration?.tipoMoneda} />
            <input name="Exponente" type="hidden" value={vm.cecaSignatureConfiguration?.exponente} />
            <input name="Pago_soportado" type="hidden" value="SSL" />
            <input type="hidden" name="Descripcion" value={vm.cecaSignatureConfiguration?.descripcion} />
            <input name="Idioma" type="hidden" value="1" />
          </form>

          {needPayment ? (
            <Grid container justifyContent="start" alignItems="start" style={{ marginBottom: "20px", width: "100%" }}>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <TypographyTheme variant="h5" color={Colors.primary} text={vm.translate("payment.button.buying.book")} />
              </Grid>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <TypographyTheme variant="body1" text={vm.translate("payment.button.buying.book.generate.reference")} />
              </Grid>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <Paper elevation={3} style={{ display: "grid", paddingTop: "5px", marginTop: "1rem", width: "100%" }}>
                  <div style={{ padding: "10px", display: "grid" }}>
                    <Grid display="inline-flex">
                      <TypographyTheme variant="h6" text={vm.translate("payment.button.book.create.access")} />
                    </Grid>
                    <Grid display="inline-flex">
                      <TypographyTheme
                        variant="h6"
                        text={`${vm.translate("report.block0.buildingIdentification.paymentButton.amount")} ${getTotalAmount()}€ ${vm.translate(
                          "vat.included"
                        )}`}
                      />
                    </Grid>
                    <Grid item xs={12} display="inline-flex" sx={{ marginTop: "1rem", marginBottom: "1rem", width: "100%" }}>
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel id="billing-address-select-label">{vm.translate("payment.button.billing.address")}</InputLabel>
                        <Select
                          labelId="billing-address-select-label"
                          label={vm.translate("payment.button.billing.address")}
                          onChange={(ev) => setBillingAddress(ev.target.value)}
                          value={billingAddress}
                          displayEmpty
                          disabled={disableBillingAddress}
                          sx={{ width: "100%" }}
                        >
                          {billingAddresses?.map((ba) => (
                            <MenuItem key={ba.id} value={ba.id}>
                              {ba.billingAddress} - {ba.billingTown} - {ba.billingProvince}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} display={"flex"} alignItems={"center"}>
                      <Checkbox
                        aria-label={vm.translate("payment.form.billing.address.default")}
                        onChange={(ev, checked) => {
                          if (checked) {
                            setDisableBillingAddress(true);
                            setBillingAddress("");
                          } else {
                            setDisableBillingAddress(false);
                          }
                        }}
                      />
                      <Typography>{vm.translate("payment.form.billing.address.default")}</Typography>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TypographyTheme variant="body1" color={Colors.primary} text={vm.translate("payment.dialog.book.ready")} />
            </Grid>
          )}
          <Grid container xs={12} sx={{ marginTop: "1rem" }}>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                backgroundColor="grey"
                style={{ height: "2.5rem" }}
                onClick={() => {
                  vm.openPaymentForm = false;
                }}
                text={vm.translate("cancel")}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              {needPayment ? (
                <Button
                  style={{ marginLeft: "10px", height: "2.5rem" }}
                  variant="contained"
                  onClick={paydBook}
                  text={vm.translate("payment.button.book.buy")}
                />
              ) : (
                <Button style={{ marginLeft: "10px", height: "2.5rem" }} variant="contained" onClick={freeBook} text={vm.translate("profile.createBook")} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  function getTotalAmount(): string {
    var total = 0;
    const fee = (vm.parent.children.find((c) => c.navigationName === "profile") as ProfileViewModel)?.profileUser?.fee;
    if (fee) {
      const price = fee.creationBase ?? 0;
      const taxes = price * ((fee.vat ?? 0) / 100);
      total = price + taxes;
    }
    return total.toFixed(2).replace(".", ",").replaceAll(RegexHelper.checkNumberOver1000, ".");
  }
};

export const CECA_CONSTANTS = {
  CECA_PAGO_SOPORTADO: "SSL",
  CECA_TIPO_MONEDA: "978",
  CECA_CIFRADO: "SHA2",
  CECA_EXPONENTE: "2",
  CECA_URL_ACTION_URL_PROD: "https://pgw.ceca.es/tpvweb/tpv/compra.action ", //parametrized from .env
  CECA_URL_ACTION_URL_DEV: "https://tpv.ceca.es/tpvweb/tpv/compra.action ", //parametrized from .env
};
