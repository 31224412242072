/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyEfficiencyImprovementPrintedDTO
 */
export interface EnergyEfficiencyImprovementPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    ageOfInstallation?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    ageOfInstallationMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    automaticSystems?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    automaticSystemsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    circulatingPumps?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    circulatingPumpsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    consumptionAccounting?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    consumptionAccountingMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    energyCertification?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    energyCertificationMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    energyPerformanceAnalysisOnRealConditions?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    energyPerformanceAnalysisOnRealConditionsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    energySavingLightingOnCommonZones?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    energySavingLightingOnCommonZonesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    highEfficiencyElevator?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    highEfficiencyElevatorMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    installationPowerGenerationSystems?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    installationPowerGenerationSystemsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    lightingControlSystems?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    lightingControlSystemsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    pipeIsolation?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof EnergyEfficiencyImprovementPrintedDTO
     */
    pipeIsolationMedia?: MediaPrintedDTO;
}

export function EnergyEfficiencyImprovementPrintedDTOFromJSON(json: any): EnergyEfficiencyImprovementPrintedDTO {
    return EnergyEfficiencyImprovementPrintedDTOFromJSONTyped(json, false);
}

export function EnergyEfficiencyImprovementPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyEfficiencyImprovementPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageOfInstallation': !exists(json, 'ageOfInstallation') ? undefined : json['ageOfInstallation'],
        'ageOfInstallationMedia': !exists(json, 'ageOfInstallationMedia') ? undefined : MediaPrintedDTOFromJSON(json['ageOfInstallationMedia']),
        'automaticSystems': !exists(json, 'automaticSystems') ? undefined : json['automaticSystems'],
        'automaticSystemsMedia': !exists(json, 'automaticSystemsMedia') ? undefined : MediaPrintedDTOFromJSON(json['automaticSystemsMedia']),
        'circulatingPumps': !exists(json, 'circulatingPumps') ? undefined : json['circulatingPumps'],
        'circulatingPumpsMedia': !exists(json, 'circulatingPumpsMedia') ? undefined : MediaPrintedDTOFromJSON(json['circulatingPumpsMedia']),
        'consumptionAccounting': !exists(json, 'consumptionAccounting') ? undefined : json['consumptionAccounting'],
        'consumptionAccountingMedia': !exists(json, 'consumptionAccountingMedia') ? undefined : MediaPrintedDTOFromJSON(json['consumptionAccountingMedia']),
        'energyCertification': !exists(json, 'energyCertification') ? undefined : json['energyCertification'],
        'energyCertificationMedia': !exists(json, 'energyCertificationMedia') ? undefined : MediaPrintedDTOFromJSON(json['energyCertificationMedia']),
        'energyPerformanceAnalysisOnRealConditions': !exists(json, 'energyPerformanceAnalysisOnRealConditions') ? undefined : json['energyPerformanceAnalysisOnRealConditions'],
        'energyPerformanceAnalysisOnRealConditionsMedia': !exists(json, 'energyPerformanceAnalysisOnRealConditionsMedia') ? undefined : MediaPrintedDTOFromJSON(json['energyPerformanceAnalysisOnRealConditionsMedia']),
        'energySavingLightingOnCommonZones': !exists(json, 'energySavingLightingOnCommonZones') ? undefined : json['energySavingLightingOnCommonZones'],
        'energySavingLightingOnCommonZonesMedia': !exists(json, 'energySavingLightingOnCommonZonesMedia') ? undefined : MediaPrintedDTOFromJSON(json['energySavingLightingOnCommonZonesMedia']),
        'highEfficiencyElevator': !exists(json, 'highEfficiencyElevator') ? undefined : json['highEfficiencyElevator'],
        'highEfficiencyElevatorMedia': !exists(json, 'highEfficiencyElevatorMedia') ? undefined : MediaPrintedDTOFromJSON(json['highEfficiencyElevatorMedia']),
        'installationPowerGenerationSystems': !exists(json, 'installationPowerGenerationSystems') ? undefined : json['installationPowerGenerationSystems'],
        'installationPowerGenerationSystemsMedia': !exists(json, 'installationPowerGenerationSystemsMedia') ? undefined : MediaPrintedDTOFromJSON(json['installationPowerGenerationSystemsMedia']),
        'lightingControlSystems': !exists(json, 'lightingControlSystems') ? undefined : json['lightingControlSystems'],
        'lightingControlSystemsMedia': !exists(json, 'lightingControlSystemsMedia') ? undefined : MediaPrintedDTOFromJSON(json['lightingControlSystemsMedia']),
        'pipeIsolation': !exists(json, 'pipeIsolation') ? undefined : json['pipeIsolation'],
        'pipeIsolationMedia': !exists(json, 'pipeIsolationMedia') ? undefined : MediaPrintedDTOFromJSON(json['pipeIsolationMedia']),
    };
}

export function EnergyEfficiencyImprovementPrintedDTOToJSON(value?: EnergyEfficiencyImprovementPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageOfInstallation': value.ageOfInstallation,
        'ageOfInstallationMedia': MediaPrintedDTOToJSON(value.ageOfInstallationMedia),
        'automaticSystems': value.automaticSystems,
        'automaticSystemsMedia': MediaPrintedDTOToJSON(value.automaticSystemsMedia),
        'circulatingPumps': value.circulatingPumps,
        'circulatingPumpsMedia': MediaPrintedDTOToJSON(value.circulatingPumpsMedia),
        'consumptionAccounting': value.consumptionAccounting,
        'consumptionAccountingMedia': MediaPrintedDTOToJSON(value.consumptionAccountingMedia),
        'energyCertification': value.energyCertification,
        'energyCertificationMedia': MediaPrintedDTOToJSON(value.energyCertificationMedia),
        'energyPerformanceAnalysisOnRealConditions': value.energyPerformanceAnalysisOnRealConditions,
        'energyPerformanceAnalysisOnRealConditionsMedia': MediaPrintedDTOToJSON(value.energyPerformanceAnalysisOnRealConditionsMedia),
        'energySavingLightingOnCommonZones': value.energySavingLightingOnCommonZones,
        'energySavingLightingOnCommonZonesMedia': MediaPrintedDTOToJSON(value.energySavingLightingOnCommonZonesMedia),
        'highEfficiencyElevator': value.highEfficiencyElevator,
        'highEfficiencyElevatorMedia': MediaPrintedDTOToJSON(value.highEfficiencyElevatorMedia),
        'installationPowerGenerationSystems': value.installationPowerGenerationSystems,
        'installationPowerGenerationSystemsMedia': MediaPrintedDTOToJSON(value.installationPowerGenerationSystemsMedia),
        'lightingControlSystems': value.lightingControlSystems,
        'lightingControlSystemsMedia': MediaPrintedDTOToJSON(value.lightingControlSystemsMedia),
        'pipeIsolation': value.pipeIsolation,
        'pipeIsolationMedia': MediaPrintedDTOToJSON(value.pipeIsolationMedia),
    };
}

