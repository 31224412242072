import { Text, View } from "@react-pdf/renderer";
import { IconCircle } from "pdf/documents/book/resume/components/circle/IconCircle";
import { finalValuationStyles as styles } from "./finalValuationStyles";
import { useTranslation } from "react-i18next";
import { NewAccessibilityEvaluationsDTO, NewInspectionDocumentationDTO } from "clients";
import {
  getFinalAccessibilityValorationIndicatorProperties,
  getPartialValorationIndicatorProperties,
} from "pdf/documents/book/_common/utils/render-indicator-helper";

const getColors = (accessibilityEvaluation?: NewAccessibilityEvaluationsDTO, documentation?: NewInspectionDocumentationDTO) => {
  return {
    accessibilityFinalValuation: getFinalAccessibilityValorationIndicatorProperties(accessibilityEvaluation?.finalResult),
    conservationFinalValuation: getPartialValorationIndicatorProperties(documentation?.finalResult),
  };
};

interface FinalValuationProps {
  documentation?: NewInspectionDocumentationDTO;
  accessibilityEvaluation?: NewAccessibilityEvaluationsDTO;
}

export const FinalValuation = (props: FinalValuationProps) => {
  const { documentation, accessibilityEvaluation } = props;
  const { t } = useTranslation("bookResume");
  const values = getColors(accessibilityEvaluation, documentation);

  return (
    <View style={styles.table}>
      {/* First row */}
      <View style={styles.row}>
        <View style={styles.cell}>
          <Text>{t("resume.sub.section.1.3.final.valuation")}</Text>
          <IconCircle color={values.conservationFinalValuation.color} icon="engineering" />
        </View>

        <View style={styles.cell}>
          <Text style={styles.value_text}>{values.conservationFinalValuation.title ?? "-"}</Text>
        </View>
      </View>

      {/* Second row */}
      <View style={styles.row}>
        <View style={styles.cell}>
          <Text>{t("resume.sub.section.1.3.accessibility.final.valuation")}</Text>
          <IconCircle color={values.accessibilityFinalValuation.color} icon="accesible" />
        </View>

        <View style={styles.cell}>
          <Text style={styles.value_text}>{values?.accessibilityFinalValuation?.title ?? "-"}</Text>
        </View>
      </View>
    </View>
  );
};
