import { ImprovementActionPrintedDTO, ImprovementDTO, PhaseDTO, PhaseDTOPhaseOrderEnum } from "clients";

export class PhaseWithImprovements {
    phase: PhaseDTO;
    phaseImprovements: ImprovementDTO[];

    constructor(phaseDto: PhaseDTO, phaseImprovements: ImprovementDTO[]) {
        this.phase = phaseDto;
        this.phaseImprovements = phaseImprovements;
    }
}


export const getPhasesFromImprovementActions = (improvementAction: ImprovementActionPrintedDTO): PhaseWithImprovements[] => {
    const phases = [...(improvementAction.phases ?? [])].sort((a, b) => {
        return a.phaseOrder!.localeCompare(b.phaseOrder!)
      });
  
    return phases.map((phase) => {
      const phaseImprovements = improvementAction.improvements
        ?.filter((improvement) => improvement.phase && (improvement.phase as unknown as PhaseDTOPhaseOrderEnum) === phase.phaseOrder && improvement.order !== -1)
        ?.sort((a, b) => a.order! - b.order!);
  
      return {
        phase: phase,
        phaseImprovements: phaseImprovements ?? [],
      };
    });
  };
