/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CollegeFilterDTO
 */
export interface CollegeFilterDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CollegeFilterDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CollegeFilterDTO
     */
    billingEntityId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollegeFilterDTO
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CollegeFilterDTO
     */
    feeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeFilterDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeFilterDTO
     */
    searchText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollegeFilterDTO
     */
    vatApplied?: boolean;
}

export function CollegeFilterDTOFromJSON(json: any): CollegeFilterDTO {
    return CollegeFilterDTOFromJSONTyped(json, false);
}

export function CollegeFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollegeFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'billingEntityId': !exists(json, 'billingEntityId') ? undefined : json['billingEntityId'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'feeName': !exists(json, 'feeName') ? undefined : json['feeName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'searchText': !exists(json, 'searchText') ? undefined : json['searchText'],
        'vatApplied': !exists(json, 'vatApplied') ? undefined : json['vatApplied'],
    };
}

export function CollegeFilterDTOToJSON(value?: CollegeFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'billingEntityId': value.billingEntityId,
        'deleted': value.deleted,
        'feeName': value.feeName,
        'name': value.name,
        'searchText': value.searchText,
        'vatApplied': value.vatApplied,
    };
}

