/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CollegeAdministratorDTO,
    CollegeAdministratorDTOFromJSON,
    CollegeAdministratorDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface CreateCollegeAdministratorRequest {
    collegeAdministratorDTO?: CollegeAdministratorDTO;
}

export interface DeleteCollegeAdministratorRequest {
    id: string;
}

export interface GetAllCollegeAdministratorRequest {
    offset?: number;
    limit?: number;
}

export interface GetCollegeAdministratorByCollegeRequest {
    college: string;
}

export interface GetCollegeAdministratorByIDRequest {
    id: string;
}

export interface UpdateCollegeAdministratorRequest {
    collegeAdministratorDTO?: CollegeAdministratorDTO;
}

/**
 * 
 */
export class CollegeAdministratorsApi extends runtime.BaseAPI {

    /**
     * Create a new CollegeAdministrator.
     * Create a CollegeAdministrator.
     */
    async createCollegeAdministratorRaw(requestParameters: CreateCollegeAdministratorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CollegeAdministratorDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-administrator`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CollegeAdministratorDTOToJSON(requestParameters.collegeAdministratorDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollegeAdministratorDTOFromJSON(jsonValue));
    }

    /**
     * Create a new CollegeAdministrator.
     * Create a CollegeAdministrator.
     */
    async createCollegeAdministrator(requestParameters: CreateCollegeAdministratorRequest, initOverrides?: RequestInit): Promise<CollegeAdministratorDTO> {
        const response = await this.createCollegeAdministratorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing CollegeAdministrator, deletes it, and returns the object.
     * Delete an existing CollegeAdministrator by id.
     */
    async deleteCollegeAdministratorRaw(requestParameters: DeleteCollegeAdministratorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CollegeAdministratorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCollegeAdministrator.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-administrator/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollegeAdministratorDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing CollegeAdministrator, deletes it, and returns the object.
     * Delete an existing CollegeAdministrator by id.
     */
    async deleteCollegeAdministrator(requestParameters: DeleteCollegeAdministratorRequest, initOverrides?: RequestInit): Promise<CollegeAdministratorDTO> {
        const response = await this.deleteCollegeAdministratorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all the CollegeAdministrator.
     * Get all CollegeAdministrator objects.
     */
    async getAllCollegeAdministratorRaw(requestParameters: GetAllCollegeAdministratorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CollegeAdministratorDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-administrator`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CollegeAdministratorDTOFromJSON));
    }

    /**
     * Returns all the CollegeAdministrator.
     * Get all CollegeAdministrator objects.
     */
    async getAllCollegeAdministrator(requestParameters: GetAllCollegeAdministratorRequest, initOverrides?: RequestInit): Promise<Array<CollegeAdministratorDTO>> {
        const response = await this.getAllCollegeAdministratorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a CollegeAdministrator object by college.
     * Get a CollegeAdministrator object by college.
     */
    async getCollegeAdministratorByCollegeRaw(requestParameters: GetCollegeAdministratorByCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CollegeAdministratorDTO>> {
        if (requestParameters.college === null || requestParameters.college === undefined) {
            throw new runtime.RequiredError('college','Required parameter requestParameters.college was null or undefined when calling getCollegeAdministratorByCollege.');
        }

        const queryParameters: any = {};

        if (requestParameters.college !== undefined) {
            queryParameters['college'] = requestParameters.college;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-administrator/college`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollegeAdministratorDTOFromJSON(jsonValue));
    }

    /**
     * Get a CollegeAdministrator object by college.
     * Get a CollegeAdministrator object by college.
     */
    async getCollegeAdministratorByCollege(requestParameters: GetCollegeAdministratorByCollegeRequest, initOverrides?: RequestInit): Promise<CollegeAdministratorDTO> {
        const response = await this.getCollegeAdministratorByCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one CollegeAdministrator object by id.
     * Get a CollegeAdministrator object by id.
     */
    async getCollegeAdministratorByIDRaw(requestParameters: GetCollegeAdministratorByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CollegeAdministratorDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCollegeAdministratorByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-administrator/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollegeAdministratorDTOFromJSON(jsonValue));
    }

    /**
     * Returns one CollegeAdministrator object by id.
     * Get a CollegeAdministrator object by id.
     */
    async getCollegeAdministratorByID(requestParameters: GetCollegeAdministratorByIDRequest, initOverrides?: RequestInit): Promise<CollegeAdministratorDTO> {
        const response = await this.getCollegeAdministratorByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing CollegeAdministrator object, updates it, and returns the CollegeAdministrator.
     * Update an existing CollegeAdministrator.
     */
    async updateCollegeAdministratorRaw(requestParameters: UpdateCollegeAdministratorRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CollegeAdministratorDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-administrator`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CollegeAdministratorDTOToJSON(requestParameters.collegeAdministratorDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollegeAdministratorDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing CollegeAdministrator object, updates it, and returns the CollegeAdministrator.
     * Update an existing CollegeAdministrator.
     */
    async updateCollegeAdministrator(requestParameters: UpdateCollegeAdministratorRequest, initOverrides?: RequestInit): Promise<CollegeAdministratorDTO> {
        const response = await this.updateCollegeAdministratorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
