/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecurityImprovementDTO
 */
export interface SecurityImprovementDTO {
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    accessibilityElements?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    accessibilityElementsInformation?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    accessibilityElementsInformationMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    accessibilityElementsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    accessibleEntrancePool?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    accessibleEntrancePoolMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    doorsAgainstRiskOfImpact?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    doorsAgainstRiskOfImpactMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    fallProtectionBarriers?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    fallProtectionBarriersMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    flooringAgainstRiskOfFalling?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    flooringAgainstRiskOfFallingDueToIsolatedSteps?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    flooringAgainstRiskOfFallingDueToIsolatedStepsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    flooringAgainstRiskOfFallingMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    functionalConditions?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    functionalConditionsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    glazingAgainstRiskInItsCleaning?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    glazingAgainstRiskInItsCleaningMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    glazingAgainstRiskOfImpact?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    glazingAgainstRiskOfImpactMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    lightningStrikeProtection?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    lightningStrikeProtectionMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    othersConditions?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    othersConditionsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    othersInProvisionOfElements?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    othersInProvisionOfElementsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    parkingAgainstRiskOfRunOver?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    parkingAgainstRiskOfRunOverMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    poolsAgainstRiskOfDrowning?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    poolsAgainstRiskOfDrowningMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    stairsAgainstFallingRisk?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    stairsAgainstFallingRiskMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    warningSigns?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementDTO
     */
    warningSignsMedia?: string;
}

export function SecurityImprovementDTOFromJSON(json: any): SecurityImprovementDTO {
    return SecurityImprovementDTOFromJSONTyped(json, false);
}

export function SecurityImprovementDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityImprovementDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibilityElements': !exists(json, 'accessibilityElements') ? undefined : json['accessibilityElements'],
        'accessibilityElementsInformation': !exists(json, 'accessibilityElementsInformation') ? undefined : json['accessibilityElementsInformation'],
        'accessibilityElementsInformationMedia': !exists(json, 'accessibilityElementsInformationMedia') ? undefined : json['accessibilityElementsInformationMedia'],
        'accessibilityElementsMedia': !exists(json, 'accessibilityElementsMedia') ? undefined : json['accessibilityElementsMedia'],
        'accessibleEntrancePool': !exists(json, 'accessibleEntrancePool') ? undefined : json['accessibleEntrancePool'],
        'accessibleEntrancePoolMedia': !exists(json, 'accessibleEntrancePoolMedia') ? undefined : json['accessibleEntrancePoolMedia'],
        'doorsAgainstRiskOfImpact': !exists(json, 'doorsAgainstRiskOfImpact') ? undefined : json['doorsAgainstRiskOfImpact'],
        'doorsAgainstRiskOfImpactMedia': !exists(json, 'doorsAgainstRiskOfImpactMedia') ? undefined : json['doorsAgainstRiskOfImpactMedia'],
        'fallProtectionBarriers': !exists(json, 'fallProtectionBarriers') ? undefined : json['fallProtectionBarriers'],
        'fallProtectionBarriersMedia': !exists(json, 'fallProtectionBarriersMedia') ? undefined : json['fallProtectionBarriersMedia'],
        'flooringAgainstRiskOfFalling': !exists(json, 'flooringAgainstRiskOfFalling') ? undefined : json['flooringAgainstRiskOfFalling'],
        'flooringAgainstRiskOfFallingDueToIsolatedSteps': !exists(json, 'flooringAgainstRiskOfFallingDueToIsolatedSteps') ? undefined : json['flooringAgainstRiskOfFallingDueToIsolatedSteps'],
        'flooringAgainstRiskOfFallingDueToIsolatedStepsMedia': !exists(json, 'flooringAgainstRiskOfFallingDueToIsolatedStepsMedia') ? undefined : json['flooringAgainstRiskOfFallingDueToIsolatedStepsMedia'],
        'flooringAgainstRiskOfFallingMedia': !exists(json, 'flooringAgainstRiskOfFallingMedia') ? undefined : json['flooringAgainstRiskOfFallingMedia'],
        'functionalConditions': !exists(json, 'functionalConditions') ? undefined : json['functionalConditions'],
        'functionalConditionsMedia': !exists(json, 'functionalConditionsMedia') ? undefined : json['functionalConditionsMedia'],
        'glazingAgainstRiskInItsCleaning': !exists(json, 'glazingAgainstRiskInItsCleaning') ? undefined : json['glazingAgainstRiskInItsCleaning'],
        'glazingAgainstRiskInItsCleaningMedia': !exists(json, 'glazingAgainstRiskInItsCleaningMedia') ? undefined : json['glazingAgainstRiskInItsCleaningMedia'],
        'glazingAgainstRiskOfImpact': !exists(json, 'glazingAgainstRiskOfImpact') ? undefined : json['glazingAgainstRiskOfImpact'],
        'glazingAgainstRiskOfImpactMedia': !exists(json, 'glazingAgainstRiskOfImpactMedia') ? undefined : json['glazingAgainstRiskOfImpactMedia'],
        'lightningStrikeProtection': !exists(json, 'lightningStrikeProtection') ? undefined : json['lightningStrikeProtection'],
        'lightningStrikeProtectionMedia': !exists(json, 'lightningStrikeProtectionMedia') ? undefined : json['lightningStrikeProtectionMedia'],
        'othersConditions': !exists(json, 'othersConditions') ? undefined : json['othersConditions'],
        'othersConditionsMedia': !exists(json, 'othersConditionsMedia') ? undefined : json['othersConditionsMedia'],
        'othersInProvisionOfElements': !exists(json, 'othersInProvisionOfElements') ? undefined : json['othersInProvisionOfElements'],
        'othersInProvisionOfElementsMedia': !exists(json, 'othersInProvisionOfElementsMedia') ? undefined : json['othersInProvisionOfElementsMedia'],
        'parkingAgainstRiskOfRunOver': !exists(json, 'parkingAgainstRiskOfRunOver') ? undefined : json['parkingAgainstRiskOfRunOver'],
        'parkingAgainstRiskOfRunOverMedia': !exists(json, 'parkingAgainstRiskOfRunOverMedia') ? undefined : json['parkingAgainstRiskOfRunOverMedia'],
        'poolsAgainstRiskOfDrowning': !exists(json, 'poolsAgainstRiskOfDrowning') ? undefined : json['poolsAgainstRiskOfDrowning'],
        'poolsAgainstRiskOfDrowningMedia': !exists(json, 'poolsAgainstRiskOfDrowningMedia') ? undefined : json['poolsAgainstRiskOfDrowningMedia'],
        'stairsAgainstFallingRisk': !exists(json, 'stairsAgainstFallingRisk') ? undefined : json['stairsAgainstFallingRisk'],
        'stairsAgainstFallingRiskMedia': !exists(json, 'stairsAgainstFallingRiskMedia') ? undefined : json['stairsAgainstFallingRiskMedia'],
        'warningSigns': !exists(json, 'warningSigns') ? undefined : json['warningSigns'],
        'warningSignsMedia': !exists(json, 'warningSignsMedia') ? undefined : json['warningSignsMedia'],
    };
}

export function SecurityImprovementDTOToJSON(value?: SecurityImprovementDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibilityElements': value.accessibilityElements,
        'accessibilityElementsInformation': value.accessibilityElementsInformation,
        'accessibilityElementsInformationMedia': value.accessibilityElementsInformationMedia,
        'accessibilityElementsMedia': value.accessibilityElementsMedia,
        'accessibleEntrancePool': value.accessibleEntrancePool,
        'accessibleEntrancePoolMedia': value.accessibleEntrancePoolMedia,
        'doorsAgainstRiskOfImpact': value.doorsAgainstRiskOfImpact,
        'doorsAgainstRiskOfImpactMedia': value.doorsAgainstRiskOfImpactMedia,
        'fallProtectionBarriers': value.fallProtectionBarriers,
        'fallProtectionBarriersMedia': value.fallProtectionBarriersMedia,
        'flooringAgainstRiskOfFalling': value.flooringAgainstRiskOfFalling,
        'flooringAgainstRiskOfFallingDueToIsolatedSteps': value.flooringAgainstRiskOfFallingDueToIsolatedSteps,
        'flooringAgainstRiskOfFallingDueToIsolatedStepsMedia': value.flooringAgainstRiskOfFallingDueToIsolatedStepsMedia,
        'flooringAgainstRiskOfFallingMedia': value.flooringAgainstRiskOfFallingMedia,
        'functionalConditions': value.functionalConditions,
        'functionalConditionsMedia': value.functionalConditionsMedia,
        'glazingAgainstRiskInItsCleaning': value.glazingAgainstRiskInItsCleaning,
        'glazingAgainstRiskInItsCleaningMedia': value.glazingAgainstRiskInItsCleaningMedia,
        'glazingAgainstRiskOfImpact': value.glazingAgainstRiskOfImpact,
        'glazingAgainstRiskOfImpactMedia': value.glazingAgainstRiskOfImpactMedia,
        'lightningStrikeProtection': value.lightningStrikeProtection,
        'lightningStrikeProtectionMedia': value.lightningStrikeProtectionMedia,
        'othersConditions': value.othersConditions,
        'othersConditionsMedia': value.othersConditionsMedia,
        'othersInProvisionOfElements': value.othersInProvisionOfElements,
        'othersInProvisionOfElementsMedia': value.othersInProvisionOfElementsMedia,
        'parkingAgainstRiskOfRunOver': value.parkingAgainstRiskOfRunOver,
        'parkingAgainstRiskOfRunOverMedia': value.parkingAgainstRiskOfRunOverMedia,
        'poolsAgainstRiskOfDrowning': value.poolsAgainstRiskOfDrowning,
        'poolsAgainstRiskOfDrowningMedia': value.poolsAgainstRiskOfDrowningMedia,
        'stairsAgainstFallingRisk': value.stairsAgainstFallingRisk,
        'stairsAgainstFallingRiskMedia': value.stairsAgainstFallingRiskMedia,
        'warningSigns': value.warningSigns,
        'warningSignsMedia': value.warningSignsMedia,
    };
}

