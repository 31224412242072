/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewBuildingDescriptionDTO,
    NewBuildingDescriptionDTOFromJSON,
    NewBuildingDescriptionDTOFromJSONTyped,
    NewBuildingDescriptionDTOToJSON,
    NewConservationStatusDTO,
    NewConservationStatusDTOFromJSON,
    NewConservationStatusDTOFromJSONTyped,
    NewConservationStatusDTOToJSON,
    NewConstructiveDescDTO,
    NewConstructiveDescDTOFromJSON,
    NewConstructiveDescDTOFromJSONTyped,
    NewConstructiveDescDTOToJSON,
    NewDocGeneralData,
    NewDocGeneralDataFromJSON,
    NewDocGeneralDataFromJSONTyped,
    NewDocGeneralDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewDocumentationDTO
 */
export interface NewDocumentationDTO {
    /**
     * 
     * @type {NewBuildingDescriptionDTO}
     * @memberof NewDocumentationDTO
     */
    buildingDescription?: NewBuildingDescriptionDTO;
    /**
     * 
     * @type {NewConservationStatusDTO}
     * @memberof NewDocumentationDTO
     */
    conservationStatus?: NewConservationStatusDTO;
    /**
     * 
     * @type {NewConstructiveDescDTO}
     * @memberof NewDocumentationDTO
     */
    constructiveDesc?: NewConstructiveDescDTO;
    /**
     * 
     * @type {NewDocGeneralData}
     * @memberof NewDocumentationDTO
     */
    generalData?: NewDocGeneralData;
}

export function NewDocumentationDTOFromJSON(json: any): NewDocumentationDTO {
    return NewDocumentationDTOFromJSONTyped(json, false);
}

export function NewDocumentationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewDocumentationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildingDescription': !exists(json, 'buildingDescription') ? undefined : NewBuildingDescriptionDTOFromJSON(json['buildingDescription']),
        'conservationStatus': !exists(json, 'conservationStatus') ? undefined : NewConservationStatusDTOFromJSON(json['conservationStatus']),
        'constructiveDesc': !exists(json, 'constructiveDesc') ? undefined : NewConstructiveDescDTOFromJSON(json['constructiveDesc']),
        'generalData': !exists(json, 'generalData') ? undefined : NewDocGeneralDataFromJSON(json['generalData']),
    };
}

export function NewDocumentationDTOToJSON(value?: NewDocumentationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildingDescription': NewBuildingDescriptionDTOToJSON(value.buildingDescription),
        'conservationStatus': NewConservationStatusDTOToJSON(value.conservationStatus),
        'constructiveDesc': NewConstructiveDescDTOToJSON(value.constructiveDesc),
        'generalData': NewDocGeneralDataToJSON(value.generalData),
    };
}

