import { Api } from "../../../infrastructure/data/api-client"
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository"
import IFeeApi from "../ifee-api";


export default class FeeRepository extends BaseRepository<IFeeApi> {

  constructor() { super(Api.FeeApi, false); }

  async getAllFee() {
    const client = await this.apiClient;
    return await client.getAllFee();
  }
}