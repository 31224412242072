/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SecurityImprovementPrintedDTO
 */
export interface SecurityImprovementPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    accessibilityElements?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    accessibilityElementsInformation?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    accessibilityElementsInformationMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    accessibilityElementsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    accessibleEntrancePool?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    accessibleEntrancePoolMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    doorsAgainstRiskOfImpact?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    doorsAgainstRiskOfImpactMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    fallProtectionBarriers?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    fallProtectionBarriersMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    flooringAgainstRiskOfFalling?: string;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    flooringAgainstRiskOfFallingDueToIsolatedSteps?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    flooringAgainstRiskOfFallingDueToIsolatedStepsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    flooringAgainstRiskOfFallingMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    functionalConditions?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    functionalConditionsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    glazingAgainstRiskInItsCleaning?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    glazingAgainstRiskInItsCleaningMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    glazingAgainstRiskOfImpact?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    glazingAgainstRiskOfImpactMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    lightningStrikeProtection?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    lightningStrikeProtectionMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    othersConditions?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    othersConditionsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    othersInProvisionOfElements?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    othersInProvisionOfElementsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    parkingAgainstRiskOfRunOver?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    parkingAgainstRiskOfRunOverMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    poolsAgainstRiskOfDrowning?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    poolsAgainstRiskOfDrowningMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    stairsAgainstFallingRisk?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    stairsAgainstFallingRiskMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof SecurityImprovementPrintedDTO
     */
    warningSigns?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof SecurityImprovementPrintedDTO
     */
    warningSignsMedia?: MediaPrintedDTO;
}

export function SecurityImprovementPrintedDTOFromJSON(json: any): SecurityImprovementPrintedDTO {
    return SecurityImprovementPrintedDTOFromJSONTyped(json, false);
}

export function SecurityImprovementPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecurityImprovementPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibilityElements': !exists(json, 'accessibilityElements') ? undefined : json['accessibilityElements'],
        'accessibilityElementsInformation': !exists(json, 'accessibilityElementsInformation') ? undefined : json['accessibilityElementsInformation'],
        'accessibilityElementsInformationMedia': !exists(json, 'accessibilityElementsInformationMedia') ? undefined : MediaPrintedDTOFromJSON(json['accessibilityElementsInformationMedia']),
        'accessibilityElementsMedia': !exists(json, 'accessibilityElementsMedia') ? undefined : MediaPrintedDTOFromJSON(json['accessibilityElementsMedia']),
        'accessibleEntrancePool': !exists(json, 'accessibleEntrancePool') ? undefined : json['accessibleEntrancePool'],
        'accessibleEntrancePoolMedia': !exists(json, 'accessibleEntrancePoolMedia') ? undefined : MediaPrintedDTOFromJSON(json['accessibleEntrancePoolMedia']),
        'doorsAgainstRiskOfImpact': !exists(json, 'doorsAgainstRiskOfImpact') ? undefined : json['doorsAgainstRiskOfImpact'],
        'doorsAgainstRiskOfImpactMedia': !exists(json, 'doorsAgainstRiskOfImpactMedia') ? undefined : MediaPrintedDTOFromJSON(json['doorsAgainstRiskOfImpactMedia']),
        'fallProtectionBarriers': !exists(json, 'fallProtectionBarriers') ? undefined : json['fallProtectionBarriers'],
        'fallProtectionBarriersMedia': !exists(json, 'fallProtectionBarriersMedia') ? undefined : MediaPrintedDTOFromJSON(json['fallProtectionBarriersMedia']),
        'flooringAgainstRiskOfFalling': !exists(json, 'flooringAgainstRiskOfFalling') ? undefined : json['flooringAgainstRiskOfFalling'],
        'flooringAgainstRiskOfFallingDueToIsolatedSteps': !exists(json, 'flooringAgainstRiskOfFallingDueToIsolatedSteps') ? undefined : json['flooringAgainstRiskOfFallingDueToIsolatedSteps'],
        'flooringAgainstRiskOfFallingDueToIsolatedStepsMedia': !exists(json, 'flooringAgainstRiskOfFallingDueToIsolatedStepsMedia') ? undefined : MediaPrintedDTOFromJSON(json['flooringAgainstRiskOfFallingDueToIsolatedStepsMedia']),
        'flooringAgainstRiskOfFallingMedia': !exists(json, 'flooringAgainstRiskOfFallingMedia') ? undefined : MediaPrintedDTOFromJSON(json['flooringAgainstRiskOfFallingMedia']),
        'functionalConditions': !exists(json, 'functionalConditions') ? undefined : json['functionalConditions'],
        'functionalConditionsMedia': !exists(json, 'functionalConditionsMedia') ? undefined : MediaPrintedDTOFromJSON(json['functionalConditionsMedia']),
        'glazingAgainstRiskInItsCleaning': !exists(json, 'glazingAgainstRiskInItsCleaning') ? undefined : json['glazingAgainstRiskInItsCleaning'],
        'glazingAgainstRiskInItsCleaningMedia': !exists(json, 'glazingAgainstRiskInItsCleaningMedia') ? undefined : MediaPrintedDTOFromJSON(json['glazingAgainstRiskInItsCleaningMedia']),
        'glazingAgainstRiskOfImpact': !exists(json, 'glazingAgainstRiskOfImpact') ? undefined : json['glazingAgainstRiskOfImpact'],
        'glazingAgainstRiskOfImpactMedia': !exists(json, 'glazingAgainstRiskOfImpactMedia') ? undefined : MediaPrintedDTOFromJSON(json['glazingAgainstRiskOfImpactMedia']),
        'lightningStrikeProtection': !exists(json, 'lightningStrikeProtection') ? undefined : json['lightningStrikeProtection'],
        'lightningStrikeProtectionMedia': !exists(json, 'lightningStrikeProtectionMedia') ? undefined : MediaPrintedDTOFromJSON(json['lightningStrikeProtectionMedia']),
        'othersConditions': !exists(json, 'othersConditions') ? undefined : json['othersConditions'],
        'othersConditionsMedia': !exists(json, 'othersConditionsMedia') ? undefined : MediaPrintedDTOFromJSON(json['othersConditionsMedia']),
        'othersInProvisionOfElements': !exists(json, 'othersInProvisionOfElements') ? undefined : json['othersInProvisionOfElements'],
        'othersInProvisionOfElementsMedia': !exists(json, 'othersInProvisionOfElementsMedia') ? undefined : MediaPrintedDTOFromJSON(json['othersInProvisionOfElementsMedia']),
        'parkingAgainstRiskOfRunOver': !exists(json, 'parkingAgainstRiskOfRunOver') ? undefined : json['parkingAgainstRiskOfRunOver'],
        'parkingAgainstRiskOfRunOverMedia': !exists(json, 'parkingAgainstRiskOfRunOverMedia') ? undefined : MediaPrintedDTOFromJSON(json['parkingAgainstRiskOfRunOverMedia']),
        'poolsAgainstRiskOfDrowning': !exists(json, 'poolsAgainstRiskOfDrowning') ? undefined : json['poolsAgainstRiskOfDrowning'],
        'poolsAgainstRiskOfDrowningMedia': !exists(json, 'poolsAgainstRiskOfDrowningMedia') ? undefined : MediaPrintedDTOFromJSON(json['poolsAgainstRiskOfDrowningMedia']),
        'stairsAgainstFallingRisk': !exists(json, 'stairsAgainstFallingRisk') ? undefined : json['stairsAgainstFallingRisk'],
        'stairsAgainstFallingRiskMedia': !exists(json, 'stairsAgainstFallingRiskMedia') ? undefined : MediaPrintedDTOFromJSON(json['stairsAgainstFallingRiskMedia']),
        'warningSigns': !exists(json, 'warningSigns') ? undefined : json['warningSigns'],
        'warningSignsMedia': !exists(json, 'warningSignsMedia') ? undefined : MediaPrintedDTOFromJSON(json['warningSignsMedia']),
    };
}

export function SecurityImprovementPrintedDTOToJSON(value?: SecurityImprovementPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibilityElements': value.accessibilityElements,
        'accessibilityElementsInformation': value.accessibilityElementsInformation,
        'accessibilityElementsInformationMedia': MediaPrintedDTOToJSON(value.accessibilityElementsInformationMedia),
        'accessibilityElementsMedia': MediaPrintedDTOToJSON(value.accessibilityElementsMedia),
        'accessibleEntrancePool': value.accessibleEntrancePool,
        'accessibleEntrancePoolMedia': MediaPrintedDTOToJSON(value.accessibleEntrancePoolMedia),
        'doorsAgainstRiskOfImpact': value.doorsAgainstRiskOfImpact,
        'doorsAgainstRiskOfImpactMedia': MediaPrintedDTOToJSON(value.doorsAgainstRiskOfImpactMedia),
        'fallProtectionBarriers': value.fallProtectionBarriers,
        'fallProtectionBarriersMedia': MediaPrintedDTOToJSON(value.fallProtectionBarriersMedia),
        'flooringAgainstRiskOfFalling': value.flooringAgainstRiskOfFalling,
        'flooringAgainstRiskOfFallingDueToIsolatedSteps': value.flooringAgainstRiskOfFallingDueToIsolatedSteps,
        'flooringAgainstRiskOfFallingDueToIsolatedStepsMedia': MediaPrintedDTOToJSON(value.flooringAgainstRiskOfFallingDueToIsolatedStepsMedia),
        'flooringAgainstRiskOfFallingMedia': MediaPrintedDTOToJSON(value.flooringAgainstRiskOfFallingMedia),
        'functionalConditions': value.functionalConditions,
        'functionalConditionsMedia': MediaPrintedDTOToJSON(value.functionalConditionsMedia),
        'glazingAgainstRiskInItsCleaning': value.glazingAgainstRiskInItsCleaning,
        'glazingAgainstRiskInItsCleaningMedia': MediaPrintedDTOToJSON(value.glazingAgainstRiskInItsCleaningMedia),
        'glazingAgainstRiskOfImpact': value.glazingAgainstRiskOfImpact,
        'glazingAgainstRiskOfImpactMedia': MediaPrintedDTOToJSON(value.glazingAgainstRiskOfImpactMedia),
        'lightningStrikeProtection': value.lightningStrikeProtection,
        'lightningStrikeProtectionMedia': MediaPrintedDTOToJSON(value.lightningStrikeProtectionMedia),
        'othersConditions': value.othersConditions,
        'othersConditionsMedia': MediaPrintedDTOToJSON(value.othersConditionsMedia),
        'othersInProvisionOfElements': value.othersInProvisionOfElements,
        'othersInProvisionOfElementsMedia': MediaPrintedDTOToJSON(value.othersInProvisionOfElementsMedia),
        'parkingAgainstRiskOfRunOver': value.parkingAgainstRiskOfRunOver,
        'parkingAgainstRiskOfRunOverMedia': MediaPrintedDTOToJSON(value.parkingAgainstRiskOfRunOverMedia),
        'poolsAgainstRiskOfDrowning': value.poolsAgainstRiskOfDrowning,
        'poolsAgainstRiskOfDrowningMedia': MediaPrintedDTOToJSON(value.poolsAgainstRiskOfDrowningMedia),
        'stairsAgainstFallingRisk': value.stairsAgainstFallingRisk,
        'stairsAgainstFallingRiskMedia': MediaPrintedDTOToJSON(value.stairsAgainstFallingRiskMedia),
        'warningSigns': value.warningSigns,
        'warningSignsMedia': MediaPrintedDTOToJSON(value.warningSignsMedia),
    };
}

