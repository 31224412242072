/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewAccessibilityEvaluationsDTO,
    NewAccessibilityEvaluationsDTOFromJSON,
    NewAccessibilityEvaluationsDTOFromJSONTyped,
    NewAccessibilityEvaluationsDTOToJSON,
    NewDocumentationDTO,
    NewDocumentationDTOFromJSON,
    NewDocumentationDTOFromJSONTyped,
    NewDocumentationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewBuildingInspectionDTO
 */
export interface NewBuildingInspectionDTO {
    /**
     * 
     * @type {Array<NewAccessibilityEvaluationsDTO>}
     * @memberof NewBuildingInspectionDTO
     */
    accessibilityEvaluations?: Array<NewAccessibilityEvaluationsDTO>;
    /**
     * 
     * @type {NewDocumentationDTO}
     * @memberof NewBuildingInspectionDTO
     */
    documentation?: NewDocumentationDTO;
}

export function NewBuildingInspectionDTOFromJSON(json: any): NewBuildingInspectionDTO {
    return NewBuildingInspectionDTOFromJSONTyped(json, false);
}

export function NewBuildingInspectionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewBuildingInspectionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibilityEvaluations': !exists(json, 'accessibilityEvaluations') ? undefined : ((json['accessibilityEvaluations'] as Array<any>).map(NewAccessibilityEvaluationsDTOFromJSON)),
        'documentation': !exists(json, 'documentation') ? undefined : NewDocumentationDTOFromJSON(json['documentation']),
    };
}

export function NewBuildingInspectionDTOToJSON(value?: NewBuildingInspectionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibilityEvaluations': value.accessibilityEvaluations === undefined ? undefined : ((value.accessibilityEvaluations as Array<any>).map(NewAccessibilityEvaluationsDTOToJSON)),
        'documentation': NewDocumentationDTOToJSON(value.documentation),
    };
}

