import { ScreenBase } from "@frui.ts/screens";
import { Avatar, Button, IAvatar, Popover, TextLink, TypographyTheme } from "@movicoders/movicoders-components";
import { Icon, IconsList } from "@movicoders/movicoders-components/dist/icon/Icon";
import { ChevronLeft, ChevronRight, Menu } from "@mui/icons-material";
import {
  Box,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import LOGO2consejogeralBlanco from "../../assets/images/LOGO2consejogeralBlanco.png";
import movicodersLogo from "../../assets/images/logoMovicoders.png";
import logo from "../../assets/images/logo.png";
import { ExtendedSignupTechnician, ProfileDTO, TechnicianDTO} from "../../clients";
import { Colors } from "../../constants";
import { SessionStoreFactory } from "../../infrastructure/data/session-store-factory";
import i18n from "../../infrastructure/localization/i18n";
import { reducedFilter } from "../../utils/arrayHelper";
import RootViewModel from "../../viewmodels/root-view-model";
import { AppBar } from "./app-bar";
import { DrawerHeader } from "./drawer-header";
import { Drawer } from "./lee-drawer";
import { ToolbarStyles } from "./toolbar-styles";

interface iPopOverProps {
  currentUser: TechnicianDTO;
  currentRegisterUser: ExtendedSignupTechnician;
  currentAdmin?: ProfileDTO;
}

interface ILeeToolbar {
  rootvm: RootViewModel;
  drawerEntries?: () => HTMLElement;
  popOverProps: iPopOverProps;
}

const drawerIcons = (navigationName: string) => {
  switch (navigationName) {
    case "dashboard":
      return <Icon element={IconsList.Map} />;
    case "roles":
      return <Icon element={IconsList.Person} />;
    case "report":
      return <Icon element={IconsList.Book} />;
    case "users":
      return <Icon element={IconsList.People} />;
    case "buildings":
      return <Icon element={IconsList.LocationCity} />;
    case "profile":
      return <Icon element={IconsList.Home} />;
    default:
      return <Icon element={IconsList.Alarm} />;
  }
};

const LeeToolbar: React.FC<ILeeToolbar> = ({ popOverProps, rootvm, children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const classes = ToolbarStyles();

  const [avatarFeatures] = useState<IAvatar>({
    border: true,
    backgroundColor: "transparent",
    alt: "avatar",
    variant: "circular",
    lettersColor: "white",
    classNameAvatar: classes.toolbar_toolbarAvatar,
    height: "40px",
    width: "40px",
    src: movicodersLogo,
  });
  const popoverContent: JSX.Element = SessionStoreFactory.getSessionStore().getToken() ? (
    <Grid container className={classes.toolbar_gridContainer}>
      <Grid item xs={12} md={12} className={classes.toolbar_gridItemHeader}>
        <Grid item xs={6} md={6}>
          <TypographyTheme text={"LEEx"} classTypography={classes.toolbar_popoverTitles} />
        </Grid>
        <Grid item xs={6} md={6} className={classes.toolbar_gridLogOff}>
          <TextLink
            text={i18n.t("toolbar.logoff")}
            size={14}
            onClick={() => {
              rootvm.logOff();
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} className={classes.toolbar_gridItemStructure}>
        <Grid item xs={4} md={4} className={classes.toolbar_gridMargin}>
          <Avatar letters={"LE"} src={movicodersLogo} height="90px" width="90px" lettersSize={25} />
        </Grid>
        <Grid item xs={8} md={8}>
          {popOverProps.currentUser.name !== undefined && (
            <React.Fragment>
              <Grid item xs={12} md={12}>
                <TypographyTheme
                  text={
                    popOverProps.currentUser
                      ? popOverProps.currentUser?.name + " " + popOverProps.currentUser.surname
                      : popOverProps.currentAdmin
                        ? popOverProps.currentAdmin.email!
                        : ""
                  }
                  bold
                  color={Colors.primary}
                  classTypography={classes.toolbar_popoverTitles}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TypographyTheme
                  text={popOverProps.currentUser.email ? popOverProps.currentUser!.email! : ""}
                  size={9}
                  color={Colors.textPrimary}
                  classTypography={classes.toolbar_popoverTitles}
                />
              </Grid>
            </React.Fragment>
          )}
          {popOverProps.currentAdmin && (
            <Grid item xs={12} md={12}>
              <TypographyTheme
                text={popOverProps.currentAdmin.email!}
                size={11}
                bold
                color={Colors.textPrimary}
                classTypography={classes.toolbar_popoverTitles}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyContent={"space-evenly"} alignItems={"center"} minWidth={"30vh"} minHeight={"10vh"}>
      <Button onClick={() => rootvm.navigate("login", {})} text={i18n.t("toolbar.login")} style={{ paddingRight: 10, paddingLeft: 10 }} />
      <Button onClick={() => rootvm.navigate("register", {})} text={i18n.t("toolbar.register")} style={{ paddingRight: 10, paddingLeft: 10 }} />
    </Grid>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <div style={{ display: "inline-flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              {SessionStoreFactory.getSessionStore().getToken() ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    marginLeft: "-5px !important",
                    ...(open && { display: "none" }),
                  }}
                >
                  <Menu />
                </IconButton>
              ) : (
                <></>
              )}
              <img width={"40px"} style={{ marginRight: 5 }} src={logo} alt="LEE logo" />
              <Typography variant="h6" noWrap component="div">
                LEEx
              </Typography>
            </div>
            {/* TODO: change harcode validation .fee?.name === "CGATE" */}
            <div style={{ display: "flex", alignItems: "center" }}> 
              {popOverProps.currentUser.fee?.name === "CGATE" && (
                <img src={LOGO2consejogeralBlanco} alt="logo consejo general" width={"175px"} />
              )}
              {popOverProps.currentRegisterUser.fee?.name === "CGATE" && (
                <img src={LOGO2consejogeralBlanco} alt="logo consejo general" width={"175px"} />
              )}
              {/* Local env easy recognition */}
              {process.env.NODE_ENV === "development" ? process.env.REACT_APP_ENV : null}
              <div style={{ marginBottom: 5 }}>
                <Popover
                  avatarButton={avatarFeatures}
                  paperProps={{ className: classes.toolbar_popover }}
                  contentFree={popoverContent}
                  verticalOpenOrigin={"top"}
                  horizontalOpenOrigin="right"
                  verticalAnchorOrigin="bottom"
                  horizontalAnchorOrigin="right"
                  anchorPosition={{ top: 50, left: 50000 }}
                />
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {SessionStoreFactory.getSessionStore().getToken() ? (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}</IconButton>
          </DrawerHeader>
          <Divider />
          <List sx={{ py: 0 }}>
            {reducedFilter(rootvm.children.filter(Boolean) as any[], ["name", "navigationName", "showInToolbar"], (item: any) => item.showInToolbar).map(
              (vm: ScreenBase, index) => {
                const selected = rootvm.activeChild?.navigationName === vm.navigationName;

                return (
                  <ListItem
                    key={vm.navigationName}
                    disablePadding
                    sx={{ display: "block" }}
                    className={
                      selected ? (open ? classes.toolbar_lisItemSelected : classes.toolbar_lisItemSelectedMiniDrawer) : classes.toolbar_listItemNotSelected
                    }
                    onClick={() => rootvm.navigate(vm.navigationName, {})}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {drawerIcons(vm.navigationName)}
                      </ListItemIcon>
                      <ListItemText primary={rootvm.translate(vm.name)} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                );
              }
            )}
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              className={classes.toolbar_listItemNotSelected}
              onClick={() => {
                window.open("http://ayudalibroedificioexistente.es/");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Icon element={IconsList.Help} />
                </ListItemIcon>
                <ListItemText primary={rootvm.translate("report.view.help")} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      ) : (
        <></>
      )}

      <Box component="main" sx={{ marginLeft: open ? 37 : 7 }}>
        <DrawerHeader />
        {children}
      </Box>
    </div>
  );
};

export default LeeToolbar;
