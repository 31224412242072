
import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import { BillApi } from "../api/bill-api";

export default class BillRepository extends BaseRepository<BillApi> {
    constructor() {
      super(Api.BillApi, false);
    }

    async getBillByBookId(id: string) {
      const client = await this.apiClient;
      return await client.getBillByBookId(id);
    }
    async getBillByTransactionId(id: string) {
      const client = await this.apiClient;
      return await client.getBillByTransactionId(id);
    }
  
}