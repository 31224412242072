/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhaseDTO
 */
export interface PhaseDTO {
    /**
     * 
     * @type {number}
     * @memberof PhaseDTO
     */
    durationInMonths?: number;
    /**
     * 
     * @type {number}
     * @memberof PhaseDTO
     */
    energyDemandReductionPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof PhaseDTO
     */
    estimatedStart?: PhaseDTOEstimatedStartEnum;
    /**
     * 
     * @type {string}
     * @memberof PhaseDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof PhaseDTO
     */
    nonRenewablePrimaryEnergyConsumptionReductionPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof PhaseDTO
     */
    phaseOrder?: PhaseDTOPhaseOrderEnum;
    /**
     * 
     * @type {Date}
     * @memberof PhaseDTO
     */
    startDate?: Date;
}

/**
* @export
* @enum {string}
*/
export enum PhaseDTOEstimatedStartEnum {
    MoreThanAYear = 'MORE_THAN_A_YEAR',
    SixToTwelveMonths = 'SIX_TO_TWELVE_MONTHS',
    ZeroToSixMonths = 'ZERO_TO_SIX_MONTHS'
}/**
* @export
* @enum {string}
*/
export enum PhaseDTOPhaseOrderEnum {
    NoScheduled = 'NO_SCHEDULED',
    Phase1 = 'PHASE_1',
    Phase2 = 'PHASE_2',
    Phase3 = 'PHASE_3',
    Phase4 = 'PHASE_4',
    Phase5 = 'PHASE_5'
}

export function PhaseDTOFromJSON(json: any): PhaseDTO {
    return PhaseDTOFromJSONTyped(json, false);
}

export function PhaseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhaseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'durationInMonths': !exists(json, 'durationInMonths') ? undefined : json['durationInMonths'],
        'energyDemandReductionPercentage': !exists(json, 'energyDemandReductionPercentage') ? undefined : json['energyDemandReductionPercentage'],
        'estimatedStart': !exists(json, 'estimatedStart') ? undefined : json['estimatedStart'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nonRenewablePrimaryEnergyConsumptionReductionPercentage': !exists(json, 'nonRenewablePrimaryEnergyConsumptionReductionPercentage') ? undefined : json['nonRenewablePrimaryEnergyConsumptionReductionPercentage'],
        'phaseOrder': !exists(json, 'phaseOrder') ? undefined : json['phaseOrder'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
    };
}

export function PhaseDTOToJSON(value?: PhaseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'durationInMonths': value.durationInMonths,
        'energyDemandReductionPercentage': value.energyDemandReductionPercentage,
        'estimatedStart': value.estimatedStart,
        'id': value.id,
        'nonRenewablePrimaryEnergyConsumptionReductionPercentage': value.nonRenewablePrimaryEnergyConsumptionReductionPercentage,
        'phaseOrder': value.phaseOrder,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
    };
}

