/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VoucherListItemDTO,
    VoucherListItemDTOFromJSON,
    VoucherListItemDTOFromJSONTyped,
    VoucherListItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface VouchersPageDTO
 */
export interface VouchersPageDTO {
    /**
     * 
     * @type {Array<VoucherListItemDTO>}
     * @memberof VouchersPageDTO
     */
    content?: Array<VoucherListItemDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof VouchersPageDTO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VouchersPageDTO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VouchersPageDTO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof VouchersPageDTO
     */
    numberOfElements?: number;
    /**
     * 
     * @type {object}
     * @memberof VouchersPageDTO
     */
    pageable?: object;
    /**
     * 
     * @type {number}
     * @memberof VouchersPageDTO
     */
    size?: number;
    /**
     * 
     * @type {object}
     * @memberof VouchersPageDTO
     */
    sort?: object;
    /**
     * 
     * @type {number}
     * @memberof VouchersPageDTO
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof VouchersPageDTO
     */
    totalPages?: number;
}

export function VouchersPageDTOFromJSON(json: any): VouchersPageDTO {
    return VouchersPageDTOFromJSONTyped(json, false);
}

export function VouchersPageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VouchersPageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(VoucherListItemDTOFromJSON)),
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'pageable': !exists(json, 'pageable') ? undefined : json['pageable'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
    };
}

export function VouchersPageDTOToJSON(value?: VouchersPageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(VoucherListItemDTOToJSON)),
        'first': value.first,
        'last': value.last,
        'number': value.number,
        'numberOfElements': value.numberOfElements,
        'pageable': value.pageable,
        'size': value.size,
        'sort': value.sort,
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
    };
}

