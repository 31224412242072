import { StyleSheet } from "@react-pdf/renderer";
import { ColorsNewPdf } from "../../../../constants";
import { fontsNewPdf } from "pdf/_common/fonts/fonts";
const styles = StyleSheet.create({
  body: {
    padding: 40,
    fontSize: 12,
    fontFamily: fontsNewPdf.RobotoRegular,
    color: ColorsNewPdf.black,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  logoContainer: {
    width: 50,
    height: 50,
    borderRadius: 10,
    backgroundColor: '#0d2b52',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  logo: {
    objectFit: 'cover',
  },
  invoiceDetails: {
    paddingLeft:10,
    paddingRight:10,
    width: '50%',
    color: ColorsNewPdf.grey_text,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontFamily: fontsNewPdf.RobotoBold,
    color: ColorsNewPdf.grey_text,
  },
  clientAndBillingEntity: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  clientDetails: {
    paddingRight:40,
    width: '50%',
  },
  billingEntityDetails: {
    marginTop: 20,
    paddingLeft: 10,
    paddingRight:10,
    width: '50%',
    borderLeft: '1.5px solid #e1dcdc',
    borderRight: '1.5px solid #e1dcdc',
},

  table: {
    display: 'flex',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeader: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontFamily: fontsNewPdf.RobotoBold,
  },
  tableCell: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  conceptColumn: {
    flex: 2,
  },
  quantityColumn: {
    flex: 1,
    textAlign: 'center',
  },
  amountColumn: {
    flex: 1,
    textAlign: 'right',
  },
  titleText: {
    fontFamily: fontsNewPdf.RobotoBold,
  },
  totalContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  totalLabelColumn: {
    flexDirection: 'column',
    color: ColorsNewPdf.grey_text,
  },
  totalValueColumn: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    color: ColorsNewPdf.grey_text,
    fontFamily: fontsNewPdf.RobotoBold,
  },
  subTotalText: {
    marginBottom: 5,
  },
  totalText: {
    fontFamily: fontsNewPdf.RobotoBold,
    color: ColorsNewPdf.black,
    fontSize: 14,
  },
});

export default styles;

