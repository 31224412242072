/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessibilityEvaluationDTO,
    AccessibilityEvaluationDTOFromJSON,
    AccessibilityEvaluationDTOFromJSONTyped,
    AccessibilityEvaluationDTOToJSON,
    AgentParticipationDTO,
    AgentParticipationDTOFromJSON,
    AgentParticipationDTOFromJSONTyped,
    AgentParticipationDTOToJSON,
    BuildingCompositionDTO,
    BuildingCompositionDTOFromJSON,
    BuildingCompositionDTOFromJSONTyped,
    BuildingCompositionDTOToJSON,
    BuildingConstructionDescriptionDTO,
    BuildingConstructionDescriptionDTOFromJSON,
    BuildingConstructionDescriptionDTOFromJSONTyped,
    BuildingConstructionDescriptionDTOToJSON,
    BuildingDTO,
    BuildingDTOFromJSON,
    BuildingDTOFromJSONTyped,
    BuildingDTOToJSON,
    BuildingLocationInfoDTO,
    BuildingLocationInfoDTOFromJSON,
    BuildingLocationInfoDTOFromJSONTyped,
    BuildingLocationInfoDTOToJSON,
    EnergyEfficiencyCertificateDTO,
    EnergyEfficiencyCertificateDTOFromJSON,
    EnergyEfficiencyCertificateDTOFromJSONTyped,
    EnergyEfficiencyCertificateDTOToJSON,
    FullTechnicianParticipationDTO,
    FullTechnicianParticipationDTOFromJSON,
    FullTechnicianParticipationDTOFromJSONTyped,
    FullTechnicianParticipationDTOToJSON,
    ImprovementActionDTO,
    ImprovementActionDTOFromJSON,
    ImprovementActionDTOFromJSONTyped,
    ImprovementActionDTOToJSON,
    InspectionDTO,
    InspectionDTOFromJSON,
    InspectionDTOFromJSONTyped,
    InspectionDTOToJSON,
    LegalDataDTO,
    LegalDataDTOFromJSON,
    LegalDataDTOFromJSONTyped,
    LegalDataDTOToJSON,
    LicenseDTO,
    LicenseDTOFromJSON,
    LicenseDTOFromJSONTyped,
    LicenseDTOToJSON,
    MaintenancePlanActionDTO,
    MaintenancePlanActionDTOFromJSON,
    MaintenancePlanActionDTOFromJSONTyped,
    MaintenancePlanActionDTOToJSON,
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
    ProceedingsDTO,
    ProceedingsDTOFromJSON,
    ProceedingsDTOFromJSONTyped,
    ProceedingsDTOToJSON,
    UrbanDataDTO,
    UrbanDataDTOFromJSON,
    UrbanDataDTOFromJSONTyped,
    UrbanDataDTOToJSON,
    UsagePlanDTO,
    UsagePlanDTOFromJSON,
    UsagePlanDTOFromJSONTyped,
    UsagePlanDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BookDTO
 */
export interface BookDTO {
    /**
     * 
     * @type {Array<AccessibilityEvaluationDTO>}
     * @memberof BookDTO
     */
    accessibilityEvaluations?: Array<AccessibilityEvaluationDTO>;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof BookDTO
     */
    actionRegistries?: Array<MediaDTO>;
    /**
     * 
     * @type {Array<AgentParticipationDTO>}
     * @memberof BookDTO
     */
    agents?: Array<AgentParticipationDTO>;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof BookDTO
     */
    bestPractices?: Array<MediaDTO>;
    /**
     * 
     * @type {BuildingCompositionDTO}
     * @memberof BookDTO
     */
    buildingComposition?: BuildingCompositionDTO;
    /**
     * 
     * @type {BuildingConstructionDescriptionDTO}
     * @memberof BookDTO
     */
    buildingConstructionDescription?: BuildingConstructionDescriptionDTO;
    /**
     * 
     * @type {BuildingLocationInfoDTO}
     * @memberof BookDTO
     */
    buildingLocationInfo?: BuildingLocationInfoDTO;
    /**
     * 
     * @type {BuildingDTO}
     * @memberof BookDTO
     */
    cadastralInfo?: BuildingDTO;
    /**
     * 
     * @type {string}
     * @memberof BookDTO
     */
    code?: string;
    /**
     * 
     * @type {Array<EnergyEfficiencyCertificateDTO>}
     * @memberof BookDTO
     */
    energyEfficiencyCertificateDTO?: Array<EnergyEfficiencyCertificateDTO>;
    /**
     * 
     * @type {string}
     * @memberof BookDTO
     */
    id?: string;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof BookDTO
     */
    ieeDocuments?: Array<MediaDTO>;
    /**
     * 
     * @type {Array<ImprovementActionDTO>}
     * @memberof BookDTO
     */
    improvements?: Array<ImprovementActionDTO>;
    /**
     * 
     * @type {Array<InspectionDTO>}
     * @memberof BookDTO
     */
    inspections?: Array<InspectionDTO>;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof BookDTO
     */
    iteDocuments?: Array<MediaDTO>;
    /**
     * 
     * @type {LegalDataDTO}
     * @memberof BookDTO
     */
    legalData?: LegalDataDTO;
    /**
     * 
     * @type {Array<LicenseDTO>}
     * @memberof BookDTO
     */
    licenses?: Array<LicenseDTO>;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof BookDTO
     */
    maintenanceContracts?: Array<MediaDTO>;
    /**
     * 
     * @type {Array<MaintenancePlanActionDTO>}
     * @memberof BookDTO
     */
    maintenancePlanActions?: Array<MaintenancePlanActionDTO>;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof BookDTO
     */
    otherDocuments?: Array<MediaDTO>;
    /**
     * 
     * @type {Array<ProceedingsDTO>}
     * @memberof BookDTO
     */
    proceedings?: Array<ProceedingsDTO>;
    /**
     * 
     * @type {string}
     * @memberof BookDTO
     */
    status?: BookDTOStatusEnum;
    /**
     * 
     * @type {Array<FullTechnicianParticipationDTO>}
     * @memberof BookDTO
     */
    technicians?: Array<FullTechnicianParticipationDTO>;
    /**
     * 
     * @type {UrbanDataDTO}
     * @memberof BookDTO
     */
    urbanData?: UrbanDataDTO;
    /**
     * 
     * @type {Array<UsagePlanDTO>}
     * @memberof BookDTO
     */
    usagePlans?: Array<UsagePlanDTO>;
}

/**
* @export
* @enum {string}
*/
export enum BookDTOStatusEnum {
    Approved = 'APPROVED',
    Finished = 'FINISHED',
    InProgress = 'IN_PROGRESS',
    MaintenanceRequested = 'MAINTENANCE_REQUESTED',
    OnMaintenance = 'ON_MAINTENANCE',
    Open = 'OPEN',
    Rejected = 'REJECTED',
    ValidationRequested = 'VALIDATION_REQUESTED',
    WithIncidents = 'WITH_INCIDENTS'
}

export function BookDTOFromJSON(json: any): BookDTO {
    return BookDTOFromJSONTyped(json, false);
}

export function BookDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibilityEvaluations': !exists(json, 'accessibilityEvaluations') ? undefined : ((json['accessibilityEvaluations'] as Array<any>).map(AccessibilityEvaluationDTOFromJSON)),
        'actionRegistries': !exists(json, 'actionRegistries') ? undefined : ((json['actionRegistries'] as Array<any>).map(MediaDTOFromJSON)),
        'agents': !exists(json, 'agents') ? undefined : ((json['agents'] as Array<any>).map(AgentParticipationDTOFromJSON)),
        'bestPractices': !exists(json, 'bestPractices') ? undefined : ((json['bestPractices'] as Array<any>).map(MediaDTOFromJSON)),
        'buildingComposition': !exists(json, 'buildingComposition') ? undefined : BuildingCompositionDTOFromJSON(json['buildingComposition']),
        'buildingConstructionDescription': !exists(json, 'buildingConstructionDescription') ? undefined : BuildingConstructionDescriptionDTOFromJSON(json['buildingConstructionDescription']),
        'buildingLocationInfo': !exists(json, 'buildingLocationInfo') ? undefined : BuildingLocationInfoDTOFromJSON(json['buildingLocationInfo']),
        'cadastralInfo': !exists(json, 'cadastralInfo') ? undefined : BuildingDTOFromJSON(json['cadastralInfo']),
        'code': !exists(json, 'code') ? undefined : json['code'],
        'energyEfficiencyCertificateDTO': !exists(json, 'energyEfficiencyCertificateDTO') ? undefined : ((json['energyEfficiencyCertificateDTO'] as Array<any>).map(EnergyEfficiencyCertificateDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ieeDocuments': !exists(json, 'ieeDocuments') ? undefined : ((json['ieeDocuments'] as Array<any>).map(MediaDTOFromJSON)),
        'improvements': !exists(json, 'improvements') ? undefined : ((json['improvements'] as Array<any>).map(ImprovementActionDTOFromJSON)),
        'inspections': !exists(json, 'inspections') ? undefined : ((json['inspections'] as Array<any>).map(InspectionDTOFromJSON)),
        'iteDocuments': !exists(json, 'iteDocuments') ? undefined : ((json['iteDocuments'] as Array<any>).map(MediaDTOFromJSON)),
        'legalData': !exists(json, 'legalData') ? undefined : LegalDataDTOFromJSON(json['legalData']),
        'licenses': !exists(json, 'licenses') ? undefined : ((json['licenses'] as Array<any>).map(LicenseDTOFromJSON)),
        'maintenanceContracts': !exists(json, 'maintenanceContracts') ? undefined : ((json['maintenanceContracts'] as Array<any>).map(MediaDTOFromJSON)),
        'maintenancePlanActions': !exists(json, 'maintenancePlanActions') ? undefined : ((json['maintenancePlanActions'] as Array<any>).map(MaintenancePlanActionDTOFromJSON)),
        'otherDocuments': !exists(json, 'otherDocuments') ? undefined : ((json['otherDocuments'] as Array<any>).map(MediaDTOFromJSON)),
        'proceedings': !exists(json, 'proceedings') ? undefined : ((json['proceedings'] as Array<any>).map(ProceedingsDTOFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'technicians': !exists(json, 'technicians') ? undefined : ((json['technicians'] as Array<any>).map(FullTechnicianParticipationDTOFromJSON)),
        'urbanData': !exists(json, 'urbanData') ? undefined : UrbanDataDTOFromJSON(json['urbanData']),
        'usagePlans': !exists(json, 'usagePlans') ? undefined : ((json['usagePlans'] as Array<any>).map(UsagePlanDTOFromJSON)),
    };
}

export function BookDTOToJSON(value?: BookDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibilityEvaluations': value.accessibilityEvaluations === undefined ? undefined : ((value.accessibilityEvaluations as Array<any>).map(AccessibilityEvaluationDTOToJSON)),
        'actionRegistries': value.actionRegistries === undefined ? undefined : ((value.actionRegistries as Array<any>).map(MediaDTOToJSON)),
        'agents': value.agents === undefined ? undefined : ((value.agents as Array<any>).map(AgentParticipationDTOToJSON)),
        'bestPractices': value.bestPractices === undefined ? undefined : ((value.bestPractices as Array<any>).map(MediaDTOToJSON)),
        'buildingComposition': BuildingCompositionDTOToJSON(value.buildingComposition),
        'buildingConstructionDescription': BuildingConstructionDescriptionDTOToJSON(value.buildingConstructionDescription),
        'buildingLocationInfo': BuildingLocationInfoDTOToJSON(value.buildingLocationInfo),
        'cadastralInfo': BuildingDTOToJSON(value.cadastralInfo),
        'code': value.code,
        'energyEfficiencyCertificateDTO': value.energyEfficiencyCertificateDTO === undefined ? undefined : ((value.energyEfficiencyCertificateDTO as Array<any>).map(EnergyEfficiencyCertificateDTOToJSON)),
        'id': value.id,
        'ieeDocuments': value.ieeDocuments === undefined ? undefined : ((value.ieeDocuments as Array<any>).map(MediaDTOToJSON)),
        'improvements': value.improvements === undefined ? undefined : ((value.improvements as Array<any>).map(ImprovementActionDTOToJSON)),
        'inspections': value.inspections === undefined ? undefined : ((value.inspections as Array<any>).map(InspectionDTOToJSON)),
        'iteDocuments': value.iteDocuments === undefined ? undefined : ((value.iteDocuments as Array<any>).map(MediaDTOToJSON)),
        'legalData': LegalDataDTOToJSON(value.legalData),
        'licenses': value.licenses === undefined ? undefined : ((value.licenses as Array<any>).map(LicenseDTOToJSON)),
        'maintenanceContracts': value.maintenanceContracts === undefined ? undefined : ((value.maintenanceContracts as Array<any>).map(MediaDTOToJSON)),
        'maintenancePlanActions': value.maintenancePlanActions === undefined ? undefined : ((value.maintenancePlanActions as Array<any>).map(MaintenancePlanActionDTOToJSON)),
        'otherDocuments': value.otherDocuments === undefined ? undefined : ((value.otherDocuments as Array<any>).map(MediaDTOToJSON)),
        'proceedings': value.proceedings === undefined ? undefined : ((value.proceedings as Array<any>).map(ProceedingsDTOToJSON)),
        'status': value.status,
        'technicians': value.technicians === undefined ? undefined : ((value.technicians as Array<any>).map(FullTechnicianParticipationDTOToJSON)),
        'urbanData': UrbanDataDTOToJSON(value.urbanData),
        'usagePlans': value.usagePlans === undefined ? undefined : ((value.usagePlans as Array<any>).map(UsagePlanDTOToJSON)),
    };
}

