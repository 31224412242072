import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { fontsNewPdf } from "pdf/_common/fonts/fonts";
import { ColorsNewPdf } from "../../../../../../constants";

import { t } from "pdf/documents/book/book-v2/book-helpers"; //TODO: CHECK THIS IMPORT, TRANSLATION FROM BOOK V2
import { PhaseDTOPhaseOrderEnum } from "clients";
import { PhaseWithImprovements } from "../../models/phase-with-improvements";
import { ImprovementIconsRow } from "./improvement-icons-row";
import { ImprovementsTable } from "../improvements-table/improvements-table";

interface PhaseTableProps {
  phaseWithImprovements: PhaseWithImprovements;
  hideDifficultyColumn?: boolean;
  hidePriorityColumn?: boolean;
  showImprovementIconsRow?: boolean;
}

export const PhaseTable: React.FC<PhaseTableProps> = ({
  phaseWithImprovements,
  showImprovementIconsRow = false,
  hideDifficultyColumn,
  hidePriorityColumn,
}) => {
  const { phase, phaseImprovements } = phaseWithImprovements;
  return (
    <View style={{ justifyContent: "center" }}>
      <Text
        style={{
          padding: "7pt",
          fontSize: "15pt",
          fontFamily: fontsNewPdf.RobotoRegular,
          color: ColorsNewPdf.green_text,
        }}
      >
        {phase?.phaseOrder
          ? t("report.block2.improvements.phase.enum." + phase.phaseOrder)
          : t("report.block2.improvements.phase.enum." + PhaseDTOPhaseOrderEnum.NoScheduled)}
      </Text>
      {/* PhaseTableHeader */}
      <View wrap={false} style={{ flexDirection: "row" }}>
        <View
          style={{
            width: "25%",
            fontSize: "8pt",
            height: "55pt",
            fontFamily: fontsNewPdf.RobotoBold,
            backgroundColor: ColorsNewPdf.eec_table_background,
            borderTop: "1pt solid #403d3e",
            borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            padding: 2,
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Text>{t("report.block2.improvements.phase.demand_reduction")}</Text>
          <Text>{(phase?.energyDemandReductionPercentage ?? 0) + "%"}</Text>
        </View>
        <View
          style={{
            width: "25%",
            fontSize: "8pt",
            height: "55pt",
            borderTop: "1pt solid #403d3e",
            borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            fontFamily: fontsNewPdf.RobotoBold,
            backgroundColor: ColorsNewPdf.eec_table_background,
            padding: 2,
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Text>{t("report.block2.improvements.phase.consumption_reduction")}</Text>
          <Text>{(phase?.nonRenewablePrimaryEnergyConsumptionReductionPercentage ?? 0) + "%"}</Text>
        </View>
        <View
          style={{
            width: "25%",
            fontSize: "8pt",
            borderTop: "1pt solid #403d3e",
            borderRight: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            height: "55pt",
            fontFamily: fontsNewPdf.RobotoBold,
            backgroundColor: ColorsNewPdf.eec_table_background,
            padding: 2,
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Text>{t("report.block2.improvements.phase.estimated_Start")}</Text>
          <Text>
            {phase?.startDate
              ? new Date(phase?.startDate ?? "")?.toLocaleString("es-ES", {
                  localeMatcher: "lookup",
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })
              : "- - -"}
          </Text>
        </View>
        <View
          style={{
            width: "25%",
            borderTop: "1pt solid #403d3e",
            borderBottom: `1pt solid ${ColorsNewPdf.phases_table_border}`,
            fontSize: "8pt",
            height: "55pt",
            fontFamily: fontsNewPdf.RobotoBold,
            backgroundColor: ColorsNewPdf.eec_table_background,
            padding: 2,
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Text>{t("report.block2.improvements.phase.estimated_duration")}</Text>
          <Text>
            {phase?.durationInMonths ?? "-"} {(phase?.durationInMonths ?? 0) > 1 ? "meses" : "mes"}
          </Text>
        </View>
      </View>
      {/* PhaseTableBody */}
      <View style={{ minHeight: 20 }}>{showImprovementIconsRow && <ImprovementIconsRow improvements={phaseImprovements} />}</View>
      <ImprovementsTable improvements={phaseImprovements} hideDifficultyColumn={hideDifficultyColumn} hidePriorityColumn={hidePriorityColumn} />
    </View>
  );
};
