/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MailConfigDTO
 */
export interface MailConfigDTO {
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    apiKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailConfigDTO
     */
    _default?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailConfigDTO
     */
    mailPropertiesMailSmtpAuth?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailConfigDTO
     */
    mailPropertiesMailSmtpStarttlsEnable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    noReplyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    noReplyName?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    port?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    replyEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    replyName?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    resourcesPath?: string;
    /**
     * 
     * @type {string}
     * @memberof MailConfigDTO
     */
    sender?: string;
}

export function MailConfigDTOFromJSON(json: any): MailConfigDTO {
    return MailConfigDTOFromJSONTyped(json, false);
}

export function MailConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'host': !exists(json, 'host') ? undefined : json['host'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mailPropertiesMailSmtpAuth': !exists(json, 'mailPropertiesMailSmtpAuth') ? undefined : json['mailPropertiesMailSmtpAuth'],
        'mailPropertiesMailSmtpStarttlsEnable': !exists(json, 'mailPropertiesMailSmtpStarttlsEnable') ? undefined : json['mailPropertiesMailSmtpStarttlsEnable'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'noReplyEmail': !exists(json, 'noReplyEmail') ? undefined : json['noReplyEmail'],
        'noReplyName': !exists(json, 'noReplyName') ? undefined : json['noReplyName'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'port': !exists(json, 'port') ? undefined : json['port'],
        'replyEmail': !exists(json, 'replyEmail') ? undefined : json['replyEmail'],
        'replyName': !exists(json, 'replyName') ? undefined : json['replyName'],
        'resourcesPath': !exists(json, 'resourcesPath') ? undefined : json['resourcesPath'],
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
    };
}

export function MailConfigDTOToJSON(value?: MailConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiKey': value.apiKey,
        'default': value._default,
        'host': value.host,
        'id': value.id,
        'mailPropertiesMailSmtpAuth': value.mailPropertiesMailSmtpAuth,
        'mailPropertiesMailSmtpStarttlsEnable': value.mailPropertiesMailSmtpStarttlsEnable,
        'name': value.name,
        'noReplyEmail': value.noReplyEmail,
        'noReplyName': value.noReplyName,
        'password': value.password,
        'port': value.port,
        'replyEmail': value.replyEmail,
        'replyName': value.replyName,
        'resourcesPath': value.resourcesPath,
        'sender': value.sender,
    };
}

