import { DocumentPage } from "pdf/documents/book/resume/components";
import React from "react";
import { PhaseWithImprovements } from "pdf/documents/book/_common/models/phase-with-improvements";
import { PhaseView } from "../components/phase-view/phase-view";

interface PhasePageProps {
  phaseWithImprovements: PhaseWithImprovements;
}

export const PhasePage: React.FC<PhasePageProps> = ({phaseWithImprovements}) => {
  return (
    <DocumentPage>
      <PhaseView phaseWithImprovements={phaseWithImprovements}/>
    </DocumentPage>
  );
};
