import { View, Text } from "@react-pdf/renderer";
import React from "react";
import styles from "../../styles/resume-styles";
export const PageFooter: React.FC = () => {
  return (
    <View
      style={styles.footer}
      fixed
      render={({ pageNumber }) => {
        const align = pageNumber % 2 === 0 ? "flex-start" : "flex-end";
        return (
          <div style={{...styles.pageNumberContainer, alignSelf:align}}>
            <Text style={styles.pageNumber} >{`${pageNumber}`}</Text>
          </div>
        );
      }}
    />
  );
};
