/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NewAddressDTO,
    NewAddressDTOFromJSON,
    NewAddressDTOFromJSONTyped,
    NewAddressDTOToJSON,
    NewUsageDTO,
    NewUsageDTOFromJSON,
    NewUsageDTOFromJSONTyped,
    NewUsageDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewBuildingIdentificationDTO
 */
export interface NewBuildingIdentificationDTO {
    /**
     * 
     * @type {NewAddressDTO}
     * @memberof NewBuildingIdentificationDTO
     */
    address?: NewAddressDTO;
    /**
     * 
     * @type {string}
     * @memberof NewBuildingIdentificationDTO
     */
    buildingType?: string;
    /**
     * 
     * @type {string}
     * @memberof NewBuildingIdentificationDTO
     */
    cadastralReference?: string;
    /**
     * 
     * @type {string}
     * @memberof NewBuildingIdentificationDTO
     */
    commonSpacesWithOthers?: string;
    /**
     * 
     * @type {string}
     * @memberof NewBuildingIdentificationDTO
     */
    community?: string;
    /**
     * 
     * @type {Array<NewUsageDTO>}
     * @memberof NewBuildingIdentificationDTO
     */
    generalDataReferences?: Array<NewUsageDTO>;
    /**
     * 
     * @type {string}
     * @memberof NewBuildingIdentificationDTO
     */
    referenceNotes?: string;
}

export function NewBuildingIdentificationDTOFromJSON(json: any): NewBuildingIdentificationDTO {
    return NewBuildingIdentificationDTOFromJSONTyped(json, false);
}

export function NewBuildingIdentificationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewBuildingIdentificationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : NewAddressDTOFromJSON(json['address']),
        'buildingType': !exists(json, 'buildingType') ? undefined : json['buildingType'],
        'cadastralReference': !exists(json, 'cadastralReference') ? undefined : json['cadastralReference'],
        'commonSpacesWithOthers': !exists(json, 'commonSpacesWithOthers') ? undefined : json['commonSpacesWithOthers'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'generalDataReferences': !exists(json, 'generalDataReferences') ? undefined : ((json['generalDataReferences'] as Array<any>).map(NewUsageDTOFromJSON)),
        'referenceNotes': !exists(json, 'referenceNotes') ? undefined : json['referenceNotes'],
    };
}

export function NewBuildingIdentificationDTOToJSON(value?: NewBuildingIdentificationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': NewAddressDTOToJSON(value.address),
        'buildingType': value.buildingType,
        'cadastralReference': value.cadastralReference,
        'commonSpacesWithOthers': value.commonSpacesWithOthers,
        'community': value.community,
        'generalDataReferences': value.generalDataReferences === undefined ? undefined : ((value.generalDataReferences as Array<any>).map(NewUsageDTOToJSON)),
        'referenceNotes': value.referenceNotes,
    };
}

