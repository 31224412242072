import { DocumentPage, SectionTitle, Paragraph } from "pdf/documents/book/resume/components";
import React from "react";
import { PhaseWithImprovements } from "pdf/documents/book/_common/models/phase-with-improvements";
import { PhaseView } from "../components/phase-view/phase-view";

interface PassportDescriptionPageProps {
   phaseWithImprovments?: PhaseWithImprovements;
}

export const PassportDescriptionPage: React.FC<PassportDescriptionPageProps> = ({phaseWithImprovments}) => {
  return (
    <DocumentPage>
      <SectionTitle textKey="resume.section.3.title" />
      <Paragraph textKey="resume.section.3.description"/>
      {phaseWithImprovments && <PhaseView phaseWithImprovements={phaseWithImprovments} />}
    </DocumentPage>
  );
};
