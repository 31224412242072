import { computed, observable } from "mobx"
import i18n from "../infrastructure/localization/i18n"

export default class ConfigurationService {
  @observable languageValue: string = "es"

  changeLanguage(language: string) {
    this.languageValue = language
    return i18n.changeLanguage(language)
  }

  translate(key: string) {
    try {
      return i18n.t(key)
    } catch (e) {
      return key
    }
  }

  @computed get language() {
    return this.languageValue
  }
}