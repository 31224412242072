import { StyleSheet } from "@react-pdf/renderer";
import { ColorsNewPdf } from "../../../../../../constants";
import { fontsNewPdf } from "../../../../../_common/fonts/fonts";

export const energyEfficiencyStyles = StyleSheet.create({
  descriptionText: {
    marginTop: "10pt",
    fontSize: "8pt",
    fontFamily: fontsNewPdf.RobotoRegular,
  },
  tableGlobalIndicator: {
    width: "95%",
    marginHorizontal: "auto",
    marginTop: "10pt",
    height: "120pt",
    marginBottom: "20pt",
  },
  tableHeader: {
    backgroundColor: ColorsNewPdf.dark_blue_text_background_lines,
    height: "24pt",
  },
  titleHeaderText: {
    textAlign: "center",
    padding: "5pt 0 5pt 0",
    color: ColorsNewPdf.white,
  },
  subTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor: ColorsNewPdf.background_containers_block1,
    padding: "7pt 0 7pt 0",
    height: "32pt",
  },
  globalEmissionsHeatContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "7pt 0 7pt 0",
    border: `1pt solid ${ColorsNewPdf.background_containers_block1}`,
    width: "45%",
    textAlign: "center",
  },
  dataEmissionsHeat: {
    padding: "2pt 0 2pt 0",
    margin: "5pt 0 5pt 0",
  },
  titleGlobal: {
    fontFamily: fontsNewPdf.RobotoBold,
    fontSize: "9pt",
  },
  dataGlobal: {
    fontFamily: fontsNewPdf.RobotoRegular,
    fontSize: "9pt",
  },
  dataEmissionsLetter: {
    height: "100%",
    border: `1pt solid ${ColorsNewPdf.background_containers_block1}`,
    width: "5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bold_tableHeaderBuildingEfficiency: {
    backgroundColor: ColorsNewPdf.dark_blue_text_background_lines,
    color: ColorsNewPdf.white,
    fontSize: "8pt",
    height: "30pt",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRight: `1pt solid ${ColorsNewPdf.white}`,
  },
  columnTable: {
    color: ColorsNewPdf.white,
    fontSize: "8pt",
    fontFamily: fontsNewPdf.RobotoBold,
    height: "18pt",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
  },
  headerRow: {
    fontSize: "10pt",
    height: "18pt",
    justifyContent: "center",
    alignItems: "center",
    borderRight: `1pt solid ${ColorsNewPdf.blue_text_background}`,
  },
});
