/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NoiseImprovementPrintedDTO
 */
export interface NoiseImprovementPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementPrintedDTO
     */
    noiseOnTheDay?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof NoiseImprovementPrintedDTO
     */
    noiseOnTheDayMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementPrintedDTO
     */
    noisesFromFacilities?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof NoiseImprovementPrintedDTO
     */
    noisesFromFacilitiesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementPrintedDTO
     */
    noisesFromOtherBuildings?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof NoiseImprovementPrintedDTO
     */
    noisesFromOtherBuildingsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementPrintedDTO
     */
    noisesFromOtherVehicles?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof NoiseImprovementPrintedDTO
     */
    noisesFromOtherVehiclesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementPrintedDTO
     */
    otherNoises?: string;
    /**
     * 
     * @type {string}
     * @memberof NoiseImprovementPrintedDTO
     */
    otherNoisesFromOutside?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof NoiseImprovementPrintedDTO
     */
    otherNoisesFromOutsideMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof NoiseImprovementPrintedDTO
     */
    otherNoisesMedia?: MediaPrintedDTO;
}

export function NoiseImprovementPrintedDTOFromJSON(json: any): NoiseImprovementPrintedDTO {
    return NoiseImprovementPrintedDTOFromJSONTyped(json, false);
}

export function NoiseImprovementPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoiseImprovementPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noiseOnTheDay': !exists(json, 'noiseOnTheDay') ? undefined : json['noiseOnTheDay'],
        'noiseOnTheDayMedia': !exists(json, 'noiseOnTheDayMedia') ? undefined : MediaPrintedDTOFromJSON(json['noiseOnTheDayMedia']),
        'noisesFromFacilities': !exists(json, 'noisesFromFacilities') ? undefined : json['noisesFromFacilities'],
        'noisesFromFacilitiesMedia': !exists(json, 'noisesFromFacilitiesMedia') ? undefined : MediaPrintedDTOFromJSON(json['noisesFromFacilitiesMedia']),
        'noisesFromOtherBuildings': !exists(json, 'noisesFromOtherBuildings') ? undefined : json['noisesFromOtherBuildings'],
        'noisesFromOtherBuildingsMedia': !exists(json, 'noisesFromOtherBuildingsMedia') ? undefined : MediaPrintedDTOFromJSON(json['noisesFromOtherBuildingsMedia']),
        'noisesFromOtherVehicles': !exists(json, 'noisesFromOtherVehicles') ? undefined : json['noisesFromOtherVehicles'],
        'noisesFromOtherVehiclesMedia': !exists(json, 'noisesFromOtherVehiclesMedia') ? undefined : MediaPrintedDTOFromJSON(json['noisesFromOtherVehiclesMedia']),
        'otherNoises': !exists(json, 'otherNoises') ? undefined : json['otherNoises'],
        'otherNoisesFromOutside': !exists(json, 'otherNoisesFromOutside') ? undefined : json['otherNoisesFromOutside'],
        'otherNoisesFromOutsideMedia': !exists(json, 'otherNoisesFromOutsideMedia') ? undefined : MediaPrintedDTOFromJSON(json['otherNoisesFromOutsideMedia']),
        'otherNoisesMedia': !exists(json, 'otherNoisesMedia') ? undefined : MediaPrintedDTOFromJSON(json['otherNoisesMedia']),
    };
}

export function NoiseImprovementPrintedDTOToJSON(value?: NoiseImprovementPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'noiseOnTheDay': value.noiseOnTheDay,
        'noiseOnTheDayMedia': MediaPrintedDTOToJSON(value.noiseOnTheDayMedia),
        'noisesFromFacilities': value.noisesFromFacilities,
        'noisesFromFacilitiesMedia': MediaPrintedDTOToJSON(value.noisesFromFacilitiesMedia),
        'noisesFromOtherBuildings': value.noisesFromOtherBuildings,
        'noisesFromOtherBuildingsMedia': MediaPrintedDTOToJSON(value.noisesFromOtherBuildingsMedia),
        'noisesFromOtherVehicles': value.noisesFromOtherVehicles,
        'noisesFromOtherVehiclesMedia': MediaPrintedDTOToJSON(value.noisesFromOtherVehiclesMedia),
        'otherNoises': value.otherNoises,
        'otherNoisesFromOutside': value.otherNoisesFromOutside,
        'otherNoisesFromOutsideMedia': MediaPrintedDTOToJSON(value.otherNoisesFromOutsideMedia),
        'otherNoisesMedia': MediaPrintedDTOToJSON(value.otherNoisesMedia),
    };
}

