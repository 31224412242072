import React from "react";
import { DocumentPage } from "../../components/document-page/document-page";
import { SectionTitle, Paragraph } from "../../components";
import { EnergyEfficiencyCertificatePrintedDTO } from "clients";
import EnergyEfficiencyView from "pdf/documents/book/_common/components/energy-eficiency-view/energy-efficiency-view";

interface EnergyFinalAssessmentProps {
  lastEnergyEfficiencyCertificate?: EnergyEfficiencyCertificatePrintedDTO;
}

export const EnergyFinalAssessment: React.FC<EnergyFinalAssessmentProps> = ({ lastEnergyEfficiencyCertificate }) => {
  const isZeroEmissionsBuilding = false;
  return (
    <DocumentPage>
      <SectionTitle textKey="resume.section.4.title" />
      {isZeroEmissionsBuilding && <SectionTitle textKey="resume.section.4.subtitle" />}
      <Paragraph textKey="resume.section.4.description" />
      <EnergyEfficiencyView energyCertificate={lastEnergyEfficiencyCertificate} />
    </DocumentPage>
  );
};
