/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtendedMediaPrintedDTO
 */
export interface ExtendedMediaPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof ExtendedMediaPrintedDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedMediaPrintedDTO
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedMediaPrintedDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ExtendedMediaPrintedDTO
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtendedMediaPrintedDTO
     */
    mediaType?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedMediaPrintedDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedMediaPrintedDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedMediaPrintedDTO
     */
    url?: string;
}

export function ExtendedMediaPrintedDTOFromJSON(json: any): ExtendedMediaPrintedDTO {
    return ExtendedMediaPrintedDTOFromJSONTyped(json, false);
}

export function ExtendedMediaPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendedMediaPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'mediaType': !exists(json, 'mediaType') ? undefined : json['mediaType'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function ExtendedMediaPrintedDTOToJSON(value?: ExtendedMediaPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'extension': value.extension,
        'id': value.id,
        'length': value.length,
        'mediaType': value.mediaType,
        'name': value.name,
        'notes': value.notes,
        'url': value.url,
    };
}

