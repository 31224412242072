/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookFilterDTO
 */
export interface BookFilterDTO {
    /**
     * 
     * @type {string}
     * @memberof BookFilterDTO
     */
    code?: string;
    /**
     * 
     * @type {Date}
     * @memberof BookFilterDTO
     */
    finalDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BookFilterDTO
     */
    initialDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof BookFilterDTO
     */
    postalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof BookFilterDTO
     */
    technician?: string;
    /**
     * 
     * @type {string}
     * @memberof BookFilterDTO
     */
    town?: string;
}

export function BookFilterDTOFromJSON(json: any): BookFilterDTO {
    return BookFilterDTOFromJSONTyped(json, false);
}

export function BookFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'finalDate': !exists(json, 'finalDate') ? undefined : (new Date(json['finalDate'])),
        'initialDate': !exists(json, 'initialDate') ? undefined : (new Date(json['initialDate'])),
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'technician': !exists(json, 'technician') ? undefined : json['technician'],
        'town': !exists(json, 'town') ? undefined : json['town'],
    };
}

export function BookFilterDTOToJSON(value?: BookFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'finalDate': value.finalDate === undefined ? undefined : (value.finalDate.toISOString()),
        'initialDate': value.initialDate === undefined ? undefined : (value.initialDate.toISOString()),
        'postalCode': value.postalCode,
        'technician': value.technician,
        'town': value.town,
    };
}

