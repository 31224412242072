import React from "react";
import { SectionTitle, DocumentPage, Paragraph } from "../../components";
import EnergyEfficiencyView from "pdf/documents/book/_common/components/energy-eficiency-view/energy-efficiency-view";
import { EnergyEfficiencyCertificatePrintedDTO } from "clients";

interface EnergyInitialAssessmentProps {
  initialEnergyEfficiencyCertificate?: EnergyEfficiencyCertificatePrintedDTO;
}

export const EnergyInitialAssessment: React.FC<EnergyInitialAssessmentProps> = ({ initialEnergyEfficiencyCertificate }) => {
  return (
      <DocumentPage>
        <SectionTitle textKey="resume.section.2.title" />
        <Paragraph textKey="resume.section.2.description" />
        <EnergyEfficiencyView energyCertificate={initialEnergyEfficiencyCertificate} />
      </DocumentPage>
  );
};
