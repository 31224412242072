/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (prod)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FireSafetyImprovementPrintedDTO
 */
export interface FireSafetyImprovementPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    fireProtections?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    fireProtectionsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    fireReactionWithMaterialsOnTheCommonPlaces?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    fireReactionWithMaterialsOnTheCommonPlacesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    fireReactionWithMaterialsOnTheFacade?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    fireReactionWithMaterialsOnTheFacadeMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    partitioningOfSpecialRiskLocations?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    partitioningOfSpecialRiskLocationsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    stairsProtection?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof FireSafetyImprovementPrintedDTO
     */
    stairsProtectionMedia?: MediaPrintedDTO;
}

export function FireSafetyImprovementPrintedDTOFromJSON(json: any): FireSafetyImprovementPrintedDTO {
    return FireSafetyImprovementPrintedDTOFromJSONTyped(json, false);
}

export function FireSafetyImprovementPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FireSafetyImprovementPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fireProtections': !exists(json, 'fireProtections') ? undefined : json['fireProtections'],
        'fireProtectionsMedia': !exists(json, 'fireProtectionsMedia') ? undefined : MediaPrintedDTOFromJSON(json['fireProtectionsMedia']),
        'fireReactionWithMaterialsOnTheCommonPlaces': !exists(json, 'fireReactionWithMaterialsOnTheCommonPlaces') ? undefined : json['fireReactionWithMaterialsOnTheCommonPlaces'],
        'fireReactionWithMaterialsOnTheCommonPlacesMedia': !exists(json, 'fireReactionWithMaterialsOnTheCommonPlacesMedia') ? undefined : MediaPrintedDTOFromJSON(json['fireReactionWithMaterialsOnTheCommonPlacesMedia']),
        'fireReactionWithMaterialsOnTheFacade': !exists(json, 'fireReactionWithMaterialsOnTheFacade') ? undefined : json['fireReactionWithMaterialsOnTheFacade'],
        'fireReactionWithMaterialsOnTheFacadeMedia': !exists(json, 'fireReactionWithMaterialsOnTheFacadeMedia') ? undefined : MediaPrintedDTOFromJSON(json['fireReactionWithMaterialsOnTheFacadeMedia']),
        'partitioningOfSpecialRiskLocations': !exists(json, 'partitioningOfSpecialRiskLocations') ? undefined : json['partitioningOfSpecialRiskLocations'],
        'partitioningOfSpecialRiskLocationsMedia': !exists(json, 'partitioningOfSpecialRiskLocationsMedia') ? undefined : MediaPrintedDTOFromJSON(json['partitioningOfSpecialRiskLocationsMedia']),
        'stairsProtection': !exists(json, 'stairsProtection') ? undefined : json['stairsProtection'],
        'stairsProtectionMedia': !exists(json, 'stairsProtectionMedia') ? undefined : MediaPrintedDTOFromJSON(json['stairsProtectionMedia']),
    };
}

export function FireSafetyImprovementPrintedDTOToJSON(value?: FireSafetyImprovementPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fireProtections': value.fireProtections,
        'fireProtectionsMedia': MediaPrintedDTOToJSON(value.fireProtectionsMedia),
        'fireReactionWithMaterialsOnTheCommonPlaces': value.fireReactionWithMaterialsOnTheCommonPlaces,
        'fireReactionWithMaterialsOnTheCommonPlacesMedia': MediaPrintedDTOToJSON(value.fireReactionWithMaterialsOnTheCommonPlacesMedia),
        'fireReactionWithMaterialsOnTheFacade': value.fireReactionWithMaterialsOnTheFacade,
        'fireReactionWithMaterialsOnTheFacadeMedia': MediaPrintedDTOToJSON(value.fireReactionWithMaterialsOnTheFacadeMedia),
        'partitioningOfSpecialRiskLocations': value.partitioningOfSpecialRiskLocations,
        'partitioningOfSpecialRiskLocationsMedia': MediaPrintedDTOToJSON(value.partitioningOfSpecialRiskLocationsMedia),
        'stairsProtection': value.stairsProtection,
        'stairsProtectionMedia': MediaPrintedDTOToJSON(value.stairsProtectionMedia),
    };
}

